/* eslint-disable indent */
import axios from 'axios';
import { useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { Button } from '../../../components/ui/button';
import {
    Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import Config from '../../../config';
import ProfilePicture from './profile-picture';

export default function ConvertProfileModal({ open, onOpenChange, next = () => { } }) {
    const [user, setUser] = useGlobal('user');

    const [isSavingProfile, setIsSavingProfile] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsSavingProfile(true);
            const { firstName, lastName, username } = e.target;
            const data = {
                firstName: firstName.value,
                lastName: lastName.value,
                email: user.email,
                phone: user.phone,
                location: user.location,
                company: user.company,
                tagLine: user.tagLine,
                ...((username.value !== user.username) ? { username: username.value } : {}),
            };
            const { data: res } = await axios({
                method: 'post',
                url: `${Config.url || ''}/api/v1/user/update_info`,
                data,
            });
            // console.log(res);
            if (res.success) {
                toast.success('Profile updated successfully.', {
                    position: 'top-center',
                });
                const { data: user } = await axios({
                    method: 'get',
                    url: `${Config.url || ''}/api/v1/user/me`,
                });
                setUser(user.data);
                next();
                onOpenChange(false);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsSavingProfile(false);
        }
    };
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        {/* <DialogTrigger asChild /> */}
        <DialogContent className="relative overflow-hidden">
          <DialogHeader>
            <DialogTitle className="text-[#101828] text-lg font-semibold font-inter leading-7">
              Convert Your Profile to a Team Account
            </DialogTitle>
            <DialogDescription className="hidden" />
          </DialogHeader>
          <div className="space-y-6">
            <hr className="opacity-50" />
            <ProfilePicture />
            <hr className="opacity-50" />
          </div>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div>
                <label htmlFor="name" className="text-slate-700 text-sm font-semibold leading-tight">
                  Name
                </label>
              </div>
              <div className="lg:col-span-2 col-span-3">
                <div className="grid grid-cols-2 gap-4">
                  <Input id="name" name="firstName" placeholder="First Name" defaultValue={user?.firstName} />
                  <Input placeholder="Last Name" name="lastName" defaultValue={user?.lastName} />
                </div>
              </div>
            </div>
            <hr className="opacity-50" />
            <div className="space-y-4">
              <div>
                <label htmlFor="username" className="text-slate-700 text-sm font-semibold leading-tight">
                  User ID
                </label>
              </div>
              <div className="lg:col-span-2 col-span-3">
                <Input placeholder="Username" type="text" id="username" className="w-full" defaultValue={user?.username} name="username" />
              </div>
            </div>
            <hr />
            <div className="flex gap-4">
              <Button
                type="button"
                onClick={() => {
                onOpenChange(false);
                next();
              }}
                variant="secondary"
                className="border shadow bg-white"
              >
                Skip
              </Button>
              <Button type="submit">
                Continue
                {' '}
                {
                                isSavingProfile && <span className="flex size-3 border-2 border-white border-t-transparent ml-1 animate-spin rounded-full" />
                            }
              </Button>
            </div>
          </form>

        </DialogContent>
      </Dialog>
    );
}
