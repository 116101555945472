/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import TimezoneSelector from 'pages/Dashboard/components/timezone-slector';
import { Button } from '../../../components/ui/button';
import { Checkbox } from '../../../components/ui/checkbox';
import {
    DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import { Label } from '../../../components/ui/label';
import {
    Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,
} from '../../../components/ui/select-schedule';
import { Switch } from '../../../components/ui/switch';
import Config from '../../../config';
import { convertTimeStringToMinutes, convertTo24Hour } from '../../../lib/utils';

function generateTimeIntervals() {
    const intervals = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const hour12 = (hour % 12) || 12; // Convert to 12-hour format
            const hourStr = String(hour12).padStart(2, '0');
            const minuteStr = String(minute).padStart(2, '0');
            const period = hour < 12 ? 'am' : 'pm';
            intervals.push(`${hourStr}:${minuteStr} ${period}`);
        }
    }
    return intervals;
}

const initialDaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => ({
    day,
    isActive: true,
    startTime: '00:00',
    endTime: '00:00',
}));

export const isPastDate = (date) => {
    const today = new Date();
    const selectedDate = new Date(date);
    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);
    return selectedDate < today;
};

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const timeFormat = ['12h', '24h'];

function formatTime(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0
        ? `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`
        : `${minutes} min${minutes > 1 ? 's' : ''}`;
}

export default function UpdateAvailability({
    isLoading, setIsLoading, setStep,
}) {
    const [user] = useGlobal('user');
    const [daysOfWeek, setDaysOfWeek] = useState(initialDaysOfWeek);
    const [copyDaysOfWeekIndex, setCopyDaysOfWeekIndex] = useState([]);
    const [slot, setSlot] = useState(15);
    const timeIntervals = useMemo(() => generateTimeIntervals(), []);

    const initialTimeZone = user?.schedule?.timeZone;
    const [selectedTimeZone, setSelectedTimezone] = useState(initialTimeZone);

    const handleCopyDaysOfWeek = useCallback((selectedDay) => {
        const updatedDaysOfWeek = daysOfWeek.map((day, index) => (
            copyDaysOfWeekIndex.includes(index)
                ? { ...selectedDay, day: day.day }
                : day
        ));

        setDaysOfWeek(updatedDaysOfWeek);
        // handleUpdateSchedule();
    }, [copyDaysOfWeekIndex, daysOfWeek]);

    const updateDayOfWeek = useCallback((index, updates) => {
        setDaysOfWeek((prev) => prev.map((day, i) => (i === index ? { ...day, ...updates } : day)));
    }, []);

    const fetchSchedule = useCallback(async () => {
        try {
            const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_schedule`);
            const schedule = data?.data?.schedule;
            if (schedule) {
                const timeSlot = convertTimeStringToMinutes(schedule.timeSlot);
                const updatedDaysOfWeek = initialDaysOfWeek.map((item) => {
                    const weekday = schedule.availableWeekdays.find((w) => w.day === item.day);
                    return weekday || item;
                });

                setSlot(timeSlot);
                setDaysOfWeek(updatedDaysOfWeek);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        fetchSchedule();
    }, [fetchSchedule]);

    const handleUpdateSchedule = useCallback(async () => {
        setIsLoading(true);
        try {
            await axios.post(
                `${Config.url || ''}/api/v1/user/back_office/save_schedule`,
                {
                    availableWeekdays: daysOfWeek,
                    timeZone: selectedTimeZone.split(' ')[0],
                    timeSlot: formatTime(slot),
                    shortDescription: '',
                },
            );
            setStep((prev) => prev + 1);
            toast.success('Schedule updated successfully', {
                position: 'top-right',
            });
        } catch (err) {
            toast.error('Something went wrong', {
                position: 'top-center',
                description: 'Something went wrong',
            });
        } finally {
            setIsLoading(false);
        }
    }, [daysOfWeek, slot, selectedTimeZone]);

    return (
      <div className="login-inner lg:w-[550px] uk-padding-small space-y-6">
        <div className="mb-4 space-y-3 w-full">
          <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Slot</h4>
          <div className="flex justify-start w-1/2">
            <div className="bg-[#f2f4f7] px-4 py-3 w-full rounded-2xl">
              <div className="flex justify-between items-center gap-7">
                <div className="flex items-center gap-2">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="clock">
                      <path id="Icon" d="M12.5 6.24609V12.2461L16.5 14.2461M22.5 12.2461C22.5 17.7689 18.0228 22.2461 12.5 22.2461C6.97715 22.2461 2.5 17.7689 2.5 12.2461C2.5 6.72325 6.97715 2.24609 12.5 2.24609C18.0228 2.24609 22.5 6.72325 22.5 12.2461Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </svg>
                  <p className="text-black text-sm font-medium leading-normal">
                    {formatTime(slot)}
                  </p>
                </div>

                <div className="flex gap-2.5">
                  <Button onClick={() => setSlot((prev) => prev + 15)} className="bg-white px-2 hover:bg-white shadow border border-[#d0d5dd]">
                    <svg className="size-5" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="plus">
                        <path id="Icon" d="M10.4998 4.41211V16.0788M4.6665 10.2454H16.3332" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                  </Button>
                  <Button
                    onClick={() => setSlot((prev) => (prev > 15 ? prev - 15 : prev))}
                    className="bg-white px-2 hover:bg-white shadow border border-[#d0d5dd]"
                  >
                    <svg className="size-5" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="minus">
                        <path id="Solid" fillRule="evenodd" clipRule="evenodd" d="M3.8335 10.2454C3.8335 9.78521 4.20659 9.41211 4.66683 9.41211H16.3335C16.7937 9.41211 17.1668 9.78521 17.1668 10.2454C17.1668 10.7057 16.7937 11.0788 16.3335 11.0788H4.66683C4.20659 11.0788 3.8335 10.7057 3.8335 10.2454Z" fill="black" />
                      </g>
                    </svg>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h4 className="text-[#101828] text-left w-full text-lg font-semibold font-inter leading-7">Your Availability</h4>
        <div className="space-y-6 bg-[#e9eef6] rounded-[10px] w-full p-4">
          {
                    daysOfWeek.map((item, i) => (
                      <div key={i} className="flex flex-col lg:flex-row lg:items-center justify-between gap-6">
                        {/*  */}
                        <div className="flex items-center space-x-2">
                          <Switch
                            id={item.day}
                            checked={item.isActive}
                            onCheckedChange={(val) => updateDayOfWeek(i, { isActive: val })}
                            className="data-[state=checked]:bg-black data-[state=unchecked]:bg-[#00000020]"
                            thumbClassname="bg-white"
                          />
                          <Label htmlFor={item.day} className="cursor-pointer text-[#344054]">{item.day}</Label>
                        </div>

                        {item.isActive && (
                        <div className="flex items-center gap-4">
                          <Select
                            value={item.startTime}
                            onValueChange={(val) => updateDayOfWeek(i, { startTime: val })}
                          >
                            <SelectTrigger className="w-[100px] px-2 bg-white border border-gray-300 text-[#344054] focus:border-none focus:ring-0 focus:shadow-none">
                              <SelectValue placeholder="Select Time" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {timeIntervals.map((item) => <SelectItem key={item} value={convertTo24Hour(item)}>{item}</SelectItem>)}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                          <span className="text-black text-base font-normal leading-normal">-</span>
                          <Select
                            value={item.endTime}
                            onValueChange={(val) => updateDayOfWeek(i, { endTime: val })}
                          >
                            <SelectTrigger className="w-[100px] px-2 bg-white border border-gray-300 text-[#344054] focus:border-none focus:ring-0 focus:shadow-none">
                              <SelectValue placeholder="Select Time" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {timeIntervals.map((item) => <SelectItem key={item} value={convertTo24Hour(item)}>{item}</SelectItem>)}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                          <span className="text-black text-base font-normal leading-normal">+</span>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <button>
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.8335 10.9134V12.7801C4.8335 13.5268 4.8335 13.9002 4.97882 14.1854C5.10665 14.4363 5.31063 14.6403 5.56151 14.7681C5.84672 14.9134 6.22009 14.9134 6.96683 14.9134H12.0335C12.7802 14.9134 13.1536 14.9134 13.4388 14.7681C13.6897 14.6403 13.8937 14.4363 14.0215 14.1854C14.1668 13.9002 14.1668 13.5268 14.1668 12.7801V7.71341C14.1668 6.96667 14.1668 6.59331 14.0215 6.30809C13.8937 6.05721 13.6897 5.85323 13.4388 5.7254C13.1536 5.58008 12.7802 5.58008 12.0335 5.58008H10.1668M2.96683 10.9134H8.0335C8.78023 10.9134 9.1536 10.9134 9.43882 10.7681C9.6897 10.6403 9.89367 10.4363 10.0215 10.1854C10.1668 9.90018 10.1668 9.52682 10.1668 8.78008V3.71341C10.1668 2.96667 10.1668 2.59331 10.0215 2.30809C9.89367 2.05721 9.6897 1.85323 9.43882 1.7254C9.1536 1.58008 8.78023 1.58008 8.0335 1.58008H2.96683C2.22009 1.58008 1.84672 1.58008 1.56151 1.7254C1.31063 1.85323 1.10665 2.05721 0.978821 2.30809C0.833496 2.59331 0.833496 2.96667 0.833496 3.71341V8.78008C0.833496 9.52682 0.833496 9.90018 0.978821 10.1854C1.10665 10.4363 1.31063 10.6403 1.56151 10.7681C1.84672 10.9134 2.22009 10.9134 2.96683 10.9134Z" stroke="black" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-56 p-3 pt-1">
                              <DropdownMenuLabel className="uppercase text-center">Copy times to</DropdownMenuLabel>
                              <DropdownMenuSeparator />
                              {
                                                daysOfWeek.map((d, i) => (
                                                  <label key={i} htmlFor={d.day + i} className="cursor-pointer py-1 flex items-center justify-between w-full">
                                                    <span>{d.day}</span>
                                                    {
                                                            item.day === d.day
                                                                ? <Checkbox checked disabled className="rounded overflow-hidden" />
                                                                : (
                                                                  <Checkbox
                                                                    id={d.day + i}
                                                                    onCheckedChange={() => {
                                                                            setCopyDaysOfWeekIndex((prev) => {
                                                                                // Use Set to handle duplicates more easily
                                                                                const newSet = new Set(prev);
                                                                                if (!newSet.has(i)) {
                                                                                    newSet.add(i);
                                                                                }
                                                                                return Array.from(newSet);
                                                                            });
                                                                        }}
                                                                    className="rounded overflow-hidden"
                                                                  />
                                                                )
                                                        }
                                                  </label>
                                                ))
                                            }
                              <DropdownMenuItem className="p-0 hover:bg-transparent">
                                <Button className="w-full mt-3" onClick={() => handleCopyDaysOfWeek(item)}>
                                  Apply
                                </Button>
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                            )}
                      </div>
                    ))
                }
        </div>

        <div className="mb-4 w-full space-y-3">
          <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Select your timezone</h4>
          <TimezoneSelector setSelectedTimezone={setSelectedTimezone} selectedTimezone={selectedTimeZone} className="bg-white focus:bg-white rounded-lg px-3.5 py-2.5 border border-gray-200" />
        </div>

        <div className="flex flex-col gap-4 justify-start w-full">
          <Button className="w-1/2" onClick={() => setStep((prev) => prev + 1)} variant="outline">
            Skip
          </Button>
          <Button className="w-1/2" onClick={handleUpdateSchedule} disabled={isLoading}>
            {isLoading ? (
              <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                    ) : (
                      <span>Continue</span>
                    )}
          </Button>
        </div>
      </div>
    );
}
