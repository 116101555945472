import axios from 'axios';
import Config from '../config';

const changePassword = (newPassword, token) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/auth/change-password`,
    data: { newPassword, token },
  });
};

export default changePassword;
