/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'sonner';
import { Button } from '../../../components/ui/button';
import {
  Dialog, DialogContent, DialogDescription,
  DialogHeader, DialogTitle, DialogTrigger,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import Config from '../../../config';

function isValidEmail(email) {
  // Regular expression to match a standard email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export default function ShareMeetingButton({ meetingId }) {
  // const user = useGlobal('user')[0];
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyLoading, setIsCopyLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  const handleCopy = async () => {
    try {
      setIsCopyLoading(true);
      const { data: res } = await axios({
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/generate_meeting_access/${meetingId}`,
        // data,
      });

      const url = `${window.location.origin}/briefs/${meetingId}/${res?.data?._id}`;
      console.log(res?.data, url);
      navigator.clipboard.writeText(url);
      setOpen(false);
      toast.success('Link copied to clipboard', {
        position: 'bottom-right',
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsCopyLoading(false);
    }
  };

  const handleInvite = async () => {
    if (!email || !isValidEmail(email)) return;
    // console.log(email);
    setIsLoading(true);
    try {
      const data = {
        requestedUrlId: meetingId,
        request_description: `Meeting Invitation to ${email}`,
        request_user_email: email,
      };
      const { data: res } = await axios({
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/send_request_access`,
        data,
      });
      setEmail('');
      // const { data: res } = await axios.post(`${Config.url || ''}/api/v1/user/send_request_access`);
      // console.log(res);
      setOpen(false);
      toast.success(`Meeting Invitation sent to ${email}`);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <button
          className="px-3 py-2 bg-white rounded-lg shadow border border-[#d0d5dd] justify-center items-center gap-1 flex text-[#323333] text-sm font-semibold leading-tight"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <path id="share_windows_2" d="M5.83333 13V8C5.83333 7.54167 5.99653 7.14931 6.32292 6.82292C6.64931 6.49653 7.04167 6.33333 7.5 6.33333H14.2917L12.1458 4.1875L13.3333 3L17.5 7.16667L13.3333 11.3333L12.1458 10.1667L14.2917 8H7.5V13H5.83333ZM4.16667 18C3.70833 18 3.31597 17.8368 2.98958 17.5104C2.66319 17.184 2.5 16.7917 2.5 16.3333V3.83333H4.16667V16.3333H14.1667V13H15.8333V16.3333C15.8333 16.7917 15.6701 17.184 15.3438 17.5104C15.0174 17.8368 14.625 18 14.1667 18H4.16667Z" fill="#173300" />
          </svg>

          Share
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            Share Meeting
          </DialogTitle>
          <DialogDescription>
            {/* Separate emails with commas (,) to invite multiple people. */}
            Share this page by entering an email address to send an invite!
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email to invite"
            id="email"
          />

          <div className="flex gap-2 5">
            <Button onClick={handleInvite} className={(!email || !isValidEmail(email)) ? 'cursor-not-allowed' : ''}>
              {
                isLoading && <span className="size-3 border-2 rounded-full border-t-transparent animate-spin mr-2" />
              }
              Invite
            </Button>
            <Button onClick={handleCopy} className="flex gap-2 items-center" variant="secondary">
              {
                isCopyLoading ? <span className="size-3 border-2 rounded-full border-black border-t-transparent animate-spin mr-2" /> : (
                  <svg className="size-4" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="copy-01" clipPath="url(#clip0_11414_26345)">
                      <path id="Icon" d="M4.51978 11.2224C3.82928 11.2224 3.48403 11.2224 3.2117 11.1096C2.84858 10.9592 2.56009 10.6707 2.40968 10.3076C2.29688 10.0353 2.29688 9.69001 2.29688 8.99951V3.96094C2.29688 3.13098 2.29688 2.716 2.4584 2.39899C2.60047 2.12015 2.82718 1.89344 3.10602 1.75136C3.42303 1.58984 3.83801 1.58984 4.66797 1.58984H9.70654C10.397 1.58984 10.7423 1.58984 11.0146 1.70265C11.3777 1.85306 11.6662 2.14155 11.8166 2.50467C11.9294 2.777 11.9294 3.12225 11.9294 3.81274M9.85474 16.4092H14.7451C15.5751 16.4092 15.9901 16.4092 16.3071 16.2477C16.5859 16.1056 16.8126 15.8789 16.9547 15.6C17.1162 15.283 17.1162 14.868 17.1162 14.0381V9.14771C17.1162 8.31774 17.1162 7.90276 16.9547 7.58576C16.8126 7.30692 16.5859 7.08021 16.3071 6.93813C15.9901 6.77661 15.5751 6.77661 14.7451 6.77661H9.85474C9.02478 6.77661 8.6098 6.77661 8.29279 6.93813C8.01395 7.08021 7.78724 7.30692 7.64516 7.58576C7.48364 7.90276 7.48364 8.31774 7.48364 9.14771V14.0381C7.48364 14.868 7.48364 15.283 7.64516 15.6C7.78724 15.8789 8.01395 16.1056 8.29279 16.2477C8.6098 16.4092 9.02478 16.4092 9.85474 16.4092Z" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_11414_26345">
                        <rect width="17.7832" height="17.7832" fill="white" transform="translate(0.814453 0.108398)" />
                      </clipPath>
                    </defs>
                  </svg>
                )
              }
              Copy Link
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
