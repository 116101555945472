/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { Button } from '../../../components/ui/button';
import Config from '../../../config';
import UsageProgressFileSize from '../../Dashboard/components/usage-progress-file';
import ConnectDrive from '../../Dashboard/pages/back-office/components/connect-drive';

const bytes = (gb) => gb * 2 ** 30;

export default function ConnectStorage({ isLoading, setIsLoading, setStep }) {
  const [user] = useGlobal('user');
  const [selectedStorage, setSelectedStorage] = useState(0);
  const [drivesWithStorage, setDrivesWithStorage] = useState([]);
  const [drives, setDrives] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { code, scope } = Object.fromEntries(searchParams.entries());
  const [usageData, setUsageData] = useState(null);

  const getUsageData = useCallback(async () => {
    try {
      const { data: res } = await axios.get(`${Config.url || ''}/api/v1/user/dashboard-usage-monthly`);
      // setDrives(res.data);
      // console.log(res?.stats);
      setUsageData(res?.stats);
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-right',
      });
      console.log(error);
    }
  }, []);

  const getStorage = useCallback(async () => {
    try {
      const requests = drives.map((drive) =>
        axios
          .get(`${Config.url || ''}/api/v1/user/back_office/get_drive_storage_info/${drive?._id}`)
          .then(({ data: { data: storage } }) => ({
            ...drive,
            storage,
          })),
      );

      const data = await Promise.all(requests);
      setDrivesWithStorage(data);
      setSelectedStorage(data[0]?._id);
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-right',
      });
      console.error(error);
    }
  }, [drives]);

  useEffect(() => {
    if (drives.length > 0) {
      getStorage();
    }
  }, [drives]);

  const verifyGoogleDriveCode = useCallback(async () => {
    try {
      const { data: res } = await axios.get(
        `${Config.url || ''}/api/v1/user/back_office/verify_drive_integration?code=${code}&redirect_uri=${window.location.origin}/onboarding`,
      );

      if (res.success) {
        toast.success('Google drive integrated successfully. ', {
          position: 'top-right',
        });
      } else {
        toast.error('Something went wrong', {
          position: 'top-right',
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || 'Something went wrong', {
        position: 'top-right',
      });
      console.log(error.response.data.message);
    } finally {
      if (searchParams.has('code')) {
        searchParams.delete('code');
        searchParams.delete('scope');
        searchParams.delete('authuser');
        searchParams.delete('prompt');
        setSearchParams(searchParams);
      }
    }
  }, [code, searchParams, setSearchParams]);

  useEffect(() => {
    // console.log(code, scope);
    if (code && scope.includes('https://www.googleapis.com/auth/drive.file')) {
      verifyGoogleDriveCode();
    }
  }, [code, scope]);

  useEffect(() => {
    getDrives();
    getUsageData();
  }, [code]);

  const getDrives = useCallback(async () => {
    try {
      const { data: res } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_drives`);
      setDrives(res.data);
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-right',
      });
      console.log(error);
    }
  }, []);

  const handleUpdatePrimaryStorage = async () => {
    if (drivesWithStorage?.length === 0) {
      setStep((prev) => prev + 1);
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${Config.url || ''}/api/v1/user/back_office/make_drive_primary/${selectedStorage === 'sb' ? drivesWithStorage[0]._id : selectedStorage}`,
      );
      setStep((prev) => prev + 1);
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-right',
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-inner lg:w-[450px] uk-padding-small space-y-6">
      <div className="space-y-6 bg-[#e9eef6] rounded-[10px] w-full p-4">
        <div className="mb-4 w-full space-y-6">
          <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Connect Storage</h4>
          <div className="flex flex-col gap-6">
            {drivesWithStorage?.map((drive, i) => (
              <div
                key={i}
                className="flex flex-col gap-3 cursor-pointer"
                onClick={() => setSelectedStorage(drive?._id)}
              >
                <div className="min-w-max pt-1 cursor-default">
                  <div
                    className={`size-5 ${selectedStorage === drive?._id ? 'border-[6px] border-gray-900' : 'border border-gray-400'}  rounded-full`}
                  />
                </div>
                <div className="bg-white py-2.5 px-6 w-full rounded-lg space-y-4">
                  <p className="text-[#667085] text-base font-normal font-inter leading-normal">
                    {drive?.email} <small>(Drive)</small>
                  </p>
                  <UsageProgressFileSize value={drive?.storage?.usedSpace} max={drive?.storage?.totalSpace} unit="MB" />
                </div>
              </div>
            ))}

            <div className="flex flex-col gap-3 cursor-pointer" onClick={() => setSelectedStorage('sb')}>
              <div className="min-w-max pt-1 cursor-default">
                <div
                  className={`size-5 ${selectedStorage === 'sb' ? 'border-[6px] border-gray-900' : 'border border-gray-400'}  rounded-full`}
                />
              </div>
              <div className="bg-white py-2.5 px-6 w-full rounded-lg space-y-4">
                <p className="text-[#667085] text-base font-normal font-inter leading-normal">Say Briefly</p>
                <UsageProgressFileSize
                  value={usageData?.storage || 0}
                  max={bytes(user?.activeSubscription?.totalStorageGbAllocated)}
                  unit="MB"
                />
              </div>
            </div>
          </div>
          <ConnectDrive />
        </div>
      </div>
      <div className="w-full space-y-4">
        <Button className="w-full" onClick={() => setStep((prev) => prev + 1)} variant="outline">
          Skip
        </Button>
        <Button className="w-full" onClick={handleUpdatePrimaryStorage} disabled={isLoading}>
          {isLoading ? (
            <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
          ) : (
            <span>Continue</span>
          )}
        </Button>
      </div>
    </div>
  );
}
