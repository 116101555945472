/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import getWorkspaceMembers from '../../../actions/getWorkspaceMembers';
import { Button } from '../../../components/ui/button';
import {
  Dialog, DialogContent, DialogDescription,
  DialogHeader, DialogTitle, DialogTrigger,
} from '../../../components/ui/dialog';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../../../components/ui/select';
import Config from '../../../config';

export default function InviteTeamButton({ meetingId, meetingDetails }) {
  const user = useGlobal('user')[0];
  const [refreshBrief, setRefreshBrief] = useGlobal('refreshBrief');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);

  // console.log(meetingDetails);

  const getWorkspaceMembersData = async () => {
    try {
      const { data: res } = await getWorkspaceMembers(user?.workspace?._id);
      const members = res.data.filter((member) => member?.user?._id !== user?._id);
      setTeamMembers(members);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWorkspaceMembersData();
  }, []);

  const handleInvite = async () => {
    if (!userId) return;
    // console.log(email);
    setIsLoading(true);
    try {
      // const data = {
      //   requestedUrlId: meetingId,
      //   request_description: `Meeting Invitation to ${userId}`,
      //   u_id: userId,
      // };
      const { data: res } = await axios({
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/add_user_to_meeting/${meetingId}/${userId}`,
      });

      // console.log(res);
      setUserId('');
      setOpen(false);
      const member = teamMembers.find((member) => member?.user?._id === userId);
      toast.success(`Meeting Invitation sent to ${member?.user?.firstName} ${member?.user?.lastName}`);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      setRefreshBrief(!refreshBrief);
    }
  };
  const handleRemove = async (u_id) => {
    if (!u_id) return;
    const concent = window.confirm('Are you sure you want to remove this team member from the meeting?');
    if (!concent) return;
    // console.log(email);
    setIsLoading(true);
    try {
      // const data = {
      //   requestedUrlId: meetingId,
      //   request_description: `Meeting Invitation to ${userId}`,
      //   u_id: userId,
      // };
      const { data: res } = await axios({
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/remove_user_from_meeting/${meetingId}/${u_id}`,
      });

      console.log(res);
      setUserId('');
      setOpen(false);
      toast.success(`Team Member removed from the meeting.`);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      setRefreshBrief(!refreshBrief);
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <button
          className="px-3 py-2 bg-white rounded-lg shadow border border-[#d0d5dd] justify-center items-center gap-1 flex text-[#323333] text-sm font-semibold leading-tight"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <path id="person_add_2" d="M15 12.1667V9.66667H12.5V8H15V5.5H16.6666V8H19.1666V9.66667H16.6666V12.1667H15ZM7.49998 10.5C6.58331 10.5 5.79859 10.1736 5.14581 9.52083C4.49304 8.86805 4.16665 8.08333 4.16665 7.16667C4.16665 6.25 4.49304 5.46528 5.14581 4.8125C5.79859 4.15972 6.58331 3.83333 7.49998 3.83333C8.41665 3.83333 9.20137 4.15972 9.85415 4.8125C10.5069 5.46528 10.8333 6.25 10.8333 7.16667C10.8333 8.08333 10.5069 8.86805 9.85415 9.52083C9.20137 10.1736 8.41665 10.5 7.49998 10.5ZM0.833313 17.1667V14.8333C0.833313 14.3611 0.954841 13.9271 1.1979 13.5312C1.44095 13.1354 1.76387 12.8333 2.16665 12.625C3.02776 12.1944 3.90276 11.8715 4.79165 11.6562C5.68054 11.441 6.58331 11.3333 7.49998 11.3333C8.41665 11.3333 9.31942 11.441 10.2083 11.6562C11.0972 11.8715 11.9722 12.1944 12.8333 12.625C13.2361 12.8333 13.559 13.1354 13.8021 13.5312C14.0451 13.9271 14.1666 14.3611 14.1666 14.8333V17.1667H0.833313ZM2.49998 15.5H12.5V14.8333C12.5 14.6806 12.4618 14.5417 12.3854 14.4167C12.309 14.2917 12.2083 14.1944 12.0833 14.125C11.3333 13.75 10.5764 13.4687 9.81248 13.2812C9.04859 13.0937 8.27776 13 7.49998 13C6.7222 13 5.95137 13.0937 5.18748 13.2812C4.42359 13.4687 3.66665 13.75 2.91665 14.125C2.79165 14.1944 2.69095 14.2917 2.61456 14.4167C2.53817 14.5417 2.49998 14.6806 2.49998 14.8333V15.5ZM7.49998 8.83333C7.95831 8.83333 8.35067 8.67014 8.67706 8.34375C9.00345 8.01736 9.16665 7.625 9.16665 7.16667C9.16665 6.70833 9.00345 6.31597 8.67706 5.98958C8.35067 5.66319 7.95831 5.5 7.49998 5.5C7.04165 5.5 6.64929 5.66319 6.3229 5.98958C5.99651 6.31597 5.83331 6.70833 5.83331 7.16667C5.83331 7.625 5.99651 8.01736 6.3229 8.34375C6.64929 8.67014 7.04165 8.83333 7.49998 8.83333Z" fill="#173300" />
          </svg>

          Invite Team
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            Invite Team Members
          </DialogTitle>
          <DialogDescription>
            Invite your team members to this meeting by entering their email addresses.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <Select
            value={userId}
            onValueChange={setUserId}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select team member" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Team Members</SelectLabel>
                {teamMembers.map((member) => (
                  <SelectItem
                    key={member?.user?._id}
                    value={member?.user?._id}
                  >
                    {member?.user?.firstName} {member?.user?.lastName} ({member?.user?.email})
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>

          <div className="flex gap-2 5">
            <Button onClick={handleInvite} className={(!userId) ? 'cursor-not-allowed' : ''}>
              {
                isLoading && <span className="size-3 border-2 rounded-full border-t-transparent animate-spin mr-2" />
              }
              Invite
            </Button>
          </div>

          {
            meetingDetails?.users?.length > 1 &&
            (<div className="max-h-[200px] bg-white p-3 border rounded space-y-3 overflow-y-auto">
              {
                meetingDetails?.users
                  ?.filter((u) => u?._id !== user?._id)
                  ?.map((user, i) => (<div key={i} className="flex items-center justify-between gap-2 py-2 px-4 bg-gray-50 border rounded-lg">
                    <span className="text-sm font-semibold">{user?.firstName} {user?.lastName}</span>

                    <button
                      onClick={() => handleRemove(user?._id)}
                      className="px-2 py-1 bg-[#f0f0f0] text-[#323333] text-sm font-semibold rounded-lg">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                    </button>
                  </div>))
              }
            </div>)
          }

        </div>
      </DialogContent>
    </Dialog >
  );
}
