/* eslint-disable no-unused-vars */
import axios from 'axios';
import WhiteBtn from 'components/common/WhiteBtn';
import { useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import editWorkspace from '../../../../../actions/editWorkspace';
import getCurrentWorkspace from '../../../../../actions/getCurrentWorkspace';
import { Button } from '../../../../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../../../components/ui/dialog';
import { Input } from '../../../../../components/ui/input';
import { Label } from '../../../../../components/ui/label';
import config from '../../../../../config';

export default function EditUrlModal({ open, setOpen }) {
  const [user, setUser] = useGlobal('user');
  const [workspaceId, setWorkspaceId] = useState(user?.workspace?.brandID);
  const [userId, setUserId] = useState(user?.username);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserIdChanged, setIsUserIdChanged] = useState(false);
  const [isWorkspaceIdChanged, setIsWorkspaceIdChanged] = useState(false);

  const isMyWorkspace = user?.workspace?.createdBy === user?._id;

  const handleSaveChange = async () => {
    try {
      setIsLoading(true);

      // Create an array to store the promises for the API calls
      const promises = [];

      // Check if the username needs to be updated
      if (user?.username !== userId) {
        promises.push(
          axios({
            method: 'post',
            url: `${config.url || ''}/api/v1/user/update_info`,
            data: {
              username: userId,
              email: user?.email,
              firstName: user?.firstName,
              lastName: user?.lastName,
            },
          })
            .then(async (res) => {
              if (res.data.success) {
                const { data: userData } = await axios({
                  method: 'get',
                  url: `${config.url || ''}/api/v1/user/me`,
                });
                setUser(userData?.data);
                setUserId(userData?.data?.username);

                toast.success('URL Updated successfully!', {
                  position: 'top-right',
                });
              }
            })
            .catch((err) => {
              console.error(err);
              toast.error('Failed to save changes.', {
                position: 'top-right',
                description: `${err?.response?.data?.error || 'Something went wrong'} ${err?.response?.data?.message || ''}`,
              });
            }),
        );
      }

      // Check if the workspace needs to be updated
      if (user?.workspace?.brandID !== workspaceId) {
        const formData = new FormData();
        formData.append('brandID', workspaceId);
        formData.append('name', user?.workspace?.name);
        formData.append('description', user?.workspace?.description);

        promises.push(
          editWorkspace(user?.workspace?._id, formData).then(async (res) => {
            if (res.data.success) {
              const { data: workspaceData } = await getCurrentWorkspace();
              setUser({
                ...user,
                workspace: workspaceData?.data?.workspace,
                workspace_member: workspaceData?.data?.workspace_member,
              });
              setWorkspaceId(workspaceData?.data?.workspace?.brandID);
            }
          }),
        );
      }

      // Wait for all promises to resolve
      await Promise.all(promises);

      // Close the modal and show success toast
      setOpen(false);
    } catch (err) {
      console.error(err);
      setOpen(false);
      toast.error('Failed to save changes.', {
        position: 'top-right',
        description:
          'The provided workplace ID or user ID is unavailable or incorrect. Please check the details and try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <WhiteBtn>Edit URL</WhiteBtn>
      </DialogTrigger>
      <DialogContent className="w-[480px]">
        <DialogHeader>
          <DialogTitle>Edit Public URL</DialogTitle>
          <DialogDescription>Make changes here to update your public url.</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex flex-col gap-0">
            <Label htmlFor="workspaceId">URL</Label>
            <div className="mt-3">
              <div className="bg-white border border-[#173300] px-2 py-2 rounded-lg flex justify-start items-center gap-1">
                <svg
                  className="flex-shrink-0"
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0003 1.16699C12.0847 3.44895 13.2693 6.41035 13.3337 9.50033C13.2693 12.5903 12.0847 15.5517 10.0003 17.8337M10.0003 1.16699C7.91593 3.44895 6.73137 6.41035 6.66699 9.50033C6.73137 12.5903 7.91593 15.5517 10.0003 17.8337M10.0003 1.16699C5.39795 1.16699 1.66699 4.89795 1.66699 9.50033C1.66699 14.1027 5.39795 17.8337 10.0003 17.8337M10.0003 1.16699C14.6027 1.16699 18.3337 4.89795 18.3337 9.50033C18.3337 14.1027 14.6027 17.8337 10.0003 17.8337M2.08367 7.00033H17.917M2.08366 12.0003H17.917"
                    stroke="#173300"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div className="text-[#173300] text-sm font-semibold space-x-1">
                  <span>{config.mainUrl.endsWith('/') ? config.mainUrl.slice(0, -1) : config.mainUrl}</span>
                  <span>/</span>
                  <span className="bg-[#17330026] px-1.5 py-0.5 rounded">
                    {user?.workspace?.brandID === workspaceId && !isWorkspaceIdChanged && isMyWorkspace
                      ? 'workspaceid'
                      : workspaceId}
                  </span>
                  <span>/</span>
                  <span className="bg-[#17330026] px-1.5 py-0.5 rounded">
                    {user?.username === userId && !isUserIdChanged ? 'userid' : userId}
                  </span>
                </div>
              </div>
              {/* <div className="flex justify-between items-center">
                <Input
                  type="text"
                  value={`${config.mainUrl}${config.mainUrl.endsWith('/') ? '' : '/'}${workspaceId}/${userId}`}
                  className="w-full outline-none border-none bg-transparent focus:ring-0 focus:outline-none truncate p-0"
                  readOnly
                />

                <button onClick={handleCopy} className="p-3 h-full rounded-lg bg-[#f0f0f0] hover:bg-[#e0e0e0] focus:outline-none focus:ring-2 focus:ring-[#ffeb5b] focus:ring-opacity-50">
                  {
                    copied
                      ? <Check size={16} />
                      : <Copy size={16} />
                  }
                </button>
              </div>
              <small className="text-xs ml-2">
                Your public schedule URL
              </small> */}
            </div>
          </div>
          {isMyWorkspace && (
            <div className="flex flex-col gap-4">
              <Label htmlFor="workspaceId">Workspace Id</Label>
              <Input
                id="workspaceId"
                value={workspaceId}
                onChange={(e) => {
                  const { value } = e.target;
                  setWorkspaceId(value.toLowerCase().replaceAll(' ', '-'));
                  setIsWorkspaceIdChanged(true);
                }}
                className="w-full"
              />
            </div>
          )}

          <div className="flex flex-col gap-4">
            <Label htmlFor="username">User Id</Label>
            <Input
              id="username"
              value={userId}
              onChange={(e) => {
                const { value } = e.target;
                setUserId(value.toLowerCase().replaceAll(' ', ''));
                setIsUserIdChanged(true);
              }}
              className="w-full"
            />
          </div>
        </div>
        <DialogFooter>
          <Button type="button" onClick={handleSaveChange}>
            Save changes
            {isLoading && (
              <span className="size-4 border-2 border-[#ffeb5b] border-t-transparent animate-spin rounded-full ml-2" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
