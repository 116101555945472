/* eslint-disable import/no-unresolved */
/* eslint-disable object-curly-newline */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import addWorkspaceMember from '../../../actions/addWorkspaceMember';
import getUserInfo from '../../../actions/getUserInfo';
import getUserSuggestion from '../../../actions/getUserSuggestion';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../../../components/ui/dialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { cn } from '../../../lib/utils';

function AddTeamMemberButton({ className, setRefresh = () => {}, teamMembers }) {
  const [user, setUser] = useGlobal('user');
  // const [teamSpaces] = useGlobal('teamSpaces');
  const workspaceId = user?.workspace?._id;
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userSuggestion, setUserSuggestion] = useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const addTeam = searchParams.get('addTeam');
  const [error, setError] = useState('');
  const { subMembers = [] } = user?.activeSubscription || {};
  // console.log(subMembers);
  
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getUserSuggestion(email);
        setUserSuggestion(data.data);
      } catch (err) {
        console.log(err);
        setUserSuggestion([]);
      }
    };
    if (email) {
      getData();
    } else {
      setUserSuggestion([]);
    }
  }, [email]);
  useEffect(() => {
    // console.log(addTeam);
    if (addTeam === 'true') {
      setIsOpen(true);
    }
  }, [addTeam]);

  const handleAddTeamMember = async () => {
    if (!email) {
      setError('Please select a member!');
      return;
    }
    const memberData = teamMembers?.some((member) => member?.user?.email === email);
    if (memberData) {
      // console.log(memberData);
      setError('Member already exists in this workspace.');
      return;
    }
    try {
      setIsLoading(true);
      setError('');
      if (email) {
        const { data: res } = await addWorkspaceMember(workspaceId, email);
        console.log(res);
        if (res.success) {
          setEmail('');
          setIsOpen(false);
          setRefresh((prev) => !prev);
          toast.success('Member Invited Successfully!', {
            position: 'top-center',
          });
          const { data: userRes } = await getUserInfo();
          if (userRes?.success) {
            setUser(userRes?.data);
          }
        } else {
          toast.error('Something went wrong!', {
            position: 'top-center',
          });
        }
      } else {
        alert('Please enter a valid email');
      }
    } catch (err) {
      // setEmail('');
      // setIsOpen(false);
      toast.error(err.response.data.error, {
        position: 'top-center',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTriggerModal = (val) => {
    setIsOpen(val);
    if (searchParams.has('addTeam')) {
      searchParams.delete('addTeam');
      setSearchParams(searchParams);
    }
  };

  const handleSelectMember = (val) => {
    setEmail(val);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleTriggerModal}>
      <DialogTrigger>
        <Button variant="outline" className={cn('', className)}>
          <span>Add Team Member</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-max bg-[#e9eef6]" closeButtonClassName="top-2 right-2">
        <DialogTitle className="hidden">Add Team Member</DialogTitle>
        <DialogDescription className="hidden" />

        <div className="space-y-4 flex-grow flex flex-col justify-center">
          {/* <label htmlFor="name" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight">Full name</span>
              <input
                type="text"
                placeholder="Full name"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label> */}
          <label htmlFor="email" className="space-y-2 block relative">
            <span className="text-[#344054] text-sm font-medium font-inter leading-tight">
              Member Email
            </span>
            <Select onValueChange={handleSelectMember}>
                
              <SelectTrigger className="py-3">
                <SelectValue placeholder="Select a subscribed member" />
              </SelectTrigger>
              <SelectContent>
                {
                      subMembers.map((member) => <SelectItem key={member?.user?.email} value={member?.user?.email}>{member?.user?.email}</SelectItem>)
                    }
                {/* <SelectItem value="m@example.com">m@example.com</SelectItem>
                  <SelectItem value="m@google.com">m@google.com</SelectItem>
                  <SelectItem value="m@support.com">m@support.com</SelectItem> */}
              </SelectContent>
            </Select>
            {
                  error && (
                  <small className="text-red-500">
                    {error}
                  </small>
                  ) 
                }
              
          </label>
          <div>
            <Button onClick={handleAddTeamMember}>
              {isLoading ? (
                <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
              ) : (
                'Invite'
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddTeamMemberButton;
