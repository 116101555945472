import axios from 'axios';
import Config from '../config';

const getMeetingsByQuery = (page, limit = 25, filter) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/meetings?date=${new Date().toLocaleDateString('en-CA')}&page=${page || 1}&limit=${limit}${filter ? `&${filter}` : ''}`,
  });
};

export default getMeetingsByQuery;
