/* eslint-disable no-unreachable */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { Button } from '../../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../components/ui/dialog';
import Config from '../../../config';

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export default function BriefApprovalButton({ meetingId, briefId, getBriefDataFunction }) {
  const [user] = useGlobal('user');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userEmails, setUserEmails] = useState([]);
  const [requestsData, setRequestsData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { addToast } = useToasts();

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const { data: res } = await axios.get(`${Config.url || ''}/api/v1/user/get_access_data/${meetingId}`);
        setRequestsData(res.data.filter((request) => request.request_user_email !== user.email));
      } catch (err) {
        console.error('Error fetching requests:', err);
      }
    };

    if (open) fetchRequests();
  }, [open, user.email]);

  const handleRequestApproval = async () => {
    const updatedEmails = userEmails;

    // Validate and set the input email if the userEmails array is empty
    if (updatedEmails.length === 0 && !isValidEmail(inputValue)) return;

    if (isValidEmail(inputValue)) {
      updatedEmails.push(inputValue);
      setUserEmails(updatedEmails);
      setInputValue('');
    }

    setIsLoading(true);

    try {
      // Send approval requests
      await axios.post(`${Config.url}/api/v1/user/request_approval/${briefId}/${meetingId}`, {
        userEmails: updatedEmails,
      });

      // Notify success and reset state
      toast.success(`Approval requests sent to ${updatedEmails.join(', ')}`);
      setOpen(false);
      setUserEmails([]);
      getBriefDataFunction();
    } catch (err) {
      // Handle errors gracefully
      const errorMessage = err.response?.data?.error || 'Failed to send requests';
      addToast(errorMessage, { appearance: 'error', autoDismiss: true });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (['Enter', ',', ' ']?.includes(e.key)) {
      e.preventDefault();
      const email = inputValue.trim();
      if (email && isValidEmail(email) && !userEmails?.includes(email)) {
        setUserEmails([...userEmails, email]);
        setInputValue('');
      } else if (email) {
        addToast('Invalid email address', { appearance: 'error' });
      }
    } else if (e.key === 'Backspace' && inputValue === '') {
      setUserEmails(userEmails.slice(0, -1));
    } else if (e.key === 'Escape') {
      setInputValue('');
    }
  };

  const filteredSuggestions = requestsData.filter(
    (data) =>
      data.request_user_email.toLowerCase().includes(inputValue.toLowerCase()) &&
      !userEmails.includes(data.request_user_email),
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="flex w-full items-center justify-center">Request for approval</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Request for Approval</DialogTitle>
          <DialogDescription>Request a brief approval from your team members.</DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <div className="relative">
            <div className="flex flex-wrap items-start p-2 border border-gray-300 rounded-md bg-white min-h-[100px] overflow-auto">
              {userEmails.map((email, index) => (
                <div
                  key={index}
                  className="flex border items-center bg-gray-100 text-gray-700 px-1.5 pt-0.5 pb-1 mr-2 mb-2 rounded"
                >
                  <span className="leading-none">{email}</span>
                  <span
                    className="ml-2 text-lg cursor-pointer leading-none"
                    onClick={() => setUserEmails(userEmails.filter((e) => e !== email))}
                  >
                    &times;
                  </span>
                </div>
              ))}
              <input
                type="text"
                value={inputValue}
                placeholder="Enter email addresses"
                className="flex-grow p-1 border-none outline-none focus:ring-0 text-sm bg-transparent"
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
              />
            </div>

            {inputValue && filteredSuggestions.length > 0 && (
              <div className="w-full absolute bg-white px-4 py-2 shadow-md rounded-md top-10 z-10 border">
                {filteredSuggestions.map((data) => (
                  <button
                    key={data._id}
                    type="button"
                    className="flex justify-between items-center hover:bg-gray-100 w-full px-2 py-1 rounded-md"
                    onClick={() => {
                      setUserEmails([...userEmails, data.request_user_email]);
                      setInputValue('');
                    }}
                  >
                    <span>{data.request_user_email}</span>
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="flex gap-2.5">
            <Button
              onClick={handleRequestApproval}
              disabled={isLoading || (userEmails.length === 0 && !isValidEmail(inputValue))}
            >
              {isLoading && <span className="size-3 border-2 rounded-full border-t-transparent animate-spin mr-2" />}
              Request
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
