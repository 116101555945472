import axios from 'axios';
import Config from '../config';

const confirmBookingByUser = (data) => {
    return axios({
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/confirm_booking`,
        data,
    });
};

export default confirmBookingByUser;
