/* eslint-disable brace-style */
/* eslint-disable no-unused-vars */
import axios from 'axios';
// eslint-disable-next-line
import { marked } from 'marked';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import AvatarGroup from '../../../components/ui/avatar-group';
import Config from '../../../config';
import { getGravatarUrl } from '../../../lib/utils';
import BriefEditor from '../components/brief-editor';
import BriefTabContents from './back-office/components/brief-tab-contents';
import BriefTabs from './back-office/components/brief-tabs';
import PlayerWithAspectRatio from './back-office/components/player-with-ratio';

const getBriefData = async (meetingId, requestId) => {
  try {
    const { data: res } = await axios.get(`${Config.url || ''}/api/v1/public/meeting_access/${meetingId}/${requestId}`);
    return res;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

function formatTranscriptToHTML(transcript) {
  // Split the transcript into individual messages using regex
  const messages = transcript.match(/user_\[.*?\]_\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z: .*?(?=user_\[|$)/gs);
  // console.log(transcript);
  if (!messages) return ''; // Return empty string if no messages are found

  // Convert each message into HTML
  const formattedMessages = messages.map((message) => {
    const match = message.match(/user_\[(.*?)\]_(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z): (.*)/s);

    if (!match) return ''; // Skip invalid messages

    const username = match[1];
    const timestamp = match[2];
    const content = match[3];

    // Convert timestamp to 12-hour format
    // const formattedTime = new Date(timestamp).toLocaleTimeString('en-US', {
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit',
    //   hour12: true, // Ensures 12-hour format with AM/PM
    // });

    return `
      <div class="mb-5">
        <span class="text-[#101828] text-sm font-normal font-inter leading-tight">[${new Date(timestamp).toLocaleTimeString()}]</span>
        <p class="text-[#475467] text-base font-normal font-roboto-mono leading-normal"><strong class="text-[#101828] text-lg font-medium leading-7 font-inter">${username}:</strong> ${content}</p>
      </div>
    `;
  });

  return formattedMessages.join('');
}

// Configure marked for consistent Markdown parsing
marked.setOptions({
  gfm: true,          // Enable GitHub Flavored Markdown
  breaks: false,      // We'll handle <br/> manually since your desired output doesn't use them
  sanitize: false,    // Allow HTML if present
  smartLists: true    // Better list handling
});

const normalizeMarkdown = (text) => {
  if (!text) return '';
  // Ensure consistent Markdown formatting
  return text
    .replace(/<br\/>/g, '\n')              // Convert <br/> to newlines
    .replace(/###\s*(.+?)\s*<br\/>/g, '### $1\n')  // Fix ### headers
    .replace(/####\s*(.+?)\s*<br\/>/g, '#### $1\n') // Fix #### headers
    .replace(/-\s*(.+?)\s*<br\/>/g, '- $1\n');      // Fix list items
};

const formatHTML = (html) => {
  // Ensure headers and lists match your structure
  return html
    .replace(/<h3>(.*?)<\/h3>/g, '<h3><strong>$1</strong></h3>')
    .replace(/<h4>(.*?)<\/h4>/g, '<h4><strong>$1</strong></h4>')
    .replace(/<p><strong>(.*?)<\/strong><\/p>/g, '<ul><li><strong>$1</strong></li></ul>')
    .replace(/<p>(.*?)<\/p>/g, '<ul><li>$1</li></ul>');
};

const parseToDesiredHTML = (text) => {
  if (!text) return '';
  try {
    const normalized = normalizeMarkdown(text);
    let html = marked(normalized);
    html = formatHTML(html);
    return html;
  } catch (error) {
    console.error('Markdown parsing failed:', error);
    return text; // Fallback to raw text
  }
};

const tabs = [
  {
    name: 'Summary',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path id="description_2" d="M6 13.5H12V12H6V13.5ZM6 10.5H12V9H6V10.5ZM4.5 16.5C4.0875 16.5 3.73438 16.3531 3.44063 16.0594C3.14688 15.7656 3 15.4125 3 15V3C3 2.5875 3.14688 2.23438 3.44063 1.94063C3.73438 1.64688 4.0875 1.5 4.5 1.5H10.5L15 6V15C15 15.4125 14.8531 15.7656 14.5594 16.0594C14.2656 16.3531 13.9125 16.5 13.5 16.5H4.5ZM9.75 6.75V3H4.5V15H13.5V6.75H9.75Z" fill="currentColor" />
      </svg>
    ),
  },
  {
    name: 'Highlights',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path id="format_list_bulleted_2" d="M6.75 14.25V12.75H15.75V14.25H6.75ZM6.75 9.75V8.25H15.75V9.75H6.75ZM6.75 5.25V3.75H15.75V5.25H6.75ZM3.75 15C3.3375 15 2.98438 14.8531 2.69063 14.5594C2.39688 14.2656 2.25 13.9125 2.25 13.5C2.25 13.0875 2.39688 12.7344 2.69063 12.4406C2.98438 12.1469 3.3375 12 3.75 12C4.1625 12 4.51562 12.1469 4.80938 12.4406C5.10313 12.7344 5.25 13.0875 5.25 13.5C5.25 13.9125 5.10313 14.2656 4.80938 14.5594C4.51562 14.8531 4.1625 15 3.75 15ZM3.75 10.5C3.3375 10.5 2.98438 10.3531 2.69063 10.0594C2.39688 9.76563 2.25 9.4125 2.25 9C2.25 8.5875 2.39688 8.23438 2.69063 7.94063C2.98438 7.64687 3.3375 7.5 3.75 7.5C4.1625 7.5 4.51562 7.64687 4.80938 7.94063C5.10313 8.23438 5.25 8.5875 5.25 9C5.25 9.4125 5.10313 9.76563 4.80938 10.0594C4.51562 10.3531 4.1625 10.5 3.75 10.5ZM3.75 6C3.3375 6 2.98438 5.85313 2.69063 5.55938C2.39688 5.26562 2.25 4.9125 2.25 4.5C2.25 4.0875 2.39688 3.73438 2.69063 3.44063C2.98438 3.14688 3.3375 3 3.75 3C4.1625 3 4.51562 3.14688 4.80938 3.44063C5.10313 3.73438 5.25 4.0875 5.25 4.5C5.25 4.9125 5.10313 5.26562 4.80938 5.55938C4.51562 5.85313 4.1625 6 3.75 6Z" fill="currentColor" />
      </svg>
    ),
  },
  {
    name: 'Transcript',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path id="speech_to_text_2" d="M12.75 7.5C12.3375 7.5 11.9844 7.35625 11.6906 7.06875C11.3969 6.78125 11.25 6.425 11.25 6V3C11.25 2.575 11.3969 2.21875 11.6906 1.93125C11.9844 1.64375 12.3375 1.5 12.75 1.5C13.175 1.5 13.5312 1.64375 13.8188 1.93125C14.1062 2.21875 14.25 2.575 14.25 3V6C14.25 6.425 14.1062 6.78125 13.8188 7.06875C13.5312 7.35625 13.175 7.5 12.75 7.5ZM3.75 16.5C3.3375 16.5 2.98438 16.3531 2.69063 16.0594C2.39688 15.7656 2.25 15.4125 2.25 15V3C2.25 2.5875 2.39688 2.23438 2.69063 1.94063C2.98438 1.64688 3.3375 1.5 3.75 1.5H9.75V3H3.75V15H12V13.5H13.5V15C13.5 15.4125 13.3531 15.7656 13.0594 16.0594C12.7656 16.3531 12.4125 16.5 12 16.5H3.75ZM5.25 13.5V12H10.5V13.5H5.25ZM5.25 11.25V9.75H9V11.25H5.25ZM13.5 12H12V10.05C11.0375 9.875 10.2344 9.40938 9.59063 8.65313C8.94688 7.89688 8.625 7.0125 8.625 6H10.125C10.125 6.725 10.3813 7.34375 10.8938 7.85625C11.4062 8.36875 12.025 8.625 12.75 8.625C13.4875 8.625 14.1094 8.36875 14.6156 7.85625C15.1219 7.34375 15.375 6.725 15.375 6H16.875C16.875 7.0125 16.5563 7.89688 15.9188 8.65313C15.2812 9.40938 14.475 9.875 13.5 10.05V12Z" fill="currentColor" />
      </svg>
    ),
  },
];

function BriefPublicPage() {
  // const editorRef = useRef(null);
  const { meetingId, requestId } = useParams();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const [transcript, setTranscript] = useState('');
  const [meeting, setMeeting] = useState(null);
  const [booking, setBooking] = useState(null);

  const [briefHTML, setBriefHTML] = useState('');
  const [briefVersions, setBriefVersions] = useState([]);

  const meetingUrl = meeting?.meetingSource === 'google' ? meeting?.meetingSourceUrl : `${Config.meetUrl || ''}/${meetingId}`;

  const video = meeting?.files?.find((f) => f.fileType === 'webm');
  // console.log(video);

  // Brief
  const [brief, setBrief] = useState('');

  const [status, setStatus] = useState('');

  const [highlight, setHighlight] = useState('');
  const [summary, setSummary] = useState('');

  const [activeTab, setActiveTab] = useState(0);

  const navigate = useNavigate();

  // console.log(meeting);
  let userAvatars = [];
  if (meeting?.meetingSource === 'saybriefly') {
    userAvatars = typeof meeting?.users[0] !== 'string'
      ? meeting?.users?.map((user) => ({
        src: user.picture
          ? `${Config.url || ''}/${user.picture.location}`
          : getGravatarUrl(`${user.firstName} ${user.lastName}`, user.email),
        fallback: `${user.firstName?.charAt(0)} ${user.lastName?.charAt(0)}`,
        alt: '',
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      }))
      : [];
  } else if (meeting?.temporaryUsers?.length === 0) {
    userAvatars = Array.isArray(meeting?.createdBy)
      ? meeting?.createdBy?.map((user) => ({
        src: user.picture
          ? `${user.picture.location}`
          : getGravatarUrl(`${user.firstName} ${user.lastName}`, user.email),
        fallback: `${user.firstName?.charAt(0)} ${user.lastName?.charAt(0)}`,
        alt: '',
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      }))
      : [
        {
          src: getGravatarUrl(`${meeting?.createdBy?.firstName} ${meeting?.createdBy?.lastName}`, meeting?.createdBy?.email),
          fallback: '',
          alt: '',
          name: `${meeting?.createdBy?.firstName} ${meeting?.createdBy?.lastName}`,
          email: meeting?.createdBy?.email,
        },
      ];
  } else {
    userAvatars = meeting?.temporaryUsers?.map((user) => ({
      src: user.picture
        ? `${user.picture.location}`
        : getGravatarUrl(`${user.name}`, user.email),
      fallback: user.name,
      alt: '',
      name: `${user.name}`,
      email: user.email,
    })) || [];
  }

  const renderedTranscriptHTML = formatTranscriptToHTML(transcript);
  // console.log(renderedTranscriptHTML);

  const getBriefDataFunction = async () => {
    try {
      const { data, success, error } = await getBriefData(meetingId, requestId);
      if (success) {
        if (data.meeting) {
          // setUsers(data?.data.meeting.users);
          setMeeting(data?.meeting);
          setBooking(data?.meeting?.booking);
        }
        if (data?.meeting?.transcript?.html) {
          // console.log(data?.data?.meeting?.transcript?.html);
          setTranscript(data?.meeting?.transcript?.html);
        } else if (data?.meeting?.transcriptions) {
          setTranscript(data?.meeting?.transcriptions);
        } else {
          toast.error('No transcriptions found', {
            position: 'top-center',
            autoClose: 5000,
          });
          // navigate('/');
        }
        if (data?.brief) {
          setBrief(data?.brief?.html);
          const brief = data?.brief?.html ? marked(data?.brief?.html) : '';
          setBriefHTML(brief);
          setBriefVersions(data?.versions);
        }
        if (data?.meeting?.status) {
          setStatus(data?.meeting?.status);
        }
        if (data?.meeting?.highlight) {
          const highlightData = data?.meeting?.highlight?.highlights
            ?.map((highlight) => highlight.replace(/\n/g, '<br/>'))
            .join('<br/><br/>');

          const highlightTextData = data?.meeting?.full_highlights_text;
          const summeryData = data?.meeting?.summary?.html.replace(/\n/g, '<br/>');

          // const highlightHTML = highlightTextData || highlightData ? marked(highlightTextData || highlightData) : '';
          const highlightHTML = parseToDesiredHTML(highlightTextData || highlightData);
          const summaryHTML = parseToDesiredHTML(summeryData);
          console.log(highlightHTML);
          setHighlight(highlightHTML);
          setSummary(summaryHTML);
        }
      } else {
        toast.error((error || 'Something Went Wrong!'), {
          position: 'top-center',
          autoClose: 5000,
        });
        // navigate('/');
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (meetingId) {
      getBriefDataFunction();
    }
  }, [meetingId, status]);

  return (
    <div className=" overflow-auto h-screen scroll-none">
      <div className="max-w-5xl mx-auto flex flex-col py-8">
        <div className="gap-4">
          <div className="flex xl:flex-row flex-col gap-4">
            <div className="flex lg:flex-row flex-col gap-3.5 lg:flex-1">
              <AvatarGroup avatars={userAvatars} max={2} />
              <div className="lg:flex-1 space-y-3">
                <div className="flex gap-3 items-end">
                  {userAvatars?.slice(0, 2).map((user, index) => (
                    <div key={user?._id} className="flex items-end">
                      <div className="flex flex-col">
                        <p
                          className="text-[#101828] text-sm font-medium leading-tight whitespace-nowrap capitalize"
                        >
                          {user?.name}
                        </p>
                        <p
                          className="text-[#475467] text-xs font-normal font-['Inter'] leading-[18px] truncate"
                          style={{
                            maxWidth: document.getElementById(`user-name-${user?._id}`)?.offsetWidth || '100px',
                          }}
                        >
                          {user?.email}
                        </p>
                      </div>
                      {index !== userAvatars.length - 1 && <span>,</span>}
                    </div>
                  ))}
                  {userAvatars.length > 2 && (
                    <p className="text-sm whitespace-nowrap capitalize">
                      <span>
                        {userAvatars.length - 2}
                        {' '}
                        more
                      </span>
                    </p>
                  )}
                </div>
                <div className="flex flex-wrap gap-3">
                  <p className="flex items-center gap-2">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="calendar-check-01">
                        <path
                          id="Icon"
                          d="M15.6357 8.04511H2.48242M11.982 2.19922V5.12216M6.1361 2.19922V5.12216M6.86684 12.4295L8.32831 13.891L11.6166 10.6027M5.98996 16.8139H12.1281C13.3559 16.8139 13.9698 16.8139 14.4387 16.575C14.8512 16.3648 15.1866 16.0295 15.3967 15.617C15.6357 15.148 15.6357 14.5342 15.6357 13.3064V7.16823C15.6357 5.94047 15.6357 5.3266 15.3967 4.85766C15.1866 4.44517 14.8512 4.1098 14.4387 3.89963C13.9698 3.66069 13.3559 3.66069 12.1281 3.66069H5.98996C4.7622 3.66069 4.14833 3.66069 3.67939 3.89963C3.2669 4.1098 2.93153 4.44517 2.72136 4.85766C2.48242 5.3266 2.48242 5.94047 2.48242 7.16823V13.3064C2.48242 14.5342 2.48242 15.148 2.72136 15.617C2.93153 16.0295 3.2669 16.3648 3.67939 16.575C4.14833 16.8139 4.7622 16.8139 5.98996 16.8139Z"
                          stroke="#454545"
                          strokeWidth="1.46147"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                    <span className="text-[#475467] text-xs font-medium font-inter leading-[18px]">
                      {/* 25/09/30 */}
                      {new Date(booking?.bookingDate || meeting?.startedAt).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      })}
                    </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="clock" clipPath="url(#clip0_11646_37997)">
                        <path
                          id="Icon"
                          d="M8.87399 4.68363V8.62961L11.5046 9.94493M15.4506 8.62961C15.4506 12.2618 12.5062 15.2062 8.87399 15.2062C5.24182 15.2062 2.29736 12.2618 2.29736 8.62961C2.29736 4.99743 5.24182 2.05298 8.87399 2.05298C12.5062 2.05298 15.4506 4.99743 15.4506 8.62961Z"
                          stroke="#454545"
                          strokeWidth="1.47375"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_11646_37997">
                          <rect
                            width="15.7839"
                            height="15.7839"
                            fill="white"
                            transform="translate(0.980957 0.737793)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="text-[#475467] text-xs font-medium font-['Inter'] leading-[18px]">
                      {/* 10:00 AM - 11:00 AM */}
                      {new Date(meeting?.createdAt).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true,
                      })}
                      {' - '}
                      {new Date(meeting?.lastEnter).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </span>
                  </p>
                  <p className="flex items-start gap-2">
                    <svg
                      width="17"
                      height="16"
                      className="mt-0.5"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="link-02" clipPath="url(#clip0_11646_37999)">
                        <path
                          id="Icon"
                          d="M6.8927 10.8433H5.57738C3.76129 10.8433 2.28906 9.4529 2.28906 7.73771C2.28906 6.02252 3.76129 4.63208 5.57738 4.63208H6.8927M10.8387 10.8433H12.154C13.9701 10.8433 15.4423 9.4529 15.4423 7.73771C15.4423 6.02252 13.9701 4.63208 12.154 4.63208H10.8387M5.57738 7.73771L12.154 7.73771"
                          stroke="#454545"
                          strokeWidth="1.75377"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_11646_37999">
                          <rect width="15.7839" height="14.907" fill="white" transform="translate(0.973633 0.28418)" />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className="text-[#475467] text-xs font-medium font-['Inter'] leading-[18px]">
                      {meetingUrl}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <button onClick={() => navigate('/')} className="px-3 py-2 bg-white rounded-lg shadow border-2 border-[#878888] justify-center items-center gap-1 flex text-[#323333] text-sm font-semibold leading-tight">
                <svg width="24" height="25" className="size-5" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="grid-01">
                    <g id="Icon">
                      <path d="M8.4 3.7334H4.6C4.03995 3.7334 3.75992 3.7334 3.54601 3.84239C3.35785 3.93827 3.20487 4.09125 3.10899 4.27941C3 4.49332 3 4.77335 3 5.3334V9.1334C3 9.69345 3 9.97348 3.10899 10.1874C3.20487 10.3756 3.35785 10.5285 3.54601 10.6244C3.75992 10.7334 4.03995 10.7334 4.6 10.7334H8.4C8.96005 10.7334 9.24008 10.7334 9.45399 10.6244C9.64215 10.5285 9.79513 10.3756 9.89101 10.1874C10 9.97348 10 9.69345 10 9.1334V5.3334C10 4.77335 10 4.49332 9.89101 4.27941C9.79513 4.09125 9.64215 3.93827 9.45399 3.84239C9.24008 3.7334 8.96005 3.7334 8.4 3.7334Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M19.4 3.7334H15.6C15.0399 3.7334 14.7599 3.7334 14.546 3.84239C14.3578 3.93827 14.2049 4.09125 14.109 4.27941C14 4.49332 14 4.77335 14 5.3334V9.1334C14 9.69345 14 9.97348 14.109 10.1874C14.2049 10.3756 14.3578 10.5285 14.546 10.6244C14.7599 10.7334 15.0399 10.7334 15.6 10.7334H19.4C19.9601 10.7334 20.2401 10.7334 20.454 10.6244C20.6422 10.5285 20.7951 10.3756 20.891 10.1874C21 9.97348 21 9.69345 21 9.1334V5.3334C21 4.77335 21 4.49332 20.891 4.27941C20.7951 4.09125 20.6422 3.93827 20.454 3.84239C20.2401 3.7334 19.9601 3.7334 19.4 3.7334Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M19.4 14.7334H15.6C15.0399 14.7334 14.7599 14.7334 14.546 14.8424C14.3578 14.9383 14.2049 15.0912 14.109 15.2794C14 15.4933 14 15.7733 14 16.3334V20.1334C14 20.6935 14 20.9735 14.109 21.1874C14.2049 21.3756 14.3578 21.5285 14.546 21.6244C14.7599 21.7334 15.0399 21.7334 15.6 21.7334H19.4C19.9601 21.7334 20.2401 21.7334 20.454 21.6244C20.6422 21.5285 20.7951 21.3756 20.891 21.1874C21 20.9735 21 20.6935 21 20.1334V16.3334C21 15.7733 21 15.4933 20.891 15.2794C20.7951 15.0912 20.6422 14.9383 20.454 14.8424C20.2401 14.7334 19.9601 14.7334 19.4 14.7334Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.4 14.7334H4.6C4.03995 14.7334 3.75992 14.7334 3.54601 14.8424C3.35785 14.9383 3.20487 15.0912 3.10899 15.2794C3 15.4933 3 15.7733 3 16.3334V20.1334C3 20.6935 3 20.9735 3.10899 21.1874C3.20487 21.3756 3.35785 21.5285 3.54601 21.6244C3.75992 21.7334 4.03995 21.7334 4.6 21.7334H8.4C8.96005 21.7334 9.24008 21.7334 9.45399 21.6244C9.64215 21.5285 9.79513 21.3756 9.89101 21.1874C10 20.9735 10 20.6935 10 20.1334V16.3334C10 15.7733 10 15.4933 9.89101 15.2794C9.79513 15.0912 9.64215 14.9383 9.45399 14.8424C9.24008 14.7334 8.96005 14.7334 8.4 14.7334Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                </svg>
                Dashboard
              </button>
            </div>
          </div>
        </div>

        <div>
          <div className="flex flex-col-reverse gap-4 lg:mt-4">
            <div className=" flex lg:flex-row flex-col gap-4">
              <div className="lg:flex-1 space-y-4 flex flex-col">
                {mode === 'edit' ? (
                  <BriefEditor briefHTML={briefHTML} setBriefHTML={setBriefHTML} />
                ) : (
                  <>
                    <div>
                      {
                        video && <PlayerWithAspectRatio url={`https://api.saybriefly.com/${video?.location}`} />
                      }

                      {/* <PlayerWithAspectRatio url="https://www.youtube.com/watch?v=4kGIIRUIRbQ" /> */}
                    </div>
                    <div className="lg:px-[30px] px-4 pt-4 pb-[30px] bg-white rounded-2xl border border-[#d0d5dd] space-y-5 flex flex-col relative">
                      <div className="flex justify-between gap-4 sticky top-[20px] z-50">
                        <BriefTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                      </div>
                      <div className="lg:flex-1 overflow-auto scroll-none relative min-h-[400px]">
                        <BriefTabContents
                          tabs={tabs}
                          activeTab={activeTab}
                          transcript={renderedTranscriptHTML}
                          status={status}
                          meetingDetails={{ meeting }}
                          highlight={highlight}
                          summary={summary}
                          briefHTML={briefHTML}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="lg:col-span-3 flex items-start" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BriefPublicPage;
