/* eslint-disable indent */
import { Link } from 'react-router-dom';
import {
  Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious,
} from '../../../components/ui/pagination';
import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from '../../../components/ui/tabs';
import MeetingHistoryItem from './meeting-history-item';

function ContactTab({ meetingsData, meetingPage, setMeetingPage, isLoading }) {
  console.log(meetingsData);
  return (
    <div>
      <Tabs defaultValue="history" className="w-full">
        <TabsList>
          <TabsTrigger className="px-8" value="history">Call History</TabsTrigger>
        </TabsList>
        <TabsContent value="history">
          <div className="space-y-4 mt-4 max-h-[500px] overflow-y-scroll scroll-none">
            {
              isLoading ? <div className="w-full flex justify-center items-center h-20">
                <span className='size-6 border-[3px] border-black border-t-transparent rounded-full animate-spin' />
              </div> : meetingsData?.map((meeting, i) => <Link key={i} to={`/briefs/${meeting._id}`}><MeetingHistoryItem maxAvatar={2} data={meeting} /></Link>)
            }
          </div>
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious onClick={() => setMeetingPage((prev) => (prev < 1 ? 1 : prev - 1))} className={meetingPage === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'} />
              </PaginationItem>
              <PaginationItem>
                <PaginationNext
                  onClick={() => setMeetingPage((prev) => (meetingsData?.length === 0 ? prev : prev + 1))}
                  className={((meetingsData?.length === 0 && meetingPage > 1) || meetingsData?.length < 10) ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </TabsContent>
        <TabsContent value="resources">
          <div className="space-y-4 mt-4 max-h-[350px] overflow-y-scroll scroll-none">
            {new Array(3).fill('').map((_, i) => (
              <div key={i} className="flex p-4 items-center justify-between gap-2.5 rounded-md border border-gray-200 bg-white">
                <div className="flex items-center gap-3">
                  <img className="w-[35px] h-[35px]" src="/image/icons/folder.svg" alt="Profile" />
                  <h4 className="text-gray-900 text-sm font-semibold leading-tight m-0">
                    Transcript
                  </h4>
                </div>
              </div>
            ))}
          </div>
          <Pagination className="mt-4">
            <PaginationContent className="mb-0">
              <PaginationItem>
                <PaginationPrevious to="#" />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink to="#">1</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink to="#">2</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink to="#">3</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
              <PaginationItem>
                <PaginationNext to="#" />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default ContactTab;
