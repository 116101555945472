import { cn } from '../../../lib/utils';

export default function SuccessItem({
  className, illustration, title, description, children,
}) {
  return (
    <div className={cn('p-4 w-[300px] rounded-lg flex flex-col items-center text-primary font-inter space-y-3 relative', className)}>
      <img src={illustration} alt="" width={150} height={150} className="w-auto h-[150px]" />

      <h5 className="text-center text-[#183400] text-[26.46px] font-semibold font-inter leading-[34.40px]">
        {title}
      </h5>
      <p className="text-[#173300] text-base font-light font-inter leading-[19.05px]">
        {description}
      </p>

      {
        children
    }
    </div>
  );
}
