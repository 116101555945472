import axios from 'axios';
import Config from '../config';

const addTeamMember = (email, workspacesIds = []) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/user/subscription/add_subscription_member`,
    data: { user_email: email, workspacesIds },
  });
};

export default addTeamMember;
