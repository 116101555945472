/* eslint-disable indent */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'sonner';
import {
  AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from '../../../../../components/ui/alert-dialog';
import { Button } from '../../../../../components/ui/button';
import { disconnectDrive } from '../../../../../actions/apiFunctions';

export default function DriveItem({ drive }) {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: handleDisconnectDrive, isPending } = useMutation({
    mutationFn: disconnectDrive,
    onSuccess: () => {
      toast.success('Drive disconnected successfully', { position: 'top-right' });
      setIsOpen(false);
      // Invalidate both calendars and drives queries to refetch them
      queryClient.invalidateQueries(['calendars']);
      queryClient.invalidateQueries(['drives']);
    },
    onError: (error) => {
      console.error(error);
      toast.error('Failed to disconnect drive', { position: 'top-right' });
    },
  });

  const toggleAlertDialog = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button variant="outline" className="px-3 py-2 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border-2 border-[#173300] flex justify-center items-center gap-1 text-[#173300] text-sm font-semibold font-inter leading-tight">
        <img src="/image/icons/google-drive.svg" className="size-4" alt="" />
        {drive?.email}
        <span className="ml-2 hover:text-red-500 cursor-pointer" title="Disconnect Drive" onClick={toggleAlertDialog}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.181 8.68a4.503 4.503 0 0 1 1.903 6.405m-9.768-2.782L3.56 14.06a4.5 4.5 0 0 0 6.364 6.365l3.129-3.129m5.614-5.615 1.757-1.757a4.5 4.5 0 0 0-6.364-6.365l-4.5 4.5c-.258.26-.479.541-.661.84m1.903 6.405a4.495 4.495 0 0 1-1.242-.88 4.483 4.483 0 0 1-1.062-1.683m6.587 2.345 5.907 5.907m-5.907-5.907L8.898 8.898M2.991 2.99 8.898 8.9" />
          </svg>
        </span>
      </Button>
      <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will delete the drive access from your account.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => handleDisconnectDrive(drive?._id)} disabled={isPending}>
              {isPending ? 'Disconnecting...' : 'Continue'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}