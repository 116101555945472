import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Button } from '../../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/dialog';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/select-schedule';
import { deleteUser } from '../../../actions/apiFunctions';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';

const reasons = [
  { label: 'The cost is too high', value: 'cost_high' },
  { label: 'The service is not what I expected', value: 'service_not_expected' },
  { label: 'Features are buggy or not working as expected', value: 'features_buggy' },
  { label: 'Other', value: 'other' },
];

export default function DeleteProfileModal({ open, setOpen }) {
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [error, setError] = useState('');
  const [user] = useGlobal('user');
  const setToken = useGlobal('token')[1];

  const logout = async () => {
    const { username } = user;
    localStorage.removeItem('token');
    document.cookie = 'jwt_token=; path=/; domain=.saybriefly.com; secure; SameSite=None;';
    await setToken(null);
    await setUser({});
  };

  // 🔥 React Query Mutation for Account Deletion
  const mutation = useMutation({
    mutationFn: async () => {
      const deleteReason = reason === 'other' ? otherReason : reason;
      if (!deleteReason) throw new Error('Please select a reason for deletion.');

      return deleteUser({ formData: { reason: deleteReason } });
    },
    onSuccess: () => {
      setOpen(false); // Close modal on success
      window.location.reload();
      setError('');
      setOtherReason('');
      setReason('');
      toast.success('Account deleted successfully.');
      logout();
    },
    onError: (err) => {
      setError(err.response?.data?.message || 'Something went wrong. Please try again.');
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you absolutely sure?</DialogTitle>
          <DialogDescription>
            This action cannot be undone. This will permanently delete your account and remove your data from our
            servers.
          </DialogDescription>

          <div className="space-y-4 py-4 text-left">
            <div>
              <Select value={reason} onValueChange={setReason} className="w-full">
                <SelectTrigger className="px-3.5 py-2 bg-white rounded-lg justify-start text-[#667085] text-base font-normal ring-0 focus:outline-none">
                  <SelectValue placeholder="Reason for Deletion" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {reasons.map((item, index) => (
                      <SelectItem key={index} value={item.value}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            {reason === 'other' && (
              <textarea
                className="w-full px-3.5 py-2 bg-white rounded-lg text-[#667085] text-base font-normal ring-0 focus:outline-none border border-[#E5E7EB] focus:border-black"
                placeholder="Please specify"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
              />
            )}
          </div>

          {error && <p className="text-red-500 text-sm">{error}</p>}
        </DialogHeader>

        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            className="bg-red-500 text-white border-red-500 hover:bg-red-700"
            onClick={() => mutation.mutate()}
            disabled={mutation.isLoading}
          >
            {mutation.isLoading ? 'Deleting...' : 'Delete Account'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
