/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import editWorkspace from '../../../actions/editWorkspace';
import getUserInfo from '../../../actions/getUserInfo';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import Config from '../../../config';
import CalenderItem from '../../Dashboard/pages/back-office/components/calender-item';
import ConnectCalendar from '../../Dashboard/pages/back-office/components/connect-calendar';

function formatTime(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return hours > 0
    ? `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`
    : `${minutes} min${minutes > 1 ? 's' : ''}`;
}

export default function ConfigWorkspace({ isLoading, setIsLoading, setStep }) {
  const [user, setUser] = useGlobal('user');
  const [calendars, setCalendars] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { code, scope } = Object.fromEntries(searchParams.entries());
  const workspaceId = user?.workspace?._id;
  const initialWorkspaceName = user?.workspace?.name === 'My Workspace' ? '' : user?.workspace?.name;
  const [workspaceName, setWorkspaceName] = useState(initialWorkspaceName);
  const [workspaceType, setWorkspaceType] = useState('solo');
  const location = useLocation();

  const getCalendars = useCallback(async () => {
    try {
      const { data: res } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_calendars`);
      setCalendars(res.data);
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-right',
      });
      console.log(error);
    }
  }, []);

  const verifyGoogleCalenderCode = useCallback(async () => {
    try {
      const { data: res } = await axios.get(
        `${Config.url || ''}/api/v1/user/back_office/verify_calendar_integration?code=${code}&redirect_uri=${window.location.origin}/onboarding`,
      );
      if (searchParams.has('code')) {
        searchParams.delete('code');
        searchParams.delete('scope');
        searchParams.delete('authuser');
        searchParams.delete('prompt');
        setSearchParams(searchParams);
      }
      console.log(res);
      if (res.success) {
        getCalendars();
        toast.success('Google calendar integrated successfully. ', {
          position: 'top-right',
        });
      } else {
        toast.error('Something went wrong', {
          position: 'top-right',
        });
      }
    } catch (error) {
      toast.error(error.response.data.message || 'Something went wrong', {
        position: 'top-right',
      });
      console.log(error.response.data.message);
    }
  }, [code, searchParams, setSearchParams]);

  useEffect(() => {
    getCalendars();
  }, []);

  useEffect(() => {
    if (code && scope.includes('calendar')) {
      verifyGoogleCalenderCode();
    }
  }, [code, scope]);

  const handleCreateWorkSpace = async (e) => {
    e.preventDefault();
    if (workspaceType === 'team') {
      setStep((prev) => prev + 1);
      return;
    }

    if (!workspaceName || !workspaceId) return;
    try {
      setIsLoading(true);
      const { data: res } = await editWorkspace(workspaceId, {
        name: workspaceName,
        description: workspaceName,
        brandID: workspaceName?.toLowerCase()?.trim()?.replace(/\s+/g, '-'),
      });
      if (res.success) {
        toast.success('Workspace created successfully.', {
          position: 'top-right',
        });
        setStep((prev) => prev + 1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      const { data: userRes } = await getUserInfo();
      if (userRes?.success) {
        setUser(userRes?.data);
      }
      setIsLoading(false);
    }
  };

  const getTeamSpaceData = async () => {
    try {
      const { data: res } = await axios.post(`${Config.url || ''}/api/v1/user/workspace/info_by_invitation_code`, {
        invitationCode: searchParams.get('teamCode'),
      });
      console.log(res.data);
      setWorkspaceName(res.data.workspace_name);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchParams.has('workspace_type')) {
      if (searchParams.get('workspace_type') === 'team') {
        getTeamSpaceData();
        setWorkspaceType('team');
      }
    }
  }, [searchParams]);

  const message = location?.search?.split('&message=')?.[1];

  return (
    <div className="login-inner lg:w-[360px] uk-padding-small space-y-6">
      <div className="space-y-6 bg-[#e9eef6] rounded-[10px] w-full p-4">
        {message === 'success' ? (
          <div
            className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-4 rounded relative"
            role="alert"
          >
            <strong className="font-bold mr-2">Success!</strong>
            <span className="block sm:inline">Plan updated successfully</span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => {
                window.location.href = `${window.location.origin}/onboarding?step=1`;
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        ) : null}
        <div className="mb-4 w-full space-y-3">
          <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">
            {workspaceType === 'team' ? "Your Team's Workspace" : 'Your workspace name'}
          </h4>
          <Input
            type="text"
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value)}
            placeholder="Ex: SayBriefly"
            disabled={workspaceType === 'team'}
            className="bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#d0d5dd]"
          />
        </div>
        <div className="mb-4 w-full space-y-3">
          <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Connect Calendar</h4>
          <div className="flex flex-col gap-3">
            {calendars.map((calendar) => (
              <CalenderItem
                key={calendar._id}
                calendar={calendar}
                getCalendars={getCalendars}
                className="w-full justify-between"
              />
            ))}
          </div>
          <ConnectCalendar />
        </div>
      </div>
      <div className="w-full space-y-4">
        <Button className="w-full" onClick={() => setStep((prev) => prev + 1)} variant="outline">
          Skip
        </Button>
        <Button className="w-full" onClick={handleCreateWorkSpace} disabled={isLoading || !workspaceName}>
          {isLoading ? (
            <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
          ) : (
            <span>Continue</span>
          )}
        </Button>
      </div>
    </div>
  );
}
