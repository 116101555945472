/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import Config from '../../../config';
import '../../../index.css';
import { generateColorShades } from '../../../lib/utils';
import CreateTeamSpaceModal from './create-teamspace-modal';
import DashboardStats from './dashboard-stats';
import SideNavigation from './side-navigation';
import { fetchUser, setCurrentWorkspace } from '../../../actions/apiFunctions';
import getWorkspaces from '../../../actions/getWorkspaces';

function Sidebar({ showSideBar }) {
  const [user, setUser] = useGlobal('user');
  const [token] = useGlobal('token');
  const setTeamSpace = useGlobal('teamSpaces')[1];
  const [openCreateTeamSpaceModal, setOpenCreateTeamSpaceModal] = useState(false);
  const workspaceSubscription = user?.workspace?.subscription;

  // Query for fetching user data
  const { data: teamSpaces } = useQuery({
    queryKey: ['teamSpaces', token],
    queryFn: async () => {
      const { data: response } = await getWorkspaces();
      setTeamSpace(response.data);
      return response.data; // Extract the innermost 'data'
    },
    onError: (err) => {
      console.log(err);
    },
    enabled: !!token, // Fetch only if token exists
  });

  const selectedTeamSpace = teamSpaces?.find((space) => space?._id === user?.workspace?._id);

  const { data: fetchedUser } = useQuery({
    queryKey: ['user', token],
    queryFn: () => fetchUser(token),
    onError: (err) => {
      console.log(err);
    },
    enabled: !!token, // Fetch only if token exists
  });

  useEffect(() => {
    if (fetchedUser) {
      setUser(fetchedUser);
    }
  }, [fetchedUser]);

  // Mutation for switching workspace
  const { mutate: handleTeamSwitch } = useMutation({
    mutationFn: setCurrentWorkspace,
    onMutate: () => {
      toast.loading('Switching workspace...', { id: 'switch-workspace' });
    },
    onSuccess: (data) => {
      toast.dismiss('switch-workspace');
      setUser({ ...user, workspace: data?.workspace, workspace_member: data?.workspace_member });
      toast.success(`Switched to ${data?.workspace?.name}`);
      window.location.reload(); // Reload application
    },
    onError: (error) => {
      console.log(error);
      toast.error('Failed to switch workspace', { id: 'switch-workspace' });
    },
  });

  // Load state from localStorage on mount
  useEffect(() => {
    const localStorageTeamSpaceModal = localStorage.getItem('openCreateTeamSpaceModal');
    if (localStorageTeamSpaceModal) {
      setOpenCreateTeamSpaceModal(JSON.parse(localStorageTeamSpaceModal));
    }
  }, []);

  // Sync state changes to localStorage
  useEffect(() => {
    localStorage.setItem('openCreateTeamSpaceModal', JSON.stringify(openCreateTeamSpaceModal));
  }, [openCreateTeamSpaceModal]);

  // Set CSS variables for branding
  if (user) {
    const shades = generateColorShades(user?.workspace?.brandColor ?? '#1bcf11').shades;
    const indexNumber = shades.findIndex((item) => item.hexcode === user?.workspace?.brandColor);
    const brandShade = indexNumber < 8 ? shades[8] : shades[indexNumber];

    document.documentElement.style.setProperty('--calenderPrimary', brandShade.hexcode);
    document.documentElement.style.setProperty('--calenderPrimaryLight', shades[2].hexcode);
    document.documentElement.style.setProperty('--calenderSecondary', shades[0].hexcode);
  }

  return (
    <>
      <div
        className={`bg-white transition-all h-screen pb-4 lg:pb-8 pt-6 border border-gray-300 space-y-4 flex flex-col scroll-none overflow-auto w-[320px] md:w-[250px] xl:w-[300px] md:relative fixed top-0 z-[9999] ${showSideBar ? 'w-[320px] md:w-[250px] xl:w-[300px] px-6 -left-0' : 'p-0 md:px-6 -left-[320px] md:-left-0 md:w-[250px] xl:w-[300px]'}`}
      >
        <Link to="/">
          <img src="/logo.svg" className="" width="168px" alt="" />
        </Link>

        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="w-full px-4 py-2 bg-[#e9eef6] rounded-full justify-between items-center inline-flex mt-1 tour-workspace">
              <div className="justify-start items-center gap-3 flex">
                {selectedTeamSpace?.brandLogo ? (
                  <img
                    alt=""
                    className="w-[24px] h-[24px] rounded"
                    src={`${Config?.url}${selectedTeamSpace?.brandLogo?.location?.slice(1)}`}
                  />
                ) : (
                  <div className="size-6 bg-[#f1eaba] rounded-lg justify-center items-center flex">
                    <span className="text-[#173300] font-bold text-xs font-inter uppercase">
                      {selectedTeamSpace?.name?.slice(0, 2)}
                    </span>
                  </div>
                )}
                <h4 className="text-[#18212f] text-base font-semibold font-inter leading-normal">
                  {selectedTeamSpace?.name}
                </h4>
              </div>
              <svg
                width="24"
                height="25"
                className="size-6"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="chevron-down">
                  <path
                    id="Icon"
                    d="M6 9.2334L12 15.2334L18 9.2334"
                    stroke="#667085"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="min-w-[200px]">
            <DropdownMenuLabel>Select Workspace</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {teamSpaces?.map((space) => (
              <DropdownMenuItem
                key={space._id}
                onClick={() => handleTeamSwitch({ workspaceId: space._id })}
                className="flex items-center justify-between gap-2.5 px-8 py-2.5"
              >
                <div className="flex items-center gap-1.5">
                  {space?.brandLogo ? (
                    <img
                      src={`${Config?.url}${space?.brandLogo?.location.slice(1)}`}
                      className="w-6 h-6 object-cover object-center rounded"
                      alt=""
                    />
                  ) : (
                    <div className="size-6 bg-[#f1eaba] rounded-lg justify-center items-center flex">
                      <span className="text-[#173300] font-bold text-xs font-inter uppercase">
                        {space?.name?.slice(0, 2)}
                      </span>
                    </div>
                  )}
                  <p className="text-[#344054] text-sm font-medium font-['Inter'] leading-tight">
                    {space?.name}{' '}
                    {space.default && space?.createdBy?.toString() === user?._id?.toString() && '(primary)'}
                  </p>
                </div>
                {space?._id === user?.workspace?._id && <div className="size-2 bg-[#17b169] rounded-full" />}
              </DropdownMenuItem>
            ))}
            <DropdownMenuSeparator />
            <DropdownMenuItem className="hover:bg-transparent p-0 my-1.5">
              <button
                onClick={() => setOpenCreateTeamSpaceModal(true)}
                className="w-full px-[19px] py-[9px] bg-[#f3f3f3] rounded-md justify-center items-center flex gap-1"
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="plus-square">
                    <path
                      id="Icon"
                      d="M8 5.33333V10.6667M5.33333 8H10.6667M5.2 14H10.8C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8V5.2C14 4.0799 14 3.51984 13.782 3.09202C13.5903 2.71569 13.2843 2.40973 12.908 2.21799C12.4802 2 11.9201 2 10.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14Z"
                      stroke="#667085"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                <span className="text-[#344054] text-sm font-medium font-inter leading-tight">Create Workspace</span>
              </button>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <div className="">
          <SideNavigation showSideBar={showSideBar} />
        </div>

        <DashboardStats />
        <div className="flex flex-col items-center justify-center gap-2 px-5 py-3 bg-[#173300]/10 rounded-xl">
          <div className="flex items-center gap-2">
            <span className="text-[#173300] text-sm font-semibold font-inter">
              {workspaceSubscription?.plan === 'small_teams' ? 'Team Workspace' : 'Solo Workspace'}
            </span>
            <img
              className="size-6"
              src={
                workspaceSubscription?.plan === 'small_teams' ? '/image/icons/clap-dark.png' : '/image/icons/rocket.png'
              }
              alt="Team workspace icon"
            />
          </div>
        </div>
      </div>
      <CreateTeamSpaceModal open={openCreateTeamSpaceModal} setOpen={setOpenCreateTeamSpaceModal} />
    </>
  );
}

export default Sidebar;
