import axios from 'axios';
import Config from '../config';

const acceptInvitation = (invitationCode) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/user/subscription/accept_subscription_invitation`,
    data: {
      invitationCode,
    },
  });
};

export default acceptInvitation;
