/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
import axios from 'axios';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { Button } from '../../../components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import Config from '../../../config';
import MeetingHostEmailSelector from './meeting-host-email-selector';

function getCleanMeetingUrl(url) {
  // Return unchanged if not a string or empty
  if (!url || typeof url !== 'string') {
    return url;
  }

  // Check if it's a complete URL with protocol
  const hasProtocol = url.startsWith('http://') || url.startsWith('https://');

  if (hasProtocol) {
    try {
      const urlObj = new URL(url);
      // Only clean if there's a meaningful path beyond just "/"
      if (urlObj.pathname.length > 1) {
        return `${urlObj.protocol}//${urlObj.hostname}${urlObj.pathname}`;
      }
      return url; // Return unchanged if path is just "/"
    } catch (error) {
      return url; // Return unchanged if invalid URL
    }
  }

  // Return unchanged if not a complete URL
  return url;
}

export default function InviteBrieflyButton({ closeDropdown }) {
  const user = useGlobal('user')[0];

  const { addToast } = useToasts();
  const [isOpen, setIsOpen] = useState(false);
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');
  const [loading, setLoading] = useState(false);
  const [meetingSource, setMeetingSource] = useState('');
  const [meetingSourceUrl, setMeetingSourceUrl] = useState('');
  const [meetingHostId, setMeetingHostId] = useState('');
  // const [meetingData, setMeetingData] = useState('');

  const handleCreateMeeting = () => {
    if (!meetingSource) {
      addToast('Please select a meeting source', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('title', 'instant meeting');
      formData.append('caller', user?._id);
      formData.append('type', 'instant');
      formData.append('isUpcoming', 'false');
      formData.append('startedAt', new Date());
      formData.append('workspaceID', user?.workspace?.brandID);
      formData.append('meetingSource', meetingSource);
      formData.append('meetingSourceUrl', meetingSourceUrl);
      formData.append('meetingHostId', meetingHostId);

      const config = {
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/create_meeting`,
        data: formData,
      };

      axios.request(config).then((data) => {
        console.log(data?.data?.message);
        // const meeting = data?.data;
        // setMeetingData(meeting);
        setRefreshMeetings(!refreshMeetings);
        setLoading(false);
        setIsOpen(false);
        closeDropdown();
        toast.success('Briefly Invited successfully.', {
          position: 'top-right',
        });
      });
    } catch (err) {
      console.log(err);
    } finally {
      // setLoading(false);
    }
  };

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(meetingSourceUrl);
  //   addToast('Link copied to clipboard', {
  //     appearance: 'success',
  //     autoDismiss: true,
  //   });
  // };

  const handleCheckIfZoomOrGoogleSource = (meetingUrl) => {
    if (meetingUrl.includes('zoom.us')) {
      setMeetingSource('zoom');
    } else if (meetingUrl.includes('meet.google.com')) {
      setMeetingSource('google');
    } else {
      setMeetingSource('');
    }
  };

  const handleLinkChange = (e) => {
    const cleanUrl = getCleanMeetingUrl(e.target.value);
    console.log(cleanUrl);
    setMeetingSourceUrl(cleanUrl);
    handleCheckIfZoomOrGoogleSource(e.target.value);
  };
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        <span className="p-2.5 text-[#1a2e05] hover:bg-[#1733001A] rounded-tl-full rounded-bl-full flex items-center gap-2 text-xs font-medium font-inter leading-[18px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path id="robot_2_2" d="M3 15.75V12C3 11.5875 3.14688 11.2344 3.44063 10.9406C3.73438 10.6469 4.0875 10.5 4.5 10.5H13.5C13.9125 10.5 14.2656 10.6469 14.5594 10.9406C14.8531 11.2344 15 11.5875 15 12V15.75H3ZM6.75 9.75C5.7125 9.75 4.82812 9.38438 4.09687 8.65313C3.36562 7.92188 3 7.0375 3 6C3 4.9625 3.36562 4.07812 4.09687 3.34687C4.82812 2.61562 5.7125 2.25 6.75 2.25H11.25C12.2875 2.25 13.1719 2.61562 13.9031 3.34687C14.6344 4.07812 15 4.9625 15 6C15 7.0375 14.6344 7.92188 13.9031 8.65313C13.1719 9.38438 12.2875 9.75 11.25 9.75H6.75ZM4.5 14.25H13.5V12H4.5V14.25ZM6.75 8.25H11.25C11.875 8.25 12.4062 8.03125 12.8438 7.59375C13.2812 7.15625 13.5 6.625 13.5 6C13.5 5.375 13.2812 4.84375 12.8438 4.40625C12.4062 3.96875 11.875 3.75 11.25 3.75H6.75C6.125 3.75 5.59375 3.96875 5.15625 4.40625C4.71875 4.84375 4.5 5.375 4.5 6C4.5 6.625 4.71875 7.15625 5.15625 7.59375C5.59375 8.03125 6.125 8.25 6.75 8.25ZM6.75 6.75C6.9625 6.75 7.14062 6.67812 7.28438 6.53438C7.42812 6.39062 7.5 6.2125 7.5 6C7.5 5.7875 7.42812 5.60938 7.28438 5.46562C7.14062 5.32187 6.9625 5.25 6.75 5.25C6.5375 5.25 6.35938 5.32187 6.21562 5.46562C6.07187 5.60938 6 5.7875 6 6C6 6.2125 6.07187 6.39062 6.21562 6.53438C6.35938 6.67812 6.5375 6.75 6.75 6.75ZM11.25 6.75C11.4625 6.75 11.6406 6.67812 11.7844 6.53438C11.9281 6.39062 12 6.2125 12 6C12 5.7875 11.9281 5.60938 11.7844 5.46562C11.6406 5.32187 11.4625 5.25 11.25 5.25C11.0375 5.25 10.8594 5.32187 10.7156 5.46562C10.5719 5.60938 10.5 5.7875 10.5 6C10.5 6.2125 10.5719 6.39062 10.7156 6.53438C10.8594 6.67812 11.0375 6.75 11.25 6.75Z" fill="#173300" />
          </svg>
          Invite Briefly
        </span>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        {loading ? (
          <>
            <DialogTitle className="hidden" />
            <DialogDescription className="hidden" />
            <div className="p-12 flex items-center justify-center">
              <span className="size-4 border-4 p-4 rounded-full border-[#173300] animate-spin border-t-[#FFED74]" />
            </div>
          </>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Invite Briefly</DialogTitle>
              <DialogDescription>Invite SayBriefly bot to your {meetingSource || ''} meeting.</DialogDescription>
            </DialogHeader>
            <div className="flex items-center space-x-2">
              <div className="grid flex-1 gap-2">
                <Label htmlFor="link" className="sr-only">
                  Link
                </Label>
                <Input
                  id="link"
                  type="text"
                  className="w-full"
                  placeholder="Enter your meeting link"
                  value={meetingSourceUrl}
                  onChange={handleLinkChange}
                />
              </div>
            </div>

            {meetingSource === 'google' && (
              <p className="text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center border bg-white p-2 rounded-lg">
                <img className="w-4 h-4" src="/image/icons/avatar.svg" alt="" />
                <MeetingHostEmailSelector
                  setMeetingHostId={setMeetingHostId}
                  meetingSource={meetingSource}
                  meetingHostId={meetingHostId}
                  className="w-full bg-white max-w-full"
                />
              </p>
            )}
            <DialogFooter className="sm:justify-start">
              <Button type="submit" size="sm" className="px-6" onClick={handleCreateMeeting}>
                Invite
              </Button>
              <DialogClose asChild>
                <Button type="button" variant="secondary">
                  Cancel
                </Button>
              </DialogClose>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
