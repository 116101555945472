import axios from 'axios';
import Config from '../config';

const updatePaymentMethod = (subId) => {
  return axios({
    method: 'get',
    url: `${Config.url || ''}/api/v1/user/subscription/${subId}/update`,
  });
};

export default updatePaymentMethod;
