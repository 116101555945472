/* eslint-disable no-unused-vars */
import {
  Document,
  Page, pdf,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { useState } from 'react';
import Html from 'react-pdf-html';
import { toast } from 'sonner';
import Conversation from '../../../../../features/Conversation';

const TABS = [
  { name: 'Files' },
  { name: 'Requirements' },
  { name: 'Meeting Chat' },
];

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontSize: 10,
    fontFamily: 'Helvetica',
    color: '#444444',
  },
});

const renderers = {
  // For regular HTML elements
  h2: ({ children }) => (
    <Text style={{
      fontSize: 12,
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: 8,
      marginTop: 12,
    }}
    >
      {children}
    </Text>
  ),
  p: ({ children }) => (
    <Text style={{
      fontSize: 10,
      color: '#444444',
      marginBottom: 6,
    }}
    >
      {children}
    </Text>
  ),
  ul: ({ children }) => (
    <View style={{ marginLeft: 20, marginBottom: 6 }}>
      {children}
    </View>
  ),
  ol: ({ children }) => (
    <View style={{ marginLeft: 20, marginBottom: 6 }}>
      {children}
    </View>
  ),
  li: ({ children }) => (
    <Text style={{
      fontSize: 10,
      color: '#444444',
      marginBottom: 4,
    }}
    >
      •
      {' '}
      {children}
    </Text>
  ),
  strong: ({ children }) => (
    <Text style={{
      color: '#000000',
      fontSize: 10,
      fontWeight: 'bold',
    }}
    >
      {children}
    </Text>
  ),

  // For chat-like content with classes
  div: ({ children }) => (
    <View style={{ marginBottom: 10 }}>
      {children}
    </View>
  ),
  span: ({ children }) => (
    <Text style={{
      fontSize: 9,
      color: '#444444',
    }}
    >
      {children}
    </Text>
  ),
};

export default function ResourceTab({ meetingDetails }) {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="h-full flex items-start gap-6">
      <div className="p-1.5 bg-gray-50 rounded-[10px] border border-[#eaecf0] flex flex-col gap-2">
        {/*  */}
        {
          TABS.map((tab, i) => (
            <button key={i} onClick={() => setSelectedTab(i)} className={`px-3 py-2 ${selectedTab === i ? 'bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06)]' : ''} rounded-md  text-[#344054] text-sm font-semibold font-inter leading-tight text-left`}>
              {tab.name}
            </button>
          ))
        }
      </div>

      <div className="h-full w-full bg-[#d0d5dd]/25 rounded-xl p-6">
        {/*  */}
        {
          selectedTab === 0 && <Files meetingDetails={meetingDetails} />
        }
        {
          selectedTab === 2 && <Conversation id={meetingDetails?.meeting?.room} input={false} />
        }
      </div>
    </div>
  );
}

function PDFDocument({ htmlContent }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Html renderers={renderers}>{htmlContent}</Html>
      </Page>
    </Document>
  );
}

function Files({ meetingDetails }) {
  const video = meetingDetails?.meeting?.files?.find((f) => f.fileType === 'webm');
  const handleDownloadResources = async (content, type) => {
    try {
      toast.loading('File processing for download.');
      const htmlContent = content;
      const blob = await pdf(<PDFDocument htmlContent={htmlContent} />).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${type || 'resource'}-${meetingDetails?.meeting?._id}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('PDF generation failed:', error);
    } finally {
      toast.dismiss();
      toast.success('Brief download started!', {
        closeButton: true,
      });
    }
  };
  return (
    <div className="flex flex-wrap gap-4">
      {/*  */}
      {
        video && (
          <a download={`Resource_${meetingDetails?.meeting?._id}`} href={`https://api.saybriefly.com/${video?.location}`} target="_blank" className="px-3 min-w-[100px] py-[15.30px] rounded-lg border border-[#d0d5dd] flex-col justify-start items-center gap-[5.74px] flex relative group" rel="noreferrer">
            <img src="/image/icons/mp4.svg" alt="" />
            <img src="/image/icons/download.svg" className="absolute top-1 right-1 group-hover:scale-110 transition-all" alt="" />
            <p className="text-center text-[#101828] text-xs font-medium font-inter leading-[17.21px]">
              Video.
              {video.fileType}
            </p>
          </a>
        )
      }
      {/* <a download={`Resource_${meetingDetails?.meeting?._id}`} href={`https://api.saybriefly.com/data/meetings/${meetingDetails?.meeting?._id}.webm`} target="_blank" className="px-3 min-w-[100px] py-[15.30px] rounded-lg border border-[#d0d5dd] flex-col justify-start items-center gap-[5.74px] flex relative group" rel="noreferrer">
        <img src="/image/icons/mp3.svg" alt="" />
        <img src="/image/icons/download.svg" className="absolute top-1 right-1 group-hover:scale-110 transition-all" alt="" />
        <p className="text-center text-[#101828] text-xs font-medium font-inter leading-[17.21px]">
          audio.mp3
        </p>
      </a> */}
      {
        meetingDetails?.transcript && (
          <button
            onClick={() => handleDownloadResources(meetingDetails?.transcript, 'transcript')}
            className="px-3 min-w-[100px] py-[15.30px] rounded-lg border border-[#d0d5dd] flex-col justify-start items-center gap-[5.74px] flex relative group"
          >
            <img src="/image/icons/txt.svg" alt="" />
            <img src="/image/icons/download.svg" className="absolute top-1 right-1 group-hover:scale-110 transition-all" alt="" />
            <p className="text-center text-[#101828] text-xs font-medium font-inter leading-[17.21px]">
              Transcript
            </p>
          </button>
        )
      }
      {
        meetingDetails?.briefHTML && (
          <button
            onClick={() => handleDownloadResources(meetingDetails?.briefHTML, 'Brief')}
            className="px-3 min-w-[100px] py-[15.30px] rounded-lg border border-[#d0d5dd] flex-col justify-start items-center gap-[5.74px] flex relative group"
          >
            <img src="/image/icons/txt.svg" alt="" />
            <img src="/image/icons/download.svg" className="absolute top-1 right-1 group-hover:scale-110 transition-all" alt="" />
            <p className="text-center text-[#101828] text-xs font-medium font-inter leading-[17.21px]">
              Brief
            </p>
          </button>
        )
      }

      {
        meetingDetails?.highlightHTML && (
          <button
            onClick={() => handleDownloadResources(meetingDetails?.highlightHTML, 'highlight')}
            className="px-3 min-w-[100px] py-[15.30px] rounded-lg border border-[#d0d5dd] flex-col justify-start items-center gap-[5.74px] flex relative group"
          >
            <img src="/image/icons/txt.svg" alt="" />
            <img src="/image/icons/download.svg" className="absolute top-1 right-1 group-hover:scale-110 transition-all" alt="" />
            <p className="text-center text-[#101828] text-xs font-medium font-inter leading-[17.21px]">
              Highlights
            </p>
          </button>
        )
      }

      {
        meetingDetails?.summaryHTML && (
          <button
            onClick={() => handleDownloadResources(meetingDetails?.summaryHTML, 'summary')}
            className="px-3 min-w-[100px] py-[15.30px] rounded-lg border border-[#d0d5dd] flex-col justify-start items-center gap-[5.74px] flex relative group"
          >
            <img src="/image/icons/txt.svg" alt="" />
            <img src="/image/icons/download.svg" className="absolute top-1 right-1 group-hover:scale-110 transition-all" alt="" />
            <p className="text-center text-[#101828] text-xs font-medium font-inter leading-[17.21px]">
              Summary
            </p>
          </button>
        )
      }

    </div>
  );
}
