function generateColorShades(baseHexColor) {
  function hexToHSL(hex) {
    // Remove "#" if present
    hex = hex.replace(/^#/, '');

    // Handle shorthand hex codes (e.g., #f00 → #ff0000)
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((char) => char + char)
        .join('');
    }

    // Convert hex to RGB
    const r = parseInt(hex.slice(0, 2), 16) / 255;
    const g = parseInt(hex.slice(2, 4), 16) / 255;
    const b = parseInt(hex.slice(4, 6), 16) / 255;

    // Compute HSL
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const l = (max + min) / 2;
    let h = 0;
    let s = 0;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default: {
          h = 0;
        }
      }

      h = Math.round(h * 60);
    }

    return {
      hue: h,
      saturation: Math.round(s * 100),
      lightness: Math.round(l * 100),
    };
  }

  function hslToHex(h, s, l) {
    s /= 100;
    l /= 100;
    const a = s * Math.min(l, 1 - l);
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  const colorRanges = [
    { name: 'Red', min: 355, max: 10 },
    { name: 'Scarlet', min: 11, max: 20 },
    { name: 'Orange Red', min: 21, max: 30 },
    { name: 'Orange', min: 31, max: 40 },
    { name: 'Golden Yellow', min: 41, max: 50 },
    { name: 'Yellow', min: 51, max: 60 },
    { name: 'Lime', min: 61, max: 80 },
    { name: 'Green', min: 81, max: 140 },
    { name: 'Emerald', min: 141, max: 160 },
    { name: 'Cyan', min: 161, max: 180 },
    { name: 'Sky Blue', min: 181, max: 200 },
    { name: 'Blue', min: 201, max: 220 },
    { name: 'Royal Blue', min: 221, max: 240 },
    { name: 'Indigo', min: 241, max: 260 },
    { name: 'Purple', min: 261, max: 280 },
    { name: 'Violet', min: 281, max: 300 },
    { name: 'Magenta', min: 301, max: 320 },
    { name: 'Pink', min: 321, max: 340 },
    { name: 'Rose', min: 341, max: 354 },
  ];

  function getColorName(hsl) {
    if (hsl.saturation < 5) {
      if (hsl.lightness < 10) return 'Black';
      if (hsl.lightness > 90) return 'White';
      return hsl.lightness < 50 ? 'Dark Gray' : 'Light Gray';
    }

    if (hsl.saturation < 15) return 'Gray';

    if (hsl.hue >= 355 || hsl.hue <= 10) return 'Red';

    const colorMatch = colorRanges.find(
      (range) => hsl.hue >= range.min && hsl.hue <= range.max,
    );
    return colorMatch ? colorMatch.name : 'Unknown';
  }

  const lightnessValues = {
    50: 96,
    100: 90,
    200: 80,
    300: 70,
    400: 60,
    500: 50,
    600: 40,
    700: 30,
    800: 20,
    900: 10,
  };

  const baseHSL = hexToHSL(baseHexColor);

  const baseShade = Object.entries(lightnessValues).reduce(
    (closest, [name, lightness]) => {
      const diff = Math.abs(lightness - baseHSL.lightness);
      return diff < closest.diff ? { name, diff } : closest;
    },
    { name: '500', diff: Infinity },
  );

  const shades = Object.entries(lightnessValues).map(([name, lightness]) => ({
    name,
    hexcode: name === baseShade.name ? baseHexColor.toLowerCase() : hslToHex(baseHSL.hue, baseHSL.saturation, lightness),
    hsl: {
      hue: baseHSL.hue,
      saturation: baseHSL.saturation,
      lightness,
    },
  }));

  return {
    name: getColorName(baseHSL),
    shades,
  };
}

export default generateColorShades;
