import axios from 'axios';
import { useCallback } from 'react';
import { toast } from 'sonner';
import { Button } from '../../../../../components/ui/button';
import Config from '../../../../../config';

export default function ConnectCalendar() {
  const handleConnectGoogleCalendar = useCallback(async () => {
    try {
      const { data: res } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/request_drive_integration?redirect_uri=${window.location.origin}/onboarding`);
      window.open(res.data.url, '_self');
    } catch (error) {
      console.log(error);

      if (error?.response?.data?.error) {
        toast.error(`${error?.response?.data?.error}`, { position: 'top-right' });
      }
    }
  }, []);
  return (
    <Button variant="outline" onClick={handleConnectGoogleCalendar} className="px-3 py-2 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border-2 border-[#173300] flex justify-center items-center gap-1 text-[#173300] text-sm font-semibold font-inter leading-tight">
      <img src="/image/icons/plus-circle.svg" className="size-4" alt="" />
      Connect Drive
    </Button>
  );
}
