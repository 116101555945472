import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import App from './App';
import './index.css';
import init from './init';
import TourProviderWrapper from './pages/Dashboard/components/tour-provider-wrapper';
import * as serviceWorker from './serviceWorker';
import store from './store';

const queryClient = new QueryClient();

init().then(() => {
  // Loading UIkit Icons plugin.
  UIkit.use(Icons);

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <GoogleOAuthProvider clientId="634674687127-4sah4reegu5rl7j3se065pmekq7980af.apps.googleusercontent.com">
      {/* <React.StrictMode> */}
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ToastProvider autoDismiss autoDismissTimeout={6000} placement="top-right">
              <App />
          </ToastProvider>
        </Provider>
      </QueryClientProvider>
      {/* </React.StrictMode> */}
    </GoogleOAuthProvider>,
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
