/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import './Login.sass';
import AcceptInvitation from './components/accept-invitation';
import ConfigWorkspace from './components/config-workspace';
import ConnectStorage from './components/connect-storage';
import SelectAccountType from './components/select-account-type';
import UpdateAvailability from './components/update-availability';
import URLEmbedContainer from './components/url-embed';

const accountTypes = [
  {
    text: 'Free',
    id: 'free',
    img: '/image/cat.png',
    color: '#ecfccb',
  },
  {
    text: 'Solopreneur',
    id: 'solopreneur',
    img: '/image/icons/rocket.png',
    color: '#f5d0fe',
  },
  {
    text: 'Team',
    id: 'small_teams',
    img: '/image/icons/clap-dark.png',
    color: '#99f6e4',
  },
];

function Onboarding() {
  // const [workspaceName, setWorkspaceName] = useState('');
  const [selectedAccountType, setSelectedAccountType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useGlobal('user');
  const [searchParams, setSearchParams] = useSearchParams();
  const { scope } = Object.fromEntries(searchParams.entries());
  const initialStep = parseInt(searchParams.get('step'), 10) || 0;
  const [step, setStep] = useState(initialStep);
  const navigate = useNavigate();

  useEffect(() => {
    if (scope && scope.includes('https://www.googleapis.com/auth/drive.file')) {
      setStep(2);
    } else if (scope && scope.includes('calendar')) {
      setStep(1);
    }
  }, [scope]);

  useEffect(() => {
    if (user && user.isOnboarded) {
      navigate('/');
      // toast.info('You are already onboarded.', {
      //   position: 'top-right',
      // });
      // console.log('User is onboarded', user);
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const newSearchParams = new URLSearchParams(searchParams);
    // If `step` is missing in the URL, set it for the first time
    if (searchParams.has('invitationCode')) {
      setStep('invitation');
    }
    if (!searchParams.has('step') && !searchParams.has('invitationCode')) {
      newSearchParams.set('step', '0');
      setSearchParams(newSearchParams);
    } else if (newSearchParams.get('step') !== String(step)) {
      newSearchParams.set('step', String(step));
      setSearchParams(newSearchParams);
    }
  }, [step, setSearchParams]);

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 lg:h-screen h-auto min-h-screen overflow-auto bg-[#fcfaf5]">
      <div
        className="flex flex-col justify-between p-6 items-center bg-contain bg-top bg-no-repeat"
        style={{
          backgroundImage: 'url("/image/bg/login-page-pattern.png")',
        }}
      >
        {step === 2 || step === 3 || step === 4 ? (
          <div className="w-full px-16 pt-8">
            <button
              onClick={() => {
                if (step > 1) {
                  setStep((step) => step - 1);
                }
              }}
              className="bg-white border border-gray-300 px-3 py-1 rounded-md flex justify-center items-center gap-2 font-medium"
            >
              Back
            </button>
          </div>
        ) : (
          <div />
        )}

        {step === 'invitation' && (
          <AcceptInvitation
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setStep={setStep}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        )}
        {step === 0 && (
          <SelectAccountType
            accountTypes={accountTypes}
            setSelectedAccountType={setSelectedAccountType}
            selectedAccountType={selectedAccountType}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setStep={setStep}
          />
        )}
        {step === 1 && <ConfigWorkspace setIsLoading={setIsLoading} isLoading={isLoading} setStep={setStep} />}
        {step === 2 && <ConnectStorage setIsLoading={setIsLoading} isLoading={isLoading} setStep={setStep} />}
        {step === 3 && <UpdateAvailability setIsLoading={setIsLoading} isLoading={isLoading} setStep={setStep} />}
        {step === 4 && <URLEmbedContainer setIsLoading={setIsLoading} isLoading={isLoading} />}
        <div className="w-full flex justify-between">
          <span className="text-[#475467] text-sm font-normal leading-tight">
            © SayBriefly {new Date().getFullYear()}
          </span>
          <a
            href="mailto:help@saybriefly.com"
            className="text-[#475467] text-sm font-normal font-inter leading-tight flex items-center gap-1"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="mail-01">
                <path
                  id="Icon"
                  d="M1.3335 4.66669L6.77678 8.47698C7.21756 8.78553 7.43795 8.9398 7.67767 8.99956C7.88943 9.05234 8.1109 9.05234 8.32265 8.99956C8.56238 8.9398 8.78277 8.78553 9.22355 8.47698L14.6668 4.66669M4.5335 13.3334H11.4668C12.5869 13.3334 13.147 13.3334 13.5748 13.1154C13.9511 12.9236 14.2571 12.6177 14.4488 12.2413C14.6668 11.8135 14.6668 11.2535 14.6668 10.1334V5.86669C14.6668 4.74658 14.6668 4.18653 14.4488 3.75871C14.2571 3.38238 13.9511 3.07642 13.5748 2.88467C13.147 2.66669 12.5869 2.66669 11.4668 2.66669H4.5335C3.41339 2.66669 2.85334 2.66669 2.42552 2.88467C2.04919 3.07642 1.74323 3.38238 1.55148 3.75871C1.3335 4.18653 1.3335 4.74658 1.3335 5.86669V10.1334C1.3335 11.2535 1.3335 11.8135 1.55148 12.2413C1.74323 12.6177 2.04919 12.9236 2.42552 13.1154C2.85334 13.3334 3.41339 13.3334 4.5335 13.3334Z"
                  stroke="currentColor"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
            help@saybriefly.com
          </a>
        </div>
      </div>
      <div className="p-6 lg:flex h-full justify-center items-center hidden">
        {(step === 0 || step === 'invitation') && (
          <div className="w-full h-full bg-[#ffeb5b] rounded-3xl overflow-hidden px-11 space-y-6 flex flex-col justify-end items-center">
            <div className="flex-1 flex items-center justify-center">
              <p className="max-w-[721.79px] text-center text-[#173300] text-7xl font-extrabold font-bricolage tracking-[3.03px]">
                Designed <br />
                for ambitious people
              </p>
            </div>
            <img src="/image/signup.png" className="w-auto h-[380px]" alt="" />
          </div>
        )}
        {step === 1 && (
          <div className="w-full h-full bg-black rounded-3xl overflow-hidden p-11 space-y-6 flex flex-col justify-end items-center">
            <div className="flex-1 flex items-center justify-center">
              <p className="max-w-[721.79px] text-center text-[#ffeb5b] text-8xl font-extrabold font-bricolage tracking-[3.03px]">
                Universal <br /> calendar
              </p>
            </div>
            <img src="/image/universal-calendar.png" className="max-w-[535px] object-contain h-auto" alt="" />
          </div>
        )}
        {step === 2 && (
          <div className="w-full h-full bg-[#f3cdfc] rounded-3xl overflow-hidden p-11 space-y-6 flex flex-col justify-end items-center">
            <div className="flex-1 flex items-center justify-center">
              <p className="max-w-[721.79px] text-center text-[#173300] text-8xl font-extrabold font-bricolage tracking-[3.03px]">
                Project <br />
                Spaces & <br /> Storage
              </p>
            </div>
            <img src="/image/project-space.png" className="max-w-[471px] object-contain h-auto" alt="" />
          </div>
        )}
        {step === 3 && (
          <div className="w-full h-full bg-black rounded-3xl overflow-hidden p-11 pb-0 space-y-6 flex flex-col justify-end items-center">
            <div className="flex-1 flex items-center justify-center">
              <p className="max-w-[721.79px] text-center text-[#f3cdfc] text-8xl font-extrabold font-bricolage tracking-[3.03px]">
                Collaboration <br />
                at it&apos;s finest
              </p>
            </div>
            <img src="/image/collab.png" className="max-w-[535px] object-contain h-auto" alt="" />
          </div>
        )}
        {step === 4 && (
          <div className="w-full h-full bg-[#DD6C3E] rounded-3xl overflow-hidden p-11 pb-0 space-y-6 flex flex-col justify-end items-center">
            <div className="flex-1 flex items-center justify-center">
              <p className="max-w-[721.79px] text-center text-[#173300] text-8xl font-extrabold font-bricolage tracking-[3.03px]">
                Never miss <br />a meeting
              </p>
            </div>
            <img src="/image/never-miss-art.png" className="w-full object-contain h-auto" alt="" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Onboarding;
