import acceptInvitation from 'actions/acceptInvitation';
import rejectInvitation from 'actions/rejectInvitation';
import { Button } from 'components/ui/button';
import { toast } from 'sonner';
import { useGlobal } from 'reactn';
import axios from 'axios';
import updateFirstTimeSub from 'actions/updateFirstTimeSubscription';
import { useEffect, useState } from 'react';
import Config from '../../../config';

export default function AcceptInvitation({ isLoading = false, setIsLoading, setStep, searchParams, setSearchParams }) {
  const [, setUser] = useGlobal('user');
  const [invitationCode, setInvitationCode] = useState(null);
  const [userName, setUserName] = useState('');
  const [workspaceNames, setWorkspaceNames] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setInvitationCode(searchParams.get('invitationCode'));
    getTeamSpaceData(searchParams.get('invitationCode'));
  }, []);

  const setUserData = async () => {
    try {
      const { data: user } = await axios({
        method: 'get',
        url: `${Config.url || ''}/api/v1/user/me`,
      });
      setUser(user.data);
    } catch (error) {
      console.log(error);
    }
  };

  const accept = async () => {
    try {
      setIsLoading(true);
      const { data: res } = await acceptInvitation(invitationCode);
      if (res.success) {
        toast.success(res.message, {
          position: 'top-center',
        });

        await updateFirstTimeSub('solopreneur');

        await setUserData();
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('invitationCode');
        newSearchParams.set('workspace_type', 'team');
        newSearchParams.set('teamCode', invitationCode);
        setSearchParams(newSearchParams);
        setStep(1);
      }
    } catch (error) {
      toast.error('Something went wrong!', {
        description: 'Please try again.',
        position: 'top-center',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTeamSpaceData = async (invitationCode) => {
    if (!invitationCode) return;
    try {
      const { data: res } = await axios.post(`${Config.url || ''}/api/v1/user/subscription/info_by_invitation_code`, {
        invitationCode,
      });
      setUserName(res.data.user_name);
      setWorkspaceNames(res.data.workspaceNames);
    } catch (error) {
      console.log(error);
    } finally {
      setLoaded(true);
    }
  };

  const reject = async () => {
    try {
      setIsLoading(true);
      const { data: res } = await rejectInvitation(invitationCode);
      if (res.success) {
        toast.success(res.message, {
          position: 'top-center',
        });

        await updateFirstTimeSub('solopreneur');

        await setUserData();
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('invitationCode');
        newSearchParams.set('workspace_type', 'team');
        newSearchParams.set('teamCode', invitationCode);
        setSearchParams(newSearchParams);
        setStep(1);
      }
    } catch (error) {
      toast.error('Something went wrong!', {
        description: 'Please try again.',
        position: 'top-center',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-4">
      <div
        className={`text-lg font-semibold text-black flex flex-col justify-center items-center gap-3 transition-all duration-150 ease-in ${loaded ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}
      >
        <div className="flex justify-center items-center gap-2">
          <span className="text-2xl font-bold text-[#173300]">{userName}</span>
          <span>has invited you to join</span>
        </div>
        <div className="flex justify-center items-center gap-2">
          <span>
            their team{workspaceNames?.length > 1 ? 's ' : ' '}
            {workspaceNames?.slice(0, 1).map((workspaceName, index) => (
              <span key={index}>
                {workspaceName}
              </span>
            ))}
            {workspaceNames?.length > 1 && (
              <span className="text-sm font-medium text-gray-500">+{workspaceNames.length - 1} more</span>
            )}
          </span>
        </div>
      </div>
      <div
        className={`w-full transition-all duration-150 ease-in ${loaded ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'} space-y-4`}
      >
        <Button onClick={accept} className="w-full" disabled={isLoading}>
          {isLoading ? (
            <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
          ) : (
            <span>Accept</span>
          )}
        </Button>
        <Button onClick={reject} className="w-full" variant="outline">
          Reject
        </Button>
      </div>
    </div>
  );
}
