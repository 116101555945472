/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../components/ui/dialog';
import BillingPage from '../pages/settings/billing';
import MyDetails from '../pages/settings/my-details';
import PricingPlan from '../pages/settings/plans';
import ProfileTeam from '../pages/settings/profile-team';
import VerifyEmailModal from '../pages/settings/verify-email-modal';
import AddTeamMemberModal from './add-team-modal';
import AddTeamSeatModal from './add-team-seat-modal';
import ConvertProfileModal from './convert-profile-modal';
import DeleteProfileModal from './delete-profile-modal';
import PricingModal from './pricing-modal';
import ProfilePicture from './profile-picture';

const navigation = [
  {
    id: 0,
    label: 'My details',
    slug: 'my-details',
  },
  {
    id: 1,
    label: 'Plan',
    slug: 'plan',
  },
  {
    id: 2,
    label: 'Team',
    slug: 'team',
  },
  {
    id: 3,
    label: 'Billing',
    slug: 'billing',
  },
  {
    id: 3,
    label: 'Danger zone',
    slug: 'billing',
  },
];

export default function ProfileSettingsModal() {
  const [user] = useGlobal('user');
  const [selectedTab, setSelectedTab] = useState(0);
  const [showProfileSetting, setShowProfileSetting] = useGlobal('showProfileSetting');
  const [searchParams, setSearchParams] = useSearchParams();
  const showProfile = searchParams.get('showProfile');
  const accountType = searchParams.get('accountType');
  const addTeam = searchParams.get('addTeam');
  const page = searchParams.get('page');
  const [showConvertProfile, setShowConvertProfile] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [showTeamAddSeatModal, setShowTeamAddSeatModal] = useState(false);
  const [showTeamAddMemberModal, setShowTeamAddMemberModal] = useState(false);
  const currentPlan = user?.activeSubscription?.plan;

  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (showProfile === 'true') {
      setShowProfileSetting(true);
    }
    if (accountType) {
      setSelectedTab(1);
    }
    if (page === 'plan') {
      setSelectedTab(1);
    }
    if (page === 'billing') {
      if (currentPlan === 'small_teams') {
        setSelectedTab(3);
      } else {
        setSelectedTab(2);
      }
    }
    if (addTeam === 'true') {
      setShowTeamAddMemberModal(true);
    }
  }, [showProfile, accountType, page]);

  const handleTriggerModal = (val) => {
    // console.log(e);
    if (!val) {
      setShowProfileSetting(val);
      searchParams.delete('showProfile');
      searchParams.delete('accountType');
      searchParams.delete('page');
      setSearchParams(searchParams);
    } else {
      setShowProfileSetting(val);
    }
  };

  return (
    <>
      <Dialog open={showProfileSetting} onOpenChange={handleTriggerModal}>
        {/* <DialogTrigger asChild /> */}
        <DialogContent
          closeButtonClassName="absolute bg-[#ffec5c] text-[#173300] scale-75 p-1.5 shadow rounded-full opacity-100 hover:scale-90 transition-all top-1 right-1"
          className="lg:max-w-4xl xl:max-w-[1366px] p-0 py-0 space-y-0 relative overflow-hidden"
        >
          <DialogHeader className="hidden">
            <DialogTitle />
            <DialogDescription />
          </DialogHeader>
          <div className="w-full overflow-y-auto overflow-x-hidden h-[90vh] scroll-none">
            <img src="/image/profile-cover-art.png" className="w-full m-0 rounded" alt="" />
            <div className="px-6 pb-6 space-y-6">
              <div className="flex items-center mt-[-45px] gap-6">
                {/*  */}
                <ProfilePicture />
                <div className="flex w-full justify-between items-center">
                  <div>
                    <h2 className="text-gray-900 text-2xl font-semibold leading-7">
                      {user?.firstName} {user?.lastName}
                    </h2>
                    <a href={`mailto:${user?.email}`} className="text-sm">
                      {user?.email}
                    </a>
                  </div>

                  {user?.activeSubscription?.plan !== 'small_teams' && (
                    <Button
                      onClick={() => {
                        setShowConvertProfile(true);
                        handleTriggerModal(false);
                        // setShowProfileSetting(false);
                      }}
                      className="flex gap-1.5 items-center hover:scale-95 transition-all"
                    >
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="stars-03" clipPath="url(#clip0_12773_22311)">
                          <path
                            id="Icon"
                            d="M15.416 6.71826V2.5516M4.58268 17.5516V13.3849M13.3327 4.63493H17.4993M2.49935 15.4683H6.66602M5.41602 1.71826L4.7623 3.02569C4.54106 3.46817 4.43044 3.68942 4.28266 3.88113C4.15152 4.05126 3.99901 4.20377 3.82889 4.3349C3.63717 4.48268 3.41593 4.59331 2.97345 4.81455L1.66602 5.46826L2.97345 6.12198C3.41593 6.34322 3.63717 6.45384 3.82889 6.60162C3.99901 6.73276 4.15152 6.88527 4.28266 7.05539C4.43044 7.24711 4.54106 7.46835 4.7623 7.91083L5.41602 9.21826L6.06973 7.91083C6.29097 7.46835 6.40159 7.24711 6.54937 7.05539C6.68051 6.88527 6.83302 6.73276 7.00314 6.60162C7.19486 6.45384 7.4161 6.34322 7.85859 6.12198L9.16602 5.46826L7.85859 4.81455C7.4161 4.59331 7.19486 4.48268 7.00314 4.3349C6.83302 4.20377 6.68051 4.05126 6.54937 3.88113C6.40159 3.68942 6.29097 3.46817 6.06973 3.02569L5.41602 1.71826ZM14.166 10.0516L13.3734 11.6368C13.1522 12.0793 13.0416 12.3005 12.8938 12.4922C12.7626 12.6624 12.6101 12.8149 12.44 12.946C12.2483 13.0938 12.027 13.2044 11.5846 13.4257L9.99935 14.2183L11.5846 15.0109C12.027 15.2321 12.2483 15.3427 12.44 15.4905C12.6101 15.6216 12.7626 15.7742 12.8938 15.9443C13.0416 16.136 13.1522 16.3572 13.3734 16.7997L14.166 18.3849L14.9586 16.7997C15.1799 16.3572 15.2905 16.136 15.4383 15.9443C15.5694 15.7742 15.7219 15.6216 15.892 15.4905C16.0838 15.3427 16.305 15.2321 16.7475 15.0109L18.3327 14.2183L16.7475 13.4257C16.305 13.2044 16.0838 13.0938 15.892 12.946C15.7219 12.8149 15.5694 12.6624 15.4383 12.4922C15.2905 12.3005 15.1799 12.0793 14.9586 11.6368L14.166 10.0516Z"
                            stroke="#FFED74"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_12773_22311">
                            <rect width="20" height="20" fill="white" transform="translate(0 0.0517578)" />
                          </clipPath>
                        </defs>
                      </svg>
                      Upgrade to Team
                    </Button>
                  )}
                  {user?.activeSubscription?.plan === 'small_teams' && (
                    <Button
                      onClick={() => {
                        setShowTeamAddMemberModal(true);
                        handleTriggerModal(false);
                      }}
                      className="flex gap-1.5 items-center hover:scale-95 transition-all"
                    >
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="plus-circle">
                          <path
                            id="Solid"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.9987 0.833984C4.93609 0.833984 0.832031 4.93804 0.832031 10.0007C0.832031 15.0633 4.93609 19.1673 9.9987 19.1673C15.0613 19.1673 19.1654 15.0633 19.1654 10.0007C19.1654 4.93804 15.0613 0.833984 9.9987 0.833984ZM9.9987 5.83398C10.4589 5.83398 10.832 6.20708 10.832 6.66732V9.16732H13.332C13.7923 9.16732 14.1654 9.54041 14.1654 10.0007C14.1654 10.4609 13.7923 10.834 13.332 10.834H10.832V13.334C10.832 13.7942 10.4589 14.1673 9.9987 14.1673C9.53846 14.1673 9.16536 13.7942 9.16536 13.334V10.834H6.66536C6.20513 10.834 5.83203 10.4609 5.83203 10.0007C5.83203 9.54041 6.20513 9.16732 6.66536 9.16732H9.16536V6.66732C9.16536 6.20708 9.53846 5.83398 9.9987 5.83398Z"
                            fill="#FCE657"
                          />
                        </g>
                      </svg>
                      Add team member
                    </Button>
                  )}
                </div>
              </div>

              <div className="">
                <ul className="flex overflow-auto scroll-none pl-0">
                  {navigation
                    ?.filter((item) => {
                      if (item.slug === 'team' && user?.activeSubscription?.plan !== 'small_teams') {
                        return false;
                      }
                      return true;
                    })
                    .map((item, i) => (
                      <li key={i}>
                        <button
                          onClick={() => setSelectedTab(i)}
                          className={`px-4 py-[12px] hover:text-gray-900 min-w-max inline-block ${
                            selectedTab === i ? 'text-gray-900 border-b-2 border-black' : 'text-slate-700 border-b'
                          }`}
                        >
                          {item.label}
                        </button>
                      </li>
                    ))}
                </ul>
                <div className="pt-4">
                  {/* <Outlet /> */}
                  <div className="px-6">
                    {selectedTab === 0 && (
                      <MyDetails
                        handleTriggerModal={handleTriggerModal}
                        editProfilePic={false}
                        setIsVerifyModalOpen={setIsVerifyModalOpen}
                      />
                    )}
                    {selectedTab === 1 && (
                      <PricingPlan
                        handleTriggerTeamModal={() => {
                          setShowTeamAddSeatModal(true);
                          handleTriggerModal(false);
                        }}
                      />
                    )}
                    {selectedTab === 2 &&
                      (currentPlan === 'small_teams' ? (
                        <ProfileTeam from="modal" callback={handleTriggerModal} />
                      ) : (
                        <BillingPage
                          callback={() => {
                            handleTriggerModal(false);
                            setShowTeamAddSeatModal(true);
                          }}
                        />
                      ))}
                    {selectedTab === 3 && currentPlan === 'small_teams' && (
                      <BillingPage
                        callback={() => {
                          handleTriggerModal(false);
                          setShowTeamAddSeatModal(true);
                        }}
                      />
                    )}
                    {(currentPlan === 'small_teams' && selectedTab === 4) ||
                    (currentPlan !== 'small_teams' && selectedTab === 3) ? (
                      <div>
                        <div className="border-2 border-dashed p-4 rounded-lg border-red-50 flex items-center justify-between hover:border-red-200 transition-all duration-300">
                          <div>
                            <h3 className="text-red-500 font-semibold">Delete your profile.</h3>
                            <p className="text-sm text-red-400">
                              This action is irreversible. All your data will be lost.
                            </p>
                          </div>
                          <Button
                            onClick={() => {
                              setShowDeleteModal(true);
                              handleTriggerModal(false);
                            }}
                            className="border-transparent bg-red-500 hover:bg-red-700 transition-all duration-300 text-white"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                            Delete profile
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/*   */}
      <ConvertProfileModal
        open={showConvertProfile}
        onOpenChange={setShowConvertProfile}
        next={() => setShowPricingModal(true)}
      />
      <AddTeamSeatModal
        open={showTeamAddSeatModal}
        onOpenChange={setShowTeamAddSeatModal}
        next={() => setShowProfileSetting(true)}
      />
      <AddTeamMemberModal isOpen={showTeamAddMemberModal} setIsOpen={setShowTeamAddMemberModal} />
      <PricingModal open={showPricingModal} onOpenChange={setShowPricingModal} />
      <VerifyEmailModal isVerifyModalOpen={isVerifyModalOpen} setIsVerifyModalOpen={setIsVerifyModalOpen} />
      <DeleteProfileModal open={showDeleteModal} setOpen={setShowDeleteModal} />
    </>
  );
}
