/* eslint-disable no-useless-escape */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from '../../../components/ui/button';
import { Checkbox } from '../../../components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../components/ui/dialog';
import Config from '../../../config';
import { cn } from '../../../lib/utils';
import MeetingHistoryItem from './meeting-history-item';
import { createProjectSpace, fetchMeetingsByUserEmail, fetchUserSuggestions } from '../../../actions/apiFunctions';

// Validation function (unchanged)
const validateEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

function AddProjectSpace({
  setProjectSpaceId = false, userEmails = [], userEmail = '', className,
}) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedMeetings, setSelectedMeetings] = useState([]);
  const queryClient = useQueryClient();

  // Query for user suggestions
  const { data: userSuggestion = [] } = useQuery({
    queryKey: ['userSuggestions', email],
    queryFn: () => fetchUserSuggestions(email),
    enabled: !!email && !validateEmail(email), // Fetch only when email is partial
    onError: (err) => {
      console.log(err);
    },
  });

  // Query for meeting history
  const { data: meetingHistory = [], isLoading: isLoadingHistory } = useQuery({
    queryKey: ['meetingHistory', email],
    queryFn: () => fetchMeetingsByUserEmail(email),
    enabled: validateEmail(email), // Fetch only when email is valid
    onError: (e) => {
      console.error(e);
      setSelectedMeetings([]); // Reset on error
    },
  });

  // Mutation for creating project space
  const { mutate: handleCreateSpace, isPending: isSubmitLoading } = useMutation({
    mutationFn: createProjectSpace,
    onSuccess: (data) => {
      toast.success(data.message, { position: 'top-right' });
      setName('');
      setEmail('');
      setOpen(false);
      queryClient.invalidateQueries(['resources']);
      if (setProjectSpaceId) {
        setProjectSpaceId(data?.data?._id);
      }
    },
    onError: (err) => {
      const message = err?.response?.data?.message || err?.response?.data?.error || 'Something went wrong';
      toast.error(message, { position: 'top-right' });
    },
  });

  // Set initial email from prop
  if (userEmail && email !== userEmail) {
    setEmail(userEmail);
  }

  const handleMeetingsByUserEmail = (emailInput) => {
    setEmail(emailInput);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button className={cn('px-3 py-2 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#d0d5dd] justify-center items-center gap-1 flex overflow-hidden text-[#344054] text-sm font-semibold font-inter leading-tight', className)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path id="add_box_2" d="M9.16667 14.1667H10.8333V10.8333H14.1667V9.16667H10.8333V5.83333H9.16667V9.16667H5.83333V10.8333H9.16667V14.1667ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM4.16667 15.8333H15.8333V4.16667H4.16667V15.8333Z" fill="#173300" />
          </svg>
          Create Space
        </button>
      </DialogTrigger>
      <DialogContent className="min-w-max p-[32px 30px] pt-[12px]">
        <DialogHeader>
          <DialogTitle className="hidden">Edit profile</DialogTitle>
          <DialogDescription className="hidden" />
        </DialogHeader>
        <div className="px-3 py-[22px] bg-[#eeeeee] rounded-2xl">
          <div className="space-y-4 flex-grow flex flex-col justify-center">
            <label htmlFor="name" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight">Project Space Name</span>
              <input
                type="text"
                placeholder="Name"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label>
            <label htmlFor="email" className="space-y-1 block relative">
              <span className="text-slate-700 text-sm font-medium leading-tight flex items-center gap-[6px]">
                Clients Email
              </span>
              <input
                type="email"
                placeholder="client@company.com"
                name="email"
                id="email"
                value={email}
                autoComplete="off"
                onChange={(e) => handleMeetingsByUserEmail(e.target.value)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
              {userSuggestion.length > 0 && !validateEmail(email) && (
                <div className="absolute w-full bg-white border top-[95%] left-0 rounded py-2 z-[50]">
                  {userSuggestion.map((user) => (
                    <button
                      key={user._id}
                      className="py-1.5 px-3 text-sm hover:bg-gray-50 cursor-pointer w-full flex text-left truncate"
                      onClick={() => handleMeetingsByUserEmail(user.contact_user.email)}
                    >
                      {user.contact_user.email}
                    </button>
                  ))}
                </div>
              )}
            </label>
            {isLoadingHistory ? (
              <span className="size-4 border-2 border-black rounded-full border-t-gray-300 animate-spin mx-auto mt-4" />
            ) : meetingHistory?.length > 0 && validateEmail(email) && (
              <>
                <div className="text-[#344054] text-sm font-medium font-['Inter'] leading-tight">
                  Select Previous Meeting associated with this space
                </div>
                <div className={`max-h-[260px] overflow-y-auto ${isLoadingHistory ? 'animate-pulse' : ''}`}>
                  <div className="space-y-3">
                    {meetingHistory?.map((meeting) => (
                      <div className="flex gap-2 items-center" key={meeting?._id}>
                        <Checkbox
                          id={meeting?._id}
                          className="border-[#d0d5dd]"
                          checked={selectedMeetings.includes(meeting?._id)}
                          onCheckedChange={(val) => {
                            if (val) {
                              setSelectedMeetings([...selectedMeetings, meeting?._id]);
                            } else {
                              setSelectedMeetings(selectedMeetings.filter((id) => id !== meeting?._id));
                            }
                          }}
                        />
                        <label htmlFor={meeting?._id} className="w-full">
                          <MeetingHistoryItem data={meeting} maxAvatar={1} />
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <Button onClick={() => handleCreateSpace({ name, userEmails, email, meetingIds: selectedMeetings })}>
            {isSubmitLoading ? (
              <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
            ) : (
              'Create'
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddProjectSpace;