/* eslint-disable import/no-unresolved */
/* eslint-disable object-curly-newline */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useGlobal } from 'reactn';
import addTeamMember from '../../../actions/addTeamMember';
import getUserInfo from '../../../actions/getUserInfo';
import getUserSuggestion from '../../../actions/getUserSuggestion';
import { Button } from '../../../components/ui/button';
import { Checkbox } from '../../../components/ui/checkbox';
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '../../../components/ui/dialog';
import { validateEmail } from '../../../lib/utils';

function AddTeamMemberModal({ setRefresh = () => {}, isOpen, setIsOpen }) {
  const [user, setUser] = useGlobal('user');
  const [teamSpaces] = useGlobal('teamSpaces');
  const workspaceId = user?.workspace?._id;
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userSuggestion, setUserSuggestion] = useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const addTeam = searchParams.get('addTeam');
  const [selectedTeamSpace, setSelectedTeamSpace] = useState([]);
  const { addToast: toast } = useToasts();

  const subscriptionData = user?.activeSubscription;

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getUserSuggestion(email);
        setUserSuggestion(data.data);
      } catch (err) {
        console.log(err);
        setUserSuggestion([]);
      }
    };
    if (email) {
      getData();
    } else {
      setUserSuggestion([]);
    }
  }, [email]);
  useEffect(() => {
    // console.log(addTeam);
    if (addTeam === 'true') {
      setIsOpen(true);
    }
  }, [addTeam]);
  // console.log(selectedTeamSpace);

  const handleAddTeamMember = async () => {
    try {
      setIsLoading(true);
      if (email) {
        const { data: res } = await addTeamMember(email, selectedTeamSpace);
        console.log(res);
        if (res.success) {
          setEmail('');
          setIsOpen(false);
          setRefresh((prev) => !prev);
          toast('Member Invited Successfully!', {
            position: 'top-center',
            appearance: 'success',
          });
          const { data: userRes } = await getUserInfo();
          if (userRes?.success) {
            setUser(userRes?.data);
          }
        } else {
          toast('Failed to invite member', {
            position: 'top-center',
            appearance: 'error',
          });
        }
      } else {
        toast('Please enter a valid email address', {
          position: 'top-center',
          appearance: 'error',
        });
      }
    } catch (err) {
      setEmail('');
      toast(err.response.data.error, {
        position: 'top-center',
        appearance: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTriggerModal = (val) => {
    setIsOpen(val);
    if (searchParams.has('addTeam')) {
      searchParams.delete('addTeam');
      setSearchParams(searchParams);
    }
  };

  const handleNavigateUpgradePlan = () => {
    // console.log('hell');
    if (searchParams.has('page')) {
      searchParams.set('page', 'plan');
    } else {
      searchParams.append('page', 'plan');
    }
    if (searchParams.has('showProfile')) {
      searchParams.set('showProfile', true);
    } else {
      searchParams.append('showProfile', true);
    }
    setSearchParams(searchParams);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleTriggerModal}>
      <DialogContent className="min-w-max bg-[#e9eef6]" closeButtonClassName="top-2 right-2">
        <DialogTitle className="hidden">Add Team Member</DialogTitle>
        <DialogDescription className="hidden" />

        {subscriptionData?.subMembers?.length >= subscriptionData?.totalSeatsAllocated ? (
          <div className="flex flex-col gap-4">
            <p className="text-slate-600 font-inter leading-normal">
              You have reached the maximum number of team members.
            </p>

            <div>
              <Button onClick={handleNavigateUpgradePlan}>Upgrade Plan</Button>
            </div>
          </div>
        ) : (
          <div className="space-y-4 flex-grow flex flex-col justify-center">
            {/* <label htmlFor="name" className="space-y-1 block">
              <span className="text-slate-700 text-sm font-medium leading-tight">Full name</span>
              <input
                type="text"
                placeholder="Full name"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
            </label> */}
            <label htmlFor="email" className="space-y-2 block relative">
              <span className="text-[#344054] text-sm font-medium font-inter leading-tight">Member Email</span>
              <input
                type="email"
                placeholder="user@example.com"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
                className="w-full block py-2.5 px-4 outline-none bg-gray-50 rounded-md border border-slate-300 focus:ring-0 focus:outline-none"
              />
              {userSuggestion.length > 0 && !validateEmail(email) && (
                <div className="absolute w-full bg-white border top-[95%] left-0 rounded py-2">
                  {userSuggestion.map((user) => (
                    <button
                      key={user._id}
                      className="py-1.5 px-3 text-sm hover:bg-gray-50 cursor-pointer w-full flex text-left truncate"
                      onClick={() => {
                        setEmail(user.contact_user.email);
                      }}
                    >
                      {user.contact_user.email}
                    </button>
                  ))}
                </div>
              )}
            </label>
            <div className="space-y-4">
              <p className="text-[#344054] text-sm font-medium font-inter leading-tight">Select Workspace</p>

              {teamSpaces.filter(item => item.createdBy === user?._id)?.map((item, i) => (
                <div key={item._id} className="flex items-center space-x-2">
                  <Checkbox
                    onCheckedChange={() => {
                      if (selectedTeamSpace.includes(item._id)) {
                        // If item._id is in the array, remove it
                        setSelectedTeamSpace(selectedTeamSpace.filter((id) => id !== item._id));
                      } else {
                        // If item._id is not in the array, add it
                        setSelectedTeamSpace([...selectedTeamSpace, item._id]);
                      }
                    }}
                    id={item._id}
                    checked={selectedTeamSpace.includes(item._id)}
                    className="border-gray-400 rounded size-5"
                  />
                  <label
                    htmlFor={item._id}
                    className="px-[30px] py-4 bg-white w-full rounded-lg justify-start items-center gap-3 flex cursor-pointer"
                  >
                    <p className="text-[#344054] text-sm font-medium font-inter leading-tight">{item.name}</p>
                  </label>
                </div>
              ))}
            </div>
            <div>
              <Button onClick={handleAddTeamMember}>
                {isLoading ? (
                  <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                ) : (
                  'Invite'
                )}
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AddTeamMemberModal;
