/* eslint-disable import/prefer-default-export */
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';

import { cn } from '../../lib/utils';
import { buttonVariants } from './button';

function Calendar({ className, classNames, showOutsideDays = true, isPastDate, ...props }) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-6 sm:space-x-6 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-lg font-medium text-calenderPrimary',
        nav: 'space-x-1 flex items-center ',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'size-5 smd:size-10 size-8 bg-transparent p-0 opacity-50 hover:opacity-100 hover:bg-calenderPrimary hover:text-white',
        ),
        nav_button_previous: 'absolute left-1 border-none',
        nav_button_next: 'absolute right-1 border-none',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-slate-500 rounded-md md:w-16 w-12 font-normal text-[1rem] text-primaryCalender',
        row: 'flex w-full mt-2',
        cell: 'md:size-16 size-12 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-slate-100/50 [&:has([aria-selected])]:bg-slate-100 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-16 dark:[&:has([aria-selected].day-outside)]:bg-slate-800/50 dark:[&:has([aria-selected])]:bg-slate-800 text-primary ',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'md:size-16 size-10 p-0 font-normal aria-selected:opacity-100 hover:text-white text-sm',
        ),
        day_range_end: 'day-range-end',
        day_selected:
          'bg-primary text-slate-50 font-medium hover:bg-primary hover:text-slate-50 focus:bg-primary focus:text-slate-50',
        // day_today: 'dark:bg-slate-800 dark:text-slate-50',
        day_outside:
          'day-outside text-slate-500 opacity-50 aria-selected:bg-slate-100/50 aria-selected:text-slate-500 aria-selected:opacity-30',
        day_disabled: 'text-slate-500 opacity-50 cursor-disabled',
        day_range_middle:
          'aria-selected:bg-slate-100 aria-selected:text-primary dark:aria-selected:bg-slate-800 dark:aria-selected:text-slate-50',
        day_hidden: 'invisible',
        ...classNames,
      }}
      modifiers={{
        pastDate: { before: new Date() },
      }}
      modifiersClassNames={{
        pastDate: 'opacity-30',
      }}
      components={{
        IconLeft: () => <ChevronLeft className="size-6" />,
        IconRight: () => <ChevronRight className="size-6" />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
