export default function MeetingNotFound() {
  return (
    <div className="w-full flex flex-col p-4 items-center gap-6 justify-center">
      <img src="/image/history-not-found.png" width={300} alt="" />

      <h4 className="text-center text-black text-3xl font-extrabold font-bricolage tracking-wider">
        Comfy couch, happy cat...
        {' '}
        <br />
        Just need some meetings to look back on
      </h4>
    </div>
  );
}
