/* eslint-disable object-curly-newline */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import {
  acceptSubscriptionInvitation,
  acceptWorkspaceInvitation,
  editWorkspace,
  fetchCurrentWorkspace,
  rejectSubscriptionInvitation,
  rejectWorkspaceInvitation,
} from '../../../../actions/apiFunctions';
import { Button } from '../../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../../components/ui/dialog';
import { Input } from '../../../../components/ui/input';
import DragAndDropInput from '../../components/dndInput';

function Workspace() {
  const [workspaceName, setWorkspaceName] = useState('');
  const [brandID, setBrandID] = useState('');
  const [brandLogo, setBrandLogo] = useState(null);
  const [workspaceId, setWorkspaceId] = useState(null);
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
  const [next, setNext] = useState('');
  const [user] = useGlobal('user');
  const [searchParams] = useSearchParams();
  const invitationCode = searchParams.get('invitationCode');
  const invitationType = searchParams.get('type');
  const queryClient = useQueryClient();

  // Query for current workspace
  const { data: currentWorkspace } = useQuery({
    queryKey: ['currentWorkspace'],
    queryFn: fetchCurrentWorkspace,
    onError: (err) => console.log(err),
  });

  useEffect(() => {
    if (currentWorkspace) {
      setWorkspaceName(currentWorkspace?.workspace?.name);
      setBrandID(currentWorkspace?.workspace?.brandID);
      setBrandLogo(currentWorkspace?.workspace?.brandLogo?.location);
      setWorkspaceId(currentWorkspace?.workspace?._id);
    }
  }, [currentWorkspace]);

  // Mutation for editing workspace
  const { mutate: handleEditWorkSpace, isPending: isLoading } = useMutation({
    mutationFn: editWorkspace,
    onSuccess: async (res) => {
      if (res.success) {
        queryClient.invalidateQueries(['currentWorkspace']);
        queryClient.invalidateQueries(['user']);
        toast.success('Workspace updated successfully.', { position: 'top-right' });
      }
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.response?.data?.message || 'Something went wrong', { position: 'top-center' });
    },
  });

  // Mutation for accepting invitation
  const { mutate: verifyOTP, isPending: isVerifyLoading } = useMutation({
    mutationFn: () =>
      invitationType === 'workspace'
        ? acceptWorkspaceInvitation(invitationCode)
        : acceptSubscriptionInvitation(invitationCode),
    onSuccess: async (res) => {
      if (res.success) {
        queryClient.invalidateQueries(['currentWorkspace']);
        queryClient.invalidateQueries(['user']);
        setIsVerifyModalOpen(false);
        toast.success(res.message, { position: 'top-center' });
        window.location.href =
          next === 'onboarding' ? `${window.location.origin}/onboarding` : `${window.location.origin}/settings`;
        localStorage.removeItem('next');
      }
    },
    onError: (err) => {
      console.log(err);
      toast.error('Something went wrong!', {
        description: 'Please try again.',
        position: 'top-center',
      });
    },
  });

  // Mutation for rejecting invitation
  const { mutate: rejectInvitation } = useMutation({
    mutationFn: () =>
      invitationType === 'workspace'
        ? rejectWorkspaceInvitation(invitationCode)
        : rejectSubscriptionInvitation(invitationCode),
    onSuccess: async (res) => {
      if (res.success) {
        queryClient.invalidateQueries(['user']);
        setIsVerifyModalOpen(false);
        toast.success(res.message, { position: 'top-center' });
        window.location.href =
          next === 'onboarding' ? `${window.location.origin}/onboarding` : `${window.location.origin}/settings`;
        localStorage.removeItem('next');
      }
    },
    onError: (err) => {
      console.log(err);
      toast.error('Something went wrong!', {
        description: 'Please try again.',
        position: 'top-center',
      });
    },
  });

  // Handle invitation code
  useEffect(() => {
    if (invitationCode) {
      setIsVerifyModalOpen(true);
      const nextTask = localStorage.getItem('next');
      if (nextTask === 'onboarding') {
        setNext('onboarding');
      }
    }
  }, [invitationCode]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!workspaceName || !workspaceId) return;
    const formData = new FormData();
    formData.append('name', workspaceName);
    formData.append('description', workspaceName);
    if (typeof brandLogo === 'object') {
      formData.append('brandLogo', brandLogo);
    }
    formData.append('brandID', brandID);
    handleEditWorkSpace({ workspaceId, formData });
  };

  return (
    <>
      <div
        className={`grid lg:grid-cols-2 grid-cols-1 gap-6 ${
          user?.workspace?.createdBy !== user?._id ? 'opacity-60 pointer-events-none' : ''
        }`}
      >
        <div className="mt-4 space-y-6">
          <div className="bg-white rounded-lg border border-slate-300">
            <hr />
            <div className="px-6 py-4 space-y-4">
              <div className="space-y-2">
                <div>
                  <h4 className="text-slate-700 text-base font-medium leading-normal">Workspace Name</h4>
                </div>
                <Input
                  placeholder="Workspace Name"
                  onChange={(e) => setWorkspaceName(e.target.value)}
                  value={workspaceName}
                />
              </div>

              <div className="space-y-2">
                <div>
                  <h4 className="text-slate-700 text-base font-medium leading-normal">Workspace ID</h4>
                </div>
                <div className="flex items-center gap-1">
                  <h4 className="text-slate-700 text-base font-medium leading-normal">@</h4>
                  <Input placeholder="Workspace ID" onChange={(e) => setBrandID(e.target.value)} value={brandID} />
                </div>
              </div>

              <div className="space-y-1.5">
                <div>
                  <h4 className="text-slate-700 text-base font-medium leading-normal">Brand Logo</h4>
                  <p className="text-slate-500 text-sm font-normal font-inter leading-tight">
                    Change the brand logo for the survey
                  </p>
                </div>
                <div className="my-3 flex items-start gap-4">
                  <div className="w-full">
                    <DragAndDropInput
                      callback={(imageObject) => setBrandLogo(imageObject)}
                      placeHolderFileUrl={brandLogo}
                    />
                  </div>
                </div>
              </div>

              {user?.workspace?.createdBy === user?._id && (
                <div className="flex gap-4">
                  <Button onClick={handleSubmit}>{isLoading ? 'Saving...' : 'Save'}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog open={isVerifyModalOpen} onOpenChange={setIsVerifyModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Accept Invitation.</DialogTitle>
            <DialogDescription>Accept invitation to join the workspace.</DialogDescription>
          </DialogHeader>
          <div className="flex gap-4 w-full mt-4">
            <Button onClick={() => verifyOTP(invitationCode)} className="flex items-center gap-1">
              Accept
              {isVerifyLoading && (
                <span className="size-2.5 border border-white border-t-transparent ml-1 animate-spin rounded-full block" />
              )}
            </Button>
            <Button
              className="bg-white hover:bg-gray-50 text-slate-700 border"
              onClick={() => rejectInvitation(invitationCode)}
            >
              Reject
              {isVerifyLoading && (
                <span className="size-2.5 border border-black border-t-transparent ml-1 animate-spin rounded-full block" />
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Workspace;
