import { useGoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import { useState } from 'react';
import { FiLogIn, FiUser } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import forgotPassword from '../../actions/forgotPassword';
import loginWithGoogle from '../../actions/googleLogin';
import registerWithGoogle from '../../actions/googleRegister';
import initIO from '../../actions/initIO';
import login from '../../actions/login';
import register from '../../actions/register';
import resetPassword from '../../actions/resetPassword';
import setAuthToken from '../../actions/setAuthToken';
import ContinueAsGuestForm from '../../components/auth/ContinueAsGuestForm';
import { Checkbox } from '../../components/ui/checkbox';
import {
  InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot,
} from '../../components/ui/input-otp';
import { getCookie, removeCookie } from '../../lib/utils';
import './Login.sass';
import Input from './components/Input';
import SuccessItem from './components/success-item';

function Login() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keep, setKeep] = useState(true);
  const [loginErrors, setLoginErrors] = useState({});

  const [emailCode, setEmailCode] = useState('');

  // const [registerUsername, setRegisterUsername] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerFirstName, setRegisterFirstName] = useState('');
  const [registerLastName, setRegisterLastName] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  // const [registerRepeatPassword, setRegisterRepeatPassword] = useState('');
  const [registerErrors, setRegisterErrors] = useState({});

  const [activePage, setActivePage] = useState('login');

  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);

  const setToken = useGlobal('token')[1];
  const setUser = useGlobal('user')[1];
  const [entryPath, setEntryPath] = useGlobal('entryPath');

  const navigate = useNavigate();

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data: res } = await login(email, password);
      if (keep) localStorage.setItem('token', res.data.token);
      if (keep) {
        if (getCookie('jwt_token')) removeCookie('jwt_token');
        if (window.location.hostname === 'localhost') document.cookie = `jwt_token=${res.data.token}; path=/; domain=localhost; secure; SameSite=None;`;
        else document.cookie = `jwt_token=${res.data.token}; path=/; domain=.saybriefly.com; secure; SameSite=None;`;
      }
      if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));
      setLoginErrors({});
      setAuthToken(res.data.token);
      setUser(jwtDecode(res.data.token));
      setToken(res.data.token);
      dispatch(initIO(res.data.token));
      if (new URL(window.location.href).searchParams.has('meetId')) {
        window.location.href = `https://meet.saybriefly.com/${new URL(window.location.href).searchParams.get(
          'meetId',
        )}`;
      } else {
        navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
      }
      // else if (entryPath?.startsWith('/meeting')) {
      //   window.location.href = `https://meet.saybriefly.com/${entryPath?.replace('/meeting/', '')}`;
      // }

      await setEntryPath(null);
    } catch (e) {
      let errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      else errors = e.response.data;
      setLoginErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onRegister = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const timezone = moment.tz.guess();
      const { data: res } = await register({
        username: registerEmail.split('@')[0],
        email: registerEmail,
        firstName: registerFirstName,
        lastName: registerLastName,
        fullname: `${registerFirstName} ${registerLastName}`,
        password: registerPassword,
        repeatPassword: registerPassword,
        timezone,
      });
      if (res.success) {
        toast.success('Registration Successful', {
          position: 'top-center',
        });
      }
      setRegisterErrors({});
      if (keep) localStorage.setItem('token', res.data.token);
      if (keep) {
        if (getCookie('jwt_token')) removeCookie('jwt_token');
        if (window.location.hostname === 'localhost') document.cookie = `jwt_token=${res.data.token}; path=/; domain=localhost; secure; SameSite=None;`;
        else document.cookie = `jwt_token=${res.data.token}; path=/; domain=.saybriefly.com; secure; SameSite=None;`;
      }
      if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));
      setAuthToken(res.data.token);
      setUser(jwtDecode(res.data.token));
      setToken(res.data.token);
      dispatch(initIO(res.data.token));
      if (new URL(window.location.href).searchParams.has('meetId')) {
        navigate(`/onboarding?meetId=${new URL(window.location.href).searchParams.get('meetId')}`);
      } else {
        navigate(`/onboarding${new URL(window.location.href).searchParams.has('invitationCode') ? `?invitationCode=${new URL(window.location.href).searchParams.get('invitationCode')}` : ''}`);
      }
      // navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
      // await setEntryPath(null);
    } catch (e) {
      console.log(e);
      let errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      else errors = e.response.data;
      setRegisterErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onGuestContinue = async (res) => {
    try {
      setIsLoading(true);
      setRegisterErrors({});
      if (keep) localStorage.setItem('token', res.data.token);
      if (keep) {
        if (getCookie('jwt_token')) removeCookie('jwt_token');
        if (window.location.hostname === 'localhost') document.cookie = `jwt_token=${res.data.token}; path=/; domain=localhost; secure; SameSite=None;`;
        else document.cookie = `jwt_token=${res.data.token}; path=/; domain=.saybriefly.com; secure; SameSite=None;`;
      }
      if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));
      setAuthToken(res.data.token);
      setUser(jwtDecode(res.data.token));
      setToken(res.data.token);
      // dispatch(initIO(res.data.token));
      if (new URL(window.location.href).searchParams.has('meetId')) {
        console.log('https://meet.saybriefly.com/', new URL(window.location.href).searchParams.get('meetId'));
        window.location.href = `https://meet.saybriefly.com/${new URL(window.location.href).searchParams.get(
          'meetId',
        )}`;
      } else {
        navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
      }
      // else if (entryPath?.startsWith('/meeting')) {
      //   window.location.href = `https://meet.saybriefly.com/${entryPath?.replace('/meeting/', '')}`;
      // }
      await setEntryPath(null);
    } catch (e) {
      console.log(e);
      let errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      else errors = e.response.data;
      setRegisterErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { data: res } = await loginWithGoogle(tokenResponse?.access_token);
        if (keep) localStorage.setItem('token', res?.data?.token);
        if (keep) {
          if (getCookie('jwt_token')) removeCookie('jwt_token');
          if (window.location.hostname === 'localhost') document.cookie = `jwt_token=${res.data.token}; path=/; domain=localhost; secure; SameSite=None;`;
          else document.cookie = `jwt_token=${res.data.token}; path=/; domain=.saybriefly.com; secure; SameSite=None;`;
        }
        if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));
        setLoginErrors({});
        setAuthToken(res.data.token);
        setUser(res.data.user);
        setToken(res.data.token);
        dispatch(initIO(res.data.token));
        if (res.data?.isNewUser) {
          if (new URL(window.location.href).searchParams.has('meetId')) {
            navigate(`/onboarding?meetId=${new URL(window.location.href).searchParams.get('meetId')}`);
          } else {
            navigate('/onboarding');
          }
        } else if (new URL(window.location.href).searchParams.has('meetId')) {
          window.location.href = `https://meet.saybriefly.com/${new URL(window.location.href).searchParams.get(
            'meetId',
          )}`;
        } else {
          navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
        }
        // else if (entryPath?.startsWith('/meeting')) {
        //   window.location.href = `https://meet.saybriefly.com/${entryPath?.replace('/meeting/', '')}`;
        // }

        await setEntryPath(null);
      } catch (e) {
        console.log(e);
        let errors = {};
        if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
        else errors = e?.response?.data;
        setLoginErrors(errors);
      } finally {
        setIsGoogleLoading(false);
      }
    },
    onError: (e) => {
      const errors = {
        message: (e?.error === 'access_denied' ? 'Access denied' : e?.error || e?.message) || 'Something went wrong',
      };
      setLoginErrors(errors);
      setIsGoogleLoading(false);
      console.log(e);
    },
    onNonOAuthError: (e) => {
      const errors = {
        message: e.message,
      };
      setLoginErrors(errors);
      setIsGoogleLoading(false);
      console.log(e);
    },
  });

  const googleRegister = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const timezone = moment.tz.guess();
        const { data: res } = await registerWithGoogle(tokenResponse?.code, timezone);
        if (keep) localStorage.setItem('token', res?.data?.token);
        if (keep) {
          if (getCookie('jwt_token')) removeCookie('jwt_token');
          if (window.location.hostname === 'localhost') document.cookie = `jwt_token=${res.data.token}; path=/; domain=localhost; secure; SameSite=None;`;
          else document.cookie = `jwt_token=${res.data.token}; path=/; domain=.saybriefly.com; secure; SameSite=None;`;
        }
        if (keep) localStorage.setItem('user', JSON.stringify(jwtDecode(res.data.token)));
        setLoginErrors({});
        setAuthToken(res.data.token);
        setUser(jwtDecode(res.data.token));
        setToken(res.data.token);
        dispatch(initIO(res.data.token));
        if (res.data?.isNewUser) {
          if (new URL(window.location.href).searchParams.has('meetId')) {
            navigate(`/onboarding?meetId=${new URL(window.location.href).searchParams.get('meetId')}`);
          } else {
            navigate(`/onboarding${new URL(window.location.href).searchParams.has('invitationCode') ? `?invitationCode=${new URL(window.location.href).searchParams.get('invitationCode')}` : ''}`);
          }
        } else if (new URL(window.location.href).searchParams.has('meetId')) {
          window.location.href = `https://meet.saybriefly.com/${new URL(window.location.href).searchParams.get(
            'meetId',
          )}`;
        } else {
          navigate(['/login', '/'].includes(entryPath) ? '/' : entryPath, { replace: true });
        }

        // else if (entryPath?.startsWith('/meeting')) {
        //   window.location.href = `https://meet.saybriefly.com/${entryPath?.replace('/meeting/', '')}`;
        // }

        await setEntryPath(null);
      } catch (e) {
        console.log(e);
        let errors = {};
        if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
        else errors = e?.response?.data;
        setLoginErrors(errors);
      } finally {
        setIsGoogleLoading(false);
      }
    },
    onError: (e) => {
      const errors = {
        message: (e?.error === 'access_denied' ? 'Access denied' : e?.error || e?.message) || 'Something went wrong',
      };
      setLoginErrors(errors);
      setIsGoogleLoading(false);
      console.log(e);
    },
    onNonOAuthError: (e) => {
      const errors = {
        message: e.message,
      };
      setLoginErrors(errors);
      setIsGoogleLoading(false);
      console.log(e);
    },
    flow: 'auth-code',
    // scope: 'email profile openid https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.file',
    scope:
      'email profile openid https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.file',
    access_type: 'offline',
    prompt: 'consent',
    redirect_uri: 'https://app.saybriefly.com',
    include_granted_scopes: true,
  });

  const onResetPassword = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data: res } = await forgotPassword(email);
      console.log(res);
      if (res.success) {
        toast.success('One time password has been sent to your email.', {
          position: 'top-center',
        });
        setActivePage('reset-password');
      }
    } catch (e) {
      let errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      else errors = e.response.data;
      setLoginErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdatePassword = async (e) => {
    e.preventDefault();
    if (!email && !password && !emailCode) {
      toast.error('Please fill all the input fields.', {
        position: 'top-center',
      });
      return;
    }
    try {
      setIsLoading(true);
      const { data: res } = await resetPassword(email, emailCode, password);
      console.log(res);
      if (res.success) {
        toast.success('Password changed successfully.', {
          position: 'top-center',
        });
        setActivePage('login');
        setEmailCode('');
        setPassword('');
      }
    } catch (e) {
      const errors = {};
      if (!e.response || typeof e.response.data !== 'object') errors.generic = 'Could not connect to server.';
      setLoginErrors(errors);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (entryPath?.startsWith('/meeting')) {
  //     setActivePage('ask-for-meeting');
  //   }
  // }, []);

  const loginInfo = Object.keys(loginErrors).map((key) => (
    <div className="uk-text-center" key={key}>
      <span className="uk-text-danger">{loginErrors[key]}</span>
    </div>
  ));

  const registerInfo = Object.keys(registerErrors).map((key) => (
    <div className="uk-text-center" key={key}>
      <span className="uk-text-danger">{registerErrors[key]}</span>
    </div>
  ));

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 lg:h-screen h-auto min-h-screen overflow-auto bg-[#fcfaf5]">
      <div
        className="flex flex-col justify-between p-6 items-center bg-contain bg-top bg-no-repeat"
        style={{
          backgroundimg: 'url("/image/bg/login-page-pattern.png")',
        }}
      >
        <div />
        <div className="login-inner lg:w-[360px] w-full uk-padding-small" data-uk-scrollspy="cls: uk-animation-fade">
          {/* <Logo /> */}
          {activePage === 'login' ? (
            <div className="text-center mb-6 w-full space-y-3">
              <img src="/image/logo-beta.svg" alt="Login" className="mx-auto hidden lg:inline-block mb-6" />
              <img src="/logo-mark.svg" alt="Login" className="mx-auto lg:hidden" />
              <h4 className="text-[#101828] text-3xl font-semibold">Login</h4>
              <p className="text-[#475467]">Please enter your details.</p>
            </div>
          ) : activePage === 'continue-as-guest' ? (
            <div className="text-center mb-6 w-full space-y-3">
              <h4 className="text-[#101828] text-3xl font-semibold">Continue as guest</h4>
              <p className="text-[#475467]">Continue as guest. Please enter your details.</p>
            </div>
          ) : activePage === 'ask-for-meeting' ? (
            <div className="text-center mb-6 w-full space-y-3">
              <h4 className="text-[#101828] text-3xl font-semibold">Get started</h4>
              <p className="text-[#475467]">Select how you want to get started</p>
            </div>
          ) : activePage === 'forgot-password' || activePage === 'reset-password' ? (
            <div className="mb-4 w-full space-y-3">
              <h4 className="text-[#101828] text-3xl font-semibold">Reset password</h4>
              <p className="text-[#475467]">Enter your email to reset your password.</p>
            </div>
          ) : (
            <div className="mb-4 w-full space-y-3">
              <img src="/logo-mark.svg" alt="Register" className="lg:hidden" />
              <h4 className="text-[#101828] text-3xl font-semibold">Sign up</h4>
              <p className="text-[#475467]">Start your 30-day free trial.</p>
            </div>
          )}

          <div className="w-full">
            {(activePage === 'login' || activePage === 'register')
              && window.location
              && new URL(window.location.href).searchParams.has('meetId') && (
                <button
                  onClick={() => {
                    setActivePage('continue-as-guest');
                  }}
                  className="w-full px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex mb-4"
                >
                  <FiUser />
                  <span> Continue as Guest </span>
                </button>
            )}
            {activePage === 'login' && (
              <form onSubmit={onLogin}>
                <fieldset className="lg:space-y-6 space-y-3">
                  {loginInfo}
                  <Input
                    icon="user"
                    placeholder="Username (or email)"
                    label="Email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    icon="lock"
                    placeholder="Password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="my-4 flex flex-col lg:flex-row gap-3 items-center justify-between">
                    <label className="flex items-center gap-1">
                      <Checkbox
                        checked={keep}
                        onCheckedChange={(e) => setKeep(e)}
                        className="rounded overflow-hidden"
                      />
                      {/* <input
                          className="uk-checkbox"
                          type="checkbox"
                          onChange={(e) => setKeep(e.target.checked)}
                          checked={keep}
                        /> */}
                      {' '}
                      <span className="text-[#344054] text-sm font-medium leading-tight">Remember for 30 days</span>
                    </label>
                    <button
                      type="button"
                      onClick={() => setActivePage('forgot-password')}
                      className="text-[#323333] text-sm font-semibold leading-tight"
                    >
                      Forgot password
                    </button>
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      className="w-full px-4 py-2.5 bg-black rounded-lg shadow border border-black justify-center items-center gap-1.5 inline-flex text-white text-base font-semibold leading-normal"
                    >
                      {isLoading ? (
                        <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                      ) : (
                        <span>Sign in</span>
                      )}
                    </button>
                  </div>
                </fieldset>
              </form>
            )}
            {activePage === 'forgot-password' && (
              <form onSubmit={onResetPassword}>
                <fieldset className="space-y-6">
                  {loginInfo}
                  <Input
                    placeholder="Email"
                    label="Email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div>
                    <button
                      type="submit"
                      className="w-full px-4 py-2.5 bg-black rounded-lg shadow border border-black justify-center items-center gap-1.5 inline-flex text-white text-base font-semibold leading-normal"
                    >
                      {isLoading ? (
                        <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                      ) : (
                        <span>Reset Password</span>
                      )}
                    </button>
                  </div>
                </fieldset>
              </form>
            )}
            {activePage === 'reset-password' && (
              <form onSubmit={onUpdatePassword}>
                <fieldset className="space-y-3">
                  {loginInfo}
                  <Input
                    placeholder="Email"
                    label="Email"
                    type="text"
                    disabled
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div>
                    <p className="text-slate-700 text-sm font-medium leading-tight mb-2 block">OTP</p>
                    <InputOTP
                      onChange={(e) => setEmailCode(e)}
                      value={emailCode}
                      maxLength={6}
                      containerClassName="bg-white w-full"
                    >
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={2} />
                        <InputOTPSlot index={3} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </div>
                  <Input
                    placeholder="New Password"
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* <OTPInput></OTPInput> */}
                  <div>
                    <button
                      type="submit"
                      className="w-full px-4 py-2.5 bg-black rounded-lg shadow border border-black justify-center items-center gap-1.5 inline-flex text-white text-base font-semibold leading-normal"
                    >
                      {isLoading ? (
                        <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                      ) : (
                        <span>Reset Password</span>
                      )}
                    </button>
                  </div>
                </fieldset>
              </form>
            )}

            {activePage === 'register' && (
              <form onSubmit={onRegister} className="lg:space-y-6 space-y-3">
                {registerInfo}
                {/* <Input
                    icon="user"
                    placeholder="Username"
                    label="Username"
                    type="text"
                    onChange={(e) => setRegisterUsername(e.target.value)}
                  /> */}
                <Input
                  icon="mail"
                  placeholder="Email"
                  label="Email"
                  type="email"
                  onChange={(e) => setRegisterEmail(e.target.value)}
                />
                <Input
                  icon="pencil"
                  placeholder="First Name"
                  label="First Name"
                  type="text"
                  onChange={(e) => setRegisterFirstName(e.target.value)}
                />
                <Input
                  icon="pencil"
                  placeholder="Last Name"
                  label="Last Name"
                  type="text"
                  onChange={(e) => setRegisterLastName(e.target.value)}
                />
                <Input
                  icon="lock"
                  placeholder="••••••••"
                  label="Password"
                  type="password"
                  onChange={(e) => setRegisterPassword(e.target.value)}
                />
                {/* <Input
                    icon="lock"
                    placeholder="••••••••"
                    label="Repeat Password"
                    type="password"
                    onChange={(e) => setRegisterRepeatPassword(e.target.value)}
                  /> */}
                <div>
                  <button
                    type="submit"
                    className="w-full px-4 py-2.5 bg-black rounded-lg shadow border border-black justify-center items-center gap-1.5 inline-flex text-white text-base font-semibold leading-normal"
                  >
                    {isLoading ? (
                      <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
                    ) : (
                      <span>Get Started</span>
                    )}
                  </button>
                </div>
              </form>
            )}

            {activePage === 'ask-for-meeting' && (
              <div className="flex gap-3">
                <button
                  onClick={() => {
                    setActivePage('continue-as-guest');
                  }}
                  className="w-full px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
                >
                  <FiUser />
                  <span> Guest </span>
                </button>

                <button
                  onClick={() => {
                    setActivePage('login');
                  }}
                  className="w-full px-4 py-2.5 bg-black text-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
                >
                  <FiLogIn />
                  <span> Login </span>
                </button>
              </div>
            )}
            {activePage === 'continue-as-guest' && (
              <ContinueAsGuestForm onGuestContinue={onGuestContinue} entryPath={entryPath} />
            )}

            {/* <form className="toggle-password" hidden>
              <Input icon="mail" placeholder="Email" type="email" />
              <div className="uk-margin-bottom">
                <button type="submit" className="uk-button uk-button-primary uk-border-pill uk-width-1-1">
                  SEND CODE
                </button>
              </div>
            </form> */}
            {activePage === 'login' && (
              <div className="mt-6">
                <button
                  onClick={() => {
                    setIsGoogleLoading(true);
                    googleLogin();
                  }}
                  className="w-full px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
                >
                  {isGoogleLoading ? (
                    <span className="h-5 w-5 border-2 border-black rounded-full border-t-transparent animate-spin" />
                  ) : (
                    <>
                      <img src="/image/icons/google.svg" className="h-6 w-6" alt="" />
                      <span className="text-slate-700 text-base font-semibold leading-normal">
                        {activePage === 'login' ? 'Sign in with Google' : 'Sign up with Google'}
                      </span>
                    </>
                  )}
                </button>
              </div>
            )}
            {activePage === 'register' && (
              <div className="mt-6">
                <button
                  onClick={() => {
                    setIsGoogleLoading(true);
                    googleRegister();
                  }}
                  className="w-full px-4 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-3 inline-flex"
                >
                  {isGoogleLoading ? (
                    <span className="h-5 w-5 border-2 border-black rounded-full border-t-transparent animate-spin" />
                  ) : (
                    <>
                      <img src="/image/icons/google.svg" className="h-6 w-6" alt="" />
                      <span className="text-slate-700 text-base font-semibold leading-normal">
                        {activePage === 'login' ? 'Sign in with Google' : 'Sign up with Google'}
                      </span>
                    </>
                  )}
                </button>
              </div>
            )}

            <div className="mt-6">
              {activePage === 'register' ? (
                <p className="text-center">
                  Already registered?
                  {' '}
                  <span onClick={() => setActivePage('login')} className="cursor-pointer text-[#323333]">
                    Login now
                  </span>
                </p>
              ) : (
                <p className="text-center">
                  Don&apos;t have an account?
                  {' '}
                  <span onClick={() => setActivePage('register')} className="cursor-pointer text-[#323333]">
                    Sign up
                  </span>
                </p>
              )}

              {/* <div className="uk-text-center" style={{ marginTop: 12 }}>
                <a className="uk-link-reset uk-text-small" href="#">
                  <Link to="/forgot-password">Forgot your password?</Link>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="w-full text-center lg:text-left mt-6">
          © SayBriefly
          {new Date().getFullYear()}
        </div>
      </div>
      <div className="p-6 lg:flex h-full justify-center items-center hidden">
        {activePage === 'login' && (
          <div className="w-full h-full bg-[#f6d0ff] rounded-3xl overflow-hidden px-11 pb-6 pt-8 space-y-6 flex flex-col justify-between">
            <div className="flex flex-1 items-center">
              <div className="space-y-6">
                <h2 className="font-bricolage text-[#173300] text-[80px] font-extrabold leading-[80px] tracking-[3.03px]">
                  Meeting
                  {' '}
                  <br />
                  {' '}
                  intelligence,
                  {' '}
                  <br />
                  not just
                  {' '}
                  <br />
                  {' '}
                  meeting
                  {' '}
                  <br />
                  {' '}
                  notes.
                </h2>

                <div className="flex gap-6">
                  <div className="2xl:min-w-max min-w-[200px]">
                    <img src="/login-scratch.svg" alt="" />
                  </div>
                  <div className="min-w-max">
                    <img src="/login-art.png" className="max-w-[320px] -mt-16" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <div className="text-[#475467] text-sm font-normal font-inter leading-tight">
                Integrates with:
                <img src="/image/icons/gmeet.svg" alt="" className="h-5 w-5 inline-block ml-2" />
              </div>
              <a
                href="mailto:help@saybriefly.com"
                className="text-[#475467] text-sm font-normal font-inter leading-tight flex gap-2 items-center hover:text-[#173300]"
              >
                <svg
                  className="size-4"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="mail-01">
                    <path
                      id="Icon"
                      d="M1.33301 4.66669L6.77629 8.47698C7.21707 8.78553 7.43746 8.9398 7.67718 8.99956C7.88894 9.05234 8.11041 9.05234 8.32217 8.99956C8.56189 8.9398 8.78228 8.78553 9.22306 8.47698L14.6663 4.66669M4.53301 13.3334H11.4663C12.5864 13.3334 13.1465 13.3334 13.5743 13.1154C13.9506 12.9236 14.2566 12.6177 14.4484 12.2413C14.6663 11.8135 14.6663 11.2535 14.6663 10.1334V5.86669C14.6663 4.74658 14.6663 4.18653 14.4484 3.75871C14.2566 3.38238 13.9506 3.07642 13.5743 2.88467C13.1465 2.66669 12.5864 2.66669 11.4663 2.66669H4.53301C3.4129 2.66669 2.85285 2.66669 2.42503 2.88467C2.0487 3.07642 1.74274 3.38238 1.55099 3.75871C1.33301 4.18653 1.33301 4.74658 1.33301 5.86669V10.1334C1.33301 11.2535 1.33301 11.8135 1.55099 12.2413C1.74274 12.6177 2.0487 12.9236 2.42503 13.1154C2.85285 13.3334 3.4129 13.3334 4.53301 13.3334Z"
                      stroke="#667085"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                help@saybriefly.com
              </a>
            </div>
          </div>
        )}

        {activePage !== 'login' && (
          <div className="w-full h-full bg-[#ffeb5b] rounded-3xl overflow-hidden px-11 py-6 space-y-6 flex flex-col justify-between">
            <div className="flex-1 flex justify-center items-center">
              <div className="flex flex-col h-full w-full space-y-6 justify-center">
                <div className="flex flex-col lg:flex-row justify-center lg:-space-x-8">
                  <SuccessItem
                    title="Clients"
                    description="Chat with your contractors without the hassle, review and discuss projects, provide feedback and communicate without taking notes."
                    className="bg-[#fcfaf5] origin-top-left rotate-[6.30deg] lg:ml-0 ml-6"
                    illustration="/image/clients.png"
                  />
                  <SuccessItem
                    title="Freelancers"
                    description="Stay on top of bookings and client needs, remember meetings, team up on briefs, and run projects without those 'he said, she said' headaches."
                    className="origin-top-left rotate-[-11deg] bg-[#f6d0ff] relative top-12"
                    illustration="/image/freelancers.png"
                  />
                </div>
                <div className="flex flex-col lg:flex-row justify-center gap-12">
                  <SuccessItem
                    title="Small Studios"
                    description="Juggle clients and contractors, keep chatter flowing, and give each client their own project space without the chaos."
                    className="relative lg:top-6 origin-top-left rotate-[-6.99deg] bg-[#a8e5e5]"
                    illustration="/image/s-studios.png"
                  />
                  <SuccessItem
                    title="Startups"
                    description="Run feedback sessions, implement updates, and keep investors in the loop - all with easy note-sharing and dedicated project spaces."
                    className="relative origin-top-left rotate-[4.38deg] bg-[#D5F5C2]"
                    illustration="/image/freelancers.png"
                  />
                </div>
              </div>
            </div>
            <div className="text-[#475467] text-sm font-normal font-inter leading-tight">
              Integrates with:
              <img src="/image/icons/gmeet.svg" alt="" className="h-5 w-5 inline-block ml-2" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
