/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { Button } from '../../../components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import Config from '../../../config';
import { convertTimeStringToMinutes } from '../../../lib/utils';
import MeetingHostEmailSelector from './meeting-host-email-selector';
import MeetingScheduleSuccessModalUser from './meeting-schedule-success-modal-user';
import MeetingSourceSelector from './meeting-source-selector';

export default function UploadICSFileButton() {
  const [user] = useGlobal('user');
  const [file, setFile] = useState(null);
  const [icsData, setIcsData] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useGlobal('refreshMeetings');
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [scheduleId, setScheduleId] = useState('');
  const [slot, setSlot] = useState(0);
  const [loading, setLoading] = useState(false);
  const [meetingHostId, setMeetingHostId] = useState('');
  const [meetingSource, setMeetingSource] = useState('google');
  const [guestEmail, setGuestEmail] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [bookingData, setBookingData] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!file) return;
    const getData = async () => {
      const data = new FormData();
      data.append('file', file);
      const { data: res } = await axios.post(`${Config.url || ''}/api/v1/user/meeting_from_ics`, data);

      setIcsData(res.data);
      const { attendee, organizer } = res.data;
      const attendeeEmail = attendee?.val?.toLowerCase()?.replace('mailto:', '');
      const organizerEmail = organizer?.val?.toLowerCase()?.replace('mailto:', '');
      const isOrganizer = organizerEmail === user?.email?.toLowerCase();
      const isAttendee = attendeeEmail === user?.email?.toLowerCase();

      if (isOrganizer) {
        setGuestEmail(attendeeEmail);
      } else if (isAttendee) {
        setGuestEmail(organizerEmail);
      } else {
        setGuestEmail('');
      }
    };
    getData();
  }, [file]);

  useEffect(() => {
    if (!open) return;
    setLoading(true);
    axios
      .get(`${Config.url || ''}/api/v1/user/book_schedule/get_bookings_with_schedule`)
      .then((data) => {
        const schedule = data?.data?.data?.schedule;
        const slot = Number(convertTimeStringToMinutes(schedule?.timeSlot));
        setSlot(slot);

        setSelectedTimezone(schedule?.timeZone);
        setScheduleId(schedule?._id);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [open]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setDragActive(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile?.type === 'text/calendar') {
      setFile(droppedFile);
    } else {
      // toast.error('Please upload a valid .ics file.');
      alert('Please upload a valid .ics file.');
    }
  };

  const handleUploadICS = async () => {
    if (!file) return;

    if (!guestEmail) {
      alert('Please enter a guest email.');
      return;
    }

    try {
      setIsLoading(true);

      const {
        start, end, summary, description,
      } = icsData;

      const startDate = moment(start)?.format('MM-DD-YYYY');
      const startTime = moment(start)?.format('HH:mm');

      const formData = new FormData();
      formData.append('date', startDate);
      formData.append('time', startTime);
      formData.append('workspaceID', user?.workspace?.brandID);
      formData.append('meetingSource', meetingSource);
      formData.append('meetingHostId', meetingHostId);
      formData.append('timeZone', selectedTimezone);

      const { data: bookScheduleRes } = await axios.post(`${Config.url || ''}/api/v1/user/book_schedule`, formData);

      const bookingId = bookScheduleRes?.data?._id;
      const meetingId = bookScheduleRes?.data?.meeting;

      console.log(meetingId, bookingId);
      console.log(bookScheduleRes);

      const guestFormData = {
        scheduleId,
        bookingId,
        guests: [{ name: 'Guest User', email: guestEmail }],
      };

      const { data: guestRes } = await axios.post(`${Config.url || ''}/api/v1/user/book_schedule/save_all_guests`, guestFormData);

      console.log(guestRes);

      const bookingFormData = new FormData();
      // bookingFormData.append('formId', selectedForm?._id);
      bookingFormData.append('bookingId', bookingId);
      bookingFormData.append('preMeetingFormRequired', false);
      bookingFormData.append('meetingSource', meetingSource);
      bookingFormData.append('meetingHostId', meetingHostId);

      const { data: inviteRes } = await axios.post(`${Config.url || ''}/api/v1/user/book_schedule/save_n_invite`, bookingFormData);

      console.log(inviteRes);
      setBookingData(inviteRes?.data?.booking);
      setShowSuccessModal(true);
    } catch (err) {
      toast.error(err?.response?.data.error, {
        position: 'top-center',
      });
    } finally {
      setOpen(false);
      setFile(null);
      setIsLoading(false);
      setRefresh(!refresh);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger>
          <span className="p-2.5 text-[#1a2e05] hover:bg-[#1733001A] rounded-tl-full rounded-bl-full flex items-center gap-2 text-xs font-medium font-inter leading-[18px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path id="convert_to_text_2" d="M7.5 12.75H10.5V11.25H7.5V12.75ZM7.5 9.75H12.75V8.25H7.5V9.75ZM5.25 6.75H12.75V5.25H5.25V6.75ZM1.5 16.5V15H3.4125C2.8125 14.7125 2.32812 14.2875 1.95938 13.725C1.59063 13.1625 1.40625 12.525 1.40625 11.8125C1.40625 10.825 1.75313 9.98438 2.44688 9.29062C3.14062 8.59687 3.98125 8.25 4.96875 8.25V9.75C4.40625 9.75 3.92188 9.95 3.51562 10.35C3.10938 10.75 2.90625 11.2375 2.90625 11.8125C2.90625 12.3 3.05625 12.7313 3.35625 13.1063C3.65625 13.4813 4.0375 13.7188 4.5 13.8188V12H6V16.5H1.5ZM7.5 15.75V14.25H14.25V3.75H3.75V6.75H2.25V3.75C2.25 3.3375 2.39687 2.98438 2.69062 2.69063C2.98438 2.39688 3.3375 2.25 3.75 2.25H14.25C14.6625 2.25 15.0156 2.39688 15.3094 2.69063C15.6031 2.98438 15.75 3.3375 15.75 3.75V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 15.6031 14.6625 15.75 14.25 15.75H7.5Z" fill="#173300" />
            </svg>
            Drop ICS file
          </span>
        </DialogTrigger>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Drop your ICS file here</DialogTitle>
            <DialogDescription className="hidden" />
          </DialogHeader>

          {loading ? (
            <div className="p-12 flex items-center justify-center">
              <span className="size-4 border-4 p-4 rounded-full border-[#173300] animate-spin border-t-[#FFED74]" />
            </div>
          ) : (
            <>
              {
                !file && (
                  <label
                    htmlFor="ics-upload"
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    className={`relative flex flex-col items-center justify-center w-full py-6 border-2 ${dragActive ? 'border-blue-500' : 'border-gray-300'
                      } border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100`}
                  >
                    <div className="text-center">
                      <div className="rounded-md flex justify-center mx-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="lucide lucide-upload-cloud"
                        >
                          <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" />
                          <path d="M12 12v9" />
                          <path d="m16 16-4-4-4 4" />
                        </svg>
                      </div>
                      <p className="mt-2 text-sm text-gray-600">
                        <span className="font-semibold">Drag files</span>
                      </p>
                      <p className="text-xs text-gray-500">Click to upload files (files should be under 10 MB )</p>
                    </div>
                    <Input
                      className="hidden"
                      id="ics-upload"
                      name="ics-file"
                      type="file"
                      accept=".ics"
                      onChange={handleFileChange}
                    />
                  </label>
                )
              }

              {file && (
                <div className="w-full flex flex-col gap-4">
                  <div>
                    <p className="text-sm font-medium leading-tight m-0">
                      Ics File:
                    </p>
                    <p className="flex items-center justify-between gap-1 mt-2 text-gray-500 text-sm font-semibold border p-2 rounded">
                      <span className="w-[350px] truncate">{file?.name}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4 mt-1 cursor-pointer hover:text-red-500"
                        onClick={() => setFile(null)}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium leading-tight m-0">
                      Meeting Source & Host:
                    </p>
                    <p className=" text-black text-sm font-medium leading-tight mt-2 flex gap-2 items-center w-full border p-2 rounded">
                      <img className="w-4 h-4" src="/image/icons/video.svg" alt="" />
                      <MeetingSourceSelector setMeetingSource={setMeetingSource} meetingSource={meetingSource} className="max-w-full" />
                    </p>
                  </div>
                  {meetingSource === 'google' && (
                    <p className=" text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center w-full border p-2 rounded">
                      <img className="w-4 h-4" src="/image/icons/avatar.svg" alt="" />
                      <MeetingHostEmailSelector
                        setMeetingHostId={setMeetingHostId}
                        meetingSource={meetingSource}
                        meetingHostId={meetingHostId}
                        className="max-w-full"
                      />
                    </p>
                  )}

                  <div>
                    <p className="text-sm font-medium leading-tight mb-2">
                      Guest Email:
                    </p>
                    <Input className="w-full" placeholder="Enter guest email" value={guestEmail} onChange={(e) => setGuestEmail(e.target.value)} />
                  </div>
                </div>
              )}

              <DialogFooter className="sm:justify-start">
                <Button onClick={handleUploadICS} size="sm" className="px-6">
                  Save
                  {isLoading && (
                    <span className="size-3 border-2 rounded-full border-white border-t-transparent animate-spin ml-2" />
                  )}
                </Button>
                <DialogClose asChild>
                  <Button type="button" variant="secondary">
                    Cancel
                  </Button>
                </DialogClose>
              </DialogFooter>
            </>
          )}

        </DialogContent>
      </Dialog>

      <MeetingScheduleSuccessModalUser
        open={showSuccessModal}
        setOpen={setShowSuccessModal}
        date={bookingData?.bookingDate}
        time={bookingData?.bookingTime}
        timeZone={selectedTimezone}
        bookingId={bookingData?._id}
        scheduleId={scheduleId}
        userName={user.username}
        slot={slot}
      />
    </>
  );
}
