/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-unresolved */
import moment from 'moment';
import { useState } from 'react';
import { Calendar } from '../../../../../components/ui/calendar';
import { cn, convertTo24Hour, generateTimeArray, isTimeLater } from '../../../../../lib/utils';
import { isPastDate } from '../scheduling';
import MeetingInfo from './meeting-info';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const timeFormat = ['12h', '24h'];

const isSameDate = (date1, date2) => moment(date1).isSame(date2, 'day');

const shouldDisable = (bookings, date, timeStr, selectedTimezone) =>
  bookings.some(
    (booking) =>
      isSameDate(booking?.bookingDate, selectedTimezone ? moment(date).tz(selectedTimezone) : date) &&
      convertTo24Hour(booking?.bookingTime) === convertTo24Hour(timeStr),
  );

function Scheduler({
  setSelectedTimezone,
  meetingSource,
  setMeetingSource,
  date,
  setDate,
  daysOfWeek,
  setSelectedTime,
  selectedTime,
  bookings,
  slot,
  meetingHostId,
  setMeetingHostId,
  selectedTimezone,
}) {
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(timeFormat[0]);
  // const { addToast } = useToasts();

  const timeSlots = () => {
    const currentDay = moment.tz(date, selectedTimezone).day();
    const daySchedule = daysOfWeek[currentDay];

    // Check if the day is active and has valid start and end times
    if (!daySchedule?.isActive || !daySchedule?.startTime || !daySchedule?.endTime) {
      return [];
    }

    // Generate time slots for the day
    const generatedSlots = generateTimeArray(
      daySchedule.startTime,
      daySchedule.endTime,
      selectedTimeFormat,
      slot,
      selectedTimezone,
    );

    // Filter out booked, past, and invalid time slots
    return generatedSlots.filter((item) => {
      const isBookedOrPast = shouldDisable(bookings, date, item, selectedTimezone);
      const isPastTimeToday = !isTimeLater(item, selectedTimezone) && isSameDate(date, moment().tz(selectedTimezone));

      return !(isBookedOrPast || isPastTimeToday);
    });
  };

  return (
    <div className="flex lg:flex-row flex-col gap-6 p-6 bg-[#EEEEEE] rounded-xl 2xl:max-w-[1400px] w-full">
      <MeetingInfo
        setSelectedTimezone={setSelectedTimezone}
        selectedTimezone={selectedTimezone}
        setMeetingSource={setMeetingSource}
        meetingSource={meetingSource}
        slot={slot}
        meetingHostId={meetingHostId}
        setMeetingHostId={setMeetingHostId}
      />
      <hr className="border-gray-500 lg:hidden" />
      <Calendar
        mode="single"
        selected={date}
        isPastDate={isPastDate(moment.tz(date, selectedTimezone)?.toDate() || new Date())}
        onSelect={(d) => {
          if (!isPastDate(d)) {
            setDate(d);
            setSelectedTime('');
          } else {
            // toast.error('You can not select a previous date.', {
            //   position: 'top-center',
            // });
          }
        }}
        className="flex justify-center lg:pr-6 px-0 lg:border-r border-calenderPrimary"
        classNames={{
          head_cell: `w-12 md:w-16 text-calenderPrimary`,
          cell: `size-16 text-calenderPrimary`,
          day: `size-14 lg:text-base font-semibold hover:bg-calenderPrimary hover:text-calenderSecondary rounded-xl`,
          day_selected: `size-14 bg-calenderPrimary text-calenderSecondary rounded-xl`,
          caption: `flex justify-center pt-1 relative items-center`,
        }}
      />
      <hr className="border-calenderPrimary lg:hidden" />
      <div className="space-y-6">
        <div className="flex items-center lg:justify-between justify-center gap-6">
          {/*  */}
          <p className="flex gap-[12px] items-center m-0">
            <span className="text-calenderPrimary text-base font-semibold leading-normal">
              {date && days[moment.tz(date, selectedTimezone).day()]}
            </span>
            <span className="text-calenderPrimary text-sm font-medium leading-tight">
              {date && moment.tz(date, selectedTimezone).date()}
            </span>
          </p>
          <div className="px-1 py-.5 rounded-md border border-calenderPrimary justify-end items-center gap-[13.66px] inline-flex">
            {timeFormat.map((item, i) => (
              <div
                key={i}
                onClick={() => setSelectedTimeFormat(item)}
                className={`px-3 py-[4.25px] cursor-pointer ${
                  selectedTimeFormat === item
                    ? 'bg-calenderPrimary rounded text-center text-white text-sm font-medium leading-[17.50px]'
                    : 'text-calenderPrimary'
                }`}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-2 lg:gap-3 gap-1.5 lg:max-h-[550px] max-h-[200px] w-[350px] overflow-auto p-2">
          {timeSlots().length > 0
            ? timeSlots()?.map((item, i) => (
                <button
                  key={i}
                  onClick={() =>
                    setSelectedTime((prev) => {
                      if (prev === item) {
                        return '';
                      }
                      return item;
                    })
                  }
                  className={cn(
                    `lg:px-12 py-2 rounded-lg border border-gray-300/50 text-calenderPrimary text-sm font-semibold leading-tight hover:bg-calenderPrimary min-w-max hover:text-white ${
                      selectedTime === item ? 'bg-calenderPrimary text-white' : 'bg-white'
                    }`,
                  )}
                >
                  {item}
                </button>
              ))
            : 'No Slots Available'}
        </div>
      </div>
    </div>
  );
}

export default Scheduler;
