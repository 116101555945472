/* eslint-disable indent */
import {
    Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle,
} from '../../../components/ui/dialog';
import PricingPlan from '../pages/settings/plans';

export default function PricingModal({ open, onOpenChange }) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {/* <DialogTrigger asChild /> */}
      <DialogContent className="lg:max-w-4xl xl:max-w-6xl relative overflow-hidden">
        <DialogHeader className="hidden">
          <DialogTitle />
          <DialogDescription />
        </DialogHeader>
        <div className="max-h-[90vh] overflow-auto scroll-none">
          <PricingPlan />
        </div>
      </DialogContent>
    </Dialog>
  );
}
