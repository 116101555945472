import InvoiceTableContainer from '../../components/invoice-table-container';
import Package from '../../components/package';
import PaymentMethod from '../../components/payment-methode';

function BillingPage({ callback = () => { } }) {
  return (
    <div className="space-y-8">
      <div>
        <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Billing</h4>
        <p className="text-slate-600 text-base font-normal leading-normal m-0">
          Manage your billing and payment details.
        </p>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
        <div>
          <Package callback={callback} />
        </div>
        <div>
          <PaymentMethod />
        </div>
      </div>
      <InvoiceTableContainer />

      <p className="text-[#183401] text-center text-sm font-normal">
        <a href="https://www.saybriefly.com/terms-of-use" target="_blank" rel="noopener noreferrer" className='hover:text-blue-500 font-bold'>Terms of Service</a>  -  <a href="https://www.saybriefly.com/privacy-policy" target="_blank" rel="noopener noreferrer" className='hover:text-blue-500 font-bold'>Privacy Policy</a>.
      </p>
    </div>
  );
}

export default BillingPage;
