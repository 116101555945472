import axios from 'axios';
import Config from '../config';

const deleteMeetingHistory = (id) => {
  return axios({
    method: 'delete',
    url: `${Config.url || ''}/api/v1/user/meeting/${id}`,
  });
};

export default deleteMeetingHistory;
