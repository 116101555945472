/* eslint-disable import/prefer-default-export */
export function DriveIcon() {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="logos:google-drive" clipPath="url(#clip0_12761_68194)">
        <path id="Vector" d="M1.97481 18.4306L2.98723 20.2077C3.1976 20.5819 3.49998 20.8759 3.855 21.0897C4.87184 19.7779 5.57967 18.7713 5.97848 18.0698C6.38321 17.3579 6.88063 16.2444 7.47074 14.7293C5.88029 14.5165 4.67507 14.4102 3.85509 14.4102C3.06805 14.4102 1.86277 14.5165 0.239258 14.7293C0.239258 15.1435 0.344445 15.5577 0.55482 15.9319L1.97481 18.4306Z" fill="#0066DA" />
        <path id="Vector_2" d="M19.5806 21.0897C19.9357 20.8759 20.2381 20.5819 20.4484 20.2078L20.8691 19.4729L22.8809 15.9319C23.0873 15.5658 23.1961 15.1513 23.1963 14.7293C21.5634 14.5165 20.3603 14.4102 19.5872 14.4102C18.7563 14.4102 17.5532 14.5165 15.978 14.7293C16.5611 16.2528 17.052 17.3663 17.4507 18.0698C17.8526 18.7796 18.5626 19.7862 19.5806 21.0897Z" fill="#EA4335" />
        <path id="Vector_3" d="M11.7178 7.24652C12.8943 5.80248 13.7051 4.68898 14.1502 3.90602C14.5087 3.27556 14.9031 2.26893 15.3335 0.886128C14.9785 0.672329 14.5709 0.56543 14.1502 0.56543H9.28539C8.86464 0.56543 8.45716 0.685726 8.10205 0.886128C8.64966 2.47203 9.11429 3.60069 9.49594 4.2721C9.91777 5.01411 10.6584 6.00558 11.7178 7.24652Z" fill="#00832D" />
        <path id="Vector_4" d="M15.9649 14.7285H7.47112L3.85547 21.0889C4.2104 21.3027 4.61797 21.4096 5.03872 21.4096H18.3973C18.818 21.4096 19.2257 21.2893 19.5806 21.0888L15.9649 14.7285Z" fill="#2684FC" />
        <path id="Vector_5" d="M11.7176 7.24604L8.10196 0.885742C7.74685 1.09954 7.44447 1.39345 7.23409 1.76764L0.55482 13.5263C0.348324 13.8924 0.239543 14.307 0.239258 14.7289H7.47074L11.7176 7.24604Z" fill="#00AC47" />
        <path id="Vector_6" d="M19.5414 7.64703L16.2017 1.76764C15.9914 1.39345 15.6889 1.09954 15.3339 0.885742L11.7183 7.24613L15.965 14.729H23.1834C23.1834 14.3147 23.0782 13.9006 22.8679 13.5264L19.5414 7.64703Z" fill="#FFBA00" />
      </g>
      <defs>
        <clipPath id="clip0_12761_68194">
          <rect width="22.9565" height="20.8696" fill="white" transform="translate(0.239258 0.56543)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function LinkIcon({ className }) {
  return (
    <svg width="21" height="20" className={className} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="link-04" clipPath="url(#clip0_12761_68675)">
        <path id="Icon" d="M7.01058 5.83301H6.59391C4.29273 5.83301 2.42725 7.69849 2.42725 9.99968C2.42725 12.3009 4.29273 14.1663 6.59391 14.1663H8.26058C10.5618 14.1663 12.4272 12.3009 12.4272 9.99968M14.5106 14.1663H14.9272C17.2284 14.1663 19.0939 12.3009 19.0939 9.99968C19.0939 7.69849 17.2284 5.83301 14.9272 5.83301H13.2606C10.9594 5.83301 9.09391 7.69849 9.09391 9.99968" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_12761_68675">
          <rect width="20" height="20" fill="white" transform="translate(0.760742)" />
        </clipPath>
      </defs>
    </svg>
  );
}
