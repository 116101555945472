/* eslint-disable no-unused-vars */

import { Progress } from '../../../components/ui/progress';

function UsageProgress({ value = 0, max = 100, unit = 'm' }) {
  // Calculate percentage for progress bar
  const percentage = (value === 0 && max === 0) ? 100 : ((value / max) * 100);

  return (
    <div className="relative pt-6">
      {' '}
      {/* Added top padding for floating label */}
      <div
        className="absolute top-4 z-10 transform -translate-y-full transition-all duration-300"
        style={{
          left: `${Math.min(Math.max((percentage > 100 ? 100 : percentage), 0), 100)}%`,
          transform: 'translateX(-50%) translateY(-100%)',
        }}
      >
        <div className="relative px-2 py-[5.33px] bg-white rounded-full border border-gray-300 shadow text-[#344054] min-w-max text-xs font-medium font-inter leading-3">
          {value}
          {' '}
          {unit}
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[5px] border-r-[5px] border-t-[8px] border-transparent border-t-[#173300]" />
        </div>
      </div>
      <div
        className="absolute top-4 transform -translate-y-full transition-all duration-300"
        style={{
          left: '100%',
          transform: 'translateX(-100%) translateY(-100%)',
        }}
      >
        <div className="px-2 py-[5.33px] bg-white rounded-full border border-[#eaecf0] text-[#344054] text-sm font-semibold font-inter leading-3 min-w-max">
          {max}
          {unit}
        </div>
      </div>

      <Progress
        value={percentage > 100 ? 100 : percentage}
        className="h-3 bg-slate-100"
      />
    </div>
  );
}

export default UsageProgress;
