/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import cancelMeeting from '../../../actions/cancelMeeting';
import getMeetingById from '../../../actions/getMeetingById';
import {
    AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from '../../../components/ui/alert-dialog';
import config from '../../../config';
import { getGravatarUrl } from '../../../lib/utils';
import Reschedule from './reschedule';
import ViewMeetingDetailsModal from './view-meeting-details-modal';

function MeetingCardExtended({ data }) {
  const user = useGlobal('user')[0];
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');
  const [showDetails, setShowDetails] = useState(false);
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [meetingData, setMeetingData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [calendars, setCalendars] = useState([]);

  // const secondUser = data?.booking?.guests?.length > 0 ? data?.booking?.guests?.[0] : null;
  // const userEmail = (secondUser ? secondUser?.email : data?.createdBy?.email) || data?.createdBy?.email;
  // const userName = secondUser
  //   ? `${secondUser?.name}`
  //   : `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;

  const meetingUrl = meetingData?.meeting?.meetingSource === 'google' ? meetingData?.meeting?.meetingSourceUrl : `${config.meetUrl}${data?.meetingCode}`;

  const userData = meetingData?.calendarEvent?.attendees?.filter((attendee) => !calendars.some((calendar) => calendar.calendarEmail === attendee.email));
  // console.log(userData, 'Heyyy');

  const gravatarUrl = getGravatarUrl((userData?.[0]?.email || 'SB'), userData?.[0]?.email);
  useEffect(() => {
    if (data?._id) {
      const getData = async () => {
        try {
          setIsLoading(true);
          const { data: result } = await getMeetingById(data?._id);
          setMeetingData(result.data);
          if (result?.data?.booking?.formSubmission) {
            setIsFormSubmitted(true);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      };

      getData();
    }
  }, [data]);

  const getCalendars = useCallback(async () => {
    try {
      const { data: res } = await axios.get(`${config.url || ''}/api/v1/user/back_office/get_calendars`);
      setCalendars(res.data);
    } catch (error) {
      toast.error('Something went wrong', {
        position: 'top-right',
      });
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getCalendars();
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(meetingUrl);
    toast.success('Link copied to clipboard', {
      position: 'top-center',
    });
  };

  const handleCancelMeeting = async () => {
    const requestData = {
      bookingId: meetingData.booking._id,
      scheduleId: meetingData.booking.schedule._id,
      cancelledBy: user.email,
    };
    try {
      const { data } = await cancelMeeting(requestData);
      console.log(data);
      toast.success('Meeting cancelled successfully', {
        position: 'top-center',
      });
      setRefreshMeetings(!refreshMeetings);
    } catch (err) {
      console.log(err.response);
      toast.error(err.response.data.error, {
        position: 'top-center',
      });
    }
  };
  return (
    <>
      <div className="w-full p-5 bg-[#e9eef6] rounded-xl border border-[#eaecf0] font-inter relative ove">
        <div className="flex flex-col lg:flex-row gap-3 items-start">
          <div className="flex gap-3">
            <div className="min-w-max">
              <div className="size-[40px] border-4 border-white rounded-full flex items-center justify-center relative">
                <img
                  className="w-full h-full object-cover object-center rounded-full"
                  src={gravatarUrl}
                  alt=""
                />
                {data?.users?.length > 1 && (
                <div className="absolute bottom-0 right-0 size-4 rounded-full flex items-center justify-center text-xs font-semibold bg-[#FFED74]">
                  {(data?.users?.length || 0) - 1}
                </div>
                )}
              </div>
            </div>
            <div className="space-y-[6px] lg:flex-1">
              <h4 className="text-[#101828] text-sm font-medium leading-tight font-inter cursor-pointer max-w-[120px] truncate" title={meetingData?.calendarEvent?.title || (meetingData?.booking?.bookingType === 'instant' ? 'Instant Meeting' : meetingData?.meeting?.notes)}>
                {meetingData?.calendarEvent?.title || (meetingData?.booking?.bookingType === 'instant' ? 'Instant Meeting' : meetingData?.meeting?.notes)}
              </h4>
              {/* <a href={`mailto:${userEmail}`} className=" text-[#475467] hover:text-[#2c3440] text-xs font-normal leading-tight block w-[150px] truncate">
                {userEmail}
              </a> */}

              <p className="text-[#475467] text-xs font-normal font-inter min-w-max leading-tight lowercase">
                {moment(data?.booking?.bookingDate).tz(user?.schedule?.timeZone)?.format('HH:mm - DD/MM/YYYY')}
                {/* {
                  moment.tz(`${moment(data?.booking?.bookingDate)?.format('YYYY-MM-DD')} ${convertTo24Hour(data?.booking?.bookingTime)}`, 'YYYY-MM-DD HH:mm', user?.schedule?.timeZone)?.format('HH:mm - DD/MM/YYYY')
                } */}
                {/* {data?.booking?.bookingDate
                  ? moment(data?.booking?.bookingDate).tz(user?.schedule?.timeZone)?.format('HH:mm - DD/MM/YYYY') : convertTo24Hour(data?.booking?.bookingTime)}
                {data?.booking?.bookingDate && ` - ${moment(data?.booking?.bookingDate).tz(user?.schedule?.timeZone)?.format('DD/MM/YYYY')}`} */}
              </p>
            </div>
          </div>
          <div className="lg:min-w-max flex-grow w-full space-y-3 lg:text-right text-center">
            <Link to={meetingUrl} target="_blank" rel="noreferrer" className="h-9 lg:min-w-max w-full lg:inline flex gap-1 items-center justify-center px-3 py-2 bg-[#fce657] rounded-lg shadow border-2 border-[#efd215] text-[#173300] hover:text-[#173300d8] text-xs font-semibold font-inter leading-tight">
              Join Meeting Room
              {' '}
              {
                data?.peers?.length > 0 && (
                <span className="relative inline-flex size-2">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#173300] opacity-75" />
                  <span className="relative inline-flex rounded-full size-2 bg-[#173300]" />
                </span>
                )
              }
            </Link>

            <p className="text-sm">
              <Reschedule userName={user?.username} workspaceId={user?.workspace?.brandID} bookingId={meetingData?.booking?._id} scheduleId={meetingData?.booking?.schedule?._id}>
                <span className="text-[#475467] font-normal underline leading-tight cursor-pointer">Reschedule</span>
              </Reschedule>
              {' or '}
              <span onClick={() => setShowCancelAlert(true)} className="text-[#475467] font-normal underline leading-tight cursor-pointer">Cancel</span>
            </p>
          </div>
        </div>

        <div className="flex gap-3 mt-4 lg:mt-0">
          <div className="lg:size-[40px]" />
          <button onClick={handleCopyLink} className="flex items-center gap-2.5 text-[#475467] w-full">
            <span className="min-w-max">
              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="copy-04" clipPath="url(#clip0_11714_87357)">
                  <path id="Icon" d="M9.96972 10.4927V12.2374C9.96972 12.9354 9.96972 13.2843 9.83389 13.5509C9.71441 13.7854 9.52376 13.9761 9.28927 14.0955C9.02268 14.2314 8.67371 14.2314 7.97575 14.2314H3.24007C2.54211 14.2314 2.19313 14.2314 1.92655 14.0955C1.69205 13.9761 1.50141 13.7854 1.38192 13.5509C1.24609 13.2843 1.24609 12.9354 1.24609 12.2374V7.50171C1.24609 6.80376 1.24609 6.45478 1.38192 6.1882C1.50141 5.9537 1.69205 5.76305 1.92655 5.64357C2.19313 5.50774 2.54211 5.50774 3.24007 5.50774H4.98479M6.97876 10.4927H11.7144C12.4124 10.4927 12.7614 10.4927 13.028 10.3568C13.2625 10.2374 13.4531 10.0467 13.5726 9.81222C13.7084 9.54563 13.7084 9.19665 13.7084 8.4987V3.76302C13.7084 3.06506 13.7084 2.71608 13.5726 2.4495C13.4531 2.215 13.2625 2.02435 13.028 1.90487C12.7614 1.76904 12.4124 1.76904 11.7144 1.76904H6.97876C6.28081 1.76904 5.93183 1.76904 5.66525 1.90487C5.43075 2.02435 5.2401 2.215 5.12062 2.4495C4.98479 2.71608 4.98479 3.06506 4.98479 3.76302V8.4987C4.98479 9.19666 4.98479 9.54563 5.12062 9.81222C5.2401 10.0467 5.43075 10.2374 5.66525 10.3568C5.93183 10.4927 6.28081 10.4927 6.97876 10.4927Z" stroke="#475467" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_11714_87357">
                    <rect width="14.9548" height="14.9548" fill="white" transform="translate(0 0.522705)" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span className="text-xs lg:text-left text-center font-normal leading-tight w-full truncate">
              {meetingUrl}
            </span>
          </button>
        </div>
        <div className="h-[0px] border border-[#d3d6db] my-2" />

        <div className="flex flex-col lg:flex-row gap-4 justify-between items-center">
          <div className="flex items-center gap-2">
            <svg className="size-4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="check-circle-broken">
                <path id="Icon" d="M22 11.0857V12.0057C21.9988 14.1621 21.3005 16.2604 20.0093 17.9875C18.7182 19.7147 16.9033 20.9782 14.8354 21.5896C12.7674 22.201 10.5573 22.1276 8.53447 21.3803C6.51168 20.633 4.78465 19.2518 3.61096 17.4428C2.43727 15.6338 1.87979 13.4938 2.02168 11.342C2.16356 9.19029 2.99721 7.14205 4.39828 5.5028C5.79935 3.86354 7.69279 2.72111 9.79619 2.24587C11.8996 1.77063 14.1003 1.98806 16.07 2.86572M22 4L12 14.01L9 11.01" stroke={isFormSubmitted ? '#16A34A' : '#475467'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </g>
            </svg>
            <p className="text-[#475467] text-sm font-normal leading-tight">
              Questionnaire
              {' '}
              {isFormSubmitted ? 'complete' : 'incomplete'}
            </p>
          </div>

          <button onClick={() => setShowDetails(true)} className="px-3 py-2 bg-white rounded-lg shadow border border-[#d0d5dd] text-[#344054] text-sm font-semibold leading-tight">
            View Details
          </button>
        </div>

        {
          isLoading && (
          <div className="w-full h-full bg-gray-100/50 backdrop-blur absolute top-0 left-0 flex items-center justify-center">
            <div className="size-6 border-4 border-[#47546744] border-t-black rounded-full animate-spin" />
          </div>
          )
        }

      </div>
      {data?._id && (
        <ViewMeetingDetailsModal
          open={showDetails}
          setOpen={setShowDetails}
          meetingData={meetingData}
          meetingId={data?.meetingCode}
        />
      )}

      {
        showCancelAlert && (
          <AlertDialog open={showCancelAlert} onOpenChange={setShowCancelAlert}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Confirm Action</AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to cancel this meeting? Once canceled, this action cannot be undone. Press
                  {' '}
                  <strong>Continue</strong>
                  {' '}
                  to confirm.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleCancelMeeting}>Continue</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )
      }
    </>
  );
}

export default MeetingCardExtended;
