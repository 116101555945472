import ReactQuill from 'react-quill';

export default function BriefEditor({ briefHTML, setBriefHTML, readonly }) {
  return (
    <div>
      <ReactQuill
        value={briefHTML}
        onChange={setBriefHTML}
        theme="snow" // You can change the theme to "bubble" if you prefer
        placeholder="Start typing..."
        readOnly={readonly}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // Font dropdown (Normal)
            ['bold', 'italic'], // Bold, Italic
            ['blockquote'], // Blockquote
            ['link', 'image'], // Link, Image
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }], // Ordered list, Unordered list
          ],
        }}
      />
    </div>
  );
}
