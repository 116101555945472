import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import React from 'react';

const WhiteBtn = React.forwardRef(({ children, className, ...props }, ref) => {
  return (
    <Button
      ref={ref} // Forward the ref to the Button component
      className={cn(
        'px-3 py-2 rounded-lg text-sm font-medium leading-tight bg-white text-[#344054] border border-gray-300 hover:bg-white shadow-sm active:shadow',
        className
      )}
      {...props}
    >
      {children}
    </Button>
  );
});

// Optional: Add a display name for better debugging
WhiteBtn.displayName = 'WhiteBtn';

export default WhiteBtn;