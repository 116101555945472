/* eslint-disable indent */
import axios from 'axios';
import Config from '../config';

const updateFirstTimeSub = (subId) => {
    return axios({
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/update-subscription-for-first-time/${subId}`,
    });
};

export default updateFirstTimeSub;
