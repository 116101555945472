/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { Button } from '../../../components/ui/button';
import { Calendar } from '../../../components/ui/calendar';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../components/ui/dialog';
import Config from '../../../config';
import {
  cn,
  convertTimeStringToMinutes,
  convertTo24Hour,
  formatDateString,
  generateTimeArray,
  isTimeLater,
} from '../../../lib/utils';
import MeetingInfo from '../pages/back-office/components/meeting-info';
import { isPastDate } from '../pages/back-office/scheduling';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const timeFormat = ['12h', '24h'];

const isSameDate = (date1, date2) => moment(date1).isSame(date2, 'day');

const shouldDisable = (bookings, date, timeStr) =>
  bookings.some(
    (booking) =>
      isSameDate(booking?.bookingDate, date) && convertTo24Hour(booking?.bookingTime) === convertTo24Hour(timeStr),
  );

function Reschedule({ userName, bookingId, scheduleId, children }) {
  const [open, setOpen] = useState(false);
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(timeFormat[0]);
  const { addToast } = useToasts();
  const [date, setDate] = useState(new Date());
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [timeZone, setSelectedTimezone] = useState('');
  const [slot, setSlot] = useState(15);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');
  const [user] = useGlobal('user');

  const workspaceId = user?.workspace?.brandID;

  useEffect(() => {
    if (userName && workspaceId) {
      axios
        .get(`${Config.url || ''}/api/v1/public/get_bookings_n_schedule/${workspaceId}/${userName}`)
        .then((data) => {
          const schedule = data?.data?.data?.schedule;
          const bookings = data?.data?.data?.bookings;
          const timeSlot = Number(convertTimeStringToMinutes(schedule?.timeSlot));
          setSlot(timeSlot);
          // console.log(timeSlot);
          setDaysOfWeek(schedule?.availableWeekdays);
          setBookings(bookings);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userName]);

  const handleReschedule = async () => {
    if (!selectedTime) {
      toast.error('Please select a time.', {
        position: 'top-center',
      });
      return;
    }

    const formData = new FormData();
    formData.append('scheduleId', scheduleId);
    formData.append('bookingId', bookingId);
    formData.append('newDate', formatDateString(date));
    formData.append('newTime', convertTo24Hour(selectedTime));
    formData.append('timeZone', timeZone.split(' ')[0]);
    formData.append('do_not_check_pending', true);

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/public/book_reschedule`,
      data: formData,
    };

    setLoading(true);
    axios
      .request(config)
      .then((data) => {
        setOpen(false);
        toast.success(data?.data?.message, {
          position: 'top-center',
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: 'top-center',
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setLoading(false);
        setRefreshMeetings(!refreshMeetings);
      });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="min-w-max">
        <DialogHeader>
          <DialogTitle className="hidden" />
          <DialogDescription className="hidden" />
        </DialogHeader>
        <div className="flex lg:flex-row flex-col gap-6 p-6 bg-[#EEEEEE] rounded-xl 2xl:max-w-[1400px]">
          <MeetingInfo setSelectedTimezone={setSelectedTimezone} slot={slot} />
          <hr className="border-gray-500 lg:hidden" />
          <Calendar
            mode="single"
            selected={date}
            onSelect={(d) => {
              if (!isPastDate(d)) {
                setDate(d);
                setSelectedTime('');
              } else {
                toast.error('You can not select a previous date.', {
                  position: 'top-center',
                });
              }
            }}
            className="flex justify-center lg:pr-6 lg:border-r-2 border-neutral-300 lg:col-span-2"
            classNames={{
              head_cell: 'w-16',
              cell: 'w-16 h-16',
            }}
          />
          <hr className="border-gray-500 lg:hidden" />
          <div className="space-y-6">
            <div className="flex items-center lg:justify-between justify-center gap-6">
              {/*  */}
              <p className="flex gap-[12px] items-center m-0">
                <span className="text-neutral-700 text-base font-semibold leading-normal">
                  {date && days[moment.tz(date, timeZone).day()]}
                </span>
                <span className="text-zinc-500 text-sm font-medium leading-tight">
                  {date && moment.tz(date, timeZone).date()}
                </span>
              </p>
              <div className="px-1 py-.5 rounded-md border border-neutral-400 justify-end items-center gap-[13.66px] inline-flex">
                {timeFormat.map((item, i) => (
                  <div
                    key={i}
                    onClick={() => setSelectedTimeFormat(item)}
                    className={`px-3 py-[4.25px] cursor-pointer ${
                      selectedTimeFormat === item
                        ? 'bg-neutral-700 rounded text-center text-zinc-300 text-sm font-medium leading-[17.50px]'
                        : ''
                    }`}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3 max-h-[550px] overflow-auto p-2">
              {(daysOfWeek[moment.tz(date, timeZone).day()]?.startTime ||
                daysOfWeek[moment.tz(date, timeZone).day()]?.endTime) &&
                generateTimeArray(
                  daysOfWeek[moment.tz(date, timeZone).day()]?.startTime,
                  daysOfWeek[moment.tz(date, timeZone).day()]?.endTime,
                  selectedTimeFormat,
                  slot,
                  timeZone,
                )?.map((item, i) => (
                  <button
                    key={i}
                    onClick={() =>
                      setSelectedTime((prev) => {
                        if (prev === item) {
                          return '';
                        }
                        if (!isTimeLater(item, timeZone) && isSameDate(date, moment().tz(timeZone))) {
                          addToast('You can not select a past time.', {
                            appearance: 'error',
                            autoDismiss: true,
                          });
                          return prev;
                        }
                        return item;
                      })
                    }
                    disabled={
                      shouldDisable(bookings, date, item) ||
                      (!isTimeLater(item, timeZone) && isSameDate(date, moment().tz(timeZone)))
                    }
                    className={cn(
                      `px-12 py-2 rounded-lg border border-gray-300 text-slate-700 text-sm font-semibold leading-tight ${
                        selectedTime === item ? 'bg-neutral-700 text-white' : 'bg-white'
                      }`,
                      shouldDisable(bookings, date, item) ||
                        (!isTimeLater(item, timeZone) && isSameDate(date, moment().tz(timeZone)))
                        ? 'hidden'
                        : '',
                    )}
                  >
                    {item}
                  </button>
                ))}
            </div>
          </div>
        </div>
        <div>
          <Button disabled={!selectedTime} onClick={handleReschedule}>
            {loading ? (
              <span className="size-4 border-2 border-[#ffed74] border-r-transparent rounded-full animate-spin" />
            ) : (
              'Reschedule'
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default Reschedule;
