/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/function-component-definition */

import axios from 'axios';
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
// eslint-disable-next-line import/no-unresolved
import { initializePaddle } from '@paddle/paddle-js';
import { useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import { Button } from '../../../../components/ui/button';
import { Label } from '../../../../components/ui/label';
import { Switch } from '../../../../components/ui/switch';
import Config from '../../../../config';
import { PLANS } from '../../../../constants/Subscription';

const PricingPlan = ({ handleTriggerTeamModal = () => { } }) => {
  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = useState();
  const [user] = useGlobal('user');
  const token = useGlobal('token')[0];
  // const [selectedSeats, setSelectedSeats] = useState(1);
  const [changePlanWithSeats, setChangePlanWithSeats] = useState(1);
  const location = useLocation();
  const trxId = location?.search?.split('_ptxn=')?.[1];
  const [loading, setLoading] = useState(false);
  const [isAnnual, setIsAnnual] = useState(false);
  // const navigate = useNavigate();
  // console.log(user?.activeSubscription?.totalSeatsAllocated);

  // Handle checkout with Paddle
  const handleCheckout = (priceId, quantity) => {
    if (!user?.activeSubscription?._id || !user?._id) {
      window.alert('Reload the page and try again!');
      window.location.reload();
      return;
    }
    setLoading(true);
    paddle?.Checkout.open({
      items: [{ priceId, quantity }],
      customData: {
        user_sub_id: user?.activeSubscription?._id,
      },
      customer: {
        email: user?.email,
        id: user?.activeSubscription?.paddleCustomerId && user?.activeSubscription?.plan !== 'free' ? user?.activeSubscription?.paddleCustomerId : null,
      },

      settings: {
        successUrl: trxId
          ? `${window.location.origin}/?showProfile=true&accountType=Team&message=update_billing_success`
          : `${window.location.origin}/?showProfile=true&accountType=Team&message=success`,
      },
    });
  };

  const handleChangePlan = (priceId) => {
    if (user?.activeSubscription?.plan === 'pay_as_you_go') {
      const answer = window.confirm('You will be charged as per usage before changing plan. Are you okay?');
      if (!answer) return;
    }

    const checkPlan = PLANS.find((plan) => plan?.id === priceId);
    setLoading(true);
    axios
      .post(
        `${Config.url || ''}/api/v1/user/change-subscription`,
        {
          seats: checkPlan?.id === 'small_teams' ? changePlanWithSeats : 1,
          priceId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/?showProfile=true&accountType=Team&message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.error?.type === 'request_error') {
          toast.success('Please update billing details and try again!', {
            position: 'top-center',
          });
          // wait here for 3 seconds
          await new Promise((resolve) => {
            setTimeout(resolve, 3000);
          });
          const trxId = err.response?.data?.error?.trx_id;
          if (trxId) {
            window.location.href = `${window.location.origin}/?showProfile=true&accountType=Team&_ptxn=${trxId}`;
          }
        } else {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePayAsYouGoPayment = () => {
    setLoading(true);
    axios
      .post(`${Config.url || ''}/api/v1/user/pay-as-you-go-payment`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/?showProfile=true&accountType=Team&message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.error?.type === 'request_error') {
          toast.success('Please update billing details and try again!', {
            position: 'top-center',
          });
          // wait here for 3 seconds
          await new Promise((resolve) => {
            setTimeout(resolve, 3000);
          });
          const trxId = err.response?.data?.error?.trx_id;
          if (trxId) {
            window.location.href = `${window.location.origin}/?showProfile=true&accountType=Team&_ptxn=${trxId}`;
          }
        } else {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        }
      });
  };

  const handleSwitchToFreePlan = () => {
    if (user?.activeSubscription?.plan === 'pay_as_you_go') {
      const answer = window.confirm('You will be charged as per usage before changing plan. Are you okay?');
      if (!answer) return;
    }

    setLoading(true);
    axios
      .post(`${Config.url || ''}/api/v1/user/switch-to-free-plan`, undefined, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success(res.data.message, {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/?showProfile=true&accountType=Team&message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        if (err.response?.data?.message) {
          toast.success(`${err.response?.data?.message}`, {
            position: 'top-center',
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({ environment: Config.paddleEnv, token: Config.paddleClientToken }).then(
      (paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );
  }, []);

  const message = location?.search?.split('&message=')?.[1];
  useEffect(() => {
    if (!user?.activeSubscription?._id || !user?._id) {
      // window.location.reload();
    } else {
      setChangePlanWithSeats(user?.activeSubscription?.totalSeatsAllocated);
    }
  }, [user]);

  useEffect(() => {
    if (message === 'success' || message === 'update_billing_success') {
      setLoading(true);
      setTimeout(() => {
        window.location.href = `${window.location.origin}/?showProfile=true&accountType=Team`;
      }, 3000);
    }
  }, [message]);

  return (
    <div className="container mx-auto px-0">
      {
        // successful message
        trxId ? (
          <div
            className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-4 rounded relative"
            role="alert"
          >
            <strong className="font-bold mr-2">Success!</strong>
            <span className="block sm:inline">Please update billing details and try again!</span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => {
                window.location.href = `${window.location.origin}/?showProfile=true&accountType=Team`;
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        ) : message === 'update_billing_success' ? (
          <div
            className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-4 rounded relative"
            role="alert"
          >
            <strong className="font-bold mr-2">Success!</strong>
            <span className="block sm:inline">
              Billing details updated successfully! Update your plan now. please wait 3s.
            </span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => {
                window.location.href = `${window.location.origin}/?showProfile=true&accountType=Team`;
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        ) : message === 'success' ? (
          <div
            className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mb-4 rounded relative"
            role="alert"
          >
            <strong className="font-bold mr-2">Success!</strong>
            <span className="block sm:inline">Plan updated! Please wait 3s.</span>
            <button
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => {
                window.location.href = `${window.location.origin}/?showProfile=true&accountType=Team`;
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        ) : null
      }
      {user?.activeSubscription?.plan === 'pay_as_you_go' && user?.activeSubscription?.payAsYouGoPaymentError ? (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline">
            Plan suspended. Payment failed. Reason: {user?.activeSubscription?.suspensionReason}
          </span>
          <button
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => {
              handlePayAsYouGoPayment();
            }}
          >
            Pay now
          </button>
        </div>
      ) : null}

      <div className="flex justify-between mb-12">
        <div>
          <div className="text-[#173300] text-lg font-semibold font-inter leading-7">Pricing in USD</div>
          <p className="text-slate-600 text-base font-normal leading-normal m-0">Manage your plans from here.</p>
        </div>

        <div className="flex items-center space-x-2">
          {/* <Switch id="pricing-switch" checked={isAnnual} onCheckedChange={setIsAnnual} />
          <Label htmlFor="pricing-switch" className="cursor-pointer">
            Annually
          </Label> */}

          <div className="flex items-center justify-center mx-auto p-2 bg-white rounded-[32px] border-2 border-neutral-300">
            <div
              className="flex justify-center items-center gap-2">
              <button onClick={() => setIsAnnual(true)} className={`lg:text-base font-bold font-inter transition-all duration-300 ease-in-out rounded-[32px] px-[12px] py-1 text-base sm:py-1 ${isAnnual ? 'bg-[#173300] text-[#FCEB5B]' : 'bg-transparent text-[#173300]'}`}>
                Annual
              </button>
              <button onClick={() => setIsAnnual(false)} className={`lg:text-base font-bold font-inter transition-all duration-300 ease-in-out rounded-[32px] px-[12px] py-1 text-base sm:py-1 ${!isAnnual ? 'bg-[#173300] text-[#FCEB5B]' : 'bg-transparent text-[#173300]'}`}>
                Monthly
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-6 ">
          {/* Free Plan */}
          <div className="h-full p-6 border-2 border-[#183401] rounded-2xl flex flex-col gap-4">
            <div className="space-y-6">
              <img src="/image/icons/gift.png" alt="" width={62} height={62} className="h-[62px]" />
              <h5 className="font-bold font-inter lg:text-2xl text-base">
                Free
              </h5>
              <div>
                <p className="text-[#183401] lg:text-5xl text-2xl font-bold font-inter lg:leading-[60px]">$0.00</p>
                <p className="lg:text-base text-sm font-normal">
                  For new freelancers with 1-2 calls monthly.
                </p>
              </div>

              {user?.activeSubscription?.plan === PLANS[0].id ? (
                <Button
                  variant="outline"
                  className="w-full text-primary font-semibold lg:text-2xl text-base"
                  disabled
                >
                  Active
                </Button>
              ) : (
                <Button
                  variant="outline"
                  className="w-full text-[#1A3300] hover:bg-[#1A3300] hover:text-[#FCEB5B] border-[#1A3300] font-semibold text-lg"
                  disabled={loading}
                  onClick={() => handleSwitchToFreePlan()}
                >
                  {loading ? 'Updating...' : 'Downgrade'}
                </Button>
              )}

              <ul className="list-disc pl-5 lg:text-lg text-base font-normal lg:space-y-2 space-y-1">
                <li>90 monthly free minutes</li>
                <li>2 x Calendars sync</li>
                <li>1GB Project Spaces</li>
                <li>1 x Workspace</li>
                <li>Public Calendar</li>
                <li>Meeting Scheduler</li>
                <li>Unlimited Highlights</li>
                <li>Unlimited Summaries</li>
                <li>Unlimited Briefs</li>
                <li>Unified Dashboard</li>
              </ul>
            </div>
          </div>

          {/* Solo Plan */}
          <div className="h-full p-6 border-2 border-[#183401] rounded-2xl flex flex-col justify-between gap-4">
            <div className="space-y-6">
              <div className="flex justify-between">
                <img src="/image/icons/rocket.png" alt="" width={62} height={62} className="h-[62px]" />

                {
                  isAnnual && (
                    <p className="h-[43px] px-[14.36px] py-[11px] bg-[#ffeb5b] rounded-[32px] border-2 border-[#173300] justify-center items-center inline-flex overflow-hidden">
                      <span className="text-center text-[#173300] text-base font-bold font-inter leading-tight">Save $36.00/year</span>
                    </p>
                  )
                }
              </div>

              <h5 className="font-bold font-inter lg:text-2xl text-base mb-2">
                Solo
              </h5>
              <div>
                <p className="text-[#183401] lg:text-5xl text-2xl font-bold font-inter lg:leading-[60px]">{!isAnnual ? PLANS[1].price : PLANS[3].price}/mo</p>
                <p className="lg:text-base text-sm font-normal">
                  Best for freelancers & solo founders or clients.
                </p>
              </div>

              {user?.activeSubscription?.plan === PLANS[1].id ? (
                <Button
                  variant="outline"
                  className="w-full text-[#1A3300] border-[#1A3300] font-semibold text-lg"
                  disabled
                >
                  Active
                </Button>
              ) : (
                <div>
                  {/* {user?.activeSubscription?.paddleSubscriptionId && (
                    <input
                      type="number"
                      value={changePlanWithSeats}
                      onChange={(e) => setChangePlanWithSeats(e.target.value)}
                      className="border rounded-lg p-2 mb-4 bg-transparent text-[#1A3300] border-[#1A3300] w-full font-bold"
                    />
                  )} */}
                  <Button
                    variant="outline"
                    disabled={loading}
                    onClick={() => {
                      if (user?.activeSubscription?.paddleSubscriptionId && !isAnnual) {
                        handleChangePlan(PLANS[1].planId, 0);
                      } else if (user?.activeSubscription?.paddleSubscriptionId && isAnnual) {
                        handleChangePlan(PLANS[3].planId, 0);
                      } else if (isAnnual) {
                        handleCheckout(PLANS[3].planId, 0);
                      } else {
                        handleCheckout(PLANS[1].planId, 0);
                      }
                    }}
                    className="w-full text-[#1A3300] bg-transparent hover:bg-[#1A3300] hover:text-[#FCEB5B] border-[#1A3300] font-semibold text-lg"
                  >
                    {loading ? 'Updating...' : 'Switch To Solopreneurs'}
                  </Button>
                </div>
              )}

              <ul className="list-disc pl-5 lg:text-lg text-base font-normal lg:space-y-2 space-y-1">
                <li className="font-bold">5,000 monthly minutes</li>
                <li className="font-bold">4 x Calendars sync</li>
                <li className="font-bold">10GB Project Spaces</li>
                <li className="font-bold">2 x Workspaces</li>
                <li className="font-bold">Priority Support</li>
                <li>Public Calendar</li>
                <li>Meeting Scheduler</li>
                <li>Unlimited Highlights</li>
                <li>Unlimited Summaries</li>
                <li>Unlimited Briefs</li>
                <li>Unified Dashboard</li>
              </ul>
            </div>

            {/* <div>
              <hr className='mb-3 border-[#183401]' />
              <p className="text-[#183401] text-sm font-normal">
                <a href="https://www.saybriefly.com/terms-of-use" target="_blank" rel="noopener noreferrer" className='hover:text-blue-500 font-bold'>Terms of Service</a>  -  <a href="https://www.saybriefly.com/privacy-policy" target="_blank" rel="noopener noreferrer" className='hover:text-blue-500 font-bold'>Privacy Policy</a>.
              </p>
            </div> */}
          </div>

          {/* Teams Plan */}
          <div className="p-6 border border-[#1A3300] bg-[#FCEB5B] text-black rounded-2xl flex flex-col justify-between gap-6 relative">
            <div className={`absolute ${isAnnual ? 'top-[-38px]' : 'top-[38px]'} transition-all duration-300 z-[-1] left-6 lg:px-6 px-4 h-[38px] bg-[#173300] rounded-2xl rounded-bl-none rounded-br-none flex items-center justify-center`}>
              <div className="text-center text-[#fceb5b] lg:text-sm font-bold font-inter">2 months free</div>
            </div>
            <div className="absolute top-[-19px] right-6 px-6 h-[38.02px] bg-[#173300] rounded-[50.52px] flex items-center justify-center">
              <div className="text-center text-[#fceb5b] lg:text-sm font-bold font-inter">Popular</div>
            </div>
            <div className="space-y-6">
              <img src="/image/icons/clap-dark.png" alt="" width={62} height={62} className="h-[62px]" />
              <div className="flex items-center">
                <h5 className="font-bold font-inter text-2xl">Teams</h5>
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <p className="text-[#183401] lg:text-5xl text-2xl font-bold font-inter lg:leading-[60px]">{!isAnnual ? PLANS[2].price : PLANS[4].price}/mo</p>
                  <p className="text-primary text-base font-medium font-inter">per seat</p>
                </div>
                <p className="text-[#173300] lg:text-base text-sm font-normal font-inter leading-tight">Best for studios and startups with small teams.</p>
              </div>

              {user?.activeSubscription?.plan === PLANS[2].id ? (
                <>
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (user?.activeSubscription?.paddleSubscriptionId) {
                        handleTriggerTeamModal();
                      } else if (isAnnual) {
                        handleCheckout(PLANS[4].planId, 0);
                      } else {
                        handleCheckout(PLANS[2].planId, 0);
                      }
                    }}
                    className="w-full text-lg bg-[#1A3300] text-[#FCEB5B] border-transparent"
                  >
                    Add Seats
                  </Button>
                  <p
                    className="text-[#1A3300] text-sm font-bold font-inter leading-[18px]"
                    style={{ marginTop: '6px' }}
                  >
                    {user?.activeSubscription?.subMembers?.length}/{user?.activeSubscription?.totalSeatsAllocated} seat
                  </p>
                </>
              ) : (
                <div>
                  {/* {user?.activeSubscription?.paddleSubscriptionId && (
                    <input
                      type="number"
                      value={changePlanWithSeats}
                      onChange={(e) => setChangePlanWithSeats(e.target.value)}
                      min={1}
                      className="w-full p-2 text-lg bg-[#1A3300] text-[#FCEB5B] border-transparent"
                    />
                  )} */}
                  <Button
                    variant="outline"
                    disabled={loading}
                    onClick={() => {
                      if (user?.activeSubscription?.paddleSubscriptionId && !isAnnual) {
                        handleChangePlan(PLANS[2].planId, user?.activeSubscription?.totalSeatsAllocated || 0);
                      } else if (user?.activeSubscription?.paddleSubscriptionId && isAnnual) {
                        handleChangePlan(PLANS[4].planId, user?.activeSubscription?.totalSeatsAllocated || 0);
                      } else if (isAnnual) {
                        handleCheckout(PLANS[4].planId, 0);
                      } else {
                        handleCheckout(PLANS[2].planId, 0);
                      }
                    }}
                    className="w-full text-lg bg-[#1A3300] text-[#FCEB5B] border-transparent"
                  >
                    {loading ? 'Updating...' : 'Switch To Teams'}
                  </Button>
                </div>
              )}

              <ul className="list-disc pl-5 lg:text-lg text-base font-normal lg:space-y-2 space-y-1">
                <li className="font-bold">10,000 monthly minutes</li>
                <li className="font-bold">8 x Calendars sync</li>
                <li className="font-bold">20GB Project Spaces</li>
                <li className="font-bold">Unlimited Workspaces</li>
                <li className="font-bold">Team Admin Access</li>
                <li className="font-bold">Slack Support</li>
                <li>Public Calendar</li>
                <li>Meeting Scheduler</li>
                <li>Unlimited Highlights</li>
                <li>Unlimited Summaries</li>
                <li>Unlimited Briefs</li>
                <li>Unified Dashboards</li>
              </ul>
            </div>

            {/* <div>
              <hr className='mb-3 border-[#183401]' />
              <p className="text-[#183401] text-sm font-normal">
                <a href="https://www.saybriefly.com/terms-of-use" target="_blank" rel="noopener noreferrer" className='hover:text-blue-500 font-bold'>Terms of Service</a>  -  <a href="https://www.saybriefly.com/privacy-policy" target="_blank" rel="noopener noreferrer" className='hover:text-blue-500 font-bold'>Privacy Policy</a>.
              </p>
            </div> */}
          </div>
        </div>

        <p className="text-[#183401] text-sm font-normal pt-2">
          <a href="https://www.saybriefly.com/terms-of-use" target="_blank" rel="noopener noreferrer" className='hover:text-blue-500 font-bold'>Terms of Service</a>  -  <a href="https://www.saybriefly.com/privacy-policy" target="_blank" rel="noopener noreferrer" className='hover:text-blue-500 font-bold'>Privacy Policy</a>.
        </p>

        {/* Enterprise Section */}
        <div className="border-2 border-[#1A3300] rounded-2xl p-8 flex flex-col lg:flex-row lg:items-end my-8 gap-8">
          <img src="/image/icons/building.png" alt="" width={100} height={115} />
          <div className="flex-grow">
            <h4 className="font-bold lg:text-2xl text-lg font-inter mb-2">Enterprise</h4>
            <p className="text-base font-normal">
              Our Enterprise plans include features like Single Sign-On (SSO), Data Retention Policies, Custom Branding,
              and Advanced Controls for teams of 100 or more. To learn more, simply fill out the form.
            </p>
          </div>
          <Button className="px-12" onClick={() => window.open(PLANS[3].contact_link, '_blank')}>
            Contact Sales
          </Button>
        </div>
      </div>

      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {PLANS.map((plan) => (
          <div key={plan.name} className="border border-gray-300 rounded-lg p-6 shadow-md">
            <h3 className="text-2xl font-semibold mb-2">{plan.name}</h3>
            <p className="text-3xl font-bold mb-4">{plan.price}</p>
            <p className="text-gray-600 mb-4">{plan.description}</p>
            <ul className="mb-6">
              {plan.features.map((feature, index) => (
                <li key={index} className="text-gray-800 mb-2">
                  {feature}
                </li>
              ))}
            </ul>
            {user?.workspace?.plan === plan?.id ? (
              <>
                <p className="text-gray-800 mb-2">
                  Status: <span className="text-green-500">Active</span>
                </p>
                {!['free', 'pay_as_you_go'].includes(user?.workspace?.plan) && (
                  <>
                    <input
                      type="number"
                      value={selectedSeats}
                      onChange={(e) => setSelectedSeats(e.target.value)}
                      className="border border-gray-300 rounded-lg p-2 mb-4"
                    />
                    <button
                      disabled={loading}
                      onClick={() => handleSeatsChange(plan.planId, 1, true)}
                      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                    >
                      {loading ? 'Updating...' : 'Add More Seats'}
                    </button>
                  </>
                )}
              </>
            ) : plan?.contact_link ? (
              <a
                href={plan.contact_link}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              >
                Contact Us
              </a>
            ) : plan?.id !== 'free' ? (
              <>
                {user?.workspace?.paddleSubscriptionId && plan?.id !== 'pay_as_you_go' ? (
                  <input
                    type="number"
                    value={changePlanWithSeats}
                    onChange={(e) => setChangePlanWithSeats(e.target.value)}
                    className="border border-gray-300 rounded-lg p-2 mb-4"
                  />
                ) : null}
                <button
                  disabled={loading}
                  onClick={() => {
                    if (user?.workspace?.paddleSubscriptionId) {
                      handleChangePlan(plan.planId, user?.workspace?.seats || 0);
                    } else {
                      handleCheckout(plan.planId, 0);
                    }
                  }}
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                >
                  {loading ? 'Updating...' : 'Upgrade'}
                </button>
              </>
            ) : (
              <button
                disabled={loading}
                onClick={() => handleSwitchToFreePlan()}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              >
                {loading ? 'Updating...' : 'Switch To Free'}
              </button>
            )}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default PricingPlan;
