/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useState } from 'react';
import { Button } from '../../../components/ui/button';
import {
  DropdownMenu, DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import { cn } from '../../../lib/utils';
import CreateInstantMeeting from './create-instant-meeting';
import CreateMeetingButton from './create-meeting-button';
import UploadICSFileButton from './drop-ics-button';
import InviteBrieflyButton from './invite-briefly-button';

export default function CreateMeetingDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger>
        <Button className={cn('flex items-center gap-2 hover:bg-[#193601] bg-[#173300] text-[#FFED74] px-3 py-2 text-sm font-inter')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path id="add_diamond_2" d="M9.16666 13.3333H10.8333V10.8333H13.3333V9.16662H10.8333V6.66663H9.16666V9.16662H6.66666V10.8333H9.16666V13.3333ZM9.99999 18.3333C9.77777 18.3333 9.56596 18.2951 9.36457 18.2187C9.16318 18.1423 8.9861 18.0208 8.83332 17.8541L2.18749 11.1875C2.03471 11.0208 1.90971 10.8368 1.81249 10.6354C1.71527 10.434 1.66666 10.2222 1.66666 9.99996C1.66666 9.77774 1.71527 9.56593 1.81249 9.36454C1.90971 9.16315 2.03471 8.98607 2.18749 8.83329L8.83332 2.16663C8.99999 1.99996 9.18054 1.87496 9.37499 1.79163C9.56943 1.70829 9.77777 1.66663 9.99999 1.66663C10.2222 1.66663 10.4375 1.70829 10.6458 1.79163C10.8542 1.87496 11.0347 1.99996 11.1875 2.16663L17.8125 8.83329C17.9653 8.99996 18.0903 9.18051 18.1875 9.37496C18.2847 9.5694 18.3333 9.77774 18.3333 9.99996C18.3333 10.2222 18.2882 10.434 18.1979 10.6354C18.1076 10.8368 17.9792 11.0208 17.8125 11.1875L11.1875 17.8541C11.0347 18.0069 10.8542 18.125 10.6458 18.2083C10.4375 18.2916 10.2222 18.3333 9.99999 18.3333Z" fill="currentColor" />
          </svg>
          <span className="text-[#ffed74] text-sm font-semibold leading-tight">Create meeting</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-[200px] rounded-md bg-white flex flex-col z-1">
        <CreateInstantMeeting>
          <span className="p-2.5 text-[#1a2e05] hover:bg-[#1733001A] rounded-tl-full rounded-bl-full flex items-center gap-2 text-xs font-medium font-inter leading-[18px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path id="acute_2" d="M11.25 15C9.575 15 8.15625 14.4187 6.99375 13.2563C5.83125 12.0938 5.25 10.675 5.25 9C5.25 7.3375 5.83125 5.92188 6.99375 4.75313C8.15625 3.58438 9.575 3 11.25 3C12.9125 3 14.3281 3.58438 15.4969 4.75313C16.6656 5.92188 17.25 7.3375 17.25 9C17.25 10.675 16.6656 12.0938 15.4969 13.2563C14.3281 14.4187 12.9125 15 11.25 15ZM11.25 13.5C12.5 13.5 13.5625 13.0625 14.4375 12.1875C15.3125 11.3125 15.75 10.25 15.75 9C15.75 7.75 15.3125 6.6875 14.4375 5.8125C13.5625 4.9375 12.5 4.5 11.25 4.5C10 4.5 8.9375 4.9375 8.0625 5.8125C7.1875 6.6875 6.75 7.75 6.75 9C6.75 10.25 7.1875 11.3125 8.0625 12.1875C8.9375 13.0625 10 13.5 11.25 13.5ZM12.9562 11.7938L14.025 10.725L12 8.7V6H10.5V9.31875L12.9562 11.7938ZM1.5 6.75V5.25H4.5V6.75H1.5ZM0.75 9.75V8.25H4.5V9.75H0.75ZM1.5 12.75V11.25H4.5V12.75H1.5Z" fill="#173300" />
            </svg>
            Create Instant meeting
          </span>
        </CreateInstantMeeting>
        <CreateMeetingButton>
          <span className="p-2.5 text-[#1a2e05] hover:bg-[#1733001A] rounded-tl-full rounded-bl-full flex items-center gap-2 text-xs font-medium font-inter leading-[18px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path id="calendar_add_on_2" d="M12.75 16.5V14.25H10.5V12.75H12.75V10.5H14.25V12.75H16.5V14.25H14.25V16.5H12.75ZM3.75 15C3.3375 15 2.98438 14.8531 2.69063 14.5594C2.39688 14.2656 2.25 13.9125 2.25 13.5V4.5C2.25 4.0875 2.39688 3.73438 2.69063 3.44063C2.98438 3.14688 3.3375 3 3.75 3H4.5V1.5H6V3H10.5V1.5H12V3H12.75C13.1625 3 13.5156 3.14688 13.8094 3.44063C14.1031 3.73438 14.25 4.0875 14.25 4.5V9.075C14 9.0375 13.75 9.01875 13.5 9.01875C13.25 9.01875 13 9.0375 12.75 9.075V7.5H3.75V13.5H9C9 13.75 9.01875 14 9.05625 14.25C9.09375 14.5 9.1625 14.75 9.2625 15H3.75ZM3.75 6H12.75V4.5H3.75V6Z" fill="#173300" />
            </svg>
            Schedule meeting
          </span>
        </CreateMeetingButton>
        <InviteBrieflyButton closeDropdown={() => setIsOpen(false)} />
        <UploadICSFileButton />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
