/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { marked } from 'marked';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import cancelMeeting from '../../../actions/cancelMeeting';
import confirmBookingByUser from '../../../actions/confirmBookingByUser';
import getMeetingById from '../../../actions/getMeetingById';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../components/ui/alert-dialog';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../components/ui/dialog';
import { addMinutesToTime, convertTimeStringToMinutes } from '../../../lib/utils';
import Reschedule from './reschedule';

function enhanceDescription(text) {
  if (!text) return '';

  let enhanced = text;

  // Step 1: Wrap URLs
  const urlRegex = /https?:\/\/[^\s<]+/g;
  enhanced = enhanced.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" class="text-blue-500">${url}</a>`;
  });

  // Step 2: Wrap Phone Numbers
  const phoneRegex = /(?<!<a[^>]*>)(?<!<a[^>]*>\s*)(\+?1\s?)?(\d{3}[\s.-]?\d{3}[\s.-]?\d{4})(?![^<]*<\/a>)/g;
  enhanced = enhanced.replace(phoneRegex, (match, countryCode, phoneNumber) => {
    // Clean the phone number for the href (remove spaces, dots, dashes)
    const cleanPhone = phoneNumber.replace(/[^\d]/g, '');
    const fullPhone = countryCode ? countryCode.replace(/[^\d+]/g, '') + cleanPhone : cleanPhone;
    // Keep the original match for display
    return `<a href="tel:${fullPhone}" class="text-blue-500">${match}</a>`;
  });

  return enhanced;
}

function ViewMeetingDetailsModalNotification({ open, setOpen, meetingId }) {
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const user = useGlobal('user')[0];
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');
  const [meetingData, setMeetingData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const timezone = user?.schedule?.timeZone;

  // const { meeting } = meetingData || {};

  const title =
    meetingData?.booking?.submittedType === 'private'
      ? `${meetingData?.booking?.bookingTimeSlot} meeting between ${user?.firstName} ${user?.lastName} ${
          meetingData?.booking?.guests[0]?.name
            ? `and ${meetingData?.booking?.guests[0]?.name === 'Guest User' ? meetingData?.booking?.guests[0]?.email : meetingData?.booking?.guests[0]?.name}`
            : ''
        }`.replace('0 min ', '')
      : meetingData?.meeting?.title;

  const event = meetingData?.calendarEvent;
  const attendees = event?.attendees;
  const currentUserExist = attendees?.find(
    (attendee) => attendee.email === meetingData?.meeting?.meetingSourceHostEmail,
  );
  if (!currentUserExist) {
    attendees?.push({
      email: meetingData?.meeting?.meetingSourceHostEmail,
      organizer: !attendees?.some((item) => item.organizer),
      self: true,
    });
  }
  attendees?.sort((a, b) => {
    if (a.organizer) return -1;
    if (b.organizer) return 1;
    if (a.self) return -1;
    if (b.self) return 1;
    return 0;
  });

  const description = event?.description ? marked(event?.description || '') : meetingData?.meeting?.notes;

  // console.log(enhanceDescription(description));

  const toggleAlert = () => {
    setOpen(false);
    setShowCancelAlert(true);
  };

  const toggleConfirmAlert = () => {
    setOpen(false);
    setShowConfirmAlert(true);
  };

  const handleCancelMeeting = async () => {
    const requestData = {
      bookingId: meetingData.booking._id,
      scheduleId: meetingData.booking.schedule._id,
      cancelledBy: user.email,
    };
    try {
      const { data } = await cancelMeeting(requestData);
      setOpen(false);
      toast.success('Meeting cancelled successfully', {
        position: 'top-center',
      });
    } catch (err) {
      console.log(err.response);
      toast.error(err.response.data.error, {
        position: 'top-center',
      });
    } finally {
      setRefreshMeetings(!refreshMeetings);
    }
  };

  const handleConfirm = async (confirmed) => {
    const requestData = {
      bookingId: meetingData.booking._id,
      confirmed,
    };
    try {
      const { data } = await confirmBookingByUser(requestData);
      setOpen(false);
      if (confirmed && data?.data) {
        toast.success('Meeting confirmed successfully', {
          position: 'top-center',
        });
      } else {
        toast.success('Meeting rejected successfully', {
          position: 'top-center',
        });
      }
    } catch (err) {
      console.log(err.response);
      toast.error(err.response.data.error, {
        position: 'top-center',
      });
    } finally {
      setRefreshMeetings(!refreshMeetings);
    }
  };

  useEffect(() => {
    if (meetingId) {
      const getData = async () => {
        try {
          setIsLoading(true);
          const { data: result } = await getMeetingById(meetingId);
          setMeetingData(result.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      };

      getData();
    }
  }, [meetingId]);

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="min-w-[600px] p-0 bg-transparent border-none" closeButtonClassName="right-2 top-6">
          <DialogHeader>
            <DialogTitle className="hidden" />
            <DialogDescription className="hidden" />
          </DialogHeader>
          <div className="bg-[#EAECF0] p-6 rounded-2xl space-y-5 max-h-[700px] overflow-auto scroll-none">
            {isLoading ? (
              <div className="flex items-center justify-center h-full bg-white rounded-2xl">
                <span className="border-4 size-6 rounded-full border-gray-700 border-t-gray-200 animate-spin" />
              </div>
            ) : null}
            <div className="bg-white p-3 py-4 rounded-2xl space-y-6">
              <div className="space-y-2.5">
                <h4 className="text-gray-900 text-lg font-semibold leading-7 w-full truncate">
                  {event?.title || title}
                </h4>

                <p className="text-[#475467] text-sm font-normal font-inter">
                  {moment.tz(meetingData?.booking?.bookingDate, timezone)?.format('dddd, MMMM Do, YYYY')} <br />{' '}
                  {moment.tz(meetingData?.booking?.bookingDate, timezone)?.format('hh:mm A')} -{' '}
                  {addMinutesToTime(
                    moment.tz(meetingData?.booking?.bookingDate, timezone)?.format('hh:mm A'),
                    convertTimeStringToMinutes(meetingData?.booking?.bookingTimeSlot || '15 min'),
                  )}{' '}
                  ({timezone})
                </p>
              </div>
              <div className="flex flex-col gap-6">
                <div className="space-y-6">
                  <div className="space-y-[10px]">
                    <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Who</p>

                    <div className="grid grid-cols-3 gap-4">
                      {attendees?.map((attendee) => (
                        <div
                          key={attendee.email}
                          className={`${attendee.email === meetingData?.meeting?.meetingSourceHostEmail ? 'col-span-2' : 'col-span-1'}`}
                        >
                          <p className="text-slate-600 text-sm font-normal leading-tight flex items-center gap-2.5 m-0">
                            {attendee.email === meetingData?.meeting?.meetingSourceHostEmail
                              ? `${meetingData?.meeting?.createdBy?.firstName} ${meetingData?.meeting?.createdBy?.lastName} `
                              : ''}
                            {/* {meetingData?.meeting?.createdBy?.firstName} {meetingData?.meeting?.createdBy?.lastName}{' '} */}
                            <button className="px-2.5 py-0.5 bg-gray-50 rounded-md border border-gray-200 text-center text-slate-700 text-sm font-medium leading-tight">
                              {attendee.organizer ? 'Host' : 'Guest'}
                            </button>
                          </p>
                          <a
                            href={`mailto:${attendee.email}`}
                            className="text-slate-600 text-sm font-normal block truncate"
                          >
                            {attendee.email}
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="space-y-[10px]">
                    <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Description</p>

                    {/* <p
                      id="meetingDescription"
                      className="text-[#475467] text-sm font-normal font-inter leading-tight"
                      dangerouslySetInnerHTML={{ __html: event?.description.replaceAll('\n', '<br />') || meetingData?.meeting?.notes }}
                    /> */}
                    <p
                      id="meetingDescription"
                      className="text-[#475467] text-sm font-normal font-inter leading-tight space-y-4"
                      dangerouslySetInnerHTML={{
                        __html: enhanceDescription(description),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-[12px]" onClick={() => setOpen(false)}>
                {/* {meetingData?.meeting?.meetingSource === 'saybriefly' ? (
                  <>
                    <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Where</p>
                    <Link
                      to={`${config.meetUrl}${meetingId}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-slate-600 text-sm font-semibold leading-tight block"
                    >
                      {`${config.meetUrl}${meetingId}`}
                    </Link>
                    <Link
                      to={`${config.meetUrl}${meetingId}`}
                      target="_blank"
                      rel="noreferrer"
                      className="flex text-sm font-semibold leading-tight"
                    >
                      <Button className="px-3 py-2 font-semibold rounded-lg shadow">Join Now</Button>
                    </Link>
                  </>
                ) : (
                  meetingData?.meeting?.meetingSourceUrl?.length > 4 && (
                    <>
                      <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Where</p>
                      <Link
                        to={`${meetingData?.meeting?.meetingSourceUrl}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-slate-600 text-sm font-semibold leading-tight block"
                      >
                        {`${meetingData?.meeting?.meetingSourceUrl}`}
                      </Link>
                      <Link
                        to={`${meetingData?.meeting?.meetingSourceUrl}`}
                        target="_blank"
                        rel="noreferrer"
                        className="flex text-sm font-semibold leading-tight"
                      >
                        <Button className="px-3 py-2 font-semibold rounded-lg shadow">Join Now</Button>
                      </Link>
                    </>
                  )
                )} */}

                {meetingData?.meeting?.meetingSourceUrl && (
                  <>
                    <p className="text-slate-600 text-sm font-semibold leading-tight m-0">Where</p>
                    <Link
                      to={`${meetingData?.meeting?.meetingSourceUrl}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-slate-600 text-sm font-semibold leading-tight block"
                    >
                      {`${meetingData?.meeting?.meetingSourceUrl}`}
                    </Link>
                    <Link
                      to={`${meetingData?.meeting?.meetingSourceUrl}`}
                      target="_blank"
                      rel="noreferrer"
                      className="flex text-sm font-semibold leading-tight"
                    >
                      <Button className="px-3 py-2 font-semibold rounded-lg shadow">Join Now</Button>
                    </Link>
                  </>
                )}
              </div>

              {meetingData?.booking?.formSubmission && (
                <>
                  <hr />
                  {meetingData?.booking?.formSubmission?.answers?.map((item, i) => (
                    <div key={i} className="space-y-[10px]">
                      <h5 className="text-slate-600 text-sm font-semibold leading-tight m-0">
                        {meetingData?.booking?.formSubmission?.fields[i]?.question}
                      </h5>
                      <p className="text-slate-600 text-sm font-normal leading-tight m-0">{item}</p>
                    </div>
                  ))}
                </>
              )}
            </div>

            {meetingData?.booking?.confirmedByUser === false && meetingData?.meeting?.status !== 'cancelled' && (
              <div className="items-center gap-1 flex justify-center text-slate-600 text-sm font-normal font-inter leading-tight">
                <button className="bg-red-600 text-white px-3 py-2 rounded-lg" onClick={toggleConfirmAlert}>
                  Action Needed
                </button>
              </div>
            )}

            {meetingData?.booking?.confirmedByUser === false && meetingData?.meeting?.status === 'cancelled' && (
              <div className="items-center gap-1 flex justify-center text-slate-600 text-sm font-normal font-inter leading-tight">
                <span className="bg-red-600 text-white px-3 py-2 rounded-lg">Meeting Cancelled by You</span>
              </div>
            )}

            <div className="items-center gap-1 flex justify-center text-slate-600 text-sm font-normal font-inter leading-tight">
              Need to make a change?
              <Reschedule
                userName={user?.username}
                bookingId={meetingData?.booking?._id}
                scheduleId={meetingData?.booking?.schedule?._id}
              >
                <button className="underline">Reschedule</button>
              </Reschedule>
              or
              <button className="underline" onClick={toggleAlert}>
                Cancel
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {showCancelAlert && (
        <AlertDialog open={showCancelAlert} onOpenChange={setShowCancelAlert}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm Action</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to cancel this meeting? Once canceled, this action cannot be undone. Press{' '}
                <strong>Continue</strong> to confirm.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={handleCancelMeeting}>Continue</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}

      {showConfirmAlert && (
        <AlertDialog open={showConfirmAlert} onOpenChange={setShowConfirmAlert}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm Meeting Action</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to confirm this meeting? Note: Once canceled, this action cannot be undone. Press{' '}
                <strong>Yes</strong> to confirm.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Close</AlertDialogCancel>
              <AlertDialogAction onClick={() => handleConfirm(false)}>Cancel</AlertDialogAction>
              <AlertDialogAction onClick={() => handleConfirm(true)}>Continue</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
}

export default ViewMeetingDetailsModalNotification;
