function BriefTabs({ tabs, activeTab, setActiveTab }) {
  return (
    <div className="p-1.5 bg-[#e7e5e4] rounded-tl-full rounded-bl-full flex items-center gap-1">
      {tabs.map((item, index) => (
        <button
          key={item.name}
          onClick={() => setActiveTab(index)}
          className={`px-3 py-2 flex items-center gap-2 transition-all duration-300 ease-in-out rounded-full ${
            index === activeTab
              ? 'bg-white shadow-sm'
              : 'hover:bg-gray-200'
          }`}
        >
          <div className={`transition-colors duration-300 min-w-max ${index === activeTab ? 'text-[#173300]' : 'text-[#667085]'}`}>
            {item.icon}
          </div>
          <div
            className={`text-sm font-semibold leading-tight transition-colors duration-300 ${
              index === activeTab ? 'text-[#173300]' : 'text-[#667085]'
            }`}
          >
            {item.name}
          </div>
        </button>
      ))}
    </div>
  );
}

export default BriefTabs;
