import CreateMeetingDropdown from './create-meeting-dropdown';
import NotificationButton from './notification-button';
import ProfileDropdown from './profile-dropdown';
// import SwitchSubscriptionDropdown from './switch-subscription-dropdown';

function Topbar({ showSideBar, setShowSideBar }) {
  return (
    <div className="py-[10px] lg:px-8 px-4 bg-white border-b border-gray-200 justify-between items-center gap-[15px] flex">
      <button onClick={() => setShowSideBar((prev) => !prev)} className={`transition-all duration-300 ease-in-out md:hidden bg-white rounded-full z-[49] p-1 flex items-center justify-center ${showSideBar ? 'fixed border pl-4 pr-1.5 left-[310px]' : 'relative left-0'}`}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-6 transition-all duration-300 ease-in-out ${showSideBar ? 'hidden' : ''}`}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`size-6 transition-all duration-300 ease-in-out ${showSideBar ? '' : 'hidden'}`}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>
      <div className="flex items-center gap-[15px] ml-auto">
        {/* <SwitchSubscriptionDropdown /> */}
        <CreateMeetingDropdown />
        <NotificationButton />
        <ProfileDropdown />
      </div>
    </div>

  );
}

export default Topbar;
