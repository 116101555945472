/* eslint-disable indent */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import getResources from '../../../actions/getResources';
import { Button } from '../../../components/ui/button';
import {
  Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger,
} from '../../../components/ui/dialog';
import { Label } from '../../../components/ui/label';
import {
  Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,
} from '../../../components/ui/select';
import Config from '../../../config';

export default function MoveMeetingButton({ meetingId }) {
  const user = useGlobal('user')[0];
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      //   setIsLoading(true);
      try {
        const { data } = await getResources();
        // Sort the data to move the default item to the first index
        const sortedData = data.data.sort((a) => (a.default === true ? -1 : 1));
        const myResources = sortedData?.filter((resource) => (resource?.createdBy === user?._id));
        setResources(myResources);
        setSelectedResource(myResources[0]?._id);
      } catch (e) {
        console.error(e);
      }
    };

    getData();
  }, []);

  const handleMoveMeeting = async () => {
    console.log(selectedResource, meetingId);
    try {
      setIsLoading(true);
      const { data: res } = await axios.post(`${Config.url || ''}/api/v1/user/add_meeting_to_resource`, {
        meetingId,
        resourceId: selectedResource,
      });
      if (res?.success) {
        setOpen(false);
        toast.success(res?.message, {
          position: 'top-right',
        });
      } else {
        toast.error('Something went wrong', {
          position: 'top-right',
        });
      }
      console.log(res);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <button
          className="px-3 py-2 bg-white rounded-lg shadow border border-[#d0d5dd] justify-center items-center gap-1 flex text-[#323333] text-sm font-semibold leading-tight"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path d="M2.16667 15C1.70833 15 1.31597 14.8368 0.989583 14.5104C0.663194 14.184 0.5 13.7917 0.5 13.3333V10H2.16667V13.3333H13.8333V1.66667H2.16667V5H0.5V1.66667C0.5 1.20833 0.663194 0.815972 0.989583 0.489583C1.31597 0.163194 1.70833 0 2.16667 0H13.8333C14.2917 0 14.684 0.163194 15.0104 0.489583C15.3368 0.815972 15.5 1.20833 15.5 1.66667V13.3333C15.5 13.7917 15.3368 14.184 15.0104 14.5104C14.684 14.8368 14.2917 15 13.8333 15H2.16667ZM6.75 11.6667L5.58333 10.4583L7.70833 8.33333H0.5V6.66667H7.70833L5.58333 4.54167L6.75 3.33333L10.9167 7.5L6.75 11.6667Z" fill="#173300" />
          </svg>

          Move
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Move Meeting</DialogTitle>
          <DialogDescription className="hidden">
            Move your meeting to another resource.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-2">
          <Label htmlFor="resource">
            Resources
          </Label>
          <Select onValueChange={setSelectedResource} value={selectedResource}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select a resource" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {
                  resources?.map((item, i) => <SelectItem key={i} value={item._id}>{item.name}</SelectItem>)
                }
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <DialogFooter>
          <Button onClick={handleMoveMeeting}>
            Move
            {
              isLoading && <span className="size-3 ml-2 p-0.5 border-2 border-t-white border-transparent rounded-full animate-spin" />
            }
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
