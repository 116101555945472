/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { Button } from '../../../components/ui/button';
import {
  Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle,
} from '../../../components/ui/dialog';
import Config from '../../../config';
import { PLANS } from '../../../constants/Subscription';

export default function AddTeamSeatModal({ open, onOpenChange, next = () => { } }) {
  const [user] = useGlobal('user');
  const [seats, setSeats] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // const []
  const subscriptionData = user?.activeSubscription;

  // console.log(user?.activeSubscription);

  // const handleContinue = async (e) => {
  //   onOpenChange(false);
  //   next();
  // };

  const handleContinue = () => {
    // onOpenChange(false);
    // next();
    setIsLoading(true);
    axios
      .post(
        `${Config.url || ''}/api/v1/user/update-seats-subscription`,
        {
          seats,
          priceId: PLANS[2].planId,
        },
      )
      .then((res) => {
        // if (res.data.success) {
        //   handleCheckout(priceId, quantity);
        // }
        toast.success('Seat added successfully.', {
          position: 'top-center',
        });
        window.location.href = `${window.location.origin}/?showProfile=true&accountType=Team&message=success`;
      })
      .catch(async (err) => {
        console.log(err);
        toast.error('Something Went Wrong!', {
          position: 'top-right',
        });
      }).finally(() => {
        setIsLoading(false);
        onOpenChange(false);
        setTimeout(() => {
          next();
        }, 3000);
      });
  };

  const handleNavigateUpgradePlan = () => {
    // console.log('hell');
    if (searchParams.has('page')) {
      searchParams.set('page', 'plan');
    } else {
      searchParams.append('page', 'plan');
    }
    if (searchParams.has('showProfile')) {
      searchParams.set('showProfile', true);
    } else {
      searchParams.append('showProfile', true);
    }
    setSearchParams(searchParams);
    onOpenChange(false);
  };
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {/* <DialogTrigger asChild /> */}
      <DialogContent className="relative overflow-hidden">
        <DialogHeader>
          <DialogTitle className="text-[#101828] text-lg font-semibold font-inter leading-7">
            Update your teamspace seat plan
          </DialogTitle>
          <DialogDescription className="hidden" />
        </DialogHeader>
        <hr className="opacity-50" />
        {!(subscriptionData?.paddleSubscriptionId && subscriptionData?.plan === 'small_teams') ? (
          <div className="flex flex-col gap-4">
            <p className="text-slate-600 font-inter leading-normal">
              You have to update the plan to add more seats.
            </p>

            <div>
              <Button onClick={handleNavigateUpgradePlan}>
                Upgrade Plan
              </Button>
            </div>
          </div>
        ) : (
          <div className="gap-6 flex flex-col">
            <div className="p-4 bg-[#f2f4f7] rounded-2xl justify-between items-center gap-[26px] flex">
              <div className="justify-start items-center gap-2 flex">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="users-02">
                    <path id="Fill" opacity="0.12" d="M9.67969 12.6055C12.165 12.6055 14.1797 10.5908 14.1797 8.10547C14.1797 5.62019 12.165 3.60547 9.67969 3.60547C7.19441 3.60547 5.17969 5.62019 5.17969 8.10547C5.17969 10.5908 7.19441 12.6055 9.67969 12.6055Z" fill="black" />
                    <path id="Icon" d="M16.1797 4.07323C17.6614 4.80958 18.6797 6.33861 18.6797 8.10547C18.6797 9.87233 17.6614 11.4014 16.1797 12.1377M18.1797 17.3719C19.6911 18.0558 21.0522 19.1705 22.1797 20.6055M2.17969 20.6055C4.12618 18.128 6.76887 16.6055 9.67969 16.6055C12.5905 16.6055 15.2332 18.128 17.1797 20.6055M14.1797 8.10547C14.1797 10.5908 12.165 12.6055 9.67969 12.6055C7.19441 12.6055 5.17969 10.5908 5.17969 8.10547C5.17969 5.62019 7.19441 3.60547 9.67969 3.60547C12.165 3.60547 14.1797 5.62019 14.1797 8.10547Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </svg>

                <div className="text-[#101828] text-lg font-semibold font-inter leading-7">
                  {seats}
                  {' '}
                  seat
                </div>
              </div>
              <div className="items-center gap-2.5 flex">
                <Button
                  onClick={() => setSeats(seats + 1)}
                  className="px-3 py-2 bg-white text-black hover:text-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#d0d5dd] justify-center items-center gap-1 flex overflow-hidden"
                >
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="plus">
                      <path id="Icon" d="M10.4998 4.41211V16.0788M4.6665 10.2454H16.3332" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </svg>
                </Button>
                <Button
                  onClick={() => setSeats(seats === 1 ? seats : seats - 1)}
                  className="px-3 py-2 bg-white text-black hover:text-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#d0d5dd] justify-center items-center gap-1 flex overflow-hidden"
                >
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="minus">
                      <path id="Solid" fillRule="evenodd" clipRule="evenodd" d="M3.8335 10.2454C3.8335 9.78521 4.20659 9.41211 4.66683 9.41211H16.3335C16.7937 9.41211 17.1668 9.78521 17.1668 10.2454C17.1668 10.7057 16.7937 11.0788 16.3335 11.0788H4.66683C4.20659 11.0788 3.8335 10.7057 3.8335 10.2454Z" fill="currentColor" />
                    </g>
                  </svg>
                </Button>
              </div>
            </div>
            <Button onClick={handleContinue}>
              Continue
              {' '}
              {
                isLoading && <span className="flex size-3 border-2 border-white border-t-transparent ml-1 animate-spin rounded-full" />
              }
            </Button>
          </div>
        )}

      </DialogContent>
    </Dialog>
  );
}
