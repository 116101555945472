/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import getUserInfo from '../../../../actions/getUserInfo';
import { Button } from '../../../../components/ui/button';
import Config from '../../../../config';
import { getGravatarUrl } from '../../../../lib/utils';
import UsageProgress from '../../components/usage-progress';

function ProfileTeam({ from = 'page', callback = null }) {
  const [user, setUser] = useGlobal('user');
  const [refresh, setRefresh] = useState(false);
  // const [subscriptionData, setSubscriptionData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const subscriptionData = user?.activeSubscription;
  const getData = async () => {
    try {
      const { data: res } = await axios.get(`${Config.url}/api/v1/user/subscription/members/${subscriptionData?._id}`);
      // setSubscriptionData(res?.data);
      console.log(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const deleteMember = async (id) => {
    try {
      const concent = window.confirm('Are you sure you want to delete this member?');
      if (concent) {
        const { data: res } = await axios.post(
          `${Config.url}/api/v1/user/subscription/remove_subscription_member/${id}`,
        );
        // console.log(res);
        if (res.success) {
          toast.success('Member Deleted Successfully!', {
            position: 'top-center',
          });
          const { data: userRes } = await getUserInfo();
          if (userRes?.success) {
            setUser(userRes?.data);
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setRefresh(!refresh);
    }
  };

  const handleNavigate = () => {
    if (callback !== null) callback();
    if (searchParams.has('addTeam')) {
      searchParams.set('addTeam', true);
    } else {
      searchParams.append('addTeam', true);
    }
    setSearchParams(searchParams);
  };

  return (
    <div className={`space-y-8  ${user?.workspace?.createdBy !== user?._id ? 'opacity-60 pointer-events-none' : ''}`}>
      <div className="flex items-center justify-between">
        <div>
          <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Teams</h4>
          <p className="text-[#475467] text-sm font-normal font-inter leading-tight">
            You&apos;re on the following teams. You can create a new team here.
          </p>
        </div>
      </div>
      <hr />
      <div className="flex justify-between">
        <div className="flex items-end gap-6">
          <div className="min-w-[250px]">
            <UsageProgress
              value={subscriptionData?.subMembers?.length || 0}
              max={subscriptionData?.totalSeatsAllocated || 0}
              unit=" seat"
            />
          </div>
          {/* <p className="text-[#101828] text-xs font-semibold font-inter">
            {user?.activeSubscription?.subMembers?.length || 0}
            /
            {user?.activeSubscription?.totalSeatsAllocated}
            {' '}
            seat
          </p> */}
        </div>
        <Button onClick={handleNavigate} variant="outline">
          <span>Add Team Member</span>
        </Button>
      </div>
      <table className="w-full rounded-xl overflow-hidden">
        <thead className="bg-[#eaecf0] border-b border-[#eaecf0]">
          <tr>
            <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3 w-[50%]">Title</td>
            <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Email</td>
            <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Role</td>
            <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Status</td>
            <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Action</td>
          </tr>
        </thead>
        <tbody>
          {/* {
            user?.activeSubscription?.subMembers?.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <p className="text-center p-3">
                    No member&apos;s found
                  </p>
                </td>
              </tr>
            )
          } */}
          {user?.activeSubscription?.subMembers?.map((member) => (
            <tr key={member?._id} className="border-b border-[#eaecf0]">
              <td className="py-4 px-6">
                <div className="flex items-center gap-3">
                  <img
                    className="w-[40px] h-[40px] rounded-full object-cover object-center"
                    src={getGravatarUrl(`${member?.user?.firstName} ${member?.user?.lastName}`, member?.user?.email)}
                    alt=""
                  />
                  <div>
                    <h4 className="text-[#101828] text-sm font-normal leading-tight mb-1">
                      {`${member?.user?.firstName} ${member?.user?.lastName}`}
                    </h4>
                  </div>
                </div>
              </td>
              <td className="py-4 px-6">
                <a
                  href={`mailto:${member?.user?.email}`}
                  target="_blank"
                  className="text-[#475467] text-sm font-normal font-inter leading-tight"
                  rel="noreferrer"
                >
                  {member?.user?.email}
                </a>
              </td>
              <td className="py-4 px-6">
                <span className="text-[#475467] text-sm font-normal font-inter leading-tight capitalize">
                  {member?.role}
                </span>
              </td>
              <td className="py-4 px-6">
                <span className="text-[#475467] text-sm font-normal font-inter leading-tight capitalize">
                  {member?.status}
                </span>
              </td>
              <td className="py-4 px-6">
                {member?.role === 'owner' ? (
                  '-'
                ) : (
                  <Button variant="ghost" onClick={() => deleteMember(member._id)}>
                    Delete
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProfileTeam;
