import { useNavigate } from 'react-router-dom';

function BackButton() {
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate(-1)} className="px-3 py-2 bg-white rounded-lg shadow border-2 border-[#173300] justify-center items-center gap-1 flex text-[#323333] text-sm font-semibold leading-tight">
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <g id="Buttons/Button" filter="url(#filter0_d_9082_1821)">
          <path d="M-11.7847 -4.76837e-07C-11.7847 -4.41828 -8.20295 -8 -3.78467 -8H66.2153C70.6336 -8 74.2153 -4.41828 74.2153 0V20C74.2153 24.4183 70.6336 28 66.2153 28H-3.78467C-8.20295 28 -11.7847 24.4183 -11.7847 20V-4.76837e-07Z" fill="white" />
          <path d="M-11.0347 -4.76837e-07C-11.0347 -4.00406 -7.78873 -7.25 -3.78467 -7.25H66.2153C70.2194 -7.25 73.4653 -4.00406 73.4653 0V20C73.4653 24.0041 70.2194 27.25 66.2153 27.25H-3.78467C-7.78873 27.25 -11.0347 24.0041 -11.0347 20V-4.76837e-07Z" stroke="#173300" strokeOpacity="0.47" strokeWidth="1.5" />
          <g id="arrow_back_ios_new">
            <mask id="mask0_9082_1821" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
              <rect id="Bounding box" x="0.215332" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_9082_1821)">
              <path id="arrow_back_ios_new_2" d="M13.5487 18.3333L5.21533 10L13.5487 1.66666L15.0278 3.14583L8.17367 10L15.0278 16.8542L13.5487 18.3333Z" fill="#173300" />
            </g>
          </g>
        </g>
        <defs>
          <filter id="filter0_d_9082_1821" x="-13.7847" y="-9" width="90" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9082_1821" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9082_1821" result="shape" />
          </filter>
        </defs>
      </svg>
      Back
    </button>
  );
}

export default BackButton;
