/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import getUserInfo from '../../../../actions/getUserInfo';
import getWorkspaceMembers from '../../../../actions/getWorkspaceMembers';
import { Button } from '../../../../components/ui/button';
import Config from '../../../../config';
import { getGravatarUrl } from '../../../../lib/utils';
import AddTeamMemberButton from '../../components/add-team-button';

function Team() {
  const [user, setUser] = useGlobal('user');
  const workspaceId = user?.workspace?._id;
  const [teamMembers, setTeamMembers] = useState();
  const [refresh, setRefresh] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [teamSpaces] = useGlobal('teamSpaces');
  const selectedTeamSpace = teamSpaces?.find((space) => space?._id === user?.workspace?._id);

  const isOwner = user?.workspace?.createdBy === user?._id;

  // console.log(selectedTeamSpace);

  const getWorkspaceMembersData = async () => {
    try {
      const { data: res } = await getWorkspaceMembers(workspaceId);
      // console.log('teammembers: \n', res.data);
      setTeamMembers(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    try {
      const { data: res } = await axios.get(`${Config.url}/api/v1/user/subscription/user_subscription`);
      setSubscriptionData(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getWorkspaceMembersData();
    getData();
  }, [refresh]);

  const deleteMember = async (id) => {
    try {
      const concent = window.confirm('Are you sure you want to delete this member?');
      if (concent) {
        const { data: res } = await axios.post(`${Config.url}/api/v1/user/workspace/remove_workspace_member/${id}`);
        // console.log(res);
        if (res.success) {
          toast.success('Member Deleted Successfully!', {
            position: 'top-center',
          });
          const { data: userRes } = await getUserInfo();
          if (userRes?.success) {
            setUser(userRes?.data);
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setRefresh(!refresh);
    }
  };

  return (
    <>
      {isOwner ? (
        <div className="space-y-8">
          <div className="flex items-end justify-between">
            <div>
              <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Teams</h4>
              <p className="text-[#475467] text-sm font-normal font-inter leading-tight">
                You&apos;re on the following teams. You can create a new team here.
              </p>
            </div>
            <AddTeamMemberButton
              setRefresh={setRefresh}
              subscriptionData={subscriptionData}
              teamMembers={teamMembers}
            />
          </div>
          <hr />
          <table className="w-full rounded-xl overflow-hidden">
            <thead className="bg-[#eaecf0] border-b border-[#eaecf0]">
              <tr>
                <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3 w-[50%]">Title</td>
                <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Email</td>
                <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Role</td>
                <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Status</td>
                <td className="text-[#475467] text-xs font-medium leading-[18px] px-6 py-3">Action</td>
              </tr>
            </thead>
            <tbody>
              {teamMembers?.length === 0 && (
                <tr>
                  <td colSpan={4}>
                    <p className="text-center p-3">No member&apos;s found</p>
                  </td>
                </tr>
              )}
              {teamMembers?.map((member) => (
                <tr key={member?._id} className="border-b border-[#eaecf0]">
                  <td className="py-4 px-6">
                    <div className="flex items-center gap-3">
                      <img
                        className="w-[40px] h-[40px] rounded-full object-cover object-center"
                        src={getGravatarUrl(`${member?.user?.firstName} ${member?.user?.lastName}`, member?.user?.email)}
                        alt=""
                      />
                      <div>
                        <h4 className="text-[#101828] text-sm font-normal leading-tight mb-1">
                          {`${member?.user?.firstName} ${member?.user?.lastName}`}
                        </h4>
                      </div>
                    </div>
                  </td>
                  <td className="py-4 px-6">
                    <a
                      href={`mailto:${member?.user?.email}`}
                      target="_blank"
                      className="text-[#475467] text-sm font-normal font-inter leading-tight"
                      rel="noreferrer"
                    >
                      {member?.user?.email}
                    </a>
                  </td>
                  <td className="py-4 px-6">
                    <span className="text-[#475467] text-sm font-normal font-inter leading-tight capitalize">
                      {member?.role}
                    </span>
                  </td>
                  <td className="py-4 px-6">
                    <span className="text-[#475467] text-sm font-normal font-inter leading-tight capitalize">
                      {member?.status}
                    </span>
                  </td>
                  <td className="py-4 px-6">
                    {member?.role === 'owner' ? (
                      '-'
                    ) : (
                      <Button variant="ghost" onClick={() => deleteMember(member?._id)}>
                        Delete
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="space-y-8">
          <div>
            <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">On teams</h4>
            <p className="text-[#475467] text-sm font-normal font-inter leading-tight">
              You&apos;re currently on these teams.
            </p>
          </div>
          <div className="border-t border-b border-[#eaecf0] flex items-end justify-between py-6">
            <div className="items-center gap-3 flex">
              {selectedTeamSpace?.brandLogo ? (
                <img
                  alt=""
                  className="w-[30px] h-[30px] rounded"
                  src={`${Config?.url}${selectedTeamSpace?.brandLogo?.location?.slice(1)}`}
                />
              ) : (
                <div className="size-6 bg-[#f1eaba] rounded-lg justify-center items-center flex">
                  <span className="text-[#173300] font-bold text-xs font-inter uppercase">
                    {selectedTeamSpace?.name?.slice(0, 2)}
                  </span>
                </div>
              )}
              <h4 className="text-[#18212f] text-base font-semibold font-inter leading-normal">
                {selectedTeamSpace?.name}
              </h4>
            </div>

            <button className="text-[#475467] text-sm font-semibold font-inter leading-tight">Leave</button>
          </div>
        </div>
      )}

      <p />
    </>
  );
}

export default Team;
