function data({ data }) {
  return (
    <div className="p-4 bg-[#e9eef6] rounded-xl gap-6 flex">

      <div className="gap-[6.67px] flex">
        <div className="pt-[3.33px]">
          <div className="size-[8.33px] bg-[#8292d5] rounded-full" />
        </div>
        <div className="flex-col gap-[3.33px] flex">
          <p className="text-[#475467] text-xs font-semibold min-w-max leading-[15px]">
            Total Time
          </p>
          <h4 className="text-[#101828] text-base font-medium font-inter leading-normal min-w-max">
            {data?.totalMeetingTime || 0}
            {' '}
            min
          </h4>
        </div>
      </div>
      <div className="gap-[6.67px] flex">
        <div className="pt-[3.33px]">
          <div className="size-[8.33px] bg-[#e08bff] rounded-full" />
        </div>
        <div className="flex-col gap-[3.33px] flex">
          <p className="text-[#475467] text-xs font-semibold leading-[15px] min-w-max">
            Total Meetings
          </p>
          <h4 className="text-[#101828] text-base font-medium font-inter leading-normal min-w-max">
            {data?.totalMeetings || 0}
          </h4>
        </div>
      </div>
      <div className="gap-[6.67px] flex">
        <div className="pt-[3.33px]">
          <div className="size-[8.33px] bg-[#8292d5] rounded-full" />
        </div>
        <div className="flex-col gap-[3.33px] flex">
          <p className="text-[#475467] text-xs min-w-max font-semibold leading-[15px]">
            Avg. Meeting
          </p>
          <h4 className="text-[#101828] text-base font-medium font-inter leading-normal min-w-max">
            {data?.avgMeetingDuration || 0}
            {' '}
            min
          </h4>
        </div>
      </div>
    </div>
  );
}

export default data;
