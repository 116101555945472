/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import { DriveIcon, LinkIcon } from '../../../assets/svgElements';
import { Button } from '../../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '../../../components/ui/dialog';
import Config from '../../../config';
import UsageProgressFileSize from './usage-progress-file';

// API Functions for React Query
const fetchDriveStorage = async (drives) => {
  const requests = drives.map((drive) =>
    axios.get(`${Config.url || ''}/api/v1/user/back_office/get_drive_storage_info/${drive?._id}`)
      .then(({ data: { data: storage } }) => ({
        ...drive,
        storage,
      }))
  );
  return Promise.all(requests);
};

const updatePrimaryDrive = async (driveId) => {
  const { data } = await axios.post(`${Config.url || ''}/api/v1/user/back_office/make_drive_primary/${driveId}`);
  return data;
};

export default function SelectDriveModal({ drives }) {
  const [selectedStorage, setSelectedStorage] = useState(0);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  // Query to fetch drive storage info
  const { data: drivesWithStorage = [], isLoading: isStorageLoading } = useQuery({
    queryKey: ['driveStorage', drives?.map(d => d._id)],
    queryFn: () => fetchDriveStorage(drives),
    enabled: open && drives?.length > 0,
    onError: (error) => {
      toast.error('Something went wrong fetching storage', { position: 'top-right' });
      console.error(error);
    },
  });

  useEffect(() => {
    if (drivesWithStorage?.length > 0) {
      const primaryDrive = drivesWithStorage.find((drive) => drive.primary);
      setSelectedStorage(primaryDrive?._id);
    }
  }, [drivesWithStorage]);

  // Mutation to update primary drive
  const { mutate: handleUpdatePrimaryStorage, isPending: isLoading } = useMutation({
    mutationFn: updatePrimaryDrive,
    onSuccess: () => {
      queryClient.invalidateQueries(['drives']); // Refetch drives in Scheduling
      setOpen(false);
      toast.success('Primary storage updated successfully', { position: 'top-right' });
    },
    onError: (error) => {
      toast.error('Something went wrong', { position: 'top-right' });
      console.error(error);
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen} className="bg-white">
      <DialogTrigger asChild>
        <button className={`px-[12.52px] py-[5.57px] ${drives?.length > 0 ? 'bg-[#e9eef6]' : 'bg-[#fcd6d6]'} rounded-md justify-center items-center gap-[5.57px] inline-flex overflow-hidden`}>
          <DriveIcon />
          <LinkIcon className={drives?.length > 0 ? 'text-[#17B26A]' : 'text-red-500'} />
        </button>
      </DialogTrigger>
      <DialogContent closeButtonClassName="top-1.5 right-1.5" className="bg-white max-w-[500px]">
        <DialogTitle className="hidden" />
        <DialogDescription className="hidden" />
        {isStorageLoading ? (
          <div className="flex p-6 justify-center">
            <span className="size-8 border-4 border-black border-t-transparent rounded-full animate-spin" />
          </div>
        ) : (
          <>
            <div className="px-3 py-[22px] bg-[#e9eef6] space-y-6 rounded-xl">
              <h4 className="text-[#344054] text-sm font-medium leading-tight">
                Select Primary Storage of your media
              </h4>
              {drivesWithStorage?.map((drive, i) => (
                <div key={i} className="flex gap-3 cursor-pointer" onClick={() => setSelectedStorage(drive?._id)}>
                  <div className="min-w-max pt-1 cursor-default">
                    <div className={`size-5 ${selectedStorage === drive?._id ? 'border-[6px] border-gray-900' : 'border border-gray-400'} rounded-full`} />
                  </div>
                  <div className="bg-white py-2.5 px-6 w-full rounded-lg space-y-4">
                    <p className="text-[#667085] text-base font-normal font-inter leading-normal">
                      {drive?.email} <small>{drive?.primary ? '(primary)' : ''}</small>
                    </p>
                    <UsageProgressFileSize value={drive?.storage?.usedSpace} max={drive?.storage?.totalSpace} unit="MB" />
                  </div>
                </div>
              ))}
              {drives?.length === 0 && <p>Drive not connected.</p>}
            </div>
            <div className="flex gap-4">
              <Button onClick={() => handleUpdatePrimaryStorage(selectedStorage)}>
                Update
                {isLoading && <span className="size-3 ml-2 border-2 rounded-full border-white border-t-transparent animate-spin" />}
              </Button>
              <Link to="/calendar/scheduling">
                <Button variant="outline">
                  Connect More Storage
                </Button>
              </Link>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}