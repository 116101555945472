/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { fetchMeetingStats } from '../../../actions/apiFunctions';
import UsageProgress from './usage-progress';

const tabs = [
  {
    title: 'This Week',
    slug: 'this-week',
  },
  {
    title: 'This Month',
    slug: 'this-month',
  },
];

export default function DashboardStats() {
  const [selectedFilter, setSelectedFilter] = useState(0);

  // Query for fetching meeting stats
  const { data: meetingStats } = useQuery({
    queryKey: ['meetingStats', tabs[selectedFilter].slug],
    queryFn: () => fetchMeetingStats(tabs[selectedFilter].slug),
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <div className="p-4 bg-[#e9eef6] rounded-xl space-y-2">
      <div className="flex gap-4">
        {tabs.map((item, i) => (
          <button
            key={item.slug}
            onClick={() => setSelectedFilter(i)}
            className={`text-[#475467]${selectedFilter === i ? '' : '/50'} text-xs font-semibold font-inter leading-[15px]`}
          >
            {item.title}
          </button>
        ))}
      </div>
      <hr />
      <div className="py-2 gap-3 flex flex-col w-full">
        <div className="grid grid-cols-2 gap-3">
          <div className="gap-[6.67px] flex">
            <div className="pt-[3.33px]">
              <div className="size-[8.33px] bg-[#8292d5] rounded-full" />
            </div>
            <div className="flex-col gap-[3.33px] flex">
              <p className="text-[#475467] text-xs font-semibold leading-[15px]">Total Time</p>
              <h4 className="text-[#101828] text-base font-medium font-inter leading-normal">
                {meetingStats?.totalMeetingTime || 0} min
              </h4>
            </div>
          </div>
          <div className="gap-[6.67px] flex">
            <div className="pt-[3.33px]">
              <div className="size-[8.33px] bg-[#cf7249] rounded-full" />
            </div>
            <div className="flex-col gap-[3.33px] flex">
              <p className="text-[#475467] text-xs font-semibold leading-[15px]">Time Saved</p>
              <h4 className="text-[#101828] text-base font-medium font-inter leading-normal">
                {meetingStats?.timeSaved || 0} min
              </h4>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="gap-[6.67px] flex">
            <div className="pt-[3.33px]">
              <div className="size-[8.33px] bg-[#e08bff] rounded-full" />
            </div>
            <div className="flex-col gap-[3.33px] flex">
              <p className="text-[#475467] text-xs font-semibold leading-[15px] min-w-max">Total Meetings</p>
              <h4 className="text-[#101828] text-base font-medium font-inter leading-normal">
                {meetingStats?.totalMeetings || 0}
              </h4>
            </div>
          </div>
          <div className="gap-[6.67px] flex">
            <div className="pt-[3.33px]">
              <div className="size-[8.33px] bg-[#8292d5] rounded-full" />
            </div>
            <div className="flex-col gap-[3.33px] flex">
              <p className="text-[#475467] text-xs font-semibold leading-[15px]">Avg. Meeting</p>
              <h4 className="text-[#101828] text-base font-medium font-inter leading-normal">
                {meetingStats?.avgMeetingDuration || 0} min
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white px-3 py-2 rounded-lg">
        <h1 className="font-semibold mb-3">Minute Usage</h1>
        <UsageProgress
          value={
            meetingStats?.subscriptionByWorkspace.hoursUsedMonthly !== undefined
              ? Math.floor(meetingStats.subscriptionByWorkspace.hoursUsedMonthly * 60)?.toFixed(2)
              : 0
          }
          max={meetingStats?.subscriptionByWorkspace?.totalMinutesAllocated || 0}
          unit=" m"
        />
      </div>
    </div>
  );
}
