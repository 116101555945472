export default function ProjectSpaceNotFound() {
  return (
    <div className="w-full flex flex-col p-4 items-center gap-6 justify-center">
      <img src="/image/project-space-not-found.png" width={200} alt="" />

      <h4 className="text-center text-black text-3xl font-extrabold font-bricolage tracking-wider">
        No projects yet, just potential.
      </h4>
    </div>
  );
}
