import InvoiceTable from './invoice-table';

function InvoiceTableContainer() {
  return (
    <div className="space-y-6">
      <div className="flex justify-between">
        <div>
          <h2 className="text-gray-900 text-lg font-semibold leading-7 mb-2">Billing and invoicing</h2>
          <p className="text-slate-600 text-sm font-normal leading-tight m-0">
            Pick an account plan that fits your workflow.
          </p>
        </div>
      </div>
      <InvoiceTable />
    </div>
  );
}

export default InvoiceTableContainer;
