/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios';
import { Copy } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useGlobal } from 'reactn';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogTitle, DialogTrigger } from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import Config from '../../../config';
import { cn, convertTimeStringToMinutes, convertTo24Hour, generateTimeArray, isTimeLater } from '../../../lib/utils';
import MeetingHostEmailSelector from './meeting-host-email-selector';
import MeetingSourceSelector from './meeting-source-selector';

function getClosestTime(timeArray, currentTime) {
  // Convert time string to minutes since 00:00
  const timeToMinutes = (time) => {
    if (!time) return;
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const currentTimeInMinutes = timeToMinutes(currentTime);

  // Find the closest time
  let closestTime = timeArray[0];
  let smallestDifference = Math.abs(currentTimeInMinutes - timeToMinutes(closestTime));

  for (const time of timeArray) {
    const timeInMinutes = timeToMinutes(time);
    const difference = Math.abs(currentTimeInMinutes - timeInMinutes);

    if (difference < smallestDifference) {
      smallestDifference = difference;
      closestTime = time;
    }
  }

  return closestTime;
}

const isSameDate = (date1, date2) => moment(date1).isSame(date2, 'day');

const shouldDisable = (bookings, date, timeStr) => bookings.some(
  (booking) => isSameDate(booking?.bookingDate, date) && convertTo24Hour(booking?.bookingTime) === convertTo24Hour(timeStr),
);

function CreateInstantMeeting({ className, children }) {
  const user = useGlobal('user')[0];

  const { addToast } = useToasts();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');
  const [bookingData, setBookingData] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [loading, setLoading] = useState(true);
  const [meetingSource, setMeetingSource] = useState('google');
  const [meetingHostId, setMeetingHostId] = useState('');
  const [data, setData] = useState(null);
  const [meetingURL, setMeetingURL] = useState('');
  const navigate = useNavigate();

  const [calendars, setCalendars] = useState([]);

  // const meetingURL = data?.booking?.meeting?.meetingSourceUrl || `${Config.meetUrl}${data?.booking?.meeting?.meetingCode}`;

  useEffect(() => {
    const bookingId = bookingData?.booking?._id;
    if (bookingId) {
      const config = {
        method: 'get',
        url: `${Config.url || ''}/api/v1/user/book_schedule/get_booking/${bookingId}`,
      };
      axios
        .request(config)
        .then(({ data }) => {
          // console.log(data.data, 'Booking Data');
          setData(data.data);
          // console.log(data);
          setMeetingURL(data?.data?.booking?.meeting?.meetingSourceUrl);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response.data.error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [bookingData]);

  useEffect(() => {
    getCalendars();
  }, [isOpen]);

  const getCalendars = async () => {
    try {
      const { data: res } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_calendars`);
      // console.log('calenders data:', res?.data);
      setCalendars(res?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSchedule = () => {
    if (!selectedTime || !meetingSource) {
      addToast('Please select a meeting source', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('date', moment().tz(schedule?.timeZone)?.format('MM-DD-YYYY'));
      formData.append('time', selectedTime);
      formData.append('workspaceID', user?.workspace?.brandID);
      formData.append('meetingSource', meetingSource);
      formData.append('meetingHostId', meetingHostId);
      formData.append('meetingType', 'instant');

      const config = {
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/book_schedule`,
        data: formData,
      };

      axios
        .request(config)
        .then((data) => {
          // console.log(data?.data?.message);
          const bookingId = data?.data?.data?._id;

          const formData = new FormData();
          formData.append('formId', selectedForm?._id);
          formData.append('bookingId', bookingId);
          formData.append('bookingType', 'instant');
          formData.append('preMeetingFormRequired', 'false');
          formData.append('meetingSource', meetingSource);
          formData.append('meetingHostId', meetingHostId);

          const config = {
            method: 'post',
            url: `${Config.url || ''}/api/v1/user/book_schedule/save_n_invite`,
            data: formData,
          };

          axios
            .request(config)
            .then((data) => {
              if (data?.data?.success) {
                // console.log(data?.data?.data);
                setBookingData(data?.data?.data);
                setRefreshMeetings(!refreshMeetings);
                // setLoading(false);
              }
            })
            .catch((err) => {
              addToast(err?.response?.data?.error, {
                appearance: 'error',
                autoDismiss: true,
              });
            });
        })
        .catch((err) => {
          addToast(err?.response?.data?.error, {
            appearance: 'error',
            autoDismiss: true,
          });
          // console.log(err.response.data.error);
        });
    } catch (err) {
      console.log(err);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${Config.url || ''}/api/v1/user/book_schedule/get_bookings_with_schedule`)
      .then((data) => {
        const schedule = data?.data?.data?.schedule;
        const bookings = data?.data?.data?.bookings;
        // console.log(bookings, 'bookings');
        const slot = Number(convertTimeStringToMinutes(schedule?.timeSlot));
        const daysOfWeek = schedule?.availableWeekdays;
        const currentTime = moment().tz(schedule?.timeZone);
        const currentTime24h = currentTime?.format('HH:mm');
        // console
        const timeArray = (daysOfWeek[currentTime.day()]?.startTime || daysOfWeek[currentTime.day()]?.endTime) &&
          generateTimeArray(
            daysOfWeek[currentTime.day()]?.startTime,
            daysOfWeek[currentTime.day()]?.endTime,
            '24h',
            slot,
            schedule?.timeZone,
          );

        const timeArrayFiltered = timeArray.filter((time) => !shouldDisable(bookings, currentTime, time, schedule?.timeZone) && isTimeLater(time, schedule?.timeZone));

        const closestTime = getClosestTime(timeArrayFiltered, currentTime24h);
        setSelectedTime(closestTime);
        setSchedule(schedule);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      axios
        .get(`${Config.url || ''}/api/v1/user/book_schedule/get_forms`)
        .then((data) => {
          const forms = data?.data?.data;
          setSelectedForm(forms[0]);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setData(null);
      setMeetingURL('');
      setSelectedForm(null);
      setBookingData(null);
      setCalendars([]);
      setSelectedTime('');
    }
  }, [isOpen]);

  const handleCopy = () => {
    navigator.clipboard.writeText(meetingURL);
    addToast('Link copied to clipboard', {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        {
          children ||
          <Button className={cn('flex items-center gap-2 hover:bg-[#193601] bg-[#173300] text-[#FFED74]', className)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="plus-circle">
                <path
                  id="Solid"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0007 0.833252C4.93804 0.833252 0.833984 4.93731 0.833984 9.99992C0.833984 15.0625 4.93804 19.1666 10.0007 19.1666C15.0633 19.1666 19.1673 15.0625 19.1673 9.99992C19.1673 4.93731 15.0633 0.833252 10.0007 0.833252ZM10.0007 5.83325C10.4609 5.83325 10.834 6.20635 10.834 6.66658V9.16658H13.334C13.7942 9.16658 14.1673 9.53968 14.1673 9.99992C14.1673 10.4602 13.7942 10.8333 13.334 10.8333H10.834V13.3333C10.834 13.7935 10.4609 14.1666 10.0007 14.1666C9.54041 14.1666 9.16732 13.7935 9.16732 13.3333V10.8333H6.66732C6.20708 10.8333 5.83398 10.4602 5.83398 9.99992C5.83398 9.53968 6.20708 9.16658 6.66732 9.16658H9.16732V6.66658C9.16732 6.20635 9.54041 5.83325 10.0007 5.83325Z"
                  fill="currentColor"
                />
              </g>
            </svg>
            <span className="text-base font-semibold leading-normal">Create meeting</span>
          </Button>
        }
      </DialogTrigger>
      <DialogContent closeButtonClassName="top-2 right-2">

        {(loading) ? (
          <>
            <DialogTitle className="hidden" />
            <DialogDescription className="hidden" />
            <div className="p-12 flex items-center justify-center">
              <span className="size-4 border-4 p-4 rounded-full border-[#173300] animate-spin border-t-[#FFED74]" />
            </div>
          </>
        ) : calendars.length === 0 ? (
          <div>
            <div>
              <h2 className="font-bold text-2xl">
                Oops! No Calendar Found or Synced
              </h2>
            </div>
            <div className="py-4">
              <p className="text-gray-500">
                It looks like you don&apos;t have a calendar connected yet. Please connect your calendar to get started!
              </p>
            </div>
            <div className="flex gap-2">
              <Button
                className={cn('flex items-center gap-2', className)}
                onClick={() => {
                  setIsOpen(false);
                  navigate('/calendar/scheduling');
                }}
              >
                {' '}
                <span>
                  <FiSettings />
                </span>{' '}
                <span>Go to setup</span>
              </Button>

              <Button
                className={cn(
                  'flex items-center gap-2 bg-white text-black hover:bg-white hover:text-black border border-black',
                  className,
                )}
                onClick={() => setIsOpen(false)}
              >
                {' '}
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        ) : (
          <>
            {
              (meetingURL && data) ?
                <>
                  <DialogTitle>
                    Meeting Scheduled
                  </DialogTitle>
                  <DialogDescription>
                    Your meeting has been scheduled successfully. Copy the link below to share with others.
                  </DialogDescription>
                  <div className="flex items-center space-x-2">
                    <div className="grid flex-1 gap-2">
                      <Label htmlFor="link" className="sr-only">
                        Link
                      </Label>
                      <Input
                        id="link"
                        defaultValue={meetingURL}
                        readOnly
                      />
                    </div>
                    <Button type="submit" size="sm" onClick={handleCopy} className="px-3">
                      <span className="sr-only">Copy</span>
                      <Copy className="size-4" />
                    </Button>
                  </div>
                </> :
                <>
                  <DialogTitle>
                    Schedule Instant Meeting
                  </DialogTitle>
                  <DialogDescription>
                    Select the meeting source and host email to create an instant meeting.
                  </DialogDescription>
                  <p className=" text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center border bg-white p-2 rounded-lg">
                    <img className="w-4 h-4" src="/image/icons/video.svg" alt="" />
                    <MeetingSourceSelector
                      setMeetingSource={setMeetingSource}
                      meetingSource={meetingSource}
                      className="w-full bg-white max-w-full"
                    />
                  </p>
                  {meetingSource === 'google' && (
                    <p className=" text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center border bg-white p-2 rounded-lg">
                      <img className="w-4 h-4" src="/image/icons/avatar.svg" alt="" />
                      <MeetingHostEmailSelector
                        setMeetingHostId={setMeetingHostId}
                        meetingSource={meetingSource}
                        meetingHostId={meetingHostId}
                        className="w-full bg-white max-w-full"
                      />
                    </p>
                  )}
                </>
            }
            <DialogFooter className="sm:justify-start">
              {
                (meetingURL && data) ?
                  <Button onClick={() => {
                    setIsOpen(false);
                    window.open(meetingURL);
                  }}
                  >
                    Join Meeting
                  </Button> :
                  <Button onClick={handleSchedule} disabled={loading}>
                    Start Meeting
                  </Button>
              }
              <DialogClose asChild>
                <Button type="button" variant="secondary">
                  Close
                </Button>
              </DialogClose>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default CreateInstantMeeting;
