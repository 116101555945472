/* eslint-disable indent */
import axios from 'axios';
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp';
import { useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { Button } from '../../../../components/ui/button';
import {
    Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle,
} from '../../../../components/ui/dialog';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '../../../../components/ui/input-otp';
import config from '../../../../config';

export default function VerifyEmailModal({ isVerifyModalOpen, setIsVerifyModalOpen }) {
    const [otp, setOTP] = useState('');
    const setUser = useGlobal('user')[1];
    const [isVerifyLoading, setIsVerifyLoading] = useState(false);

    const handleVerifyOTP = async () => {
        if (!otp) {
            toast.error('Please Enter your OTP', {
                position: 'top-right',
            });
            return;
        }
        try {
            setIsVerifyLoading(true);
            const { data: res } = await axios({
                method: 'post',
                url: `${config.url || ''}/api/v1/user/verify_email`,
                data: {
                    emailCode: otp,
                },
            });
            if (res.success) {
                toast.success(res.message, {
                    position: 'top-right',
                });
                const { data: user } = await axios({
                    method: 'get',
                    url: `${config.url || ''}/api/v1/user/me`,
                });
                setUser(user.data);
            }
        } catch (err) {
            console.log(err);
            toast.error('Something went wrong!', {
                description: 'Please try again.',
                position: 'top-right',
            });
        } finally {
            setIsVerifyLoading(false);
            setIsVerifyModalOpen(false);
        }
    };
    return (
      <Dialog open={isVerifyModalOpen} onOpenChange={setIsVerifyModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Verify your email address.
            </DialogTitle>
            <DialogDescription>
              Please check your email and enter the OTP to verify your email address.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-3">
            <InputOTP
              value={otp}
              onChange={(value) => setOTP(value)}
              maxLength={6}
              pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
            >
              <InputOTPGroup>
                <InputOTPSlot index={0} />
                <InputOTPSlot index={1} />
                <InputOTPSlot index={2} />
              </InputOTPGroup>
              {/* <InputOTPSeparator /> */}
              <InputOTPGroup>
                <InputOTPSlot index={3} />
                <InputOTPSlot index={4} />
                <InputOTPSlot index={5} />
              </InputOTPGroup>
            </InputOTP>
            <Button onClick={handleVerifyOTP} className="flex items-center gap-1">
              Verify OTP
              {
                            isVerifyLoading && <span className="size-2.5 border border-white border-t-transparent ml-1 animate-spin rounded-full block" />
                        }
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
}
