/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { changeWorkspaceCalendar, fetchCalendars, fetchCurrentWorkspace } from '../../../actions/apiFunctions';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/select';
import Config from '../../../config';
import MeetingCalendarWrapper from '../components/meeting-calendar-wrapper';
import UpcomingMeetings from '../components/upcoming-meetings';
import WelcomeHeading from '../components/welcome-heading';
import Layout from '../layout';

function DashboardIndex() {
  const [user] = useGlobal('user');
  const [teamSpaces] = useGlobal('teamSpaces');
  const [selectedWorkspace, setSelectedWorkspace] = useGlobal('selectedWorkspace');
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [currentCalendarView, setCurrentCalendarView] = useState(() => {
    return localStorage.getItem('calendarView') || 'Week';
  });
  const [fetchingTime, setFetchingTime] = useState(null);
  const isDefault = user?.workspace?.default && user?.workspace?.createdBy === user?._id;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Query for current workspace
  const { data: currentWorkspace } = useQuery({
    queryKey: ['currentWorkspace'],
    queryFn: fetchCurrentWorkspace,
    onError: (err) => console.log(err),
  });

  const currentWorkspaceCalendar = currentWorkspace?.workspace_calendar;

  useEffect(() => {
    if (currentWorkspaceCalendar) {
      setSelectedCalendar(currentWorkspaceCalendar?._id);
    }
  }, [currentWorkspaceCalendar]);

  // Query for calendars
  const { data: calendars = [] } = useQuery({
    queryKey: ['calendars'],
    queryFn: fetchCalendars,
    onError: (error) => console.log(error),
  });

  // Mutation for changing workspace calendar
  const { mutate: updateWorkspaceCalendar } = useMutation({
    mutationFn: changeWorkspaceCalendar,
    onMutate: () => {
      toast.loading('Changing Workspace Calendar', { id: 'change-calendar' });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['currentWorkspace']); // Refetch current workspace
      toast.success(data.message, { id: 'change-calendar' });
    },
    onError: (error) => {
      console.log(error);
      toast.error('Failed to change workspace calendar', { id: 'change-calendar' });
    },
    onSettled: () => {
      toast.dismiss('change-calendar');
    },
  });

  // Guest check (kept as useEffect since it’s navigation-related)
  useEffect(() => {
    if (user?.isGuest && !location.pathname.includes('/meeting')) {
      navigate('/not-allowed');
    }
  }, [user, navigate]);

  // Handle calendar change
  const handleCalendarChange = (calendarId) => {
    setSelectedCalendar(calendarId);
    if (calendarId && calendarId !== currentWorkspaceCalendar?._id) {
      updateWorkspaceCalendar({
        calendarId,
        workspaceId: user?.workspace?._id,
      });
    }
  };

  return (
    <Layout className="w-full">
      <div className="space-y-6 flex flex-col px-6 lg:px-0 py-6 lg:py-0">
        <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-6">
          <WelcomeHeading />
          <div className="flex gap-6 items-center">
            {!isDefault && (
              <Select onValueChange={handleCalendarChange} value={selectedCalendar}>
                <SelectTrigger className="focus:ring-transparent bg-[#e9eef6] w-[200px]">
                  <SelectValue placeholder="Workspace Calendar" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={null} className="hidden">
                      <div className="flex gap-2 items-center">Workspace Calendar</div>
                    </SelectItem>
                    {calendars?.map((item) => (
                      <SelectItem key={item._id} value={item._id}>
                        <div className="flex gap-2 w-full truncate items-center">
                          <span>
                            {item.calendarEmail}{' '}
                            {item.calendarEmail === currentWorkspaceCalendar?.calendarEmail && '(current)'}
                          </span>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            )}
            {isDefault && (
              <Select onValueChange={(e) => setSelectedWorkspace(e)} value={selectedWorkspace}>
                <SelectTrigger className="focus:ring-transparent w-[200px] bg-[#e9eef6]">
                  <SelectValue placeholder="Select Workspace" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={null}>
                      <div className="flex gap-2 items-center">Unified Workspace</div>
                    </SelectItem>
                    {teamSpaces?.map((item) => (
                      <SelectItem key={item._id} value={item._id}>
                        <div className="flex gap-2 items-center">
                          {item?.brandLogo ? (
                            <img
                              alt=""
                              className="w-[24px] h-[24px] rounded"
                              src={`${Config?.url}${item?.brandLogo?.location?.slice(1)}`}
                            />
                          ) : (
                            <div className="size-6 bg-[#f1eaba] rounded-lg justify-center items-center flex">
                              <span className="text-[#173300] font-bold text-xs font-inter uppercase truncate">
                                {item?.name?.slice(0, 2)}
                              </span>
                            </div>
                          )}
                          <span>
                            {item.name}{' '}
                            {item.default && item?.createdBy?.toString() === user?._id?.toString() && '(primary)'}
                          </span>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            )}
          </div>
        </div>
        <div className="flex flex-nowrap w-full gap-6">
          <div className="flex-1 lg:block hidden">
            <MeetingCalendarWrapper
              setCurrentView={setCurrentCalendarView}
              setFetchingTime={setFetchingTime}
              fetchingTime={fetchingTime}
              currentView={currentCalendarView}
              selectedWorkspace={selectedWorkspace}
            />
          </div>
          <div className="lg:w-[450px] max-h-[80vh] overflow-y-auto scroll-none">
            <UpcomingMeetings currentCalendarView={currentCalendarView} selectedWorkspace={selectedWorkspace} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default DashboardIndex;
