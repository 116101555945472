import { useTour } from "@reactour/tour";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../components/ui/dialog";

export default function WelcomeModal() {
    const [show, setShow] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const welcomeModal = searchParams.get('welcome');
    const { setIsOpen } = useTour();

    useEffect(() => {
        if (welcomeModal === 'true') {
            setShow(true);
            setIsOpen(false);
        }
    }, [welcomeModal]);

    const handleClose = () => {
        setShow(false);
        searchParams.delete('welcome');
        setSearchParams(searchParams);
    }
    const handleStartTour = () => {
        setIsOpen(true);
        setShow(false);
        searchParams.delete('welcome');
        setSearchParams(searchParams);
    }

    return (
        <Dialog open={show} onOpenChange={handleClose}>
            <DialogContent className="sm:max-w-[425px] p-0" closeButtonClassName="-top-3 -right-3 bg-[#173300] text-[#FFEC5D] opacity-100 p-1 rounded-full">
                <DialogHeader className='hidden'>
                    <DialogTitle />
                    <DialogDescription />
                </DialogHeader>
                <div className="bg-[#FCFAF5] p-6 rounded-lg space-y-4">
                    <div className="flex gap-6">
                        <div className="space-y-5">
                            <h4 className="text-[#1a2e05] text-base font-semibold font-inter leading-normal">
                                Welcome to SayBriefly! 👋
                            </h4>
                            <p className="max-w-[210px] text-sm text-[#475569] font-normal leading-tight">This is your unified calendar view. Don't worry if it looks empty – your meetings will pop up here as your calendars sync.</p>
                        </div>
                        <div>
                            <img src="/image/laptop.png" alt="welcome" className="w-[142px] h-auto" />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <Button onClick={handleClose} className="hover:text-[#FFEC5D] text-[#173300] border-[#173300] bg-transparent hover:bg-[#173300] px-14">
                            Skip
                        </Button>
                        <Button onClick={handleStartTour} className="px-8">
                            Start Tour 🚀
                        </Button>
                    </div>

                    <p className="text-center text-[#173300] text-xs font-normal leading-3">Step 1 of 5</p>
                </div>
            </DialogContent>
        </Dialog>
    )
}
