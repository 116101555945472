/* eslint-disable react/jsx-indent */
/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */

import { Player } from '@lottiefiles/react-lottie-player';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGlobal } from 'reactn';
import { EMOJIS } from '../../../constants/Emojis';

export default function EmojiButton() {
    const [emojiOpen, setEmojiOpen] = useState(false);
    const io = useSelector((state) => state.io.io);
    const [reactions, setReactions] = useGlobal('reactions');

    const user = useGlobal('user')[0];
    const meeting = useGlobal('meeting')[0];

    const handleReact = async (emojiType) => {
        if (io) {
            await io.request('sent_reactions', { socketID: io.id, roomID: meeting?.meeting?._id, userID: user?._id, reaction: { emojiType, timestamp: Date.now() } });
            setReactions(reactions?.length > 0 ? [...reactions, { emojiType, timestamp: Date.now(), user: { firstName: 'You' } }] : [{ emojiType, timestamp: Date.now(), user: { firstName: 'You' } }]);
        }
    };

    return (
        <div className="relative">
            {
                emojiOpen && (
                    <div className="absolute bottom-10 left-0 transform -translate-x-1 -translate-y-6 bg-[#ffffffbe] backdrop-blur rounded-lg py-2 px-4">
                        <div className="flex items-center gap-6 w-full border-b border-[#D2D2D2] text-3xl">
                            {
                                EMOJIS?.map((emoji) => (
                                    <button key={emoji?.type} onClick={() => handleReact(emoji?.type)}>
                                        <Player
                                            src={emoji?.src}
                                            loop
                                            autoplay
                                            style={{ height: '2rem', width: '2rem' }}
                                        />
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                )
            }
            <div
                className={`cursor-pointer w-12 h-12 p-3 rounded-xl justify-center items-center flex ${emojiOpen ? 'bg-white text-black hover:bg-opacity-90' : 'bg-white text-white bg-opacity-25 hover:bg-opacity-20'}`}
                onClick={() => setEmojiOpen(!emojiOpen)}
                style={{
                    borderRadius: emojiOpen ? '0.75rem' : '1.7rem', // Use numeric values for smooth interpolation
                    transition: 'border-radius 0.4s ease-in-out, box-shadow 0.4s ease-in-out', // Explicit transition for border-radius and box-shadow
                    boxShadow: emojiOpen ? '0px 4px 12px rgba(0, 0, 0, 0.2)' : 'none',
                }}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="face-content">
                        <path id="Icon" d="M8 14C8 14 9.5 16 12 16C14.5 16 16 14 16 14M17 9.24C16.605 9.725 16.065 10 15.5 10C14.935 10 14.41 9.725 14 9.24M10 9.24C9.605 9.725 9.065 10 8.5 10C7.935 10 7.41 9.725 7 9.24M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                </svg>
            </div>
        </div>

    );
}
