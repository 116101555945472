/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
export default function AudioDeviceList({ audioDevices, audioDeviceId, switchAudioDevice }) {
    return (
        <select
            onChange={(e) => {
                switchAudioDevice(e.target.value);
            }}
            className="w-100 h-12 p-3 rounded-xl bg-black text-white w-full"
        >
            {
                audioDevices?.length > 0 && audioDevices.map((device, i) => <option key={i} value={device.deviceId} selected={audioDeviceId === device.deviceId} className="w-full truncate">{device.label}</option>)
            }
        </select>
    );
}
