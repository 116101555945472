/* eslint-disable indent */
import { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/select';
import { cn } from '../../../lib/utils';

// function getTimezoneOffsetWithName(timezone) {
//     const now = moment().tz(timezone);
//     const gmtOffset = now.format('Z');
//     return `${timezone} GMT${gmtOffset}`;
// }

function MeetingSourceSelector({ setMeetingSource = () => {}, meetingSource, className }) {
  const data = [
    {
      icon: '/image/icons/google-meet.svg',
      name: 'Google Meet',
      value: 'google',
    },
    {
      icon: '/image/icons/zoom.svg',
      name: 'Zoom (Coming soon)',
      value: 'zoom',
      disabled: true,
    },
  ];
  const [currentSource, setcurrentSource] = useState(meetingSource);

  useEffect(() => {
    if (currentSource) {
      setMeetingSource(currentSource);
    }
  }, [currentSource]);

  return (
    <Select value={currentSource} onValueChange={(value) => setcurrentSource(value)}>
      <SelectTrigger className={cn('min-w-[150px] max-w-[300px] bg-transparent p-0 py-0 border-none h-auto focus:ring-0 focus:bg-transparent focus:shadow-none focus:border-none ring-offset-0 focus:ring-offset-0', className)}>
        <SelectValue placeholder="Select Meeting Source" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {data.map((item, index) => (
            <SelectItem key={index} value={item?.value} disabled={item?.disabled}>
              {item?.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export default MeetingSourceSelector;
