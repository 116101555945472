import { useState } from "react";
import { Calendar } from "../../../../components/ui/calendar";
import Config from "../../../../config";
import { cn, generateTimeArray, getGravatarUrl } from "../../../../lib/utils";
import MeetingSourceSelector from "../../components/meeting-source-selector";
import TimezoneSelector from "../../components/timezone-slector";
import { isPastDate } from "./scheduling";

const timeFormat = ['12h', '24h'];
function formatTime(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0
        ? `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`
        : `${minutes} min${minutes > 1 ? 's' : ''}`;
}

export default function CalendarPreview({ user, colorShades, slot, mode, colors, daysOfWeek, selectedTimezone, selectedMeetingSource, selectedTime, setSelectedTime, days, shortDescription, setSelectedMeetingSource, selectedTimeFormat, setSelectedTimeFormat
}) {
    const [date, setDate] = useState(new Date());
    return (
        <div
            className={`rounded-2xl p-6 ${mode === 'desktop' ? 'bg-calenderBackground' : 'bg-white'} `}
            // style={{ backgroundColor: mode === 'desktop' ? colorShades?.[0].hexcode : 'white' }}
        >
            <div className={cn('flex gap-6 mx-auto', mode === 'desktop' ? 'w-full md:flex-row md:justify-between flex-col' : 'lg:w-[420px] w-full flex-col h-[720px] bg-calenderSecondary py-6 rounded-xl overflow-y-auto overflow-x-hidden px-4')}>
                <div className="space-y-4 lg:pr-6">
                    <div className="flex items-center gap-2">
                        <img
                            className="size-7 rounded-full object-cover object-center"
                            src={
                                user?.picture?.location
                                    ? `${Config.url}/${user?.picture?.location}`
                                    : getGravatarUrl(
                                        `${user?.firstName} ${user?.lastName}`,
                                        user?.email,
                                        40,
                                    )
                            }
                            alt=""
                        />
                        <h4
                            className="text-calenderPrimary text-sm font-bold leading-tight m-0"
                            // style={{ color: colors?.primaryColor }}
                        >
                            {user?.firstName}
                            {' '}
                            {user?.lastName}
                        </h4>
                    </div>
                    <div
                        className="text-calenderPrimary text-sm font-normal font-inter max-w-[200px] leading-tight"
                        // style={{ color: colors?.primaryColor }}
                    >
                        {shortDescription}
                    </div>
                    <p
                        className=" text-calenderPrimary text-sm font-medium leading-tight m-0 flex gap-2 items-center"
                        // style={{ color: colors?.primaryColor }}
                    >
                        <svg className="w-4 h-4" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVG" clipPath="url(#clip0_10573_21273)">
                                <path id="Vector" d="M7.9987 14.6673C11.6806 14.6673 14.6654 11.6825 14.6654 8.00065C14.6654 4.31875 11.6806 1.33398 7.9987 1.33398C4.3168 1.33398 1.33203 4.31875 1.33203 8.00065C1.33203 11.6825 4.3168 14.6673 7.9987 14.6673Z" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                <path id="Vector_2" d="M8 4V8L10.6667 9.33333" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_10573_21273">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        {formatTime(slot)}
                    </p>
                    <p
                        className=" text-calenderPrimary text-sm font-medium leading-tight m-0 flex gap-2 items-center"
                        // style={{ color: colors?.primaryColor }}
                    >
                        <svg width="16" className="w-4 h-4" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="icon.svg" clipPath="url(#clip0_10573_21278)">
                                <g id="Clip path group">
                                    <mask
                                        id="mask0_10573_21278"
                                        style={{
                                            maskType: 'luminance',
                                        }}
                                        maskUnits="userSpaceOnUse"
                                        x="0"
                                        y="0"
                                        width="16"
                                        height="16"
                                    >
                                        <g id="a">
                                            <path id="Vector" d="M12.375 0H3.625C1.62297 0 0 1.62297 0 3.625V12.375C0 14.377 1.62297 16 3.625 16H12.375C14.377 16 16 14.377 16 12.375V3.625C16 1.62297 14.377 0 12.375 0Z" fill="white" />
                                        </g>
                                    </mask>
                                    <g mask="url(#mask0_10573_21278)">
                                        <g id="Group">
                                            <path id="Vector_2" d="M12.375 0H3.625C1.62297 0 0 1.62297 0 3.625V12.375C0 14.377 1.62297 16 3.625 16H12.375C14.377 16 16 14.377 16 12.375V3.625C16 1.62297 14.377 0 12.375 0Z" fill="url(#paint0_linear_10573_21278)" />
                                            <g id="Group_4" filter="url(#filter0_di_10573_21278)">
                                                <path id="Vector_55" d="M8.39075 4.8125H4.28045C3.46973 4.8125 2.8125 5.46973 2.8125 6.28045V9.70569C2.8125 10.5164 3.46973 11.1736 4.28045 11.1736H8.39075C9.20147 11.1736 9.8587 10.5164 9.8587 9.70569V6.28045C9.8587 5.46973 9.20147 4.8125 8.39075 4.8125Z" fill="white" />
                                            </g>
                                            <g id="Group_5" filter="url(#filter1_ddi_10573_21278)">
                                                <path id="Vector_56" d="M10.3242 8.66025V7.27572C10.3242 7.04538 10.4158 6.82445 10.5786 6.66157L11.9098 5.33037C12.0538 5.18637 12.2492 5.10547 12.4528 5.10547H12.4768C12.665 5.10547 12.8456 5.18028 12.9788 5.31343C13.112 5.44659 13.1868 5.62718 13.1868 5.8155V10.1204C13.1868 10.3088 13.112 10.4894 12.9788 10.6225C12.8456 10.7557 12.665 10.8305 12.4768 10.8305H12.4528C12.2492 10.8305 12.0538 10.7496 11.9098 10.6056L10.5786 9.27438C10.4158 9.11152 10.3242 8.89058 10.3242 8.66025Z" fill="url(#paint2_linear_10573_21278)" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <path id="Vector_57" d="M12.375 0.046875H3.625C1.64886 0.046875 0.046875 1.64886 0.046875 3.625V12.375C0.046875 14.3512 1.64886 15.9531 3.625 15.9531H12.375C14.3512 15.9531 15.9531 14.3512 15.9531 12.375V3.625C15.9531 1.64886 14.3512 0.046875 12.375 0.046875Z" stroke="#EDE9FE" strokeOpacity="0.25" strokeWidth="0.09375" />
                            </g>
                            <defs>
                                <filter id="filter0_di_10573_21278" x="2.25" y="4.375" width="8.17188" height="7.48633" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="0.125" />
                                    <feGaussianBlur stdDeviation="0.28125" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10573_21278" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10573_21278" result="shape" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="0.299657" />
                                    <feGaussianBlur stdDeviation="0.329623" />
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_10573_21278" />
                                </filter>
                                <filter id="filter1_ddi_10573_21278" x="9.76172" y="4.66797" width="3.98828" height="6.84961" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="0.125" />
                                    <feGaussianBlur stdDeviation="0.28125" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10573_21278" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="0.1875" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="effect1_dropShadow_10573_21278" result="effect2_dropShadow_10573_21278" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_10573_21278" result="shape" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="0.299657" />
                                    <feGaussianBlur stdDeviation="0.329623" />
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_10573_21278" />
                                </filter>
                                <linearGradient id="paint0_linear_10573_21278" x1="8" y1="0" x2="8" y2="19" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="currentColor" />
                                    <stop offset="1" stopColor="currentColor" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_10573_21278" x1="3.81255" y1="2.53125" x2="8.00006" y2="16.0625" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#D9D9D9" stopOpacity="0" />
                                    <stop offset="1" stopColor="#D9D9D9" />
                                </linearGradient>
                                <linearGradient id="paint2_linear_10573_21278" x1="11.7555" y1="5.10547" x2="11.7555" y2="10.8305" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="white" />
                                    <stop offset="1" stopColor="#E8EDF0" />
                                </linearGradient>
                                <clipPath id="clip0_10573_21278">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <MeetingSourceSelector meetingSource={selectedMeetingSource} setMeetingSource={setSelectedMeetingSource} />
                    </p>
                    <p
                        className=" text-calenderPrimary text-sm font-medium leading-tight m-0 flex gap-2 items-center"
                        // style={{ color: colors?.primaryColor }}
                    >
                        <svg className="w-4 h-4" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g
                                id="SVG"
                                clipPath="url(#clip0_10573_21353)"
                            >
                                <path
                                    id="Vector"
                                    d="M7.9987 14.6673C11.6806 14.6673 14.6654 11.6825 14.6654 8.00065C14.6654 4.31875 11.6806 1.33398 7.9987 1.33398C4.3168 1.33398 1.33203 4.31875 1.33203 8.00065C1.33203 11.6825 4.3168 14.6673 7.9987 14.6673Z"
                                    stroke="currentColor"
                                    strokeWidth="1.33333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path id="Vector_2" d="M7.9987 1.33398C6.28685 3.13142 5.33203 5.51848 5.33203 8.00065C5.33203 10.4828 6.28685 12.8699 7.9987 14.6673C9.71054 12.8699 10.6654 10.4828 10.6654 8.00065C10.6654 5.51848 9.71054 3.13142 7.9987 1.33398Z" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                <path id="Vector_3" d="M1.33203 8H14.6654" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_10573_21353">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <TimezoneSelector selectedTimezone={selectedTimezone} />
                    </p>
                </div>
                {
                    mode === 'phone' && (<hr className="border-calenderPrimary" />)
                }
                <div className="lg:min-w-max">
                    <Calendar
                        mode="single"
                        selected={date}
                        onSelect={(d) => {
                            if (!isPastDate(d)) {
                                setDate(d);
                            } else {
                                toast.error('You can not select a previous date.', {
                                    position: 'top-center',
                                });
                            }
                        }}
                        className={cn('flex justify-center lg:px-6 ', mode === 'desktop' ? 'lg:border-r lg:border-l border-calenderPrimary' : '')}
                        // className="flex justify-center lg:px-6 border-none "
                        classNames={{
                            head_cell: `${mode === 'desktop' ? 'lg:w-16' : 'w-12'}  text-calenderPrimary`,
                            cell: `${mode === 'desktop' ? 'lg:size-16' : 'size-12'} p-2 text-calenderPrimary`,
                            day: `${mode === 'desktop' ? 'lg:size-16' : 'size-10'} lg:text-base font-semibold hover:bg-calenderPrimary hover:text-calenderSecondary rounded-xl`,
                            day_selected: `${mode === 'desktop' ? 'lg:size-16' : 'size-10'}  bg-calenderPrimary text-calenderSecondary rounded-xl`,
                            caption: `flex justify-center pt-1 relative items-center`,
                            day_outside: 'text-calenderPrimary opacity-30',
                        }}
                    />
                </div>

                {
                    mode === 'phone' && (<hr className="border-calenderPrimary" />)
                }

                <div className="space-y-6 lg:px-6 flex-grow">
                    <div className="flex items-center justify-center gap-6">
                        {/*  */}
                        <p className="flex gap-[12px] items-center m-0">
                            <span className="text-calenderPrimary text-base font-semibold leading-normal">
                                {days[new Date(date).getDay()]}
                            </span>
                            <span className="text-calenderPrimary text-sm font-medium leading-tight">
                                {new Date(date).getDate()}
                            </span>
                        </p>
                        <div className="px-1 py-.5 rounded-md border border-neutral-400 justify-end items-center gap-[13.66px] inline-flex">
                            {
                                timeFormat.map((item, i) => (
                                    <div key={i} onClick={() => setSelectedTimeFormat(item)} className={`px-3 py-[4.25px] cursor-pointer ${selectedTimeFormat === item ? 'bg-calenderPrimary rounded text-center text-white text-sm font-medium leading-[17.50px]' : 'text-calenderPrimary'}`}
                                        // style={{
                                        //     backgroundColor: selectedTimeFormat === item ? colors?.primaryColor : 'transparent',
                                        //     color: selectedTimeFormat === item ? colors?.secondaryColor : colors?.primaryColor
                                        // }}
                                    >
                                        {item}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 max-h-[460px] overflow-auto">
                        {
                            (daysOfWeek[new Date(date).getDay()]?.startTime
                                && daysOfWeek[new Date(date).getDay()]?.endTime && daysOfWeek[new Date(date).getDay()]?.isActive)
                                ? generateTimeArray(
                                    daysOfWeek[new Date(date).getDay()]?.startTime,
                                    daysOfWeek[new Date(date).getDay()]?.endTime,
                                    selectedTimeFormat,
                                    slot,
                                    selectedTimezone,
                                )
                                    ?.map((item, i) => (
                                        <button onClick={() => { setSelectedTime(item); }} key={i} className={`px-3 py-2 
                                            ${selectedTime === item ? 'bg-calenderPrimary text-white' : 'bg-white text-calenderPrimary'} rounded-lg border text-sm font-medium leading-tight`}
                                            // style={{ backgroundColor: selectedTime === item ? colors?.primaryColor : 'white', color: selectedTime === item ? colors?.secondaryColor : colors?.primaryColor }}
                                        >
                                            {item}
                                        </button>
                                    )) : (
                                    <p className="text-center w-full col-span-2">
                                        No slots available.
                                    </p>
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
