import axios from 'axios';
import Config from '../config';

const rejectInvitation = (invitationCode) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/user/subscription/reject_subscription_invitation`,
    data: {
      invitationCode,
    },
  });
};

export default rejectInvitation;
