import { RadioGroup, RadioGroupItem } from '../../../../../@/components/ui/radio-group';
import { Button } from '../../../../../components/ui/button';
import { Checkbox } from '../../../../../components/ui/checkbox';
import { Input } from '../../../../../components/ui/input';

const FormFields = ({
    fields,
    setFields,
    className,
    colorShades,
}) => {
    const renderField = (field, index) => {
        switch (field.fieldType) {
            case 'textarea':
                return (
                    <textarea
                        className="w-full border focus:outline-none p-2 rounded-lg"
                        placeholder="Type your answer here..."
                        id={field._id}
                        name={field._id}
                        value={field.answer || ""}
                        style={{
                            borderColor: colorShades?.[8].hexcode,
                            color: colorShades?.[8].hexcode,
                        }}
                        onChange={(e) => {
                            const newFields = [...fields];
                            newFields[index].answer = e.target.value;
                            setFields(newFields);
                        }}
                    />
                );

            case 'radio':
                const radioOptions = field.options?.map(opt => opt.trim()) || [];
                return (
                    <RadioGroup
                        value={field.answer || ""}
                        onValueChange={(value) => {
                            const newFields = [...fields];
                            newFields[index].answer = value;
                            setFields(newFields);
                        }}
                        className="space-y-2"
                    >
                        {
                            radioOptions.map((option) => (
                                <div key={option} className="flex items-center space-x-2">
                                    <RadioGroupItem
                                        style={{
                                            borderColor: colorShades?.[8].hexcode,
                                        }}
                                        value={option} id={`${field._id}-${option}`} />
                                    <label
                                        htmlFor={`${field._id}-${option}`}
                                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                        style={{
                                            color: colorShades?.[8].hexcode,
                                        }}
                                    >
                                        {option}
                                    </label>
                                </div>
                            ))
                        }
                    </RadioGroup>
                );

            case 'checkbox':
                const checkboxOptions = field.options?.map(opt => opt.trim()) || [];
                const selectedValues = (field.answer || "").split(", ").filter(Boolean);

                return (
                    <div className="space-y-2">
                        {checkboxOptions.map((option) => (
                            <div key={option} className="flex items-center space-x-2">
                                <Checkbox
                                    id={`${field._id}-${option}`}
                                    style={{
                                        borderColor: colorShades?.[8].hexcode,
                                    }}
                                    checked={selectedValues.includes(option)}
                                    onCheckedChange={(checked) => {
                                        const newFields = [...fields];
                                        const currentValues = (newFields[index].answer || "")
                                            .split(', ')
                                            .filter(Boolean);

                                        const newValues = checked
                                            ? [...currentValues, option]
                                            : currentValues.filter(item => item !== option);

                                        newFields[index].answer = newValues.join(', ');
                                        setFields(newFields);
                                    }}
                                />
                                <label
                                    htmlFor={`${field._id}-${option}`}
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    style={{
                                        color: colorShades?.[8].hexcode,
                                    }}
                                >
                                    {option}
                                </label>
                            </div>
                        ))}
                    </div>
                );

            default:
                return (
                    <Input
                        type={field.fieldType === "phone" ? "tel" : field.fieldType}
                        id={field._id}
                        placeholder="Type your answer here..."
                        name={field._id}
                        value={field.answer || ""}
                        style={{
                            borderColor: colorShades?.[8].hexcode,
                            color: colorShades?.[8].hexcode,
                        }}
                        onChange={(e) => {
                            const newFields = [...fields];
                            newFields[index].answer = e.target.value;
                            setFields(newFields);
                        }}
                    />
                );
        }
    };

    const isValidField = (field) => {
        if (!field.required) return true;
        if (field.fieldType === 'checkbox') {
            return (field.answer || "").split(', ').filter(Boolean).length > 0;
        }
        return Boolean(field.answer?.trim());
    };

    return (
        <div className={className}>
            {fields?.map((field, index) => (
                <div key={field._id} className="space-y-2">
                    <label
                        htmlFor={field._id}
                        style={{
                            color: colorShades?.[8].hexcode,
                        }}
                        className="text-sm font-semibold leading-none"
                    >
                        {field.question || "What's your question ?"}
                        {field.required && (
                            <span className="text-red-500"> *</span>
                        )}
                    </label>

                    {renderField(field, index)}
                </div>
            ))}

            <div className="flex justify-start">
                <Button
                    className={
                        !fields?.every(isValidField)
                            ? "cursor-not-allowed"
                            : ""
                    }
                    disabled={!fields?.every(isValidField)}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default FormFields;