export default {
  url: process.env.REACT_APP_BACKEND_URL,
  meetUrl: process.env.REACT_APP_MEET_URL || 'http://localhost:3000/meeting/',
  mainUrl: process.env.REACT_APP_MAIN_URL || 'http://localhost:3000/',
  appUrl: process.env.REACT_APP_APP_URL || 'http://localhost:3000/',
  whisperUrl: process.env.REACT_APP_WHISPER_URL,
  demo: process.env.REACT_APP_DEMO === 'true',
  appName: process.env.REACT_APP_SITE_TITLE || 'Say Briefly',
  brand: process.env.REACT_APP_SITE_BRAND || 'Say Briefly',
  showCredits: process.env.REACT_APP_SHOW_CREDITS === 'true',
  paddleEnv: process.env.REACT_APP_PADDLE_ENVIRONMENT || 'sandbox',
  paddleClientToken: process.env.REACT_APP_PADDLE_CLIENT_TOKEN || 'test_8bb35b35fe5e76274549501c179',
  mode: process.env.NODE_ENV || 'production',
};
