/* eslint-disable no-mixed-operators */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-one-expression-per-line */
import { useGlobal } from 'reactn';
import { Button } from '../../../components/ui/button';
import { Progress } from '../../../components/ui/progress';
import { PLANS } from '../../../constants/Subscription';

function Package({ callback = () => { } }) {
  const [user] = useGlobal('user');
  // const workspace = user?.workspace;
  const subscription = user?.activeSubscription;
  const PLANDATA = subscription?.plan === 'free' ? PLANS[0] : PLANS?.find((plan) => (plan?.id === subscription?.plan) && (plan?.type === subscription?.type));
  // const getData = async () => {
  //   try {
  //     // const { data: res } = await axios.get(`${Config.url}/api/v1/user/subscription/user_subscription`);
  //     const { data: res } = await axios.get(`${Config.url}/api/v1/user/subscription/user_subscription`);
  //     console.log(res.data, 'Heyy');
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   // getWorkspaceMembersData();
  //   getData();
  // }, []);
  return (
    <div className="bg-white h-full rounded-xl shadow border border-gray-200 flex-col justify-start items-start inline-flex w-full">
      <div className="p-6 flex-col justify-start items-start gap-6 flex w-full">
        <div className="gap-8 flex justify-between w-full">
          <div className="flex-col justify-start items-start gap-1 inline-flex">
            <div className="items-center flex gap-3">
              <div className="text-gray-900 text-lg font-semibold leading-7 capitalize">
                {subscription?.plan?.replace(/_/g, ' ')} plan
              </div>
              <p className="bg-white rounded-full border text-zinc-800 text-sm font-medium leading-tight px-3 py-1 m-0 capitalize">
                {(!subscription?.paddleSubscriptionId && subscription?.trial_mode) ? 'Trial' : PLANDATA?.type}
              </p>
            </div>
            <div className="text-slate-600 text-sm font-normal leading-tight">
              {PLANDATA?.description}
            </div>
          </div>
          <div className="flex items-end gap-0.5">
            <div className="flex justify-start items-end gap-2.5">
              <h4 className="text-gray-900 text-4xl font-semibold leading-[44px]">{`$${PLANDATA?.priceNumber}`}</h4>
              <span className="text-slate-600 text-base font-medium leading-normal min-w-max">per {subscription?.plan === 'small_teams' ? 'seat' : 'month'}</span>
            </div>
          </div>
        </div>
        {
          subscription?.plan === 'small_teams' && (
            <div className="flex-col justify-start items-start gap-3 flex w-full">
              <div className="text-gray-900 text-sm font-medium leading-tight">
                {subscription?.subMembers?.length} of {`${subscription?.plan === 'pay_as_you_go' ? 'unlimited' : subscription?.totalSeatsAllocated || 1}`}{' users'}
              </div>
              <Progress value={((subscription?.subMembers?.length || 0) / subscription?.totalSeatsAllocated) * 100} className="h-2" />
            </div>
          )
        }
      </div>
      {
        subscription?.plan === 'small_teams' && (
          <>
            <hr className="w-full m-0" />
            <div className="p-4 justify-between items-center w-full flex">
              <Button
                onClick={callback}
                className="flex gap-1.5 items-center hover:scale-95 transition-all"
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="plus-circle">
                    <path id="Solid" fillRule="evenodd" clipRule="evenodd" d="M9.9987 0.833984C4.93609 0.833984 0.832031 4.93804 0.832031 10.0007C0.832031 15.0633 4.93609 19.1673 9.9987 19.1673C15.0613 19.1673 19.1654 15.0633 19.1654 10.0007C19.1654 4.93804 15.0613 0.833984 9.9987 0.833984ZM9.9987 5.83398C10.4589 5.83398 10.832 6.20708 10.832 6.66732V9.16732H13.332C13.7923 9.16732 14.1654 9.54041 14.1654 10.0007C14.1654 10.4609 13.7923 10.834 13.332 10.834H10.832V13.334C10.832 13.7942 10.4589 14.1673 9.9987 14.1673C9.53846 14.1673 9.16536 13.7942 9.16536 13.334V10.834H6.66536C6.20513 10.834 5.83203 10.4609 5.83203 10.0007C5.83203 9.54041 6.20513 9.16732 6.66536 9.16732H9.16536V6.66732C9.16536 6.20708 9.53846 5.83398 9.9987 5.83398Z" fill="#FCE657" />
                  </g>
                </svg>
                Add Seats
              </Button>
              {/* <Link to="/settings/plan" className="flex gap-2">
          <div className="text-zinc-800 text-sm font-semibold leading-tight">Upgrade plan</div>
          <img src="/image/icons/arrow-up-right.svg" alt="" className="h-6 w-6" />
        </Link> */}
            </div>
          </>
        )
      }

    </div>
  );
}

export default Package;
