import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Slider } from '../../../../../components/ui/slider';

function PlayerWithAspectRatio({ url }) {
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [seeking, setSeeking] = useState(false);

  // Toggle play/pause
  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  // Update progress bar
  const handleProgress = (state) => {
    if (!seeking) {
      setProgress(state.played * 100);
      setDuration(playerRef.current.getDuration());
      // console.log(state.loadedSeconds, playerRef.current.getDuration());
    }
  };

  // Handle seek start
  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  // Handle seek end
  const handleSeekMouseUp = (value) => {
    setSeeking(false);
    if (playerRef.current) {
      playerRef.current.seekTo(value[0] / 100);
    }
  };

  // Handle seek change
  const handleSeek = (value) => {
    setProgress(value[0]);
  };

  // Format duration time
  const formatTime = (seconds) => {
    if (Number.isNaN(seconds) || !seconds) return '0:00';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Toggle Fullscreen
  const handleFullscreen = () => {
    if (!isFullscreen) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) {
        containerRef.current.mozRequestFullScreen();
      } else if (containerRef.current.msRequestFullscreen) {
        containerRef.current.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  // Detect fullscreen mode changes
  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);
  // console.log(url);
  if (!url) return null;
  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      {/* Video Player */}
      <div
        id="player"
        className={`rounded-2xl overflow-hidden ${
          isFullscreen ? '' : 'shadow-lg'
        }`}
        style={{
          position: 'relative',
          paddingTop: '56.25%',
          backgroundColor: '#000',
        }}
      >
        <ReactPlayer
          ref={playerRef}
          url={url}
          playing={playing}
          volume={volume}
          onProgress={handleProgress}
          onDuration={(d) => setDuration(d)}
          width="100%"
          height="100%"
          className="player"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          controls={false}
        />
      </div>

      {/* Custom Controls */}
      <div
        className={`flex items-center justify-between gap-4 px-4 py-2 rounded-xl ${
          isFullscreen ? 'absolute bottom-0 left-0 w-full bg-black bg-opacity-60' : 'mt-4 bg-[#173300]'
        }`}
        style={{ zIndex: isFullscreen ? 9999 : 'auto' }}
      >
        {/* Play/Pause Button */}
        <button onClick={handlePlayPause}>
          {playing ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFED74" className="size-6 -ml-1">
              <path
                fillRule="evenodd"
                d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg className="size-4" width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.253418 1.81277C0.253418 0.815222 1.34724 0.203999 2.19681 0.726809L8.849 4.82047C9.6581 5.31838 9.6581 6.49447 8.849 6.99238L2.19681 11.086C1.34724 11.6088 0.253418 10.9976 0.253418 10.0001V1.81277Z"
                fill="#FFED74"
              />
            </svg>
          )}
        </button>

        {/* Progress Bar */}
        <div className="w-full flex items-center gap-2">
          <Slider
            value={[progress]}
            onValueChange={handleSeek}
            onValueCommit={handleSeekMouseUp}
            onPointerDown={handleSeekMouseDown}
            max={100}
            step={0.1}
            className="bg-transparent border-none"
          />
          <p className="text-[#ffed74] min-w-max" style={{ fontSize: '12px' }}>
            {formatTime((progress / 100) * duration)}
            {' / '}
            {formatTime(duration)}
          </p>
        </div>

        {/* Volume Control */}
        <div className="w-[100px] flex items-center gap-2">
          <Slider
            value={[volume * 100]}
            onValueChange={(value) => setVolume(value[0] / 100)}
            max={100}
            step={1}
          />
        </div>

        {/* Fullscreen Button */}
        <button onClick={handleFullscreen}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ffed74" className="size-6">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default PlayerWithAspectRatio;
