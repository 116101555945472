/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import axios from 'axios';
import { debounce } from 'lodash';
import moment from 'moment/moment';
import { useEffect, useMemo, useState } from 'react';
import { FiPlus, FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useGlobal } from 'reactn';
import getResourcesByUserEmails from '../../../actions/getResourcesByUserEmails';
import getUserSuggestion from '../../../actions/getUserSuggestion';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '../../../components/ui/dialog';
import { Switch } from '../../../components/ui/switch';
import Config from '../../../config';
import { cn, convertTimeStringToMinutes, convertTo24Hour, formatDateString } from '../../../lib/utils';
import AddGuestModal from '../pages/back-office/components/add-guest-modal';
import GuestUserForm from '../pages/back-office/components/guest-user-form';
import Scheduler from '../pages/back-office/components/scheduler';
import AddProjectSpace from './add-project-space';
import FormBuilderForMeeting from './form-builder';
import MeetingScheduleSuccessModalUser from './meeting-schedule-success-modal-user';

function CreateMeetingButton({ className, userData, resource, children }) {
  const user = useGlobal('user')[0];
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');

  const { addToast } = useToasts();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [meetingSource, setMeetingSource] = useState('google');
  const [meetingHostId, setMeetingHostId] = useState('');
  const [resources, setResources] = useState([]);

  const [slot, setSlot] = useState(15);

  const [selectedTime, setSelectedTime] = useState('');
  const [date, setDate] = useState(moment().toDate());

  const [loading, setLoading] = useState(true);
  const [scheduleId, setScheduleId] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [bookings, setBookings] = useState('');
  const [meetingId, setMeetingId] = useState('');

  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [requirePreMeetingForm, setRequirePreMeetingForm] = useState(true);

  const [isLoadingAddGuest, setIsLoadingAddGuest] = useState(false);
  const [showAddGuestModal, setShowAddGuestModal] = useState(false);
  const [isEditFormModalOpen, setIsEditFormModalOpen] = useState(false);

  const [name, setName] = useState('Guest User');
  const [email, setEmail] = useState('');

  const [guestName, setGuestName] = useState('');
  const [guestEmail, setGuestEmail] = useState('');
  // Guest
  const [guests, setGuests] = useState([]);

  const [selectedProject, setSelectedProject] = useState('');

  const [userSuggestion, setUserSuggestion] = useState([]);
  const [calendars, setCalendars] = useState([]);

  const navigate = useNavigate();

  const handleSchedule = () => {
    if (!selectedTime) {
      addToast('Please select a time.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    if (meetingSource === 'google' && meetingHostId?.length === 0) {
      addToast('Please select a host or connect a calendar', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('date', formatDateString(date));
    formData.append('time', convertTo24Hour(selectedTime));
    formData.append('workspaceID', user?.workspace?.brandID);
    formData.append('meetingSource', meetingSource);
    formData.append('meetingHostId', meetingHostId);
    formData.append('timeZone', selectedTimezone);
    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule`,
      data: formData,
    };

    setIsSubmitLoading(true);
    axios
      .request(config)
      .then((data) => {
        // console.log(data?.data?.message);
        const bookingId = data?.data?.data?._id;
        const meetingId = data?.data?.data?.meeting;
        setBookingId(bookingId);
        setMeetingId(meetingId);
        // toast.success(data?.data?.message, {
        //   position: 'top-center',
        // });
        setStep(1);
        if (userData) {
          setName(userData.name);
          setEmail(userData.email);
        }
      })
      .catch((err) => {
        addToast(err?.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const debouncedGetData = useMemo(
    () =>
      debounce(async (email) => {
        try {
          const { data } = await getUserSuggestion(email);
          setUserSuggestion(data.data);
        } catch (err) {
          console.error(err);
          setUserSuggestion([]);
        }
      }, 500),
    [],
  );

  useEffect(() => {
    // console.log(email);
    if (email) {
      debouncedGetData(email);
    } else {
      setUserSuggestion([]);
    }

    // Cleanup the debounce when the component unmounts
    return () => {
      debouncedGetData.cancel();
    };
  }, [email, debouncedGetData]);

  const fetchData = async () => {
    // setLoading(true);
    try {
      // Fetch all resources in parallel using Promise.all
      const [bookingResponse, calendarsResponse, formsResponse] = await Promise.all([
        axios.get(`${Config.url || ''}/api/v1/user/book_schedule/get_bookings_with_schedule`),
        axios.get(`${Config.url || ''}/api/v1/user/back_office/get_calendars`),
        axios.get(`${Config.url || ''}/api/v1/user/book_schedule/get_forms`),
      ]);

      // Handle bookings and schedule data
      const { schedule, bookings } = bookingResponse?.data?.data || {};
      const slot = Number(convertTimeStringToMinutes(schedule?.timeSlot));

      setSlot(slot);
      setSelectedTimezone(schedule?.timeZone);
      setScheduleId(schedule?._id);
      setDaysOfWeek(schedule?.availableWeekdays);
      setBookings(bookings);

      // Handle calendars data
      setCalendars(calendarsResponse?.data?.data);

      // Handle forms data
      const forms = formsResponse?.data?.data;
      setForms(forms);
      setSelectedForm(forms[0]);
    } catch (error) {
      console.log(error);
      // You might want to add proper error handling here
      // setError(error);
    } finally {
      // console.log('hello');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      // console.log(isOpen, 'Hello');
      fetchData();
      return;
    }
    setStep(0);
    setEmail('');
    setName('Guest User');
    setGuests([]);
  }, [isOpen]);

  const getResources = async () => {
    try {
      const guestEmails = guests.map((guest) => guest.email);
      const emailList = [...guestEmails, email];
      const { data } = await getResourcesByUserEmails(emailList);
      // Sort the data to move the default item to the first index
      const sortedData = data.data.sort((a) => (a.default === true ? -1 : 1));
      setResources(sortedData);
      setSelectedProject(sortedData[0]?._id);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getResources();
  }, [guests, email]);

  const handleTriggerEditFormModal = (formData) => {
    setSelectedForm(formData);
    setIsOpen((prev) => !prev);
    setIsEditFormModalOpen((prev) => !prev);
    setStep(3);
  };

  const handleTriggerAddGuestModal = () => {
    setIsOpen((prev) => !prev);
    setShowAddGuestModal((prev) => !prev);
  };

  const handleSaveAndInvite = () => {
    if (!selectedForm) {
      addToast('Please select a Form', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append('formId', selectedForm?._id);
    formData.append('bookingId', bookingId);
    formData.append('preMeetingFormRequired', requirePreMeetingForm);
    formData.append('meetingSource', meetingSource);
    formData.append('meetingHostId', meetingHostId);
    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule/save_n_invite`,
      data: formData,
    };

    setIsSubmitLoading(true);
    axios
      .request(config)
      .then((data) => {
        if (data?.data?.success) {
          setIsOpen(false);

          addToast(data?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
          });
          // Reset Everything
          setStep(0);
          // setSelectedTime('');
          setDaysOfWeek([]);
          setForms([]);
          setSelectedForm(null);
          // setDate(new Date());
          setShowSuccessModal(true);
          setRefreshMeetings(!refreshMeetings);
        }
      })
      .catch((err) => {
        addToast(err?.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });
        // console.log(err.response.data.error);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const handleBookSchedule = async (guestArray = null) => {
    // eslint-disable-next-line eqeqeq
    const isGuestAdded = await handleAddGuest();
    if (!isGuestAdded) {
      return;
    }
    setIsSubmitLoading(true);
    const formData = {
      scheduleId,
      bookingId,
      guests: guestArray || guests,
    };

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule/save_all_guests`,
      data: formData,
    };

    axios
      .request(config)
      .then((data) => {
        setStep((prev) => prev + 1);
        setIsSubmitLoading(false);
        if (resource) {
          handleAddResources(meetingId, resource);
        }
        setRefreshMeetings(!refreshMeetings);
      })
      .catch((err) => {
        addToast(err?.response?.data?.error, {
          appearance: 'error',
          autoDismiss: true,
        });

        console.log(err?.response?.data?.error);
        // if (err?.response?.data?.error === 'Guest already verified. Added to booking.') {
        //   setStep((prev) => prev + 1);
        // }
        setIsSubmitLoading(false);
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  const handleAddGuest = () => {
    if (!name) {
      addToast('Please enter your name', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return false;
    }
    if (!email) {
      addToast('Please enter your email', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return false;
    }
    // eslint-disable-next-line eqeqeq
    if (user?.email == email) {
      addToast('You cannot add your own mail', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return false;
    }
    let flag = false;

    // Check if the email is already exist on guest list.
    guests.forEach((guest) => {
      if (guest.email === email) {
        addToast('Email already exist on guest list', {
          appearance: 'warning',
          autoDismiss: true,
        });
        flag = true;
      }
    });

    if (flag) return false;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('scheduleId', scheduleId);
    formData.append('bookingId', bookingId);

    const config = {
      method: 'post',
      url: `${Config.url || ''}/api/v1/user/book_schedule/send_guest_email`,
      data: formData,
    };
    setIsLoadingAddGuest(true);
    return axios
      .request(config)
      .then((data) => {
        return true;
      })
      .catch((err) => {
        addToast(err?.response?.data?.error, {
          appearance: 'info',
          autoDismiss: true,
        });
        if (err?.response?.data?.error === 'Guest already verified. Added to booking.') {
          setStep((prev) => prev + 1);
          if (resource) {
            handleAddResources(meetingId, resource);
          }
        }
        return false;
      })
      .finally(() => {
        setIsLoadingAddGuest(false);
      });
  };

  const handleAddResources = async (mID, rId) => {
    if (!mID || !rId) return;

    console.log('Meeting ID ', mID);
    console.log('Resource ID ', rId);
    setIsSubmitLoading(true);
    try {
      const res = await axios.post(`${Config.url || ''}/api/v1/user/add_meeting_to_resource`, {
        resourceId: rId,
        meetingId: mID,
      });
      // addToast(res.data.message, { appearance: 'success' });
      setStep(3);
      setRefreshMeetings(!refreshMeetings);
    } catch (err) {
      if (err?.response?.data?.message) {
        addToast(err?.response?.data?.message, { appearance: 'error' });
      } else {
        addToast(err?.response?.data?.error || 'Something went wrong', { appearance: 'error' });
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger>
          {children || (
            <Button className={cn('flex items-center gap-2 hover:bg-[#193601] bg-[#173300] text-[#FFED74]', className)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="plus-circle">
                  <path
                    id="Solid"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0007 0.833252C4.93804 0.833252 0.833984 4.93731 0.833984 9.99992C0.833984 15.0625 4.93804 19.1666 10.0007 19.1666C15.0633 19.1666 19.1673 15.0625 19.1673 9.99992C19.1673 4.93731 15.0633 0.833252 10.0007 0.833252ZM10.0007 5.83325C10.4609 5.83325 10.834 6.20635 10.834 6.66658V9.16658H13.334C13.7942 9.16658 14.1673 9.53968 14.1673 9.99992C14.1673 10.4602 13.7942 10.8333 13.334 10.8333H10.834V13.3333C10.834 13.7935 10.4609 14.1666 10.0007 14.1666C9.54041 14.1666 9.16732 13.7935 9.16732 13.3333V10.8333H6.66732C6.20708 10.8333 5.83398 10.4602 5.83398 9.99992C5.83398 9.53968 6.20708 9.16658 6.66732 9.16658H9.16732V6.66658C9.16732 6.20635 9.54041 5.83325 10.0007 5.83325Z"
                    fill="currentColor"
                  />
                </g>
              </svg>
              <span className="text-base font-semibold leading-normal">Create meeting</span>
            </Button>
          )}
        </DialogTrigger>
        <DialogContent className="lg:min-w-max overflow-auto lg:h-auto h-full" closeButtonClassName="top-2 right-2">
          {/* To Remove Warning */}
          <DialogTitle className="hidden" />
          <DialogDescription className="hidden" />
          {loading ? (
            <div className="p-12 flex items-center justify-center">
              <span className="size-4 border-4 p-4 rounded-full border-[#173300] animate-spin border-t-[#FFED74]" />
            </div>
          ) : (
            <div className="space-y-6">
              {step === 0 &&
                (scheduleId && calendars.length > 0 ? (
                  <Scheduler
                    date={date}
                    setDate={setDate}
                    daysOfWeek={daysOfWeek}
                    selectedTimezone={selectedTimezone}
                    setSelectedTimezone={setSelectedTimezone}
                    selectedTime={selectedTime}
                    setMeetingSource={setMeetingSource}
                    meetingSource={meetingSource}
                    setSelectedTime={setSelectedTime}
                    bookings={bookings}
                    slot={slot}
                    meetingHostId={meetingHostId}
                    setMeetingHostId={setMeetingHostId}
                  />
                ) : calendars.length === 0 ? (
                  <div>
                    <div>
                      <h2 className="font-bold text-2xl">Oops! No Calendar Found or Synced</h2>
                    </div>
                    <div className="py-4">
                      <p className="text-gray-500">
                        It looks like you don&apos;t have a calendar connected yet. Please connect your calendar to get
                        started!
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <Button
                        className={cn('flex items-center gap-2', className)}
                        onClick={() => {
                          setIsOpen(false);
                          navigate('/calendar/scheduling');
                        }}
                      >
                        {' '}
                        <span>
                          <FiSettings />
                        </span>{' '}
                        <span>Go to setup</span>
                      </Button>

                      <Button
                        className={cn(
                          'flex items-center gap-2 bg-white text-black hover:bg-white hover:text-black border border-black',
                          className,
                        )}
                        onClick={() => setIsOpen(false)}
                      >
                        {' '}
                        <span>Cancel</span>
                      </Button>
                    </div>
                  </div>
                ) : !scheduleId ? (
                  <div>
                    <div>
                      <h2 className="font-bold text-2xl">No Available Slots</h2>
                    </div>
                    <div className="py-4">
                      <p className="text-gray-500">
                        You currently have no available slots set up in your schedule. Please configure your
                        availability in the back office to start booking meetings.
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <Button
                        className={cn('flex items-center gap-2 bg-black text-white', className)}
                        onClick={() => navigate('/calendar/scheduling')}
                      >
                        {' '}
                        <span>
                          <FiSettings />
                        </span>{' '}
                        <span>Go to setup</span>
                      </Button>

                      <Button
                        className={cn(
                          'flex items-center gap-2 bg-white text-black hover:bg-white hover:text-black border border-black',
                          className,
                        )}
                        onClick={() => setIsOpen(false)}
                      >
                        {' '}
                        <span>Cancel</span>
                      </Button>
                    </div>
                  </div>
                ) : (
                  ''
                ))}

              {step === 1 && (
                <div>
                  <GuestUserForm
                    setStep={setStep}
                    step={step}
                    selectedTimezone={selectedTimezone}
                    selectedTime={selectedTime}
                    scheduleId={scheduleId}
                    setSelectedTimezone={setSelectedTimezone}
                    setMeetingSource={setMeetingSource}
                    meetingSource={meetingSource}
                    date={date}
                    bookingId={bookingId}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    isLoadingAddGuest={isLoadingAddGuest}
                    setIsLoadingAddGuest={setIsLoadingAddGuest}
                    handleAddGuest={handleAddGuest}
                    handleBookSchedule={handleBookSchedule}
                    name={name}
                    setName={setName}
                    email={email}
                    setEmail={setEmail}
                    guests={guests}
                    setGuests={setGuests}
                    isSubmitLoading={isSubmitLoading}
                    handleTriggerAddGuestModal={handleTriggerAddGuestModal}
                    userSuggestion={userSuggestion}
                    slot={slot}
                    meetingHostId={meetingHostId}
                    setMeetingHostId={setMeetingHostId}
                  />
                </div>
              )}
              {step === 2 && (
                <div className="max-w-[700px] space-y-6">
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 items-end overflow-y-auto scroll-none">
                    {resources?.map((resource) => (
                      <div key={resource?._id} onClick={() => setSelectedProject(resource?._id)}>
                        <div className={selectedProject === resource?._id ? 'text-[#182230]' : 'text-[#d0d5dd]'}>
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.594727 10.1675C0.594727 4.92078 4.84802 0.66748 10.0947 0.66748C15.3414 0.66748 19.5947 4.92078 19.5947 10.1675C19.5947 15.4142 15.3414 19.6675 10.0947 19.6675C4.84802 19.6675 0.594727 15.4142 0.594727 10.1675Z"
                              fill="#F9FAFB"
                            />
                            <path
                              d="M0.594727 10.1675C0.594727 4.92078 4.84802 0.66748 10.0947 0.66748C15.3414 0.66748 19.5947 4.92078 19.5947 10.1675C19.5947 15.4142 15.3414 19.6675 10.0947 19.6675C4.84802 19.6675 0.594727 15.4142 0.594727 10.1675Z"
                              stroke="#D0D5DD"
                            />
                            <path
                              d="M6.09473 10.1675C6.09473 7.95834 7.88559 6.16748 10.0947 6.16748C12.3039 6.16748 14.0947 7.95834 14.0947 10.1675C14.0947 12.3766 12.3039 14.1675 10.0947 14.1675C7.88559 14.1675 6.09473 12.3766 6.09473 10.1675Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <button className="px-[18px] w-full py-3.5 bg-[#e9eef6] rounded-lg justify-start items-center gap-[25px] inline-flex overflow-hidden mt-1">
                          <svg
                            width="27"
                            height="21"
                            viewBox="0 0 27 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect x="1.82471" y="1.81641" width="11.1593" height="4.11955" fill="#a8e5e5" />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.4115e-07 4.21162C-1.91278e-05 3.68385 -3.64399e-05 3.22851 0.0360985 2.8535C0.0742384 2.45767 0.158446 2.06398 0.386091 1.68514C0.725703 1.11998 1.26761 0.66048 1.93413 0.372513C2.38091 0.179486 2.84521 0.108084 3.31202 0.0757438C3.7543 0.0451031 4.29127 0.0451186 4.91371 0.0451365L9.6193 0.0450283C10.3202 0.0442436 10.9365 0.0435534 11.5032 0.2093C11.9996 0.354471 12.4517 0.591392 12.8268 0.902917C13.2551 1.25859 13.53 1.72634 13.8426 2.25823L14.8988 4.04975H19.1772C20.1277 4.04974 20.9121 4.04973 21.5511 4.094C22.2148 4.13998 22.825 4.23866 23.398 4.48625C24.2867 4.87021 25.0093 5.48287 25.4621 6.23642C25.7541 6.72235 25.8705 7.23967 25.9247 7.80245C25.9769 8.34429 25.9769 9.00945 25.9769 9.81538V14.3034C25.9769 15.1094 25.9769 15.7745 25.9247 16.3164C25.8705 16.8791 25.7541 17.3965 25.4621 17.8824C25.0093 18.6359 24.2867 19.2486 23.398 19.6326C22.825 19.8801 22.2148 19.9788 21.5511 20.0248C20.9121 20.0691 20.1277 20.0691 19.1772 20.0691H6.79971C5.84923 20.0691 5.06475 20.0691 4.42572 20.0248C3.76201 19.9788 3.15191 19.8801 2.57884 19.6326C1.69014 19.2486 0.967602 18.6359 0.514785 17.8824C0.222793 17.3965 0.106411 16.8791 0.0521844 16.3164C-2.55623e-05 15.7745 -1.35808e-05 15.1094 9.4115e-07 14.3034V4.21162ZM9.47246 2.04755C10.4057 2.04755 10.5907 2.06061 10.7332 2.10227C10.8986 2.15066 11.0493 2.22964 11.1744 2.33348C11.282 2.42287 11.3785 2.55733 11.7959 3.26515L12.2586 4.04975L2.36162 4.04975C2.36214 3.5842 2.36576 3.26596 2.38979 3.01656C2.416 2.74456 2.46048 2.64372 2.49023 2.59422C2.60344 2.40583 2.78407 2.25267 3.00625 2.15668C3.06463 2.13145 3.18355 2.09373 3.50432 2.07151C3.83888 2.04833 4.27845 2.04755 4.95922 2.04755H9.47246Z"
                              fill="#a8e5e5"
                            />
                            <path
                              d="M0 9.01367H25.9769V16.4184C25.9769 18.5784 24.2259 20.3294 22.0659 20.3294H3.91096C1.751 20.3294 0 18.5784 0 16.4184V9.01367Z"
                              fill="#173300"
                            />
                          </svg>

                          <p className="text-[#101828] text-sm font-medium leading-tight w-full text-left truncate">
                            {resource?.name}
                          </p>
                        </button>
                      </div>
                    ))}
                    <AddProjectSpace
                      setProjectSpaceId={async (e) => {
                        await setSelectedProject(e);
                        handleAddResources(meetingId, e);
                        getResources();
                      }}
                      userEmails={guests.map((guest) => guest.email)}
                      userEmail={email}
                      className="py-[11px]"
                    />
                  </div>
                </div>
              )}

              {step === 3 && (
                <div>
                  {forms?.length === 0 && (
                    <div>
                      <div>
                        <h2 className="font-bold text-2xl">No Available Forms</h2>
                      </div>
                      <div className="py-4">
                        <p className="text-gray-500">
                          You currently have no available forms. Please add atleast one default forms in the back
                          office.
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <Button
                          className={cn('flex items-center gap-2 bg-black text-white', className)}
                          onClick={() => navigate('/back-office/form-builder')}
                        >
                          {' '}
                          <span>
                            <FiPlus />
                          </span>{' '}
                          <span>Go to form setup</span>
                        </Button>

                        <Button
                          className={cn(
                            'flex items-center gap-2 bg-white text-black hover:bg-white hover:text-black border border-black',
                            className,
                          )}
                          onClick={() => setIsOpen(false)}
                        >
                          {' '}
                          <span>Cancel</span>
                        </Button>
                      </div>
                    </div>
                  )}
                  {forms && (
                    <div>
                      <div className="flex items-center gap-2 mb-[20px] border-t pt-2.5 mt-2">
                        <Switch
                          id="pre-meeting-form"
                          checked={requirePreMeetingForm}
                          onCheckedChange={setRequirePreMeetingForm}
                        />
                        {/* <div className={requirePreMeetingForm ? 'text-[#182230]' : 'text-[#d0d5dd]'}>
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.594727 10.1675C0.594727 4.92078 4.84802 0.66748 10.0947 0.66748C15.3414 0.66748 19.5947 4.92078 19.5947 10.1675C19.5947 15.4142 15.3414 19.6675 10.0947 19.6675C4.84802 19.6675 0.594727 15.4142 0.594727 10.1675Z"
                                fill="#F9FAFB"
                              />
                              <path
                                d="M0.594727 10.1675C0.594727 4.92078 4.84802 0.66748 10.0947 0.66748C15.3414 0.66748 19.5947 4.92078 19.5947 10.1675C19.5947 15.4142 15.3414 19.6675 10.0947 19.6675C4.84802 19.6675 0.594727 15.4142 0.594727 10.1675Z"
                                stroke="#D0D5DD"
                              />
                              <path
                                d="M6.09473 10.1675C6.09473 7.95834 7.88559 6.16748 10.0947 6.16748C12.3039 6.16748 14.0947 7.95834 14.0947 10.1675C14.0947 12.3766 12.3039 14.1675 10.0947 14.1675C7.88559 14.1675 6.09473 12.3766 6.09473 10.1675Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div> */}
                        <label
                          htmlFor="pre-meeting-form"
                          className="text-slate-800 text-sm font-medium leading-[14px] cursor-pointer"
                        >
                          Require Pre-Meeting Form
                        </label>
                      </div>
                      <div>
                        {forms.map((form, i) => (
                          <div key={i} className="flex items-center gap-5">
                            {/* <Checkbox
                                checked={form?._id === selectedForm?._id}
                                onCheckedChange={(e) => {
                                  if (form?._id === selectedForm?._id) {
                                    setSelectedForm(null);
                                  } else {
                                    setSelectedForm(form);
                                  }
                                }}
                                id="form"
                                className="border-gray-500 rounded"
                              /> */}
                            <div className="lg:w-[500px] w-full bg-[#eeeeee] rounded-lg border border-[#eaecf0] px-6 py-4 justify-between lg:items-center gap-2.5 flex lg:flex-row flex-col">
                              <label htmlFor="form" className="justify-start items-center gap-3 flex cursor-pointer">
                                <svg
                                  width="40"
                                  height="40"
                                  viewBox="0 0 40 40"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="file-search-02">
                                    <path
                                      id="Icon"
                                      d="M23.3332 18.3335H13.3332M16.6665 25.0002H13.3332M26.6665 11.6668H13.3332M33.3332 17.5002V11.3335C33.3332 8.53323 33.3332 7.1331 32.7882 6.06354C32.3088 5.12273 31.5439 4.35783 30.6031 3.87846C29.5336 3.3335 28.1334 3.3335 25.3332 3.3335H14.6665C11.8662 3.3335 10.4661 3.3335 9.39655 3.87846C8.45574 4.35783 7.69084 5.12273 7.21147 6.06354C6.6665 7.1331 6.6665 8.53323 6.6665 11.3335V28.6668C6.6665 31.4671 6.6665 32.8672 7.21147 33.9368C7.69084 34.8776 8.45574 35.6425 9.39655 36.1219C10.4661 36.6668 11.8662 36.6668 14.6665 36.6668H19.1665M36.6665 36.6668L34.1665 34.1668M35.8332 30.0002C35.8332 33.2218 33.2215 35.8335 29.9998 35.8335C26.7782 35.8335 24.1665 33.2218 24.1665 30.0002C24.1665 26.7785 26.7782 24.1668 29.9998 24.1668C33.2215 24.1668 35.8332 26.7785 35.8332 30.0002Z"
                                      stroke="black"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                </svg>

                                <p className="text-[#101828] text-sm font-medium leading-tight">
                                  {/* Form ({form._id}) */}
                                  Pre-Meeting Questionnaire
                                </p>
                              </label>
                              <button
                                onClick={() => handleTriggerEditFormModal(form)}
                                className="px-3 py-2 bg-white rounded-lg shadow border border-[#d0d5dd] justify-center items-center gap-1 flex text-[#344054]"
                              >
                                <span className="text-sm font-semibold leading-tight">Edit Form</span>
                                <svg
                                  className="lg:size-5 size-4"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="edit-05" clipPath="url(#clip0_11380_17018)">
                                    <path
                                      id="Fill"
                                      opacity="0.12"
                                      d="M6.6665 11.9378C6.6665 11.5301 6.6665 11.3263 6.71255 11.1345C6.75338 10.9644 6.82072 10.8018 6.9121 10.6527C7.01517 10.4845 7.1593 10.3404 7.44755 10.0521L15.4165 2.0832C16.1069 1.39284 17.2262 1.39284 17.9165 2.0832C18.6069 2.77355 18.6069 3.89284 17.9165 4.5832L9.94758 12.5521C9.65932 12.8404 9.5152 12.9845 9.347 13.0876C9.19788 13.179 9.03531 13.2463 8.86525 13.2871C8.67343 13.3332 8.46961 13.3332 8.06196 13.3332H6.6665V11.9378Z"
                                      fill="black"
                                    />
                                    <path
                                      id="Icon"
                                      d="M9.1665 3.3332H5.6665C4.26637 3.3332 3.56631 3.3332 3.03153 3.60568C2.56112 3.84536 2.17867 4.22782 1.93899 4.69822C1.6665 5.233 1.6665 5.93307 1.6665 7.3332V14.3332C1.6665 15.7333 1.6665 16.4334 1.93899 16.9682C2.17867 17.4386 2.56112 17.821 3.03153 18.0607C3.56631 18.3332 4.26637 18.3332 5.6665 18.3332H12.6665C14.0666 18.3332 14.7667 18.3332 15.3015 18.0607C15.7719 17.821 16.1543 17.4386 16.394 16.9682C16.6665 16.4334 16.6665 15.7333 16.6665 14.3332V10.8332M6.66648 13.3332H8.06193C8.46959 13.3332 8.67341 13.3332 8.86522 13.2871C9.03528 13.2463 9.19786 13.179 9.34698 13.0876C9.51517 12.9845 9.6593 12.8404 9.94755 12.5521L17.9165 4.5832C18.6069 3.89284 18.6069 2.77355 17.9165 2.0832C17.2261 1.39284 16.1069 1.39284 15.4165 2.0832L7.44753 10.0521C7.15928 10.3404 7.01515 10.4845 6.91208 10.6527C6.8207 10.8018 6.75336 10.9644 6.71253 11.1345C6.66648 11.3263 6.66648 11.5301 6.66648 11.9378V13.3332Z"
                                      stroke="currentColor"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_11380_17018">
                                      <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {step !== 1 && (
                <div className="flex items-center gap-4 justify-between">
                  <Button
                    disabled={step === 0}
                    onClick={() => {
                      if (step >= 1) {
                        setStep((prev) => prev - 1);
                      }
                    }}
                    className="flex gap-1.5 items-center bg-white text-[#173310] border-[#173310] hover:text-[#ffed74]"
                  >
                    <svg
                      className="size-4"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="arrow-circle-left" clipPath="url(#clip0_11648_9531)">
                        <path
                          id="Icon"
                          d="M9.99984 6.66675L6.6665 10.0001M6.6665 10.0001L9.99984 13.3334M6.6665 10.0001H13.3332M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z"
                          stroke="currentColor"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_11648_9531">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    Back
                  </Button>

                  {step === 0 && scheduleId && (
                    <Button disabled={!selectedTime} onClick={handleSchedule}>
                      {isSubmitLoading ? (
                        <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                      ) : (
                        'Continue'
                      )}
                    </Button>
                  )}

                  {step === 2 && (
                    <Button
                      onClick={() => handleAddResources(meetingId, selectedProject)}
                      className="px-[22px] py-4 font-inter leading-7"
                    >
                      {isSubmitLoading ? (
                        <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                      ) : (
                        'Continue'
                      )}
                    </Button>
                  )}
                  {step === 3 && (
                    <Button onClick={handleSaveAndInvite} className="px-[22px] py-4 font-semibold font-inter leading-7">
                      {isSubmitLoading ? (
                        <span className="border-2 border-white border-r-transparent rounded-full h-4 w-4 animate-spin" />
                      ) : (
                        'Create Meeting'
                      )}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={isEditFormModalOpen} onOpenChange={handleTriggerEditFormModal}>
        <DialogContent className="min-w-max overflow-y-auto max-h-[90vh]" closeButtonClassName="text-xl">
          <DialogTitle>
            <p className="text-black text-base font-medium font-inter leading-normal">
              Form customization for meeting id #{meetingId}
            </p>
          </DialogTitle>
          <DialogDescription className="hidden" />
          <FormBuilderForMeeting
            formData={selectedForm}
            date={date}
            time={selectedTime}
            bookingId={bookingId}
            callBack={handleTriggerEditFormModal}
          />
        </DialogContent>
      </Dialog>
      <AddGuestModal
        showAddGuestModal={showAddGuestModal}
        handleAddGuest={handleAddGuest}
        isLoadingAddGuest={isLoadingAddGuest}
        name={guestName}
        setName={setGuestName}
        email={guestEmail}
        setEmail={setGuestEmail}
        handleTriggerAddGuestModal={handleTriggerAddGuestModal}
        setGuests={setGuests}
      />
      <MeetingScheduleSuccessModalUser
        open={showSuccessModal}
        setOpen={setShowSuccessModal}
        date={date?.toDateString()}
        time={selectedTime}
        timeZone={selectedTimezone}
        bookingId={bookingId}
        scheduleId={scheduleId}
        userName={user.username}
        slot={slot}
      />
    </>
  );
}

export default CreateMeetingButton;
