/* eslint-disable indent */
import { registerLicense } from '@syncfusion/ej2-base';
import {
  Day,
  Inject,
  Month,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
} from '@syncfusion/ej2-react-schedule';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';
import getMeetingsByQuery from '../../../actions/getMeetingsByQuery';
import Config from '../../../config';
import EventContainer from './event-container';
import ViewMeetingDetailsModalNotification from './view-meeting-details-modal-notification';

// API Functions
const fetchTimezone = async () => {
  const response = await axios.get(`${Config.url || ''}/api/v1/user/book_schedule/get_bookings_with_schedule`);
  return response.data?.data?.schedule?.timeZone;
};

const fetchMeetings = async (selectedWorkspace, dateRange) => {
  const [from, to] = dateRange;
  const response = await getMeetingsByQuery(
    '',
    1000,
    `${selectedWorkspace ? `workspaceId=${selectedWorkspace}&showCalendar=true` : 'showCalendar=true'}
    ${from ? `&from=${from}` : ''}
    ${to ? `&to=${to}` : ''}`,
  );

  return response.data?.data;
};

// Utility Function
function generateStartEnd(bookingDate, bookingTime, bookingTimeSlot, timezone) {
  try {
    // Combine bookingDate and bookingTime into a full datetime string
    // const dateTimeString = `${bookingDate} ${bookingTime}`;
    const start = moment.tz(bookingDate, timezone);

    // Validate the start datetime
    if (!start.isValid()) {
      throw new Error(`Invalid start datetime: ${dateTimeString} in timezone: ${timezone}`);
    }

    // Convert bookingTimeSlot to minutes (assuming it's a number in minutes for now)
    const minutes = parseInt(bookingTimeSlot, 10);
    if (isNaN(minutes) || minutes <= 0) {
      throw new Error(`Invalid time slot value: ${bookingTimeSlot}`);
    }

    // Calculate the end time by adding the minutes
    const end = start.clone().add(minutes, 'minutes');

    // Return ISO 8601 formatted strings
    return {
      start: start.toISOString(),
      end: end.toISOString(),
    };
  } catch (error) {
    console.error('Error:', error.message);
    return { start: null, end: null };
  }
}

registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFiW35ZcHxWQmRZV0B/Vw==');

export default function MeetingCalendarWrapper({ selectedWorkspace, currentView, setCurrentView }) {
  const scheduleRef = useRef(null);
  const [meetingData, setMeetingData] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [timezone, setTimezone] = useState('');
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');
  const [showEventContainer, setShowEventContainer] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  // Fetch timezone
  const { data: timezoneData } = useQuery({
    queryKey: ['timezone'],
    queryFn: fetchTimezone,
    staleTime: Infinity,
  });

  // Fetch meetings
  const { data: meetingsData, isFetching: isLoading } = useQuery({
    queryKey: ['meetings', selectedWorkspace, currentView, dateRange, refreshMeetings],
    queryFn: () => fetchMeetings(selectedWorkspace, dateRange),
    enabled: !!timezone,
  });

  useEffect(() => {
    if (timezoneData) {
      setTimezone(timezoneData);
    }
  }, [timezoneData]);

  useEffect(() => {
    if (meetingsData && timezone) {
      const hostColorMap = {};
      const colorPairs = [
        { primary: '#173300', secondary: '#FFED74' },
        { primary: '#FFED74', secondary: '#173300' },
        { primary: '#e9eef6', secondary: '#173300' },
        { primary: '#2C3E50', secondary: '#FFED74' },
        { primary: '#27AE60', secondary: '#F9FBE7' },
        { primary: '#1B4F72', secondary: '#D6EAF8' },
        { primary: '#196F3D', secondary: '#E8F8F5' },
        { primary: '#2E4053', secondary: '#F8F9F9' },
        { primary: '#145A32', secondary: '#DAF7A6' },
        { primary: '#1A5F7A', secondary: '#E0F7FA' },
      ];
      let colorIndex = 0;

      const formattedMeetings = meetingsData.map((meeting) => {
        const { start, end } = generateStartEnd(
          meeting.booking.bookingDate,
          meeting.booking.bookingTime,
          meeting.booking.bookingTimeSlot,
          timezone,
        );

        // console.log(start, end);
        if (!hostColorMap[meeting.meetingSourceHostEmail]) {
          hostColorMap[meeting.meetingSourceHostEmail] = colorPairs[colorIndex % colorPairs.length];
          colorIndex++;
        }

        return {
          Id: meeting._id,
          Subject: `${meeting?.booking?.confirmedByUser === false && meeting?.status !== 'cancelled' ? '! ' : ''} ${
            meeting?.users?.[1]
              ? `Meeting between ${meeting?.users[0]?.firstName} ${meeting?.users[0]?.lastName} and ${meeting.booking.guests[0]?.name}`.replace(
                  '0 min ',
                  '',
                )
              : meeting.notes || 'Untitled Meeting'
          }`,
          Description: meeting.notes || 'Untitled Meeting',
          StartTime: start,
          EndTime: end,
          Location: meeting?.meetingSourceUrl?.includes('https://meet.google.com') ? 'Google Meet' : 'Say Briefly',
          CategoryColor: hostColorMap[meeting.meetingSourceHostEmail].primary,
          TextColor: hostColorMap[meeting.meetingSourceHostEmail].secondary,
        };
      });
      setMeetingData(formattedMeetings);

      // Update the scheduler directly
      if (scheduleRef.current) {
        // Use the scheduler's public API to update the data
        if (currentView !== 'Month') {
          const newTimeStr = moment.tz(timezone).subtract(30, 'minute').set('minute', 0)?.format('HH:mm');
          setTimeout(() => {
            scheduleRef?.current?.scrollTo(newTimeStr);
          }, 100);
        }
        scheduleRef.current.eventSettings.dataSource = formattedMeetings;
        scheduleRef.current.dataBind();
      }
    }
  }, [meetingsData, timezone]);

  const onPopupOpen = async (args) => {
    args.cancel = true;
    try {
      const meetingId = args.data.Id;
      if (meetingId) {
        setSelectedMeeting(meetingId);
        setShowDetails(true);
      }
    } catch (err) {
      console.log(err);
    }
    if (args.type === 'EventContainer') {
      console.log(args);
      setShowEventContainer(true);
      setEventData(args?.data?.event);
    }
    if (args.type === 'QuickInfo') {
      const editButton = args.element.querySelector('.e-event-edit');
      if (editButton) {
        editButton.style.display = 'none';
      }
    }
  };

  const onEventRendered = (args) => {
    if (args.data.CategoryColor) {
      args.element.style.backgroundColor = args.data.CategoryColor;
      args.element.style.borderRadius = '8px';
      args.element.style.padding = '0px 4px';
    }
    if (args.data.TextColor) {
      args.element.style.color = args.data.TextColor;
    }
  };

  const onActionComplete = (args) => {
    // console.log(args, 'schedule action complete');
    let newView = currentView;
    if (args.requestType === 'viewNavigate') {
      newView = document.querySelector('.e-active-view .e-tbar-btn-text').textContent;
      setCurrentView(newView);
      localStorage.setItem('calendarView', newView);
    }

    if (args.requestType === 'viewNavigate' || args.requestType === 'dateNavigate') {
      let dateRange;
      if (newView === 'Month') {
        const month = document.querySelector('.e-schedule-table.e-content-table')?.ariaLabel;
        const extractDates = (str) => {
          // Define a reusable date pattern that captures day of week, month, day, and year
          const datePattern =
            /(?:Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday),\s+([A-Za-z]+)\s+(\d+),\s+(\d{4})/gi;

          // Extract all dates from the string
          const dates = [];
          let match;

          while ((match = datePattern.exec(str)) !== null) {
            const [_, month, day, year] = match;
            dates.push(moment(`${month} ${day} ${year}`, 'MMMM D YYYY').format('YYYY-MM-DD'));
          }

          // Return the first and last dates found (start and end)
          return dates.length >= 2 ? [dates[0], dates[1]] : undefined;
        };
        dateRange = extractDates(month);
      } else if (newView === 'Week') {
        const week = document.querySelector('.e-date-range .e-tbar-btn-text').textContent;
        const convertDateRange = (str) => {
          // Match patterns like "Dec 30, 2024 - Jan 05, 2025" (different years explicitly stated)
          const differentYearsMatch = str.match(
            /([A-Za-z]+)\s+(\d+),?\s+(\d{4})\s+-\s+([A-Za-z]+)\s+(\d+),?\s+(\d{4})/i,
          );

          if (differentYearsMatch) {
            return [
              moment(
                `${differentYearsMatch[1]} ${differentYearsMatch[2]} ${differentYearsMatch[3]}`,
                'MMM D YYYY',
              ).format('YYYY-MM-DD'),
              moment(
                `${differentYearsMatch[4]} ${differentYearsMatch[5]} ${differentYearsMatch[6]}`,
                'MMM D YYYY',
              ).format('YYYY-MM-DD'),
            ];
          }

          // Match patterns like "Mar 31 - Apr 06, 2025" (different months, same year)
          const differentMonthsMatch = str.match(/([A-Za-z]+)\s+(\d+)\s+-\s+([A-Za-z]+)\s+(\d+),?\s+(\d{4})/i);

          if (differentMonthsMatch) {
            const startDate = moment(
              `${differentMonthsMatch[1]} ${differentMonthsMatch[2]} ${differentMonthsMatch[5]}`,
              'MMM D YYYY',
            );
            const endDate = moment(
              `${differentMonthsMatch[3]} ${differentMonthsMatch[4]} ${differentMonthsMatch[5]}`,
              'MMM D YYYY',
            );

            // Handle implicit year transition (Dec-Jan with same year specified)
            if (
              differentMonthsMatch[1].toLowerCase().startsWith('dec') &&
              differentMonthsMatch[3].toLowerCase().startsWith('jan')
            ) {
              endDate.year(startDate.year() + 1);
            }

            return [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')];
          }

          // Match patterns like "March 24 - 30, 2025" (same month)
          const sameMonthMatch = str.match(/([A-Za-z]+)\s+(\d+)\s+-\s+(\d+),?\s+(\d{4})/i);

          if (sameMonthMatch) {
            return [
              moment(`${sameMonthMatch[1]} ${sameMonthMatch[2]} ${sameMonthMatch[4]}`, 'MMM D YYYY').format(
                'YYYY-MM-DD',
              ),
              moment(`${sameMonthMatch[1]} ${sameMonthMatch[3]} ${sameMonthMatch[4]}`, 'MMM D YYYY').format(
                'YYYY-MM-DD',
              ),
            ];
          }

          return undefined;
        };
        dateRange = convertDateRange(week);
      } else {
        const day = document.querySelector('.e-date-range .e-tbar-btn-text').textContent;
        const convertDateRange = day.split(' - ').map((date) => moment(date).format('YYYY-MM-DD'))?.[0];
        dateRange = [convertDateRange, convertDateRange];
      }
      setDateRange(dateRange);
    }
  };

  useEffect(() => {
    onActionComplete({ requestType: 'viewNavigate' });
  }, [currentView]);

  return (
    <div>
      <div className="relative">
        <ScheduleComponent
          ref={scheduleRef}
          height="84.5vh"
          actionComplete={onActionComplete}
          // selectedDate={new Date()}
          eventSettings={{
            dataSource: meetingData || [],
            allowAdding: false,
          }}
          currentView={currentView}
          eventRendered={onEventRendered}
          popupOpen={onPopupOpen}
          timezone={timezone}
          style={{
            borderRadius: '12px',
          }}
          timeScale={{ interval: 30, slotCount: 3 }}
          firstDayOfWeek={1}
        >
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" showWeekNumber />
            <ViewDirective option="Month" showWeekend />
          </ViewsDirective>
          <Inject services={[Day, Week, WorkWeek, Month]} />
        </ScheduleComponent>

        {isLoading && (
          <div className="absolute top-0 left-0 size-full flex justify-center items-center bg-white/50 backdrop-blur-sm animate-pulse rounded-lg z-10">
            <span className="border-4 size-8 border-black border-t-transparent rounded-full animate-spin" />
          </div>
        )}
      </div>

      <ViewMeetingDetailsModalNotification open={showDetails} setOpen={setShowDetails} meetingId={selectedMeeting} />

      <EventContainer
        open={showEventContainer}
        setOpen={setShowEventContainer}
        setSelectedMeeting={setSelectedMeeting}
        setShowDetails={setShowDetails}
        events={eventData}
      />
    </div>
  );
}
