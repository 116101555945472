/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import { useEffect, useRef, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { useGlobal } from 'reactn';
import getUpcomingMeetings from '../../../actions/getUpcomingMeetings';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '../../../components/ui/pagination';
import { getDateRangeFromView } from '../../../lib/utils';
import MeetingCardExtended from './meeting-card-extended';
import MeetingCardItem from './meeting-card-item';
import UpcomingNotFound from './upcoming-not-found';

function UpcomingMeetings({ selectedWorkspace, currentCalendarView }) {
  const refreshMeetings = useGlobal('refreshMeetings')[0];
  const [meetingData, setMeetingData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const datePicker = useRef(null);
  const max = 8;
  const [value, setValue] = useState([new DateObject()]);
  const [meetingCount, setMeetingCount] = useState({
    today: 0,
    thisWeek: 0,
  });
  const [user] = useGlobal('user');

  const getData = async () => {
    setIsLoading(true);
    try {
      const { from, to } = getDateRangeFromView(currentCalendarView);
      const { data } = await getUpcomingMeetings(
        page,
        max,
        `${selectedWorkspace ? `workspaceId=${selectedWorkspace}` : ''}${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}`,
      );

      const meetingCount = {
        today: data?.data?.todayCount,
        thisWeek: data?.data?.weekCount,
      };
      setMeetingCount(meetingCount);

      setMeetingData(data?.data?.meetings || []);
    } catch (err) {
      console.error('Error fetching upcoming meetings:', err);
      setMeetingData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [page, value, refreshMeetings, selectedWorkspace, currentCalendarView]);

  return (
    <div className="space-y-6">
      <div className="flex lg:flex-row flex-col lg:items-center gap-4 justify-between">
        <h4 className="text-[#101828] text-sm font-semibold font-inter leading-[14.03px]">
          Upcoming Gmeet Meetings
        </h4>

        {/* <div className="flex gap-4">
          <button className="px-3.5 py-2.5 gap-2.5 bg-white rounded-lg shadow border border-gray-300 items-center inline-flex">
            <img src="/image/icons/calendar.svg" alt="Calender" className="lg:size-5 size-4" />
            <button onClick={handleOpen} className="px-0.5 text-gray-500 text-sm font-semibold">
              {value[0] && value[1]
                ? `${value[0].format('DD/MM/YYYY')} - ${value[1].format('DD/MM/YYYY')}`
                : 'Select Date'}
            </button>
            <DatePicker inputClass="w-0 m-0 p-0" ref={datePicker} value={value} onChange={setValue} rangeHover range />
          </button>
        </div> */}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="px-2.5 py-3 bg-[#e9eef6] rounded-lg border border-[#eaecf0] flex-col justify-center items-center inline-flex">
          <h5 className="text-[#101828] text-[24px] font-medium font-inter leading-normal">
            {meetingCount?.today}
          </h5>
          <p className="self-stretch text-center text-[#475467] text-xs font-semibold font-inter leading-[18px]">Upcoming today</p>
        </div>
        <div className="px-2.5 py-3 bg-[#e9eef6] rounded-lg border border-[#eaecf0] flex-col justify-center items-center inline-flex">
          <h5 className="text-[#101828] text-[24px] font-medium font-inter leading-normal">
            {meetingCount?.thisWeek}
          </h5>
          <p className="self-stretch text-center text-[#475467] text-xs font-semibold font-inter leading-[18px]">Upcoming this week</p>
        </div>
      </div>
      {isLoading ? (
        <p className="flex items-center gap-2">
          <span className="w-4 h-4 rounded-full border-t-black border-2 border-gray-500 block animate-spin" />
          <span className="animate-pulse">Loading upcoming meetings...</span>
        </p>
      ) : (
        <>
          {meetingData?.length === 0 && <UpcomingNotFound />}
          {meetingData?.length > 0 && (
            <div className="flex flex-col gap-6 h-full">
              <div className="space-y-4">
                <MeetingCardExtended data={meetingData[0]} />
                {meetingData?.slice(1).map((item) => (
                  <MeetingCardItem type="upcoming" key={item._id} data={item} />
                ))}
              </div>
            </div>
          )}
          {/* <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={() => setPage((prev) => (prev === 1 ? 1 : prev - 1))}
                  className={page === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'}
                />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink>{page}</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationNext
                  onClick={() =>
                    setPage((prev) => (meetingData?.length === 0 || meetingData?.length < max ? prev : prev + 1))
                  }
                  className={
                    (meetingData?.length === 0 && page > 1) || meetingData?.length < max
                      ? 'text-gray-500 cursor-not-allowed'
                      : 'text-gray-800'
                  }
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination> */}

          {
            ((meetingData?.length === max) || page > 1) && (
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious
                      onClick={() => setPage((prev) => (prev === 1 ? 1 : prev - 1))}
                      className={page === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'}
                    />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink>{page}</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationNext
                      onClick={() =>
                        setPage((prev) => (meetingData?.length === 0 || meetingData?.length < max ? prev : prev + 1))
                      }
                      className={
                        (meetingData?.length === 0 && page > 1) || meetingData?.length < max
                          ? 'text-gray-500 cursor-not-allowed'
                          : 'text-gray-800'
                      }
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            )
          }
        </>
      )}
    </div>
  );
}

export default UpcomingMeetings;
