import { TourProvider } from "@reactour/tour";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../../components/ui/button";

const steps = [
    {
        selector: ".tour-calendar",
        content: () => <div className="space-y-5">
            <h4 className="text-[#1a2e05] text-base font-semibold font-inter leading-normal">
                Need to add more calendars?
            </h4>
            <div className="flex gap-6">
                <p className="max-w-[210px] text-sm text-[#475569] font-normal leading-tight">
                    Click 'Calendar' in the sidebar to sync additional calendars and prevent double bookings across all your accounts.
                </p>
                <div>
                    <img src="/image/calendar.png" alt="welcome" className="h-[70px] w-auto" />
                </div>
            </div>
        </div>,
        position: 'left',
    },
    {
        selector: ".tour-meetings",
        content: () => <div className="space-y-5">
            <h4 className="text-[#1a2e05] text-base font-semibold font-inter leading-normal">
                Once the call ends, your notes land here.
            </h4>
            <div className="flex gap-6">
                <p className="max-w-[210px] text-sm text-[#475569] font-normal leading-tight">
                    Click 'Meetings' in the sidebar to access all your summaries, highlights, and most importantly – your briefs.
                </p>
                <div>
                    <img src="/image/pencil.png" alt="welcome" className="h-[70px] w-auto" />
                </div>
            </div>
        </div>,
        position: 'left',
    },
    {
        selector: ".tour-project-space",
        content: () => <div className="space-y-5 relative">
            <h4 className="text-[#1a2e05] text-base font-semibold font-inter leading-normal">
                Organize everything in dedicated spaces
            </h4>
            <div className="flex gap-6 ">
                <p className="max-w-[210px] text-sm text-[#475569] font-normal leading-tight">
                    Store meeting notes by project in project spaces, or keep them in your custom space to organize later.
                </p>
                <div>
                    <img src="/image/folder.png" alt="welcome" className="h-[70px] w-auto" />
                </div>
            </div>

            <div className="w-96 h-16 bg-[#fde047] rounded-lg overflow-hidden flex items-center gap-4 p-4 absolute bottom-[-190px] left-[-20px]">
                <img className="w-6 h-11" src="/image/hand.png" />
                <div className="w-full">
                    <span className="text-lime-950 text-sm font-bold leading-tight">Pro tip:</span>
                    <span className="text-lime-950 text-sm font-normal leading-tight"> Meeting notes default to your main space if no project space is created.</span>
                </div>
            </div>
        </div>,
        position: 'left',
    },
    {
        selector: ".tour-workspace",
        content: () => <div className="space-y-5 ">
            <h4 className="text-[#1a2e05] text-base font-semibold font-inter leading-normal">
                Switch between workspaces here
            </h4>
            <div className="flex gap-6">
                <p className="max-w-[210px] text-sm text-[#475569] font-normal leading-tight">
                    Switch between your primary and secondary workspaces to keep client meetings private and separate.
                </p>
                <div>
                    <img src="/image/workspace.png" alt="welcome" className="h-[70px] w-auto" />
                </div>
            </div>
        </div>,
        position: 'left',
    },
];

export default function TourProviderWrapper({ children }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentStepCount, setCurrentStepCount] = useState(0);
    return (
        <>
            <TourProvider
                steps={steps}
                onClickClose={(e) => {
                    e.setCurrentStep(0);
                    e.setIsOpen(false);
                }}
                prevButton={() => null}
                disableInteraction
                nextButton={({ currentStep, setCurrentStep, steps }) => {
                    const last = currentStep === steps.length - 1;
                    const first = currentStep === 0;
                    return (
                        <div className="w-full space-y-3">
                            <div className="flex justify-between w-full">
                                <Button
                                    className="hover:text-[#FFEC5D] text-[#173300] border-[#173300] bg-transparent hover:bg-[#173300] px-8"
                                    onClick={() => {
                                        if (first) {
                                            // Close the tour
                                            setCurrentStep(0)
                                            setCurrentStepCount(0)
                                            searchParams.append('welcome', 'true');
                                            setSearchParams(searchParams);
                                        } else {
                                            setCurrentStepCount((s) => s - 1)
                                            setCurrentStep((s) => s - 1)
                                        }
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    className="px-8"
                                    onClick={() => {
                                        if (last) {
                                            // Close the tour
                                            setCurrentStep(null)
                                            setCurrentStepCount(0)
                                        } else {
                                            setCurrentStep((s) => s + 1)
                                            setCurrentStepCount((s) => s + 1)
                                        }
                                    }}
                                >
                                    {last ? "Finish" : "Next"}
                                </Button>
                            </div>
                            <p className="text-center text-[#173300] text-xs font-normal leading-3">Step {currentStep + 1 + 1} of {steps.length + 1}</p>
                        </div>
                    )
                }}
                afterNav={({ currentStep, steps }) => (
                    <div className="mt-2 text-center">
                        <span className="text-sm text-gray-500">
                            Step {currentStep + 1} of {steps.length}
                        </span>
                    </div>
                )}
                showBadge={false}
                showDots={false}
            >
                {children}
            </TourProvider>

            <div>
                {currentStepCount === 3 && <div className="bg-[#fde047] rounded-lg  items-center gap-4 py-4 px-6 fixed top-[80px] right-[20px] z-[99999] after:absolute after:content-[''] after:-right-[-35%] after:top-[-18px] after:rotate-[270deg] after:w-0 after:h-0 after:border-t-[12px] after:border-b-[12px] after:border-l-[20px] after:border-t-transparent after:border-b-transparent after:border-l-[#fde047] md:flex hidden">
                    <img className="w-6 h-11" src="/image/hand.png" />
                    <div className="w-full">
                        <span className="text-lime-950 text-sm font-bold leading-tight">Pro tip:</span>
                        <span className="text-lime-950 text-sm font-normal leading-tight">  Create a new workspace here. <br /> New workspace = separate business
                            <br />
                            Just like Slack channels.
                        </span>
                    </div>
                </div>}
            </div>
        </>
    )
}
