/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-perLine */
import { useGlobal } from 'reactn';
import Config from '../../../../../config';
import { getGravatarUrl } from '../../../../../lib/utils';
import MeetingHostEmailSelector from '../../../components/meeting-host-email-selector';
import MeetingSourceSelector from '../../../components/meeting-source-selector';
import TimezoneSelector from '../../../components/timezone-slector';

function formatTime(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`;
  }
  return `${minutes} min${minutes > 1 ? 's' : ''}`;
}

function MeetingInfo({ setSelectedTimezone, selectedTimezone, setMeetingSource, meetingSource, setMeetingHostId, meetingHostId, slot }) {
  const user = useGlobal('user')[0];
  const userName = `${user?.firstName} ${user?.lastName}`;
  const gravatarUrl = getGravatarUrl(userName, user?.email);
  return (
    <div className="space-y-4 lg:pr-6 lg:border-r border-calenderPrimary">
      <div className="flex items-center gap-2">
        <img className="size-6 rounded-full object-cover" src={user?.picture ? `${Config?.url}${user?.picture?.location?.slice(1)}` : gravatarUrl} alt="" />
        <h4 className="text-calenderPrimary text-sm font-semibold leading-tight m-0">
          {user?.firstName} {user?.lastName}
        </h4>
      </div>
      <h3 className="text-calenderPrimary text-xl font-semibold leading-7 m-0">
        {user?.workspace?.name}
      </h3>
      <p className=" text-calenderPrimary text-sm font-medium leading-tight m-0 flex gap-2 items-center">
        <svg className="size-4" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVG" clipPath="url(#clip0_10573_21273)">
            <path id="Vector" d="M7.9987 14.6673C11.6806 14.6673 14.6654 11.6825 14.6654 8.00065C14.6654 4.31875 11.6806 1.33398 7.9987 1.33398C4.3168 1.33398 1.33203 4.31875 1.33203 8.00065C1.33203 11.6825 4.3168 14.6673 7.9987 14.6673Z" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_2" d="M8 4V8L10.6667 9.33333" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_10573_21273">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>

        {formatTime(slot)}
      </p>
      <p className=" text-calenderPrimary text-sm font-medium leading-tight m-0 flex gap-2 items-center">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Main container rectangle */}
          <path
            d="M12.375 0H3.625C1.62297 0 0 1.62297 0 3.625V12.375C0 14.377 1.62297 16 3.625 16H12.375C14.377 16 16 14.377 16 12.375V3.625C16 1.62297 14.377 0 12.375 0Z"
            fill="currentColor"
          />

          {/* Inner white rectangle */}
          <path
            d="M8.39075 4.8125H4.28045C3.46973 4.8125 2.8125 5.46973 2.8125 6.28045V9.70569C2.8125 10.5164 3.46973 11.1736 4.28045 11.1736H8.39075C9.20147 11.1736 9.8587 10.5164 9.8587 9.70569V6.28045C9.8587 5.46973 9.20147 4.8125 8.39075 4.8125Z"
            fill="white"
          />

          {/* Triangle shape */}
          <path
            d="M10.3242 8.66025V7.27572C10.3242 7.04538 10.4158 6.82445 10.5786 6.66157L11.9098 5.33037C12.0538 5.18637 12.2492 5.10547 12.4528 5.10547H12.4768C12.665 5.10547 12.8456 5.18028 12.9788 5.31343C13.112 5.44659 13.1868 5.62718 13.1868 5.8155V10.1204C13.1868 10.3088 13.112 10.4894 12.9788 10.6225C12.8456 10.7557 12.665 10.8305 12.4768 10.8305H12.4528C12.2492 10.8305 12.0538 10.7496 11.9098 10.6056L10.5786 9.27438C10.4158 9.11152 10.3242 8.89058 10.3242 8.66025Z"
            fill="white"
          />

          {/* Border */}
          <path
            d="M12.375 0.046875H3.625C1.64886 0.046875 0.046875 1.64886 0.046875 3.625V12.375C0.046875 14.3512 1.64886 15.9531 3.625 15.9531H12.375C14.3512 15.9531 15.9531 14.3512 15.9531 12.375V3.625C15.9531 1.64886 14.3512 0.046875 12.375 0.046875Z"
            stroke="#EDE9FE"
            strokeOpacity="0.25"
            strokeWidth="0.09375"
          />
        </svg>
        <MeetingSourceSelector setMeetingSource={setMeetingSource} meetingSource={meetingSource} />
      </p>
      {meetingSource === 'google' && (
        <p className=" text-calenderPrimary text-sm font-medium leading-tight m-0 flex gap-2 items-center">
          <img className="w-4 h-4" src="/image/icons/avatar.svg" alt="" />
          <MeetingHostEmailSelector
            setMeetingHostId={setMeetingHostId}
            meetingSource={meetingSource}
            meetingHostId={meetingHostId}
          />
        </p>
      )}
      <p className="text-calenderPrimary text-sm font-medium leading-tight m-0 flex gap-2 items-center">
        <svg className='size-4' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVG" clipPath="url(#clip0_10573_21353)">
            <path id="Vector" d="M7.9987 14.6673C11.6806 14.6673 14.6654 11.6825 14.6654 8.00065C14.6654 4.31875 11.6806 1.33398 7.9987 1.33398C4.3168 1.33398 1.33203 4.31875 1.33203 8.00065C1.33203 11.6825 4.3168 14.6673 7.9987 14.6673Z" stroke="black" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_2" d="M7.9987 1.33398C6.28685 3.13142 5.33203 5.51848 5.33203 8.00065C5.33203 10.4828 6.28685 12.8699 7.9987 14.6673C9.71054 12.8699 10.6654 10.4828 10.6654 8.00065C10.6654 5.51848 9.71054 3.13142 7.9987 1.33398Z" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_3" d="M1.33203 8H14.6654" stroke="black" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_10573_21353">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <TimezoneSelector selectedTimezone={selectedTimezone} setSelectedTimezone={setSelectedTimezone} />
      </p>
    </div>
  );
}

export default MeetingInfo;
