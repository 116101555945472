import axios from "axios";
import Config from "../config";
import getResources from "./getResources";

// API Functions
const fetchDrives = async () => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_drives`);
    return data.data;
};

const fetchUsageData = async () => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/dashboard-usage-monthly`);
    return data.stats;
};

const fetchDriveStorage = async (driveId) => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_drive_storage_info/${driveId}`);
    return data.data;
};

const fetchResources = async () => {
    const { data } = await getResources();
    return data.data.sort((a) => (a.default === true ? -1 : 1)); // Sort default to top
};

const fetchWorkspaces = async () => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_workspaces`);
    return data.data;
};

const fetchCalendars = async () => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_calendars`);
    return data.data;
};

const fetchSchedule = async () => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/get_schedule`);
    return data.data.schedule;
};

const verifyGoogleCalendarCode = async (code) => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/verify_calendar_integration?code=${code}`);
    return data;
};

const verifyGoogleDriveCode = async (code) => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/verify_drive_integration?code=${code}`);
    return data;
};

const requestCalendarIntegration = async () => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/request_calendar_integration`);
    return data?.data?.url;
};

const requestDriveIntegration = async () => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/back_office/request_drive_integration`);
    return data?.data?.url;
};

const updateSchedule = async ({ availableWeekdays, timeZone, timeSlot, shortDescription, redirectUrl }) => {
    const { data } = await axios.post(`${Config.url || ''}/api/v1/user/back_office/save_schedule`, {
        availableWeekdays,
        timeZone,
        timeSlot,
        shortDescription,
        redirectUrl,
    });
    return data;
};

const disconnectCalendar = async (calendarId) => {
    const response = await axios.delete(`${Config.url || ''}/api/v1/user/back_office/disconnect_calendars/${calendarId}`);
    return response.data;
};

const disconnectDrive = async (driveId) => {
    const response = await axios.delete(`${Config.url || ''}/api/v1/user/back_office/disconnect_drive/${driveId}`);
    return response.data;
};

const fetchUserSuggestions = async (email) => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/get_user_suggestion`, { params: { email } });
    return data.data;
};

const fetchMeetingsByUserEmail = async (email) => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/get_meetings_by_user_email`, { params: { email } });
    return data.data;
};

const createProjectSpace = async ({ name, userEmails, email, meetingIds }) => {
    const { data } = await axios.post(`${Config.url || ''}/api/v1/user/add_resource`, {
        name,
        ...(userEmails ? { user_emails: userEmails } : {}),
        ...(email ? { user_email: email } : {}),
        ...(meetingIds ? { meetingIds } : {}),
    });
    return data;
};

const fetchCurrentWorkspace = async () => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/workspace/get_current_workspace`);
    return data.data;
};

const changeWorkspaceCalendar = async ({ calendarId, workspaceId }) => {
    const { data } = await axios.post(`${Config.url || ''}/api/v1/user/workspace/change_calendar`, {
        calendarId,
        workspaceId,
    });
    return data;
};

// API Functions for React Query
const fetchUser = async (token) => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/me`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return data.data;
};

const setCurrentWorkspace = async ({ workspaceId }) => {
    const { data } = await axios.post(`${Config.url || ''}/api/v1/user/workspace/set_current_workspace`, { workspaceId });
    return data.data;
};

const editWorkspace = async ({ workspaceId, formData }) => {
    const { data } = await axios.post(`${Config.url || ''}/api/v1/user/workspace/update_workspace/${workspaceId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
};

const acceptSubscriptionInvitation = async (invitationCode) => {
    const { data } = await axios.post(`${Config.url || ''}/api/v1/user/subscription/accept_subscription_invitation`, { invitationCode });
    return data;
};

const rejectSubscriptionInvitation = async (invitationCode) => {
    const { data } = await axios.post(`${Config.url || ''}/api/v1/user/subscription/reject_subscription_invitation`, { invitationCode });
    return data;
};

const acceptWorkspaceInvitation = async (invitationCode) => {
    const { data } = await axios.post(`${Config.url || ''}/api/v1/user/workspace/accept_workspace_invitation`, { invitationCode });
    return data;
};

const rejectWorkspaceInvitation = async (invitationCode) => {
    const { data } = await axios.post(`${Config.url || ''}/api/v1/user/workspace/reject_workspace_invitation`, { invitationCode });
    return data;
};

const fetchMeetingStats = async (filter) => {
    const { data } = await axios.get(`${Config.url || ''}/api/v1/user/dashboard-meeting-stats?filter=${filter}`);
    return data.stats; // Extract 'stats' from response
};


const deleteUser = async ({ formData }) => {
    const { data } = await axios.post(`${Config.url || ''}/api/v1/user/delete_account`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
};

export {
    acceptSubscriptionInvitation, changeWorkspaceCalendar, createProjectSpace, disconnectCalendar,
    disconnectDrive, editWorkspace, fetchCalendars, fetchCurrentWorkspace, fetchDrives, fetchDriveStorage, fetchMeetingsByUserEmail, fetchMeetingStats, fetchResources, fetchSchedule, fetchUsageData, fetchUser, fetchUserSuggestions, fetchWorkspaces, rejectSubscriptionInvitation, requestCalendarIntegration,
    requestDriveIntegration, setCurrentWorkspace, updateSchedule, verifyGoogleCalendarCode,
    verifyGoogleDriveCode, acceptWorkspaceInvitation, rejectWorkspaceInvitation, deleteUser
};

