import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { editWorkspace } from "../../../../actions/apiFunctions";
import { Input } from "../../../../components/ui/input";
import { generateColorShades } from "../../../../lib/utils";

export default function BrandColor({ user }) {
    const [brandColor, setBrandColor] = useState(user?.workspace?.brandColor || '#1bcf11');
    const workspaceId = user?.workspace?._id;
    const queryClient = useQueryClient();

    const colorShades = generateColorShades(brandColor);

    // Mutation for editing workspace
    const { mutate: handleEditWorkSpace } = useMutation({
        mutationFn: editWorkspace,
        onSuccess: async (res) => {
            if (res.success) {
                queryClient.invalidateQueries(['currentWorkspace']);
                queryClient.invalidateQueries(['user']);
                // toast.success('Workspace updated successfully.', { position: 'top-right' });
            }
        },
        onError: (err) => {
            console.log(err);
        },
    });

    const handleChangeColor = (e) => {
        e.preventDefault();
        const color = e.target.value;
        setBrandColor(color);

        const brandColorShades = generateColorShades(color);
        const shades = brandColorShades?.shades;

        const indexNumber = shades?.findIndex((item) => item.hexcode === color);
        const brandShade = indexNumber < 8 ? shades?.[8] : shades?.[indexNumber];

        document.documentElement.style.setProperty('--calenderBackground', shades[0].hexcode);
        document.documentElement.style.setProperty('--calenderPrimary', brandShade.hexcode);
        document.documentElement.style.setProperty('--calenderPrimaryLight', shades[2].hexcode);
        document.documentElement.style.setProperty('--calenderSecondary', shades[0].hexcode);
    };

    useEffect(() => {
        // console.log(brandColor === user?.workspace?.brandColor);
        if (!brandColor || !workspaceId || (brandColor === user?.workspace?.brandColor)) return;
        const formData = new FormData();
        formData.append('brandColor', brandColor || '#05a862');
        formData.append('name', user?.workspace?.name);
        formData.append('description', user?.workspace?.description);
        handleEditWorkSpace({ workspaceId, formData });
    }, [brandColor]);

    return (
        <div className="space-y-2">
            <div>
                <h4 className="text-slate-700 text-base font-medium leading-normal">Brand Color</h4>
                <p className="text-slate-500 text-sm font-normal font-inter leading-tight">
                    Change your brand color.
                </p>
            </div>
            <label
                htmlFor="brand-color"
                className="rounded-md border border-slate-200 bg-white px-3 py-2 flex justify-between items-center gap-2.5"
            >
                <span className="text-[#64748B] text-base font-normal">
                    {brandColor?.replace('#', '#  ') || 'Brand color'}
                </span>
                <Input
                    className="p-0 border-none ring-0 outline-none bg-transparent w-10 h-6"

                    id="brand-color"
                    type="color"
                    value={brandColor}
                    onChange={handleChangeColor}
                />
            </label>

            <p>{colorShades?.name}</p>
            <div className="grid grid-cols-10 border">
                {colorShades?.shades?.map((shade) => (
                    <div
                        key={shade.name}
                        className="p-2 h-[40px] flex items-center gap-0.5 justify-center text-xs font-bold text-gray-300"
                        style={{ backgroundColor: shade.hexcode }}
                    >
                        {shade.name}
                        {shade.hexcode === brandColor && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-3"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                                />
                            </svg>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}
