/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import changeProfilePicture from '../../../actions/changeProfilePicture';
import { Avatar, AvatarFallback, AvatarImage } from '../../../components/ui/avatar';
import Config from '../../../config';

export default function ProfilePicture() {
  const [user, setUser] = useGlobal('user');

  const [profileUrl, setProfileUrl] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [isSavingProfilePicture, setIsSavingProfilePicture] = useState(false);

  useEffect(() => {
    if (profilePicture) saveProfilePicture();
  }, [profilePicture]);

  const handleChangeProfilePicture = async (e) => {
    e.preventDefault();
    const { files } = e.target;
    if (files.length > 0) {
      setProfilePicture(files[0]);

      // Make Image url to show Profile Picture
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileUrl(e.target.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };
  const saveProfilePicture = async () => {
    try {
      setIsSavingProfilePicture(true);
      const formData = new FormData();
      formData.append('image', profilePicture);
      formData.append('crop', 'square');

      const { data } = await changeProfilePicture(formData);
      if (data.success) {
        toast.success('Profile picture updated successfully.', {
          position: 'top-center',
        });
        const { data: res } = await axios({
          method: 'get',
          url: `${Config.url || ''}/api/v1/user/me`,
        });
        console.log(res.data);
        setUser(res.data);
        // setProfileUrl('');
        setProfilePicture(null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSavingProfilePicture(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 items-start">
      <div className="relative group rounded-full overflow-hidden border-4 border-white shadow-md">
        <Avatar className="size-40">
          <AvatarImage src={profileUrl || (user?.picture ? `${Config?.url}${user?.picture?.location?.slice(1)}` : '')} alt={user?.username} className="object-cover object-center" />
          <AvatarFallback>
            {user?.firstName?.[0]}
            {user?.lastName?.[0]}
          </AvatarFallback>
        </Avatar>
        {isSavingProfilePicture ? (
          <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center bg-gray-500/50 text-white backdrop-blur-sm cursor-pointer opacity-100 transition-opacity duration-300 ease-in-out rounded-full">
            <span className="size-6 border-4 border-[#ffec5c] border-t-transparent ml-1 animate-spin rounded-full block" />
          </div>
        ) : (
          <label htmlFor="profile-picture" className="absolute w-full h-full top-0 left-0 flex items-center justify-center bg-gray-500/50 backdrop-blur-sm text-white cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 relative bottom-[-100px] group-hover:bottom-0 transition-all duration-300 ease-in-out">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
            </svg>
            <input
              type="file"
              id="profile-picture"
              name="profilePicture"
              className="hidden"
              accept="image/*"
              onChange={handleChangeProfilePicture}
            />
          </label>
        )}

      </div>
    </div>
  );
}
