/* eslint-disable indent */
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import {
  Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,
} from '../../../components/ui/select';
import { cn } from '../../../lib/utils';

function TimezoneSelector({ setSelectedTimezone = () => { }, selectedTimezone, className }) {
  const [timezones, setTimezones] = useState([]);
  const [currentTimezone, setCurrentTimezone] = useState(
    selectedTimezone || moment.tz.guess(),
  );

  useEffect(() => {
    const fetchTimezones = async () => {
      try {
        const response = await fetch(
          'https://raw.githubusercontent.com/solaiman5683/timezone/main/timezone.json',
        );
        if (!response.ok) {
          throw new Error('Failed to fetch timezones');
        }
        const data = await response.json();
        setTimezones(data);
      } catch (error) {
        console.error('Error fetching timezones:', error);
      }
    };

    fetchTimezones();
  }, []); // Runs only once when the component mounts

  useEffect(() => {
    setCurrentTimezone(selectedTimezone || moment.tz.guess());
  }, [selectedTimezone]); // Update currentTimezone when selectedTimezone changes

  useEffect(() => {
    setSelectedTimezone(currentTimezone);
  }, [currentTimezone]); // Notify parent of timezone change

  return (
    <Select value={currentTimezone} onValueChange={(value) => setCurrentTimezone(value)}>
      <SelectTrigger className={cn('min-w-[150px] max-w-[300px] bg-transparent p-0 py-0 h-auto focus:ring-0 focus:bg-transparent focus:shadow-none ring-offset-0 focus:ring-offset-0', className)}>
        <SelectValue placeholder="Select Timezone" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {
            timezones.map((item) => <SelectItem key={item} value={item}>{item}</SelectItem>)
          }
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export default TimezoneSelector;
