/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */

export default function VideoDeviceList({ videoDeviceId, videoDevices, switchVideoDevice }) {
    return (

        <select
            onChange={(e) => {
                switchVideoDevice(e.target.value);
            }}
            className="w-100 h-12 p-3 rounded-xl bg-black text-white"
        >
            {
                videoDevices?.length > 0 && videoDevices.map((device, i) => <option key={i} value={device.deviceId} selected={videoDeviceId === device.deviceId} className="w-full truncate">{device.label}</option>)
            }
        </select>
    );
}
