/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { fetchCalendars, fetchDrives, requestCalendarIntegration, requestDriveIntegration } from '../../../actions/apiFunctions';
import createWorkspace from '../../../actions/createWorkspace';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/select';

function CreateTeamSpaceModal({ open, setOpen }) {
  const [name, setName] = useState('');
  const [calenderId, setCalenderId] = useState('');
  const [externalStorageId, setExternalStorageId] = useState('');
  const { addToast } = useToasts();
  const queryClient = useQueryClient();


  // Query for calendars
  const { data: calendars = [], isLoading: isCalendarsLoading } = useQuery({
    queryKey: ['calendars'],
    queryFn: fetchCalendars,
    enabled: open,
    onError: (error) => console.log(error),
  });

  // Query for drives
  const { data: drives = [], isLoading: isDrivesLoading } = useQuery({
    queryKey: ['drives'],
    queryFn: fetchDrives,
    enabled: open,
    onError: (error) => console.log(error),
  });

  // Mutation for creating workspace
  const { mutate: createTeamSpace, isPending: isCreating } = useMutation({
    mutationFn: (data) => createWorkspace(data),
    onSuccess: (res) => {
      if (res.data.success) {
        setOpen(false);
        setName('');
        setCalenderId('');
        setExternalStorageId('');
        addToast('TeamSpace created successfully', { appearance: 'success', autoDismiss: true });
        queryClient.invalidateQueries(['teamSpaces']);
      }
    },
    onError: (error) => {
      console.log(error?.response?.data?.error);
      addToast((error?.response?.data?.error || 'Failed to create TeamSpace'), { appearance: 'error', autoDismiss: true });
    },
  });

  // Mutation for connecting Google Calendar
  const { mutate: connectGoogleCalendar, isPending: connectingCalendar } = useMutation({
    mutationFn: requestCalendarIntegration,
    onSuccess: (url) => window.open(url, '_self'),
    onError: (error) => {
      console.log(error);
      if (error?.response?.data?.error) {
        // toast.error(`${error.response.data.error}`, { position: 'top-center', duration: 3000 });
        addToast(error.response.data.error, { appearance: 'error', autoDismiss: true });
        // Removed setOpen(false) to keep modal open
      }
    },
  });

  // Mutation for connecting Google Drive
  const { mutate: connectGoogleDrive, isPending: connectingDrive } = useMutation({
    mutationFn: requestDriveIntegration,
    onSuccess: (url) => window.open(url, '_self'),
    onError: (error) => {
      console.log(error);
      if (error?.response?.data?.error) {
        // toast.error(`${error.response.data.error}`, { position: 'top-center', duration: 3000 });
        addToast(error.response.data.error, { appearance: 'error', autoDismiss: true });
      }
    },
  });

  const handleCreateSpace = (e) => {
    e.preventDefault();
    if (!name) {
      setIsToastVisible(true);
      addToast('Workspace name is required', { appearance: 'error', autoDismiss: true });
      // toast.error('Workspace name is required', { position: 'top-center', duration: 3000 });
      return;
    }
    const data = { name, description: 'My new TeamSpace', calenderId, externalStorageId };
    console.log(data);
    createTeamSpace(data);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create Workspace</DialogTitle>
          <DialogDescription className="hidden" />
        </DialogHeader>
        <form onSubmit={handleCreateSpace} className="space-y-4">
          <div className="py-[22px] px-4 bg-[#eeeeee] rounded-2xl flex flex-col gap-1.5">
            <div className="text-[#344054] text-sm font-semibold flex justify-start items-center gap-1">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1.1rem" width="1.1rem" xmlns="http://www.w3.org/2000/svg">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M6 15c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm6-8c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2C9.8 3 8 4.8 8 7s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zm6 12c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z" />
              </svg>
              <span>Workspace Name</span>
            </div>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Name"
              className="px-3.5 py-2.5 bg-white rounded-lg shadow text-[#667085] text-base font-normal"
            />

            <div className="text-[#344054] text-sm font-semibold flex justify-start items-center gap-1 mt-1">
              <img src="/image/icons/calendar.svg" alt="calender" className="h-4 w-5" />
              <span>Workspace Calendar Email</span>
            </div>
            <Select value={calenderId} onValueChange={(value) => setCalenderId(value)}>
              <SelectTrigger className="px-3.5 py-2 bg-white rounded-lg shadow text-[#667085] text-base font-normal ring-0 focus:outline-none">
                <SelectValue placeholder={isCalendarsLoading ? 'Loading...' : 'Select Calendar Email'} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup className='space-y-2'>
                  {calendars?.length === 0 && !isCalendarsLoading && <SelectItem className="py-3" value={null}>No calendars available</SelectItem>}
                  {calendars?.map((item, index) => (
                    <SelectItem className="py-3" key={index} value={item._id}>
                      {item.calendarEmail}
                    </SelectItem>
                  ))}
                  <div className="pt-1">
                    <Button
                      variant="outline"
                      onClick={() => connectGoogleCalendar()}
                      className="px-3 py-1 bg-white rounded-md border border-[#173300] flex justify-center items-center gap-1 text-[#173300] text-sm font-medium font-inter leading-tight w-full"
                    >
                      {
                        connectingCalendar ? (
                          <span className="size-3 border-2 border-t-transparent border-black rounded-full animate-spin ml-1.5" />
                        ) : <img src="/image/icons/plus-circle.svg" className="size-4" alt="" />
                      }
                      Connect Calendar
                    </Button>
                  </div>
                </SelectGroup>
              </SelectContent>
            </Select>

            <div className="text-[#344054] text-sm font-semibold flex justify-start items-center gap-1 mt-1">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1.1rem" width="1.1rem" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 8.999l-.352.015c-.824-2.375-3.312-4.015-5.898-4.015-3.309 0-6.25 2.69-6.25 6v.126c-1 .445-2.75 2.014-2.75 3.875 0 2.206 2.044 4 4.25 4h11c2.757 0 5-2.244 5-5s-2.243-5.001-5-5.001zm0 8.001h-4.5v-3.794l2.146 2.146c.098.099.226.146.354.146s.256-.049.354-.146c.195-.194.195-.512 0-.707l-2.998-3-.164-.107c-.123-.051-.260-.051-.383 0l-.162.107-3 3c-.194.195-.194.513 0 .707.099.099.227.146.354.146s.256-.049.354-.146l2.145-2.146v3.794h-5.5c-1.104 0-2-.896-2-2s.896-2 1.908-2.005l1.422.015-.248-1.201c-.055-.264-.082-.536-.082-.809 0-2.206 1.794-4 4-4 1.951 0 3.604 1.402 3.93 3.334l.187 1.102 1.073-.306c.312-.089.569-.13.812-.13 1.653 0 3 1.346 3 3s-1.348 3-3.002 3z" />
              </svg>
              <span>External Storage</span>
            </div>
            <Select value={externalStorageId} onValueChange={(value) => setExternalStorageId(value)}>
              <SelectTrigger className="px-3.5 py-2 bg-white rounded-lg shadow text-[#667085] text-base font-normal ring-0 focus:outline-none">
                <SelectValue placeholder={isDrivesLoading ? 'Loading...' : 'Select External Storage'} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup className='space-y-2'>
                  {drives?.length === 0 && !isDrivesLoading && <SelectItem className="py-3" value={null}>No drives available</SelectItem>}
                  {drives?.map((item, index) => (
                    <SelectItem className="py-3" key={index} value={item._id}>
                      {item.email}
                    </SelectItem>
                  ))}
                  <div className="pt-1">
                    <Button
                      variant="outline"
                      onClick={() => connectGoogleDrive()}
                      className="px-3 py-1 bg-white rounded-md border border-[#173300] flex justify-center items-center gap-1 text-[#173300] text-sm font-medium font-inter leading-tight w-full"
                    >
                      {
                        connectingDrive ? (
                          <span className="size-3 border-2 border-t-transparent border-black rounded-full animate-spin ml-1.5" />
                        ) : <img src="/image/icons/plus-circle.svg" className="size-4" alt="" />
                      }
                      Connect Drive
                    </Button>
                  </div>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div>
            <Button type="submit" disabled={isCreating || !name || !calenderId || !externalStorageId} className="w-full bg-[#173300]  font-semibold text-base py-3 rounded-lg">
              Create{' '}
              {isCreating && (
                <span className="size-3 border-2 border-t-transparent border-[#ffed74] rounded-full animate-spin ml-1.5" />
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateTeamSpaceModal;