/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import updatePaymentMethod from 'actions/updatePaymentMethod';
import { initializePaddle } from '@paddle/paddle-js';
import { Button } from '../../../components/ui/button';
import Config from '../../../config';

function PaymentMethod() {
  const [user] = useGlobal('user');
  const paymentMethods = user?.payment_methods;
  const [paddle, setPaddle] = useState();
  const [spinner, setSpinner] = useState(false);

  const doUpdatePaymentMethod = async () => {
    setSpinner(true);
    const { data } = await updatePaymentMethod(user.activeSubscription.paddleSubscriptionId);
    paddle?.Checkout.open({
      transactionId: data.data.id,
    });
  };
  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({ environment: Config.paddleEnv, token: Config.paddleClientToken }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);
  return (
    <div className="bg-white h-full rounded-xl shadow border border-gray-200 p-6 space-y-4 w-full">
      <div className="flex-col justify-start items-start gap-6 flex w-full">
        <div className="flex-col justify-start items-start gap-1 inline-flex">
          <div className="items-center flex gap-3">
            <div className="text-gray-900 text-lg font-semibold leading-7">Payment method</div>
          </div>
          <p className="text-slate-600 text-sm font-normal leading-tight m-0">Change how you pay for your plan.</p>
        </div>
      </div>
      <div className="p-4 bg-white rounded-lg border border-gray-200 gap-4">
        {paymentMethods && paymentMethods.length > 0 ? (
          <>
            {paymentMethods.map((item, index) => {
              if (item.paypal !== null) {
                return (
                  <div key={index} className="justify-start items-start gap-4 flex">
                    <div className="relative bg-white rounded-md border border-gray-200 p-2">
                      <img className="" src="/image/icons/paypal.svg" alt="" height={18} width={20} />
                    </div>
                    <div className="space-y-2 w-full">
                      <div className="flex justify-between w-full">
                        <div>
                          <div className="text-slate-700 text-sm font-medium leading-tight">
                            {item?.paypal?.email ?? 'email@paypal.com'}
                          </div>
                          <div className="text-slate-600 text-sm font-normal leading-tight">
                            {item?.paypal?.reference ?? 'Paypal connected'}
                          </div>
                        </div>
                        <Button
                          disabled={spinner}
                          onClick={doUpdatePaymentMethod}
                          className="text-slate-700 text-sm font-semibold leading-tight bg-white border hover:bg-gray-50"
                        >
                          {spinner ? 'Loading...' : 'Change'}
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div key={index} className="justify-start items-start gap-4 flex">
                  <div className="relative bg-white rounded-md border border-gray-200">
                    <img className="" src={`/image/icons/${item.card.type}.svg`} alt="" height={40} width={60} />
                  </div>
                  <div className="space-y-2 w-full">
                    <div className="flex justify-between w-full">
                      <div>
                        <div className="text-slate-700 text-sm font-medium leading-tight">
                          <span className="capitalize">{item.card.type}</span> ending in {item.card.last4}
                        </div>
                        <div className="text-slate-600 text-sm font-normal leading-tight">
                          Expiry {item.card.expiry_month}/{item.card.expiry_year}
                        </div>
                      </div>
                      <Button
                        disabled={spinner}
                        onClick={doUpdatePaymentMethod}
                        className="text-slate-700 text-sm font-semibold leading-tight bg-white border hover:bg-gray-50"
                      >
                        {spinner ? 'Loading...' : 'Change'}
                      </Button>
                    </div>
                    <div className="flex items-center gap-1.5">
                      <img src="/image/icons/cardholder.svg" alt="" className="w-4 h-4 relative" />
                      <div className="text-slate-600 text-sm font-medium leading-tight">
                        {item.card.cardholder_name}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="justify-start items-start gap-4 flex">
            <div className="space-y-2 w-full">
              <div className="flex justify-between w-full">
                <div>
                  <div className="text-slate-700 text-sm font-medium leading-tight">No payment method</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentMethod;
