/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */
import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';

function Toaster({
  ...props
}) {
  const { theme = 'system' } = useTheme();

  return (
    (
      <Sonner
        theme={theme}
        className="toaster group"
        style={{ zIndex: 99999999 }}
        toastOptions={{
          classNames: {
            toast:
            'group toast group-[.toaster]:bg-white group-[.toaster]:text-slate-950 group-[.toaster]:border-slate-200 group-[.toaster]:shadow-lg dark:group-[.toaster]:bg-slate-950 dark:group-[.toaster]:text-slate-50 dark:group-[.toaster]:border-slate-800',
            description: 'group-[.toast]:text-slate-500 dark:group-[.toast]:text-slate-400',
            actionButton:
            'group-[.toast]:bg-slate-900 group-[.toast]:text-slate-50 dark:group-[.toast]:bg-slate-50 dark:group-[.toast]:text-slate-900',
            cancelButton:
            'group-[.toast]:bg-slate-100 group-[.toast]:text-slate-500 dark:group-[.toast]:bg-slate-800 dark:group-[.toast]:text-slate-400',
          },
        }}
        {...props}
      />
    )
  );
}

export { Toaster };
