/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */

import { useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import WhiteBtn from 'components/common/WhiteBtn';
import config from '../../../../../config';
import { cn } from '../../../../../lib/utils';
import EditUrlModal from './edit-url-modal';

const embeddedCode = (url) => `<iframe src="${url}" width="100%" height="600" frameBorder="0" allowfullscreen />`;

function URLEmbed({ className, editDisable = false }) {
  const user = useGlobal('user')[0];
  const [editUrlModal, setEditUrlModal] = useState(false);

  const handleCopyScheduleURL = () => {
    navigator.clipboard
      .writeText(
        `${config.mainUrl}${config.mainUrl.endsWith('/') ? '' : '/'}${user?.workspace?.brandID}/${user?.username}`,
      )
      .then(() => {
        toast.success('URL copied to clipboard', {
          position: 'top-center',
          autoClose: 1000,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCopyEmbed = () => {
    const code = embeddedCode(
      `${config.mainUrl}${config.mainUrl.endsWith('/') ? '' : '/'}${user?.workspace?.brandID}/${user?.username}`,
    );
    navigator.clipboard
      .writeText(code)
      .then(() => {
        toast.success('Code copied to clipboard', {
          position: 'top-center',
          autoClose: 1000,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={cn('bg-[#f2f4f7] rounded-2xl p-6 space-y-4', className)}>
      <div>
        <p className="text-slate-800 text-base font-semibold leading-normal mb-2">Direct Link</p>
        <p className="text-slate-500 text-sm font-normal leading-tight">Use this link to your CTA</p>
      </div>
      {user && (
        <div className="space-y-3">
          <div className="px-[13px] py-3 bg-white rounded-md border border-slate-300">
            <input
              type="text"
              value={`${config.mainUrl}${config.mainUrl.endsWith('/') ? '' : '/'}${user?.workspace?.brandID}/${user?.username}`}
              className="w-full outline-none border-none bg-transparent focus:ring-0 focus:outline-none"
              readOnly
            />
          </div>
          <div className="flex gap-3">
            <WhiteBtn onClick={handleCopyScheduleURL}>Copy</WhiteBtn>
            {!editDisable && <EditUrlModal open={editUrlModal} setOpen={setEditUrlModal} />}
          </div>
        </div>
      )}

      <div>
        <p className="text-slate-800 text-base font-semibold leading-normal mb-2">HTML Embedding</p>
        <p className="text-slate-500 text-sm font-normal leading-tight">Use this code to you website to embed</p>
      </div>

      <div className="space-y-3">
        <div className="px-[13px] py-3 bg-white rounded-md border border-slate-300">
          {/* <div className="self-stretch h-6 text-gray-500 text-base font-normal font-['DM Mono'] leading-normal">https://saybriefly.com/user-name</div> */}
          <textarea
            type="text"
            rows={4}
            value={embeddedCode(
              `${config.mainUrl}${config.mainUrl.endsWith('/') ? '' : '/'}${user?.workspace?.brandID}/${user?.username}`,
            )}
            className="w-full h-auto outline-none border-none bg-transparent focus:ring-0 focus:outline-none resize-none"
            readOnly
          />
        </div>
        <WhiteBtn onClick={handleCopyEmbed}>Copy</WhiteBtn>
      </div>
    </div>
  );
}

export default URLEmbed;
