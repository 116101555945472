/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { marked } from 'marked';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import deleteMeetingHistory from '../../../actions/deleteMeetingHistory';
import {
  AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from '../../../components/ui/alert-dialog';
import {
  DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';

const tabs = [
  {
    name: 'Summary',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path id="description_2" d="M6 13.5H12V12H6V13.5ZM6 10.5H12V9H6V10.5ZM4.5 16.5C4.0875 16.5 3.73438 16.3531 3.44063 16.0594C3.14688 15.7656 3 15.4125 3 15V3C3 2.5875 3.14688 2.23438 3.44063 1.94063C3.73438 1.64688 4.0875 1.5 4.5 1.5H10.5L15 6V15C15 15.4125 14.8531 15.7656 14.5594 16.0594C14.2656 16.3531 13.9125 16.5 13.5 16.5H4.5ZM9.75 6.75V3H4.5V15H13.5V6.75H9.75Z" fill="currentColor" />
      </svg>
    ),
  },
  {
    name: 'Highlights',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path id="format_list_bulleted_2" d="M6.75 14.25V12.75H15.75V14.25H6.75ZM6.75 9.75V8.25H15.75V9.75H6.75ZM6.75 5.25V3.75H15.75V5.25H6.75ZM3.75 15C3.3375 15 2.98438 14.8531 2.69063 14.5594C2.39688 14.2656 2.25 13.9125 2.25 13.5C2.25 13.0875 2.39688 12.7344 2.69063 12.4406C2.98438 12.1469 3.3375 12 3.75 12C4.1625 12 4.51562 12.1469 4.80938 12.4406C5.10313 12.7344 5.25 13.0875 5.25 13.5C5.25 13.9125 5.10313 14.2656 4.80938 14.5594C4.51562 14.8531 4.1625 15 3.75 15ZM3.75 10.5C3.3375 10.5 2.98438 10.3531 2.69063 10.0594C2.39688 9.76563 2.25 9.4125 2.25 9C2.25 8.5875 2.39688 8.23438 2.69063 7.94063C2.98438 7.64687 3.3375 7.5 3.75 7.5C4.1625 7.5 4.51562 7.64687 4.80938 7.94063C5.10313 8.23438 5.25 8.5875 5.25 9C5.25 9.4125 5.10313 9.76563 4.80938 10.0594C4.51562 10.3531 4.1625 10.5 3.75 10.5ZM3.75 6C3.3375 6 2.98438 5.85313 2.69063 5.55938C2.39688 5.26562 2.25 4.9125 2.25 4.5C2.25 4.0875 2.39688 3.73438 2.69063 3.44063C2.98438 3.14688 3.3375 3 3.75 3C4.1625 3 4.51562 3.14688 4.80938 3.44063C5.10313 3.73438 5.25 4.0875 5.25 4.5C5.25 4.9125 5.10313 5.26562 4.80938 5.55938C4.51562 5.85313 4.1625 6 3.75 6Z" fill="currentColor" />
      </svg>
    ),
  },
];

function formatMeetingHighlights(markup) {
  const text = markup.replace(/<br\/>/g, '\n');
  const sections = text.split('---').filter((s) => s.trim());

  let html = '<div class="space-y-8 font-inter">';
  // html += '<h1 class="text-2xl font-semibold text-gray-800 mb-8">Meeting Highlights</h1>';

  sections.forEach((section) => {
    const lines = section.split('\n').filter((line) => line.trim());

    // Only create section if there are valid lines to process
    if (lines.length > 0) {
      let sectionHtml = '<div class="space-y-2">';
      let hasContent = false;

      lines.forEach((line) => {
        if (line.startsWith('### Meeting Chaptering')) {
          return;
        }

        if (line.match(/\*\*Chapter \d+:/)) {
          const title = line
            .replace(/\*\*Chapter \d+:\s*/, '**')
            .replace(/\*\*/g, '');
          sectionHtml += `<h2 class="text-xl font-semibold text-gray-900">${title}</h2>`;
          hasContent = true;
        } else if (line.includes('**Actionable Tasks:**')) {
          sectionHtml += '<h2 class="text-xl font-semibold text-gray-900">Actionable Tasks</h2>';
          hasContent = true;
        } else if (/^\d+\./.test(line)) {
          // For numbered tasks, make the number and title part bold
          const taskText = line.replace(/(\d+\.\s*)([^:]+):/, '<span class="font-semibold">$1$2:</span>');
          sectionHtml += `<div class="ml-4 text-gray-900">${taskText.replaceAll('*', '')}</div>`;
          hasContent = true;
        } else if (line.trim().startsWith('-')) {
          const indentLevel = (line.match(/^\s*/) || [''])[0].length;
          // console.log(indentLevel);
          let bulletText = line.trim().substring(2);
          bulletText = bulletText.replace(/\*\*([^*]+)\*\*/g, '<span class="font-semibold">$1</span>');
          const indentClass = indentLevel === 2 ? 'ml-8 text-gray-700' : 'ml-4 text-gray-900';
          sectionHtml += `<div class="${indentClass}">${indentLevel === 2 ? '•' : ''} ${bulletText}</div>`;
          hasContent = true;
        }
      });

      sectionHtml += '</div>';
      // Only add the section if it has content
      if (hasContent) {
        html += sectionHtml;
      }
    }
  });

  html += '</div>';
  return html;
}
function MeetingHistoryDetails({ meetingData, getData, isLoading }) {
  const [activeTab, setActiveTab] = useState(0);
  const io = useSelector((state) => state.io.io);
  const [showArchiveAlert, setShowArchiveAlert] = useState(false);
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');

  const highlightData = marked(meetingData?.highlight?.highlights?.join(' ') || '');
  const summeryData = marked(meetingData?.summary?.html.replace(/\n/g, '<br/>') || '');

  useEffect(() => {
    if (io) {
      io.on('generated_brief', (data) => {
        if (data.meetingID === meetingData?.meeting?._id) {
          if (getData) {
            getData();
          }
        }
      });

      io.on('transcript_not_found', (data) => {
        if (data.meetingID === meetingData?._id) {
          toast.error('Transcript not found', {
            position: 'top-center',
            autoClose: 5000,
          });
        }
      });
    }
  }, [io]);

  const handleDelete = async () => {
    try {
      await deleteMeetingHistory(meetingData?.meeting?._id);
    } catch (err) {
      console.log(err);
    } finally {
      setRefreshMeetings((prev) => !prev);
    }
  };

  return (
    <div className="px-4 pt-4 pb-[30px] bg-white rounded-2xl border border-[#d0d5dd] space-y-5 overflow-y-scroll scroll-none flex flex-col h-full">
      <div className="flex flex-col lg:flex-row gap-3 justify-between items-center">
        <div className="p-1.5 bg-[#f0f0f0] rounded-full flex items-center gap-1">
          {tabs.map((item, index) => (
            <button
              key={item.name}
              onClick={() => setActiveTab(index)}
              className={`px-3 py-2 flex items-center gap-2 transition-all duration-300 ease-in-out rounded-full ${index === activeTab ? 'bg-white shadow-sm' : 'hover:bg-gray-200'
                }`}
            >
              <div
                className={`transition-colors duration-300 ${index === activeTab ? 'text-[#323333]' : 'text-[#667085]'
                  }`}
              >
                {item.icon}
              </div>
              <div
                className={`text-sm font-semibold leading-tight transition-colors duration-300 ${index === activeTab ? 'text-[#323333]' : 'text-[#667085]'
                  }`}
              >
                {item.name}
              </div>
            </button>
          ))}
        </div>
        <div className="flex items-center gap-2">
          <Link
            to={`/briefs/${meetingData?.meeting._id}`}
            className="px-3 py-2 bg-white rounded-lg shadow border border-[#d0d5dd] text-[#344054] hover:text-[#344054] text-sm font-semibold font-inter leading-tight text-center"
          >
            View Details
          </Link>

          <DropdownMenu>
            <DropdownMenuTrigger>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="dots-vertical">
                  <g id="Icon">
                    <path d="M10.0003 10.833C10.4606 10.833 10.8337 10.4599 10.8337 9.99967C10.8337 9.53944 10.4606 9.16634 10.0003 9.16634C9.54009 9.16634 9.16699 9.53944 9.16699 9.99967C9.16699 10.4599 9.54009 10.833 10.0003 10.833Z" stroke="#101828" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.0003 4.99967C10.4606 4.99967 10.8337 4.62658 10.8337 4.16634C10.8337 3.7061 10.4606 3.33301 10.0003 3.33301C9.54009 3.33301 9.16699 3.7061 9.16699 4.16634C9.16699 4.62658 9.54009 4.99967 10.0003 4.99967Z" stroke="#101828" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.0003 16.6663C10.4606 16.6663 10.8337 16.2932 10.8337 15.833C10.8337 15.3728 10.4606 14.9997 10.0003 14.9997C9.54009 14.9997 9.16699 15.3728 9.16699 15.833C9.16699 16.2932 9.54009 16.6663 10.0003 16.6663Z" stroke="#101828" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </g>
              </svg>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {/* <DropdownMenuSeparator /> */}
              <DropdownMenuItem onClick={() => setShowArchiveAlert(true)}>
                <span className="text-[#101828] text-base font-medium font-inter leading-normal px-1.5">
                  Delete
                </span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="flex-1 max-h-[70vh] overflow-y-auto overflow-x-visible relative">
        {isLoading ? (
          <div className="flex items-center justify-center">
            <span className="size-3 border-4 p-3 rounded-full border-[#173300] animate-spin border-t-[#FFED74]" />
          </div>
        ) : (
          <div className="px-4 relative">
            {tabs[activeTab].name === 'Summary' && (
              <div className="space-y-2">
                {meetingData?.summary ? (
                  <div className="font-inter text-base text-gray-700" dangerouslySetInnerHTML={{ __html: summeryData }} />
                ) : !meetingData?.highlight &&
                  (meetingData?.status === 'ended' || meetingData?.status === 'ended_by_host') ? (
                  'Generating Summary... please wait for a while!'
                ) : (
                  'Summary is currently unavailable!'
                )}
              </div>
            )}
            {tabs[activeTab].name === 'Highlights' && (
              <div className="space-y-2">
                {meetingData?.highlight ? (
                  <div id="highlights-content" className="leading-[2rem]" dangerouslySetInnerHTML={{ __html: highlightData }} />
                ) : !meetingData?.highlight &&
                  (meetingData?.status === 'ended' || meetingData?.status === 'ended_by_host') ? (
                  'Generating Highlights... please wait for a while!'
                ) : (
                  'Highlights are currently unavailable!'
                )}
              </div>
            )}
          </div>
        )}

      </div>

      {
        showArchiveAlert && (
          <AlertDialog open={showArchiveAlert} onOpenChange={setShowArchiveAlert}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete your meeting.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleDelete}>Continue</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )
      }
    </div>
  );
}

export default MeetingHistoryDetails;
