import { initializePaddle } from '@paddle/paddle-js';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import getUserInfo from '../../../actions/getUserInfo';
import { Button } from '../../../components/ui/button';
import Config from '../../../config';
import { PLANS } from '../../../constants/Subscription';

export default function SelectAccountType({
  accountTypes,
  setSelectedAccountType,
  selectedAccountType,
  isLoading,
  setIsLoading,
  setStep,
}) {
  const [user, setUser] = useGlobal('user');
  // const token = useGlobal('token')[0];
  const [paddle, setPaddle] = useState(null);

  const getUser = async () => {
    const { data: userRes } = await getUserInfo();
    if (userRes?.success) {
      setUser(userRes.data);
    }
  };

  // console.log(user, 'user');

  useEffect(() => {
    getUser();
  }, []);

  // If the user has already a subscription (non-free), skip this step.
  useEffect(() => {
    if (user?.activeSubscription && user.activeSubscription.plan !== 'free') {
      setStep(1);
    }
  }, [user?.activeSubscription]);

  // Load Paddle when a non‑free account type is selected.
  useEffect(() => {
    if (selectedAccountType && selectedAccountType !== 'free') {
      initializePaddle({
        environment: Config.paddleEnv,
        token: Config.paddleClientToken,
      })
        .then((paddleInstance) => {
          if (paddleInstance) {
            setPaddle(paddleInstance);
          }
        })
        .catch((err) => console.error('Error initializing Paddle', err));
    }
  }, [selectedAccountType]);

  const handleSelectAccountType = async (e) => {
    e.preventDefault();
    if (!selectedAccountType) {
      toast.error('Please select an account type.');
      return;
    }
    setIsLoading(true);
    try {
      if (selectedAccountType === 'free') {
        // For Free selection, call the new free plan API
        // const { data: res } = await axios.post(`${Config.url}/api/v1/user/switch-to-free-plan`, null, {
        //   headers: { Authorization: `Bearer ${token}` },
        // });
        // if (res.success) {
        //   toast.success(res.message || 'Switched to Free plan successfully.');
        //   const { data: userRes } = await getUserInfo();
        //   if (userRes?.success) {
        //     setUser(userRes.data);
        //   }
        //   // Move to step 2 (as requested) for free selection

        // } else {
        //   toast.error(res.message || 'Failed to switch to Free plan.');
        // }
        setStep(1);
      } else if (selectedAccountType === 'solopreneur' || selectedAccountType === 'small_teams') {
        // For Solo or Team, trigger Paddle checkout
        const plan =
          selectedAccountType === 'solopreneur'
            ? PLANS.find((p) => p.id === 'solopreneur' && p.type === 'monthly')
            : PLANS.find((p) => p.id === 'small_teams' && p.type === 'monthly');
        if (!plan) {
          toast.error('Selected plan not found.');
          return;
        }
        if (!paddle) {
          toast.error('Payment system not initialized. Please try again.');
          return;
        }
        paddle.Checkout.open({
          items: [{ priceId: plan.planId, quantity: 1 }],
          customData: {
            user_sub_id: user?.activeSubscription?._id || '',
          },
          customer: {
            email: user?.email,
            id: user?.activeSubscription?.paddleCustomerId || null,
          },
          settings: {
            successUrl: `${window.location.origin}/onboarding?step=1&message=success`,
          },
        });
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || 'An error occurred during subscription update.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-inner lg:w-[560px] uk-padding-small space-y-6">
      <div className="space-y-6 bg-[#e9eef6] rounded-[10px] w-full p-4">
        <div className="mb-4 w-full space-y-3">
          <h4 className="text-[#101828] text-lg font-semibold font-inter leading-7">Select your Account type</h4>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {accountTypes.map((item, i) => (
            <div key={i} className="cursor-pointer h-full flex flex-col" onClick={() => setSelectedAccountType(item.id)}>
              <div
                style={{ backgroundColor: item.color }}
                className={`px-5 pt-2 rounded-xl flex-col flex-1 justify-start items-center gap-4 flex overflow-hidden`}
              >
                <div className="text-[#344054] text-sm font-medium font-inter leading-tight">{item.text}</div>
                <div className="flex-1 flex justify-center items-center">
                  <img className={i === 0 ? 'h-[90px]' : 'h-[60px]'} src={item.img} alt={item.text} />
                </div>
              </div>
              <div
                className={`mt-2 size-4 bg-white ${selectedAccountType === item.id ? 'border-4' : 'border-2'
                  } rounded-full border-[#db6803]`}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="w-full space-y-6">
        <Button className="w-full" onClick={handleSelectAccountType} disabled={isLoading}>
          {isLoading ? (
            <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
          ) : (
            <span>Get Started</span>
          )}
        </Button>
      </div>
      <p className="text-[#475467] text-xs font-normal">
        By proceeding with the payment, you agree to our <a href="https://www.saybriefly.com/terms-of-use" target="_blank" rel="noopener noreferrer" className='text-[#183401] font-bold'>Terms of Service</a> and <a href="https://www.saybriefly.com/privacy-policy" target="_blank" rel="noopener noreferrer" className='text-[#183401] font-bold'>Privacy Policy</a>.
      </p>
    </div>
  );
}
