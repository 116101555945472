/* eslint-disable indent */
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/ui/select';
import config from '../../../config';
import { cn } from '../../../lib/utils';

function MeetingHostEmailSelector({ setMeetingHostId = () => {}, meetingHostId, className }) {
  const [data, setCalendars] = useState([]);

  const [currentMeetingHostId, setCurrentMeetingHostId] = useState(meetingHostId || '');

  const getCalendars = async () => {
    try {
      const { data: res } = await axios.get(`${config.url || ''}/api/v1/user/back_office/get_calendars`);
      setCalendars(res.data);
      if (res.data?.[0]) {
        if (!meetingHostId) setCurrentMeetingHostId(res.data?.[0]?._id);
        // setMeetingHostId(res.data?.[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentMeetingHostId) {
      setMeetingHostId(currentMeetingHostId);
    }
  }, [currentMeetingHostId]);

  // useEffect(() => {
  //   if (meetingHostId) {
  //     setCurrentMeetingHostId(meetingHostId);
  //   }
  // }, [meetingHostId]);

  useEffect(() => {
    getCalendars();
  }, []);

  return (
    <Select value={currentMeetingHostId} onValueChange={(value) => setCurrentMeetingHostId(value)}>
      <SelectTrigger className={cn('min-w-[150px] max-w-[300px] bg-transparent p-0 py-0 border-none h-auto focus:ring-0 focus:bg-transparent focus:shadow-none focus:border-none ring-offset-0 focus:ring-offset-0', className)}>
        <SelectValue placeholder="Select Meeting Host" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {data.map((item, index) => (
            <SelectItem key={index} value={item._id}>
              {item.calendarEmail}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

export default MeetingHostEmailSelector;
