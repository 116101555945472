import { useQuery } from '@tanstack/react-query';
import { Info } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { fetchDrives, fetchDriveStorage, fetchResources, fetchUsageData } from '../../../actions/apiFunctions';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../../components/ui/tooltip';
import AddProjectSpace from '../components/add-project-space';
import ProjectSpaceNotFound from '../components/project-sapce-not-found';
import SelectDriveModal from '../components/select-drive-modal';
import UsageProgressFileSize from '../components/usage-progress-file';
import Layout from '../layout';

const bytes = (gb) => gb * 2 ** 30;

function ProjectSpace() {
  const [user] = useGlobal('user');
  const [teamSpaces] = useGlobal('teamSpaces');

  // Query for drives
  const { data: drives, isLoading: isDriveLoading, error: driveError } = useQuery({
    queryKey: ['drives'],
    queryFn: fetchDrives,
    onError: (error) => {
      toast.error('Something went wrong', { position: 'top-right' });
      console.log(error);
    },
  });

  // Query for usage data
  const { data: usageData, isLoading: isUsageLoading } = useQuery({
    queryKey: ['usageData'],
    queryFn: fetchUsageData,
    onError: (error) => {
      toast.error('Something went wrong', { position: 'top-right' });
      console.log(error);
    },
  });

  // Query for primary drive storage
  const primaryDrive = drives?.find((drive) => drive?.primary) || drives?.[0];

  const { data: primaryStorage, isLoading: isStorageLoading } = useQuery({
    queryKey: ['driveStorage', primaryDrive?._id],
    queryFn: () => fetchDriveStorage(primaryDrive._id),
    enabled: !!primaryDrive, // Only fetch if primaryDrive exists
    onError: (error) => {
      toast.error('Something went wrong', { position: 'top-right' });
      console.log(error);
    },
  });

  // Query for resources
  const { data: resources, isLoading: isResourcesLoading } = useQuery({
    queryKey: ['resources'],
    queryFn: fetchResources,
    onError: (error) => console.error(error),
  });

  const selectedTeamSpace = teamSpaces?.find((space) => space?._id === user?.workspace?._id);

  const defaultResource = resources?.find((resource) => resource?.default);
  const myResources = resources?.filter(
    (resource) => resource?.default === false && resource?.createdBy === user?._id
  );
  const sharedResources = resources?.filter(
    (resource) => resource?.default === false && resource?.createdBy !== user?._id
  );

  // Combined loading state
  const isLoading = isDriveLoading || isResourcesLoading || isUsageLoading || isStorageLoading;

  return (
    <Layout>
      {isLoading && (
        <div className="flex items-center justify-center">
          <span className="size-3 border-4 p-3 rounded-full border-[#173300] animate-spin border-t-[#FFED74]" />
        </div>
      )}
      {!isLoading && (
        <div className="space-y-6">
          <div className="flex flex-col lg:flex-row justify-between lg:items-center items-stretch gap-6">
            <div>
              <h4 className="text-[#101828] text-lg font-medium font-inter leading-7 flex items-center gap-2 mb-4">
                {selectedTeamSpace?.name} Project Space
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild className="cursor-help">
                    <span>
                      <Info size={16} />
                    </span>
                  </TooltipTrigger>
                  <TooltipContent side="right">
                    <div className="bg-white p-2 px-4 rounded shadow max-w-[200px]">
                      <p>All general meetings are organized here.</p>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </h4>
              {/* Default Resource */}
              {defaultResource && (
                <div>
                  <Link
                    to={`/project-space/${defaultResource?._id}`}
                    className="px-[18px] w-[200px] py-3.5 bg-[#e9eef6] rounded-lg justify-start items-center gap-[25px] inline-flex overflow-hidden"
                  >
                    <span className="min-w-max">
                      <svg width="27" height="21" className="size-6" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.82471" y="1.81641" width="11.1593" height="4.11955" fill="#9EE870" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.4115e-07 4.21162C-1.91278e-05 3.68385 -3.64399e-05 3.22851 0.0360985 2.8535C0.0742384 2.45767 0.158446 2.06398 0.386091 1.68514C0.725703 1.11998 1.26761 0.66048 1.93413 0.372513C2.38091 0.179486 2.84521 0.108084 3.31202 0.0757438C3.7543 0.0451031 4.29127 0.0451186 4.91371 0.0451365L9.6193 0.0450283C10.3202 0.0442436 10.9365 0.0435534 11.5032 0.2093C11.9996 0.354471 12.4517 0.591392 12.8268 0.902917C13.2551 1.25859 13.53 1.72634 13.8426 2.25823L14.8988 4.04975H19.1772C20.1277 4.04974 20.9121 4.04973 21.5511 4.094C22.2148 4.13998 22.825 4.23866 23.398 4.48625C24.2867 4.87021 25.0093 5.48287 25.4621 6.23642C25.7541 6.72235 25.8705 7.23967 25.9247 7.80245C25.9769 8.34429 25.9769 9.00945 25.9769 9.81538V14.3034C25.9769 15.1094 25.9769 15.7745 25.9247 16.3164C25.8705 16.8791 25.7541 17.3965 25.4621 17.8824C25.0093 18.6359 24.2867 19.2486 23.398 19.6326C22.825 19.8801 22.2148 19.9788 21.5511 20.0248C20.9121 20.0691 20.1277 20.0691 19.1772 20.0691H6.79971C5.84923 20.0691 5.06475 20.0691 4.42572 20.0248C3.76201 19.9788 3.15191 19.8801 2.57884 19.6326C1.69014 19.2486 0.967602 18.6359 0.514785 17.8824C0.222793 17.3965 0.106411 16.8791 0.0521844 16.3164C-2.55623e-05 15.7745 -1.35808e-05 15.1094 9.4115e-07 14.3034V4.21162ZM9.47246 2.04755C10.4057 2.04755 10.5907 2.06061 10.7332 2.10227C10.8986 2.15066 11.0493 2.22964 11.1744 2.33348C11.282 2.42287 11.3785 2.55733 11.7959 3.26515L12.2586 4.04975L2.36162 4.04975C2.36214 3.5842 2.36576 3.26596 2.38979 3.01656C2.416 2.74456 2.46048 2.64372 2.49023 2.59422C2.60344 2.40583 2.78407 2.25267 3.00625 2.15668C3.06463 2.13145 3.18355 2.09373 3.50432 2.07151C3.83888 2.04833 4.27845 2.04755 4.95922 2.04755H9.47246Z"
                          fill="#9EE870"
                        />
                        <path d="M0 9.01367H25.9769V16.4184C25.9769 18.5784 24.2259 20.3294 22.0659 20.3294H3.91096C1.751 20.3294 0 18.5784 0 16.4184V9.01367Z" fill="#173300" />
                      </svg>
                    </span>
                    <p className="text-[#101828] text-sm font-medium leading-tight w-full truncate">
                      Default Space
                    </p>
                  </Link>
                </div>
              )}
            </div>

            <div className="flex flex-col lg:items-end gap-6">
              <div className="flex items-center gap-3">
                <SelectDriveModal drives={drives} /> {/* Removed getDrives prop as it's handled by useQuery */}
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <span>
                      <Info size={16} />
                    </span>
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <div className="bg-white p-2 px-4 rounded shadow max-w-[200px]">
                      {drives?.length > 0 ? (
                        <p>We only display your drive storage usage and do not have full access to your drive.</p>
                      ) : (
                        <p>Connect your drive to expand storage beyond SayBriefly's allocation.</p>
                      )}
                    </div>
                  </TooltipContent>
                </Tooltip>
              </div>
              {primaryStorage ? (
                <UsageProgressFileSize value={primaryStorage?.usedSpace || 0} max={primaryStorage?.totalSpace || 0} />
              ) : (
                <UsageProgressFileSize
                  value={usageData?.storage || 0}
                  max={bytes(user?.activeSubscription?.totalStorageGbAllocated) || 0}
                />
              )}
            </div>
          </div>

          <hr className="w-full" />

          <div className="flex justify-between items-center">
            <h4 className="text-[#101828] text-lg font-medium font-inter leading-7 flex gap-2 items-center">
              Custom Spaces
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild className="cursor-help">
                  <span>
                    <Info size={16} />
                  </span>
                </TooltipTrigger>
                <TooltipContent side="right">
                  <div className="bg-white p-2 px-4 rounded shadow max-w-[200px]">
                    <p>Create dedicated areas for organizing project-related content, from initial briefs to final outputs.</p>
                  </div>
                </TooltipContent>
              </Tooltip>
            </h4>
            <AddProjectSpace />
          </div>

          <div className="flex gap-4 items-center w-full flex-wrap">
            {myResources?.length > 0 ? (
              myResources?.filter((r) => !r?.default).map((resource) => (
                <Link
                  key={resource?._id}
                  title={resource?.name}
                  to={`/project-space/${resource?._id}`}
                  className="px-[18px] w-[200px] py-3.5 bg-[#e9eef6] rounded-lg justify-start items-center gap-[25px] inline-flex overflow-hidden"
                >
                  <span className="min-w-max">
                    <svg width="27" height="21" className="size-6" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="1.82471" y="1.81641" width="11.1593" height="4.11955" fill="#a8e5e5" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.4115e-07 4.21162C-1.91278e-05 3.68385 -3.64399e-05 3.22851 0.0360985 2.8535C0.0742384 2.45767 0.158446 2.06398 0.386091 1.68514C0.725703 1.11998 1.26761 0.66048 1.93413 0.372513C2.38091 0.179486 2.84521 0.108084 3.31202 0.0757438C3.7543 0.0451031 4.29127 0.0451186 4.91371 0.0451365L9.6193 0.0450283C10.3202 0.0442436 10.9365 0.0435534 11.5032 0.2093C11.9996 0.354471 12.4517 0.591392 12.8268 0.902917C13.2551 1.25859 13.53 1.72634 13.8426 2.25823L14.8988 4.04975H19.1772C20.1277 4.04974 20.9121 4.04973 21.5511 4.094C22.2148 4.13998 22.825 4.23866 23.398 4.48625C24.2867 4.87021 25.0093 5.48287 25.4621 6.23642C25.7541 6.72235 25.8705 7.23967 25.9247 7.80245C25.9769 8.34429 25.9769 9.00945 25.9769 9.81538V14.3034C25.9769 15.1094 25.9769 15.7745 25.9247 16.3164C25.8705 16.8791 25.7541 17.3965 25.4621 17.8824C25.0093 18.6359 24.2867 19.2486 23.398 19.6326C22.825 19.8801 22.2148 19.9788 21.5511 20.0248C20.9121 20.0691 20.1277 20.0691 19.1772 20.0691H6.79971C5.84923 20.0691 5.06475 20.0691 4.42572 20.0248C3.76201 19.9788 3.15191 19.8801 2.57884 19.6326C1.69014 19.2486 0.967602 18.6359 0.514785 17.8824C0.222793 17.3965 0.106411 16.8791 0.0521844 16.3164C-2.55623e-05 15.7745 -1.35808e-05 15.1094 9.4115e-07 14.3034V4.21162ZM9.47246 2.04755C10.4057 2.04755 10.5907 2.06061 10.7332 2.10227C10.8986 2.15066 11.0493 2.22964 11.1744 2.33348C11.282 2.42287 11.3785 2.55733 11.7959 3.26515L12.2586 4.04975L2.36162 4.04975C2.36214 3.5842 2.36576 3.26596 2.38979 3.01656C2.416 2.74456 2.46048 2.64372 2.49023 2.59422C2.60344 2.40583 2.78407 2.25267 3.00625 2.15668C3.06463 2.13145 3.18355 2.09373 3.50432 2.07151C3.83888 2.04833 4.27845 2.04755 4.95922 2.04755H9.47246Z"
                        fill="#a8e5e5"
                      />
                      <path d="M0 9.01367H25.9769V16.4184C25.9769 18.5784 24.2259 20.3294 22.0659 20.3294H3.91096C1.751 20.3294 0 18.5784 0 16.4184V9.01367Z" fill="#173300" />
                    </svg>
                  </span>
                  <p className="text-[#101828] text-sm font-medium leading-tight w-full truncate">
                    {resource?.name}
                  </p>
                </Link>
              ))
            ) : (
              <ProjectSpaceNotFound />
            )}
          </div>

          {sharedResources?.length > 0 && (
            <>
              <hr className="w-full" />
              <div className="flex justify-between items-center mt-6">
                <h4 className="text-[#101828] text-lg font-medium font-inter leading-7">Shared Spaces</h4>
              </div>
              <div className="flex gap-4 items-center w-full flex-wrap">
                {sharedResources?.filter((r) => !r?.default).map((resource) => (
                  <Link
                    key={resource?._id}
                    title={resource?.name}
                    to={`/project-space/${resource?._id}`}
                    className="px-[18px] w-[200px] py-3.5 bg-[#e9eef6] rounded-lg justify-start items-center gap-[25px] inline-flex overflow-hidden"
                  >
                    <div className="min-w-max">
                      <svg width="27" height="21" className="size-6" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.82471" y="1.81641" width="11.1593" height="4.11955" fill="#a8e5e5" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.4115e-07 4.21162C-1.91278e-05 3.68385 -3.64399e-05 3.22851 0.0360985 2.8535C0.0742384 2.45767 0.158446 2.06398 0.386091 1.68514C0.725703 1.11998 1.26761 0.66048 1.93413 0.372513C2.38091 0.179486 2.84521 0.108084 3.31202 0.0757438C3.7543 0.0451031 4.29127 0.0451186 4.91371 0.0451365L9.6193 0.0450283C10.3202 0.0442436 10.9365 0.0435534 11.5032 0.2093C11.9996 0.354471 12.4517 0.591392 12.8268 0.902917C13.2551 1.25859 13.53 1.72634 13.8426 2.25823L14.8988 4.04975H19.1772C20.1277 4.04974 20.9121 4.04973 21.5511 4.094C22.2148 4.13998 22.825 4.23866 23.398 4.48625C24.2867 4.87021 25.0093 5.48287 25.4621 6.23642C25.7541 6.72235 25.8705 7.23967 25.9247 7.80245C25.9769 8.34429 25.9769 9.00945 25.9769 9.81538V14.3034C25.9769 15.1094 25.9769 15.7745 25.9247 16.3164C25.8705 16.8791 25.7541 17.3965 25.4621 17.8824C25.0093 18.6359 24.2867 19.2486 23.398 19.6326C22.825 19.8801 22.2148 19.9788 21.5511 20.0248C20.9121 20.0691 20.1277 20.0691 19.1772 20.0691H6.79971C5.84923 20.0691 5.06475 20.0691 4.42572 20.0248C3.76201 19.9788 3.15191 19.8801 2.57884 19.6326C1.69014 19.2486 0.967602 18.6359 0.514785 17.8824C0.222793 17.3965 0.106411 16.8791 0.0521844 16.3164C-2.55623e-05 15.7745 -1.35808e-05 15.1094 9.4115e-07 14.3034V4.21162ZM9.47246 2.04755C10.4057 2.04755 10.5907 2.06061 10.7332 2.10227C10.8986 2.15066 11.0493 2.22964 11.1744 2.33348C11.282 2.42287 11.3785 2.55733 11.7959 3.26515L12.2586 4.04975L2.36162 4.04975C2.36214 3.5842 2.36576 3.26596 2.38979 3.01656C2.416 2.74456 2.46048 2.64372 2.49023 2.59422C2.60344 2.40583 2.78407 2.25267 3.00625 2.15668C3.06463 2.13145 3.18355 2.09373 3.50432 2.07151C3.83888 2.04833 4.27845 2.04755 4.95922 2.04755H9.47246Z"
                          fill="#a8e5e5"
                        />
                        <path d="M0 9.01367H25.9769V16.4184C25.9769 18.5784 24.2259 20.3294 22.0659 20.3294H3.91096C1.751 20.3294 0 18.5784 0 16.4184V9.01367Z" fill="#173300" />
                      </svg>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <div className="flex-1">
                        <p className="text-[#101828] text-sm font-medium leading-tight overflow-hidden w-[100px] truncate">
                          {resource?.name}
                        </p>
                      </div>
                      <div className="min-w-max">
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.58317 4.25033C7.58317 5.53899 6.53848 6.58366 5.24984 6.58366C3.96117 6.58366 2.9165 5.53899 2.9165 4.25033C2.9165 2.96166 3.96117 1.91699 5.24984 1.91699C6.53848 1.91699 7.58317 2.96166 7.58317 4.25033Z" stroke="#173300" />
                          <path d="M8.75 6.58366C10.0386 6.58366 11.0833 5.53899 11.0833 4.25033C11.0833 2.96166 10.0386 1.91699 8.75 1.91699" stroke="#173300" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.4165 8.33398H4.08317C2.47234 8.33398 1.1665 9.63983 1.1665 11.2507C1.1665 11.895 1.68884 12.4173 2.33317 12.4173H8.1665C8.81085 12.4173 9.33317 11.895 9.33317 11.2507C9.33317 9.63983 8.02732 8.33398 6.4165 8.33398Z" stroke="#173300" strokeLinejoin="round" />
                          <path d="M9.9165 8.33398C11.5273 8.33398 12.8332 9.63983 12.8332 11.2507C12.8332 11.895 12.3109 12.4173 11.6665 12.4173H10.7915" stroke="#173300" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </Layout>
  );
}

export default ProjectSpace;