/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable indent */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { fetchCalendars, fetchDrives, fetchSchedule, requestCalendarIntegration, requestDriveIntegration, updateSchedule, verifyGoogleCalendarCode, verifyGoogleDriveCode } from '../../../../actions/apiFunctions';
import { Button } from '../../../../components/ui/button';
import { Checkbox } from '../../../../components/ui/checkbox';
import {
  DropdownMenu, DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger,
} from '../../../../components/ui/dropdown-menu';
import { Input } from '../../../../components/ui/input';
import { Label } from '../../../../components/ui/label';
import {
  Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue,
} from '../../../../components/ui/select-schedule';
import { Switch } from '../../../../components/ui/switch';
import {
  convertTimeStringToMinutes,
  convertTo24Hour, generateColorShades
} from '../../../../lib/utils';
import TimezoneSelector from '../../components/timezone-slector';
import CalendarPreview from './calendar-preview';
import CalenderItem from './components/calender-item';
import DriveItem from './components/drive-item';
import PreviewSelector from './components/preview-selector';
import URLEmbed from './components/url-embed';
import BrandColor from './brand-color';

// Utility Functions (unchanged)
function generateTimeIntervals() {
  const intervals = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hour12 = (hour % 12) || 12;
      const hourStr = String(hour12).padStart(2, '0');
      const minuteStr = String(minute).padStart(2, '0');
      const period = hour < 12 ? 'am' : 'pm';
      intervals.push(`${hourStr}:${minuteStr} ${period}`);
    }
  }
  return intervals;
}

const initialDaysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => ({
  day,
  isActive: false,
  startTime: '',
  endTime: '',
}));

export const isPastDate = (date) => {
  const today = new Date();
  const selectedDate = new Date(date);
  today.setHours(0, 0, 0, 0);
  selectedDate.setHours(0, 0, 0, 0);
  return selectedDate < today;
};

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const timeFormat = ['12h', '24h'];

function formatTime(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return hours > 0
    ? `${hours} hour${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`
    : `${minutes} min${minutes > 1 ? 's' : ''}`;
}

function isValidUrl(url) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:';
  } catch (e) {
    return false;
  }
}


function Scheduling() {
  const [daysOfWeek, setDaysOfWeek] = useState(initialDaysOfWeek);
  const [copyDaysOfWeekIndex, setCopyDaysOfWeekIndex] = useState([]);
  const [selectedTimeFormat, setSelectedTimeFormat] = useState(timeFormat[0]);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const user = useGlobal('user')[0];
  const [slot, setSlot] = useState(0);
  const [shortDescription, setShortDescription] = useState('');
  const [mode, setMode] = useState('desktop');
  const [searchParams, setSearchParams] = useSearchParams();
  const { code, scope } = Object.fromEntries(searchParams.entries());
  const [colorShades, setColorShades] = useState(null);
  const [colors, setColors] = useState({
    primaryColor: '',
    secondaryColor: '',
    accentColor: '',
    primaryForegroundColor: '',
  });
  const [selectedTime, setSelectedTime] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [selectedMeetingSource, setSelectedMeetingSource] = useState('google');

  const queryClient = useQueryClient();

  const { data: calendars = [] } = useQuery({
    queryKey: ['calendars'],
    queryFn: fetchCalendars,
    onError: (error) => {
      toast.error('Something went wrong fetching calendars', { position: 'top-right' });
      console.log(error);
    },
  });

  const { data: drives = [] } = useQuery({
    queryKey: ['drives'],
    queryFn: fetchDrives,
    onError: (error) => {
      toast.error('Something went wrong fetching drives', { position: 'top-right' });
      console.log(error);
    },
  });

  const { data: schedule } = useQuery({
    queryKey: ['schedule'],
    queryFn: fetchSchedule,
    onError: (error) => console.log(error),
  });

  useEffect(() => {
    if (schedule) {
      const timeSlot = convertTimeStringToMinutes(schedule.timeSlot);
      const updatedDaysOfWeek = initialDaysOfWeek.map((item) => {
        const weekday = schedule.availableWeekdays.find((w) => w.day === item.day);
        return weekday || item;
      });
      setSlot(timeSlot);
      setDaysOfWeek(updatedDaysOfWeek);
      setSelectedTimezone(schedule?.timeZone);
      setShortDescription(schedule?.shortDescription);
      setRedirectUrl(schedule?.redirectUrl);
    }
  }, [schedule]);


  // Mutations
  const { mutate: verifyCalendar } = useMutation({
    mutationFn: verifyGoogleCalendarCode,
    onSuccess: (data) => {
      if (searchParams.has('code')) {
        searchParams.delete('code');
        searchParams.delete('scope');
        searchParams.delete('authuser');
        searchParams.delete('prompt');
        setSearchParams(searchParams);
      }
      if (data.success) {
        queryClient.invalidateQueries(['calendars']);
        toast.success('Google calendar integrated successfully', { position: 'top-right' });
      } else {
        toast.error('Something went wrong', { position: 'top-right' });
      }
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Something went wrong', { position: 'top-right' });
      console.log(error.response?.data?.message);
    },
  });

  const { mutate: verifyDrive } = useMutation({
    mutationFn: verifyGoogleDriveCode,
    onSuccess: (data) => {
      if (searchParams.has('code')) {
        searchParams.delete('code');
        searchParams.delete('scope');
        searchParams.delete('authuser');
        searchParams.delete('prompt');
        setSearchParams(searchParams);
      }
      if (data.success) {
        queryClient.invalidateQueries(['drives']);
        toast.success('Google drive integrated successfully', { position: 'top-right' });
      } else {
        toast.error('Something went wrong', { position: 'top-right' });
      }
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || 'Something went wrong', { position: 'top-right' });
      // console.log(error.response?.data?.message);
    },
  });

  const { mutate: connectGoogleCalendar } = useMutation({
    mutationFn: requestCalendarIntegration,
    onSuccess: (url) => window.open(url, '_self'),
    onError: (error) => {
      console.log(error);
      if (error?.response?.data?.error) toast.error(`${error.response.data.error}`, { position: 'top-center' });
    },
  });

  const { mutate: connectGoogleDrive } = useMutation({
    mutationFn: requestDriveIntegration,
    onSuccess: (url) => window.open(url, '_self'),
    onError: (error) => console.log(error),
  });

  const { mutate: updateScheduleMutation, isPending: isUpdating } = useMutation({
    mutationFn: updateSchedule,
    onSuccess: () => {
      toast.success('Schedule updated successfully', { position: 'top-right' });
    },
    onError: (error) => {
      console.log(error);
      toast.error('Something went wrong', {
        position: 'top-right',
        description: 'Something went wrong',
      });
    },
  });

  // Handle Google Code Verification with useEffect
  useEffect(() => {
    if (code && scope?.includes('https://www.googleapis.com/auth/drive.file')) {
      verifyDrive(code);
    }
    if (code && scope?.includes('calendar')) {
      verifyCalendar(code);
    }
  }, [code, scope, verifyDrive, verifyCalendar]);

  if (user && !colorShades) {
    const shades = generateColorShades(user?.workspace?.brandColor ?? '#1bcf11').shades;
    setColorShades(shades);

    const indexNumber = shades.findIndex((item) => item.hexcode === user?.workspace?.brandColor);
    const brandShade = indexNumber < 8 ? shades[8] : shades[indexNumber];
    setColors({
      primaryColor: brandShade.hexcode,
      secondaryColor: shades[0].hexcode,
      accentColor: brandShade.hexcode,
      primaryForegroundColor: shades[0].hexcode,
    });

    document.documentElement.style.setProperty('--calenderBackground', shades[0].hexcode);
    document.documentElement.style.setProperty('--calenderPrimary', brandShade.hexcode);
    document.documentElement.style.setProperty('--calenderPrimaryLight', shades[2].hexcode);
    document.documentElement.style.setProperty('--calenderSecondary', shades[0].hexcode);
  }

  const timeIntervals = useMemo(() => generateTimeIntervals(), []);

  const handleUpdateSchedule = () => {
    if (redirectUrl && !isValidUrl(redirectUrl)) {
      toast.error('Redirect URL is not valid!', { position: 'top-right' });
      return;
    }

    updateScheduleMutation({
      availableWeekdays: daysOfWeek,
      timeZone: selectedTimezone.split(' ')[0],
      timeSlot: formatTime(slot),
      shortDescription,
      redirectUrl,
    });
  };

  const handleCopyDaysOfWeek = (selectedDay) => {
    const updatedDaysOfWeek = daysOfWeek.map((day, index) => (
      copyDaysOfWeekIndex.includes(index)
        ? { ...selectedDay, day: day.day }
        : day
    ));
    setDaysOfWeek(updatedDaysOfWeek);
    handleUpdateSchedule();
  };

  const updateDayOfWeek = (index, updates) => {
    setDaysOfWeek((prev) => prev.map((day, i) => (i === index ? { ...day, ...updates } : day)));
  };

  const handleDescriptionChange = (e) => {
    setShortDescription(e.target.value);
  };

  return (
    <div className="space-y-6 py-6" style={{ userSelect: 'none' }}>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-[48px]">
        <div className="space-y-6">
          <div className="space-y-4">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">Calendars</p>
            <div className="flex gap-4 flex-wrap">
              <Button variant="outline" onClick={connectGoogleCalendar} className="px-3 py-2 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border-2 border-[#173300] flex justify-center items-center gap-1 text-[#173300] text-sm font-semibold font-inter leading-tight">
                <img src="/image/icons/plus-circle.svg" className="size-4" alt="" />
                Connect Calendar
              </Button>
              {
                calendars.map((calendar) => (
                  <CalenderItem key={calendar._id} calendar={calendar} />
                ))
              }
            </div>
          </div>
          <div className="space-y-4">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">
              Storage
            </p>
            <div className="flex gap-4 flex-wrap">
              <Button variant="outline" onClick={connectGoogleDrive} className="px-3 py-2 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border-2 border-[#173300] flex justify-center items-center gap-1 text-[#173300] text-sm font-semibold font-inter leading-tight">
                <img src="/image/icons/plus-circle.svg" className="size-4" alt="" />
                Connect Drive
              </Button>
              {
                drives.map((drive) => (
                  <DriveItem key={drive._id} drive={drive} />
                ))
              }
            </div>
          </div>
          <div className="space-y-4">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">Availability</p>
            <div className="bg-[#f2f4f7] p-6 rounded-2xl space-y-6">
              {
                daysOfWeek.map((item, i) => (
                  <div key={i} className="flex flex-col lg:flex-row lg:items-center justify-between gap-6">
                    {/*  */}
                    <div className="flex items-center space-x-2">
                      <Switch
                        id={item.day}
                        checked={item.isActive}
                        onCheckedChange={(val) => updateDayOfWeek(i, { isActive: val })}
                        className="data-[state=checked]:bg-black data-[state=unchecked]:bg-[#00000020]"
                        thumbClassname="bg-white"
                      />
                      <Label htmlFor={item.day} className="cursor-pointer text-[#344054]">{item.day}</Label>
                    </div>

                    <div className={`flex items-center gap-4 ${item.isActive ? 'pointer-events-auto' : 'pointer-events-none opacity-60'}`}>
                      <Select
                        value={item.startTime}
                        onValueChange={(val) => updateDayOfWeek(i, { startTime: val })}
                      >
                        <SelectTrigger className="w-[100px] px-2 bg-white border border-gray-300 text-[#344054] focus:ring-0 focus:shadow-none">
                          <SelectValue placeholder="Select Time" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {timeIntervals.map((item) => <SelectItem key={item} value={convertTo24Hour(item)}>{item}</SelectItem>)}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <span className="text-black text-base font-normal leading-normal">-</span>
                      <Select
                        value={item.endTime}
                        onValueChange={(val) => updateDayOfWeek(i, { endTime: val })}
                      >
                        <SelectTrigger className="w-[100px] px-2 bg-white border border-gray-300 text-[#344054] focus:border-none focus:ring-0 focus:shadow-none">
                          <SelectValue placeholder="Select Time" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {timeIntervals.map((item) => <SelectItem key={item} value={convertTo24Hour(item)}>{item}</SelectItem>)}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <span className="text-black text-base font-normal leading-normal">+</span>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <button>
                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.8335 10.9134V12.7801C4.8335 13.5268 4.8335 13.9002 4.97882 14.1854C5.10665 14.4363 5.31063 14.6403 5.56151 14.7681C5.84672 14.9134 6.22009 14.9134 6.96683 14.9134H12.0335C12.7802 14.9134 13.1536 14.9134 13.4388 14.7681C13.6897 14.6403 13.8937 14.4363 14.0215 14.1854C14.1668 13.9002 14.1668 13.5268 14.1668 12.7801V7.71341C14.1668 6.96667 14.1668 6.59331 14.0215 6.30809C13.8937 6.05721 13.6897 5.85323 13.4388 5.7254C13.1536 5.58008 12.7802 5.58008 12.0335 5.58008H10.1668M2.96683 10.9134H8.0335C8.78023 10.9134 9.1536 10.9134 9.43882 10.7681C9.6897 10.6403 9.89367 10.4363 10.0215 10.1854C10.1668 9.90018 10.1668 9.52682 10.1668 8.78008V3.71341C10.1668 2.96667 10.1668 2.59331 10.0215 2.30809C9.89367 2.05721 9.6897 1.85323 9.43882 1.7254C9.1536 1.58008 8.78023 1.58008 8.0335 1.58008H2.96683C2.22009 1.58008 1.84672 1.58008 1.56151 1.7254C1.31063 1.85323 1.10665 2.05721 0.978821 2.30809C0.833496 2.59331 0.833496 2.96667 0.833496 3.71341V8.78008C0.833496 9.52682 0.833496 9.90018 0.978821 10.1854C1.10665 10.4363 1.31063 10.6403 1.56151 10.7681C1.84672 10.9134 2.22009 10.9134 2.96683 10.9134Z" stroke="black" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-56 p-3 pt-1">
                          <DropdownMenuLabel className="uppercase text-center">Copy times to</DropdownMenuLabel>
                          <DropdownMenuSeparator />
                          {
                            daysOfWeek.map((d, i) => (
                              <label key={i} htmlFor={d.day + i} className="cursor-pointer py-1 flex items-center justify-between w-full">
                                <span>{d.day}</span>
                                {
                                  item.day === d.day
                                    ? <Checkbox checked disabled className="rounded overflow-hidden" />
                                    : (
                                      <Checkbox
                                        id={d.day + i}
                                        onCheckedChange={() => {
                                          setCopyDaysOfWeekIndex((prev) => {
                                            // Use Set to handle duplicates more easily
                                            const newSet = new Set(prev);
                                            if (!newSet.has(i)) {
                                              newSet.add(i);
                                            }
                                            return Array.from(newSet);
                                          });
                                        }}
                                        className="rounded overflow-hidden"
                                      />
                                    )
                                }
                              </label>
                            ))
                          }
                          <DropdownMenuItem className="p-0 hover:bg-transparent">
                            <Button className="w-full mt-3" onClick={() => handleCopyDaysOfWeek(item)}>
                              Apply
                            </Button>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="space-y-4">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">Slot</p>
            <div className="flex justify-start">
              <div className="bg-[#f2f4f7] p-6 rounded-2xl">
                <div className="flex items-center gap-7">
                  <div className="flex items-center gap-2">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="clock">
                        <path id="Icon" d="M12.5 6.24609V12.2461L16.5 14.2461M22.5 12.2461C22.5 17.7689 18.0228 22.2461 12.5 22.2461C6.97715 22.2461 2.5 17.7689 2.5 12.2461C2.5 6.72325 6.97715 2.24609 12.5 2.24609C18.0228 2.24609 22.5 6.72325 22.5 12.2461Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                    <p className="text-black text-base font-medium leading-normal">{formatTime(slot)}</p>
                  </div>

                  <div className="flex gap-2.5">
                    <Button onClick={() => setSlot((prev) => prev + 15)} className="bg-white hover:bg-white shadow border border-[#d0d5dd]">
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="plus">
                          <path id="Icon" d="M10.4998 4.41211V16.0788M4.6665 10.2454H16.3332" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                      </svg>
                    </Button>
                    <Button
                      onClick={() => setSlot((prev) => (prev > 15 ? prev - 15 : prev))}
                      className="bg-white hover:bg-white shadow border border-[#d0d5dd]"
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="minus">
                          <path id="Solid" fillRule="evenodd" clipRule="evenodd" d="M3.8335 10.2454C3.8335 9.78521 4.20659 9.41211 4.66683 9.41211H16.3335C16.7937 9.41211 17.1668 9.78521 17.1668 10.2454C17.1668 10.7057 16.7937 11.0788 16.3335 11.0788H4.66683C4.20659 11.0788 3.8335 10.7057 3.8335 10.2454Z" fill="black" />
                        </g>
                      </svg>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">
              Short Description
            </p>
            <div className="bg-white rounded-lg shadow border border-[#d0d5dd] justify-between items-center inline-flex w-full">
              <textarea
                className="p-3 bg-transparent grow shrink basis-0 text-black text-sm font-normal font-inter leading-tight focus:outline-none w-full"
                cols={50}
                onChange={handleDescriptionChange}
                value={shortDescription}
                placeholder="Short description ensure no crucial information is missed in diverse, multilingual meetings."
              />
            </div>
          </div>
          <div className="space-y-4">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">
              Redirect URL
            </p>
            <div className="bg-white rounded-lg shadow border border-[#d0d5dd] justify-between items-center w-full inline-flex">
              <Input
                className="w-full"
                onChange={(e) => setRedirectUrl(e.target.value)}
                value={redirectUrl}
                placeholder="Enter redirect url"
              />
            </div>
          </div>

          <div className="space-y-4 flex flex-col items-start">
            <p className="text-[#101828] text-sm font-medium leading-[14px]">Timezone</p>
            <div className="px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#d0d5dd] text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center">
              <img className="w-4 h-4" src="/image/icons/world.svg" alt="" />
              <TimezoneSelector setSelectedTimezone={setSelectedTimezone} selectedTimezone={selectedTimezone} />
            </div>
          </div>

          <BrandColor user={user} />

          <Button onClick={handleUpdateSchedule}>
            {isUpdating ? (
              <>
                <span className="animate-spin inline-block w-4 h-4 mr-2 border-2 border-white border-r-transparent rounded-full" />
                <span>Updating...</span>
              </>
            ) : 'Update Schedule'}
          </Button>

        </div>
        <div>
          <URLEmbed />
        </div>
      </div>
      <div>
        <PreviewSelector mode={mode} setMode={setMode} />
      </div>

      {/* user, colorShades, slot, mode, colors, daysOfWeek, selectedTimezone, selectedMeetingSource, selectedTime, setSelectedTime, days, shortDescription, setSelectedMeetingSource */}
      <CalendarPreview
        user={user}
        colorShades={colorShades}
        slot={slot}
        mode={mode}
        colors={colors}
        daysOfWeek={daysOfWeek}
        selectedTimezone={selectedTimezone}
        selectedMeetingSource={selectedMeetingSource}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        days={days}
        shortDescription={shortDescription}
        setSelectedMeetingSource={setSelectedMeetingSource}
        selectedTimeFormat={selectedTimeFormat}
        setSelectedTimeFormat={setSelectedTimeFormat}
      />
    </div>
  );
}

export default Scheduling;
