import axios from 'axios';
import Config from '../config';

const registerWithGoogle = (token, timezone) => {
  return axios({
    method: 'post',
    url: `${Config.url || ''}/api/v1/auth/google-register`,
    data: { id_token: token, timezone },
  });
};

export default registerWithGoogle;
