/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
import { useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import logo from '../../../assets/logo.svg';
import { Button } from '../../../components/ui/button';
import { Switch } from '../../../components/ui/switch';
import './Join.sass';

function Join({ onJoin, meeting }) {
  const [isAudio, setAudio] = useGlobal('audio');
  const [isVideo, setVideo] = useGlobal('video');
  const [audio, setAudioStream] = useGlobal('audioStream');
  const [video, setVideoStream] = useGlobal('videoStream');
  const [isJoinLoading, setJoinLoading] = useState(true);
  const localVideoRef = useRef(null);

  // const getAudio = () => navigator?.mediaDevices?.getUserMedia({ audio: true })?.then((stream) => {
  //   setAudioStream(stream);
  // });

  const getAudio = async () => {
    setJoinLoading(true);
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInput = devices.some((device) => device.kind === 'audioinput');
      if (!audioInput) {
        toast.error('Oops! No microphone detected.', {
          autoClose: 2000, // Closes after 5 seconds
          position: 'top-center',
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: '🎙️',
          description: 'Please check your device settings or plug in a microphone to continue.',
        });

        setTimeout(() => {
          setAudio(false);
        }, 300);
        return;
      }
      const stream = await navigator?.mediaDevices?.getUserMedia({ audio: true });
      setAudioStream(stream);
      setJoinLoading(false);
    } catch (err) {
      console.log(err, 'err');
      setJoinLoading(false);
    }
  };

  const getVideo = async () => {
    setJoinLoading(true);
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInput = devices.some((device) => device.kind === 'videoinput');
      if (videoInput) {
        const stream = await navigator?.mediaDevices?.getUserMedia({ video: true });
        await setVideoStream(stream);
        if (localVideoRef && localVideoRef?.current) {
          localVideoRef.current.srcObject = stream;
        }
      } else {
        toast.error('Oops! No camera detected.', {
          autoClose: 2000, // Closes after 5 seconds
          position: 'top-center',
          hideProgressBar: false, // Show a progress bar
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: '🎥',
          description: 'Please check your device settings or plug in a camera to continue.',
        });

        setTimeout(() => {
          setVideo(false);
        }, 300);
      }
      setJoinLoading(false);
    } catch (err) {
      console.log(err, 'err');
      setJoinLoading(false);
    }
    // navigator?.mediaDevices?.getUserMedia({ video: true })?.then((stream) => {
    //   setVideoStream(stream);
    //   if (localVideoRef && localVideoRef?.current) {
    //     localVideoRef.current.srcObject = stream;
    //   }
    // });
  };

  useEffect(() => {
    if (isVideo) getVideo();
    if (isAudio) getAudio();
  }, []);

  const onChangeAudio = async (e) => {
    try {
      if (e) await getAudio();
      else await audio?.getTracks()?.forEach((track) => track?.stop());
      setAudio(e);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeVideo = async (e) => {
    try {
      if (e) await getVideo();
      else await video?.getTracks()?.forEach((track) => track?.stop());
      setVideo(e);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="lg:w-[532px] w-full p-6 bg-gray-50 rounded-2xl shadow space-y-5">
      <img className="mx-auto" src={logo} width={186} alt="Logo" />
      <div className="flex items-center gap-2">
        <p className="text-[#101828] text-lg font-semibold leading-7">Join Meeting</p>

        <p className="text-base text-[#667085] leading-normal font-normal">
          {meeting?.meeting?.host_allowed?.length > 0
            ? <>
              <span> - </span>
              {meeting?.meeting?.host_allowed?.[0]?.firstName} and {meeting?.meeting?.host_allowed?.length} others is waiting.
            </>
            : ''}
        </p>
      </div>
      <p className="text-base text-[#667085] leading-normal font-normal" hidden={isVideo || isAudio}>Join as spectator.</p>
      <video
        hidden={!isVideo}
        className="w-full max-h-[300px] object-cover rounded-xl border-2 border-black/10"
        ref={localVideoRef}
        onLoadedMetadata={() => localVideoRef.current.play()}
        playsInline
      />
      <p hidden={!isAudio} className="text-base text-[#667085] leading-normal font-normal">
        Speak to test audio.
      </p>
      <div className="flex items-center gap-8">
        <div className="flex items-center gap-3">
          <Switch className={isJoinLoading ? 'opacity-50' : 'opacity-100'} disabled={isJoinLoading} checked={isAudio} onCheckedChange={onChangeAudio} />
          <div className="text-[#344054] text-base font-medium font-['Inter'] leading-normal">Audio</div>
        </div>
        <div className="flex items-center gap-3">
          <Switch className={isJoinLoading ? 'opacity-50' : 'opacity-100'} disabled={isJoinLoading} checked={isVideo} onCheckedChange={onChangeVideo} />
          <div className="text-[#344054] text-base font-medium font-['Inter'] leading-normal">Video</div>
        </div>
      </div>
      <Button disabled={isJoinLoading} className={`px-4 py-2.5 rounded-lg shadow border text-base font-semibold leading-normal ${isJoinLoading ? 'opacity-50' : 'opacity-100'}`} onClick={onJoin}>
        Join
      </Button>
    </div>
  );
}

export default Join;
