/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { Button } from '../../../components/ui/button';
import config from '../../../config';
import URLEmbed from '../../Dashboard/pages/back-office/components/url-embed';

export default function URLEmbedContainer({ isLoading, setIsLoading }) {
  const [user, setUser] = useGlobal('user');
  const navigate = useNavigate();
  const [workspaceType, setWorkspaceType] = useState('solo');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('workspace_type')) {
      if (searchParams.get('workspace_type') === 'team') {
        setWorkspaceType('team');
      }
    }
  }, [searchParams]);

  const handleContinueDashboard = async () => {
    setIsLoading(true);
    try {
      navigate('/?welcome=true');
      await axios.post(`${config.url || ''}/api/v1/user/finish-onboarding`);
      // toast.success(`Welcome to Say Briefly, ${user?.firstName} ${user?.lastName}!`, {
      //   position: 'top-right',
      //   description: 'Your all-in-one solution for meetings, notes, and project management.',
      // });
      // setUser(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-inner lg:w-[550px] uk-padding-small space-y-6">
      <h4 className="text-[#101828] text-left w-full text-lg font-semibold font-inter leading-7">
        Share Your Availability
      </h4>
      <URLEmbed editDisable={workspaceType == 'team'} className="w-full" />
      <div className="flex flex-col gap-4 justify-start w-full">
        <Button className="min-w-[350px]" onClick={handleContinueDashboard} disabled={isLoading}>
          {isLoading ? (
            <span className="h-5 w-5 border-2 border-white rounded-full border-t-transparent animate-spin" />
          ) : (
            <span>Continue to dashboard</span>
          )}
        </Button>
      </div>
    </div>
  );
}
