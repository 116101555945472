import moment from "moment";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../components/ui/dialog";

export default function EventContainer({ open, setOpen, events, setShowDetails, setSelectedMeeting }) {
    // console.log('EventContainer:', events);
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="min-w-[600px] max-w-[800px] p-0 bg-transparent border-none" closeButtonClassName="right-2 top-2">
                <DialogHeader className="hidden">
                    <DialogTitle className="hidden" />
                    <DialogDescription className="hidden" />
                </DialogHeader>
                <div className="bg-[#EAECF0] p-6 rounded-2xl space-y-5 max-h-[700px] overflow-auto scroll-none">
                    <div className="bg-white p-3 py-4 rounded-2xl space-y-3">
                        {
                            events.map((event, index) => (
                                <button
                                    onClick={() => {
                                        setOpen(false);
                                        setSelectedMeeting(event.Id);
                                        setShowDetails(true);
                                    }}
                                    key={index} style={{
                                        backgroundColor: event.CategoryColor,
                                        color: event.TextColor,
                                    }} className="flex items-center gap-4 rounded-lg p-2 w-full">
                                    <span className="text-xs min-w-max">
                                        {
                                            moment(event.StartTime)?.format('hh:mm A ')
                                        }
                                    </span>
                                    <span className="font-semibold text-left w-full overflow-hidden truncate text-sm mr-auto">
                                        {event.Subject}
                                    </span>
                                    <span className="text-xs min-w-max">
                                        {event.Location}
                                    </span>
                                </button>
                            ))
                        }
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
