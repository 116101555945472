/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { Plus, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import {
  Select, SelectContent, SelectGroup, SelectItem,
  SelectTrigger, SelectValue,
} from '../../../components/ui/select';
import { Switch } from '../../../components/ui/switch';
import Config from '../../../config';
import { generateColorShades } from '../../../lib/utils';
import FormFields from '../pages/back-office/components/form-fields';
import PreviewSelector from '../pages/back-office/components/preview-selector';

const initialQuestionModel = {
  question: '',
  required: false,
  fieldType: 'text',
};

function FormBuilderForMeeting({
  formData, date, time, bookingId, callBack,
}) {
  const [user] = useGlobal('user');
  const [questionLists, setQuestionLists] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [mode, setMode] = useState('desktop');
  const [isLoading, setIsLoading] = useState(false);
  const { shades: colorShades } = generateColorShades(user?.workspace?.brandColor || '#05a862');

  useEffect(() => {
    if (formData) {
      const fields = formData?.fields?.map((item) => {
        return {
          _id: item._id,
          question: item.question,
          required: item.required,
          fieldType: item.fieldType,
        };
      });
      setQuestionLists([...fields]);
    }
  }, []);

  const handleFormUpdate = () => {
    setIsLoading(true);
    axios.post(
      `${Config.url || ''}/api/v1/user/book_schedule/save_booking_form`,
      {
        fields: questionLists,
        formId: formData?._id,
        date,
        time,
        bookingId,
      },
    ).then((data) => {
      callBack(formData);
      // toast.success(data.data.message, {
      //   position: 'top-center',
      // });
    }).catch((err) => {
      console.log(err.response);
      toast.error('Something went wrong', {
        position: 'top-center',
        description: 'Something went wrong',
      });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div>
      <PreviewSelector mode={mode} setMode={setMode} />
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-[48px] mt-6">
        <div className="space-y-6">
          {questionLists.map((item, i) => (
            <div key={i} className="space-y-4">
              <div className="flex items-stretch bg-white shadow-md rounded-lg overflow-hidden border">
                <div className="bg-[#DEE2DB] text-black p-4">
                  <p>{i + 1}</p>
                </div>
                <div className="p-4 w-full space-y-8">
                  <div className="flex items-center justify-between">
                    <div className="flex gap-4 items-center">
                      {/*  */}
                      <button>
                        <img src="/image/icons/chat.svg" alt="" />
                      </button>
                      <p className="m-0 text-black text-sm font-semibold leading-tight">
                        {item.question ? item.question : "What's your question ?"}
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        const updatedQuestionLists = [...questionLists];
                        updatedQuestionLists.splice(i, 1);
                        setQuestionLists(updatedQuestionLists);
                        setActiveQuestion(0);
                      }}
                    >
                      <img src="/image/icons/trash.svg" alt="" />
                    </button>
                  </div>
                  <div className="grid grid-cols-3 gap-3">
                    <div className="w-full space-y-2 col-span-2">
                      <Label htmlFor={`question_${i + 1}`}>Question</Label>
                      <Input
                        id={`question_${i + 1}`}
                        onChange={(e) => {
                          const updatedQuestionLists = [...questionLists];
                          updatedQuestionLists[i] = {
                            ...updatedQuestionLists[i],
                            question: e.target.value,
                          };
                          setQuestionLists(updatedQuestionLists);
                        }}
                        value={questionLists[i].question}
                        placeholder="Enter your question here."
                      />
                    </div>
                    <div className="w-full space-y-2 col-span-1">
                      <Label htmlFor={`question_${i + 1}`}>Field Type</Label>
                      <Select
                        onValueChange={(val) => {
                          const updatedQuestionLists = [...questionLists];
                          updatedQuestionLists[i] = {
                            ...updatedQuestionLists[i],
                            fieldType: val,
                          };
                          setQuestionLists(updatedQuestionLists);
                        }}
                        value={questionLists[i].fieldType}
                      >
                        <SelectTrigger className="min-w-max">
                          <SelectValue placeholder="Select a type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value="text">Text</SelectItem>
                            <SelectItem value="number">Number</SelectItem>
                            <SelectItem value="date">Date</SelectItem>
                            <SelectItem value="email">Email</SelectItem>
                            <SelectItem value="phone">Phone</SelectItem>
                            <SelectItem value="textarea">Textarea</SelectItem>
                            <SelectItem value="radio">Radio</SelectItem>
                            <SelectItem value="checkbox">Checkbox</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>

                    {
                      (
                        questionLists[i].fieldType === 'radio' || questionLists[i].fieldType === 'checkbox'
                      )
                      && (
                        <QuestionOptions index={i} questionLists={questionLists} setQuestionLists={setQuestionLists} />
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-6 pl-10">
                <div className="flex items-center space-x-2">
                  <Switch
                    id={`required_${i + 1}`}
                    onCheckedChange={(val) => {
                      const updatedQuestionLists = [...questionLists];
                      updatedQuestionLists[i] = {
                        ...updatedQuestionLists[i],
                        required: val,
                      };
                      setQuestionLists(updatedQuestionLists);
                    }}
                    className="data-[state=checked]:bg-[#000000] data-[state=unchecked]:bg-[#00000020]"
                    thumbClassname="bg-white"
                    // value={questionLists[i].required}
                    checked={questionLists[i].required}
                  />
                  <Label htmlFor={`required_${i + 1}`} className="cursor-pointer">
                    Required
                  </Label>
                </div>
              </div>
            </div>
          ))}

          <div className="flex gap-6 items-center">
            <button
              onClick={() => {
                setQuestionLists((prev) => [...prev, initialQuestionModel]);
              }}
              className="px-3.5 py-2.5 gap-2 bg-white rounded-lg shadow border border-gray-300 flex"
            >
              <img src="/image/icons/plus.svg" alt="Calender" className="w-5 h-5" />
              <span className="px-0.5 text-[#344054] text-sm font-semibold">
                Add Question
              </span>
            </button>
            <Button
              onClick={handleFormUpdate}
              className="px-3.5 py-2 rounded-lg shadow"
            >
              {isLoading ? (
                <>
                  <span className="animate-spin inline-block w-4 h-4 mr-2 border-2 border-white border-r-transparent rounded-full" />
                  <span>Updating...</span>
                </>
              ) : 'Save Form'}
            </Button>
          </div>
        </div>

        <div>
          <div style={{
            backgroundColor: colorShades?.[0]?.hexcode,
            color: colorShades?.[9]?.hexcode,
          }} className="p-8 rounded-2xl flex items-center justify-center">
            {/*  */}
            <div className="bg-white min-w-[400px] rounded-lg overflow-hidden min-h-[400px] shadow-md flex flex-col">
              <div className="flex-grow flex justify-center items-center">
                <FormFields fields={questionLists} setFields={setQuestionLists} className="space-y-6 w-full p-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function QuestionOptions({ index, questionLists, setQuestionLists }) {
  const [newOption, setNewOption] = useState('');

  const options = questionLists[index].options
    ? questionLists[index].options.filter((opt) => opt.trim())
    : [];

  const handleAddOption = () => {
    if (newOption.trim()) {
      const updatedOptions = [...options, newOption.trim()];
      const updatedQuestionLists = [...questionLists];
      updatedQuestionLists[index] = {
        ...updatedQuestionLists[index],
        options: updatedOptions,
      };
      setQuestionLists(updatedQuestionLists);
      setNewOption('');
    }
  };

  const handleRemoveOption = (optionIndex) => {
    const updatedOptions = options.filter((_, idx) => idx !== optionIndex);
    const updatedQuestionLists = [...questionLists];
    updatedQuestionLists[index] = {
      ...updatedQuestionLists[index],
      options: updatedOptions,
    };
    setQuestionLists(updatedQuestionLists);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddOption();
    }
  };

  return (
    <div className="w-full space-y-4 col-span-4">
      <Label htmlFor={`options_${index + 1}`}>Options</Label>

      <div className="flex gap-2">
        <Input
          value={newOption}
          onChange={(e) => setNewOption(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type new option..."
          className="flex-1"
        />
        <Button
          onClick={handleAddOption}
          type="button"
          className="flex items-center gap-2"
        >
          <Plus className="h-4 w-4" />
          Add Option
        </Button>
      </div>

      <div className="space-y-2">
        {options.map((option, optIndex) => (
          <div
            key={optIndex}
            className="flex items-center justify-between px-2 py-1 border bg-gray-100/70 rounded group"
          >
            <span className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">{option.trim()}</span>
            <Button
              onClick={() => handleRemoveOption(optIndex)}
              variant="ghost"
              size="sm"
              className="transition-opacity"
            >
              <X className="h-4 w-4 text-gray-500 hover:text-red-500" />
            </Button>
          </div>
        ))}
      </div>

      {options.length === 0 && (
        <p className="text-sm text-gray-500">No options added yet.</p>
      )}
    </div>
  );
}

export default FormBuilderForMeeting;
