export default function UpcomingNotFound() {
  return (
    <div className="w-full flex flex-col p-4 items-center gap-6 justify-center">
      <img src="/image/upcoming-not-found.png" width={300} alt="" />

      <h4 className="text-center text-black text-2xl font-extrabold font-bricolage tracking-wider">
        No meetings yet, just vibes
        {' '}
        <br />
        {' '}
        (and one chill cat)
      </h4>
    </div>
  );
}
