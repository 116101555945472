/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import { Button } from '../../../components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import Config from '../../../config';
import MeetingHostEmailSelector from './meeting-host-email-selector';

export default function ReInviteBriefly({ open: isOpen, setOpen: setIsOpen, meetingData }) {
  const user = useGlobal('user')[0];

  const { addToast } = useToasts();
  // const [isOpen, setIsOpen] = useState(false);
  const [refreshMeetings, setRefreshMeetings] = useGlobal('refreshMeetings');
  const [loading, setLoading] = useState(false);
  const [meetingSource, setMeetingSource] = useState('');
  const [meetingSourceUrl, setMeetingSourceUrl] = useState(meetingData?.meetingSourceUrl || '');
  const [meetingHostId, setMeetingHostId] = useState('');
  // const [meetingData, setMeetingData] = useState('');


  const handleCreateMeeting = () => {
    if (!meetingSource) {
      addToast('Please select a meeting source', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('title', 'instant meeting');
      formData.append('caller', user?._id);
      formData.append('type', 'instant');
      formData.append('isUpcoming', 'false');
      formData.append('startedAt', new Date());
      formData.append('workspaceID', user?.workspace?.brandID);
      formData.append('meetingSource', meetingSource);
      formData.append('meetingSourceUrl', meetingSourceUrl);
      formData.append('meetingHostId', meetingHostId);

      const config = {
        method: 'post',
        url: `${Config.url || ''}/api/v1/user/create_meeting`,
        data: formData,
      };

      axios.request(config).then((data) => {
        console.log(data?.data?.message);
        // const meeting = data?.data;
        // setMeetingData(meeting);
        setRefreshMeetings(!refreshMeetings);
        setLoading(false);
        setIsOpen(false);
        toast.success('Briefly Invited successfully.', {
          position: 'top-right',
        });
      });
    } catch (err) {
      console.log(err);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (meetingData) {
      // console.log(meetingData?.meetingHostId);
      // setMeetingHostId( || '');
      handleCheckIfZoomOrGoogleSource(meetingSourceUrl);
      setMeetingSourceUrl(meetingData?.meetingSourceUrl || '');
    }
  }, [meetingData]);

  const handleCheckIfZoomOrGoogleSource = (meetingUrl) => {
    if (meetingUrl.includes('zoom.us')) {
      setMeetingSource('zoom');
    } else if (meetingUrl.includes('meet.google.com')) {
      setMeetingSource('google');
    } else {
      setMeetingSource('');
    }
  };

  const handleLinkChange = (e) => {
    setMeetingSourceUrl(e.target.value);
    handleCheckIfZoomOrGoogleSource(e.target.value);
  };
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="sm:max-w-md">
        {loading ? (
          <>
            <DialogTitle className="hidden" />
            <DialogDescription className="hidden" />
            <div className="p-12 flex items-center justify-center">
              <span className="size-4 border-4 p-4 rounded-full border-[#173300] animate-spin border-t-[#FFED74]" />
            </div>
          </>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle>Re-invite Briefly</DialogTitle>
              <DialogDescription>Invite SayBriefly bot to your {meetingSource || ''} meeting.</DialogDescription>
            </DialogHeader>
            <div className="flex items-center space-x-2">
              <div className="grid flex-1 gap-2">
                <Label htmlFor="link" className="sr-only">
                  Link
                </Label>
                <Input
                  id="link"
                  type="text"
                  className="w-full"
                  placeholder="Enter your meeting link"
                  onChange={handleLinkChange}
                  value={meetingSourceUrl}
                />
              </div>
            </div>

            {meetingSource === 'google' && (
              <p className="text-black text-sm font-medium leading-tight m-0 flex gap-2 items-center border bg-white p-2 rounded-lg">
                <img className="w-4 h-4" src="/image/icons/avatar.svg" alt="" />
                <MeetingHostEmailSelector
                  setMeetingHostId={setMeetingHostId}
                  meetingSource={meetingSource}
                  meetingHostId={meetingHostId}
                  className="w-full bg-white max-w-full"
                />
              </p>
            )}
            <DialogFooter className="sm:justify-start">
              <Button type="submit" size="sm" className="px-6" onClick={handleCreateMeeting}>
                Invite
              </Button>
              <DialogClose asChild>
                <Button type="button" variant="secondary">
                  Cancel
                </Button>
              </DialogClose>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
