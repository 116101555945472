/* eslint-disable react/jsx-indent */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-unresolved */
// import moment from 'moment/moment';
import { useGlobal } from 'reactn';
import AvatarGroup from '../../../components/ui/avatar-group';
import Config from '../../../config';
import { getGravatarUrl } from '../../../lib/utils';

export function getHourMinuteDifference(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  const diffMilliseconds = Math.abs(date2 - date1);
  const days = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  return `${minutes} min.`;
}

function MeetingHistoryItem({
  data,
  selectedMeeting = null,
  setSelectedMeeting = () => { },
  maxAvatar = 2,
  borderColor = '#ffed74',
}) {
  // const [user] = useGlobal('user');

  let users = [];
  if (data?.meetingSource == 'saybriefly') {
    users =
      typeof data?.users[0] !== 'string'
        ? data?.users?.map((user) => ({
          src: user.picture
            ? `${Config.url || ''}/${user.picture.location}`
            : getGravatarUrl(`${user.firstName} ${user.lastName}`, user.email),
          fallback: user.firstName?.charAt(0) + user.lastName?.charAt(0),
          alt: '',
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        }))
        : [];
  } else if (data?.temporaryUsers?.length === 0) {
    users = Array.isArray(data?.createdBy)
      ? data?.createdBy?.map((user) => ({
        src: user.picture
          ? `${user.picture.location}`
          : getGravatarUrl(`${user.firstName} ${user.lastName}`, user.email),
        fallback: user.firstName?.charAt(0) + user.lastName?.charAt(0),
        alt: '',
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      }))
      : [
        {
          src: getGravatarUrl(`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`, data?.createdBy?.email),
          fallback: '',
          alt: '',
          name: `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`,
          email: data?.createdBy?.email,
        },
      ];
  } else {
    users =
      data?.temporaryUsers?.map((user) => ({
        src: user.picture
          ? `${user.picture.location}`
          : getGravatarUrl(`${user.name}`, user.email),
        fallback: user.name,
        alt: '',
        name: `${user.name}`,
        email: user.email,
      })) || [];
  }

  const max = maxAvatar || 1;

  const currentUser = useGlobal('user')[0];
  return (
    <div
      onClick={() => setSelectedMeeting(data)}
      className="flex flex-col lg:flex-row py-4 px-6 lg:items-center justify-between gap-2.5 rounded-xl border bg-white cursor-pointer relative w-full overflow-hidden"
      style={{
        borderColor: selectedMeeting?._id === data?._id ? borderColor : 'transparent',
      }}
    >
      <div className="flex flex-col lg:flex-row lg:items-center gap-1">
        {(users?.slice(0, 2)?.length > 1 || data?.temporaryUsers?.length !== 0) && (
          <div className={`${max === 1 ? 'w-[45px]' : 'w-[75px]'}`}>
            <AvatarGroup avatars={users} borderColor={borderColor} max={max} />
          </div>
        )}
        <div className="flex gap-1 items-end">
          {data?.users?.length <= 1 && data?.temporaryUsers?.length === 0 && (
            <div className="flex items-end">
              <div className="flex flex-col">
                <p className="text-[#101828] text-sm font-medium leading-tight whitespace-nowrap capitalize">
                  {data?.title}
                </p>
              </div>
            </div>
          )}
          {(users?.slice(0, 2)?.length > 1 || data?.temporaryUsers?.length !== 0) &&
            users?.slice(0, 2).map((user, index) => (
              <div key={user?.email} className="flex items-end">
                <div className="flex flex-col">
                  <p
                    className="text-[#101828] text-sm font-medium leading-tight whitespace-nowrap 2xl:max-w-[100px] lg:max-w-[80px] truncate capitalize"
                    title={user?.name}
                    id={`user-name-${user?.email}`}
                  >
                    {user?.name}
                  </p>
                  <p
                    className="text-[#475467] text-xs font-normal font-inter leading-[18px] truncate"
                    style={{ maxWidth: document.getElementById(`user-name-${user?._id}`)?.offsetWidth || '100px' }}
                  >
                    {user?.email}
                  </p>
                </div>
                {index !== users.length - 1 && <span>,</span>}
              </div>
            ))}
          {users.length > 2 && (
            <p className="text-[#344054] text-xs font-medium font-inter leading-[18px]">
              <span>
                {users.length - 2} {users.length - 2 === 1 ? 'other' : 'others'}
              </span>
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2.5">
        {
          // (data?.host_allowed?.length === 0 ||
          //   (data?.users?.length === 1 && data?.users[0].email === data?.createdBy[0]?.email) ||
          //   data?.temporaryUsers?.length === 0) &&
          //   ((data?.isUpcoming !== true &&
          //     moment
          //       .tz(data?.createdAt, user?.schedule?.timeZone)
          //       .add(10, 'seconds')
          //       .isBefore(moment().tz(user?.schedule?.timeZone))) ||
          //     (data?.isUpcoming === true &&
          //       moment
          //         .tz(data?.startedAt, user?.schedule?.timeZone)
          //         .add(10, 'seconds')
          //         .isBefore(moment().tz(user?.schedule?.timeZone)))) &&
          parseFloat(data?.callTime * 60, 10)?.toFixed(2) === 0 && (
            <div className="inline-flex items-center gap-1.5 px-2 py-1 bg-[#fecdca50] border border-[#9f040430] rounded-[4px]">
              <span className="text-xs font-medium text-[#9F0404]">Abort</span>
            </div>
          )
        }

        {currentUser?.workspace?._id !== data?.workspace &&
          currentUser?._id !== data?.createdBy?._id &&
          currentUser?.workspace_member?.role === 'owner' &&
          currentUser?.workspace?.default && (
            <div className="inline-flex items-center gap-1.5 px-2 py-1 bg-gray-100 border border-gray-500 rounded-[4px]">
              <span className="text-xs font-medium text-black">Guest</span>
            </div>
          )}
        {
          data?.meetingSource === 'google' &&
          data?.booking?.bookingType === 'instant' &&
          data?.botStatus !== 'error' && (
            <div className="inline-flex items-center gap-1.5 px-2 py-1 bg-[#ecfcf2] border border-[#aaefc6] rounded-[4px]">
              {/* <div className="size-1.5 bg-[#17b169] rounded-full" /> */}
              <span className="text-xs font-medium min-w-max text-[#057647]">Instant</span>
            </div>
          )
        }
        {
          data?.meetingSource === 'google' &&
          data?.booking?.bookingType !== 'instant' &&
          data?.botStatus !== 'error' && (
            <div className="inline-flex items-center gap-1.5 px-2 py-1 bg-[#ecfcf2] border border-[#aaefc6] rounded-[4px]">
              {/* <div className="size-1.5 bg-[#17b169] rounded-full" /> */}
              <span className="text-xs font-medium min-w-max text-[#057647]">
                Scheduled
              </span>
            </div>
          )
        }
        {data?.meetingSource === 'google' &&
          data?.booking?.bookingType === 'instant' &&
          data?.botStatus === 'error' && (
            <div className="inline-flex items-center gap-1.5 px-2 py-1 bg-[#fecdca50] border border-[#9f040430] rounded-[4px]">
              <span className="text-xs font-medium text-[#9F0404]">
                Briefly Rejected
              </span>
            </div>
          )}
        <div className="flex lg:flex-col gap-2 font-inter">
          <h4 className="text-[#475467] text-sm font-normal leading-tight">
            {new Date(data?.startedAt).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
            })}
          </h4>
          <h4 className="text-[#475467] text-sm font-normal leading-tight">
            {data?.callTime ? `${parseFloat(data?.callTime * 60, 10)?.toFixed(2)} min` : '0 min'}
          </h4>
        </div>
      </div>
    </div>
  );
}

export default MeetingHistoryItem;
