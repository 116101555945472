import axios from 'axios';
import { cn } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import config from '../../config';
import ProfileSettingsModal from './components/profile-settings-modal';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar';
import WelcomeModal from './components/welcome-modal';

function Layout({ children, className = 'container' }) {
  const [showSideBar, setShowSideBar] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const welcomeModal = searchParams.get('welcome');

  useEffect(() => {
    const getUser = async () => {
      try {
        setIsLoading(true);
        const {data: res} = await axios.get(`${config.url || ''}/api/v1/user/me`);
        setUser(res.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    if (!user && !isLoading) {
      navigate('/login');
    }
    if (user && !user.isOnboarded) {
      navigate('/onboarding');
    }
  }, [user, welcomeModal]);

  useEffect(() => {
    const feedbackButton = document.getElementById('productlift-widget');
    if (feedbackButton) {
      feedbackButton.style.opacity = '1';
    }

    return () => {
      if (feedbackButton) {
        feedbackButton.style.opacity = '0';
      }
    };
  }, []);

  return (
    <div className="md:flex h-screen overflow-auto">
      <Sidebar showSideBar={showSideBar} />
      <main className="lg:flex-1 flex flex-col bg-[#F9FAFB]">
        <Topbar setShowSideBar={setShowSideBar} showSideBar={showSideBar} />
        <div onClick={() => setShowSideBar(false)} className="md:flex-1 lg:overflow-y-scroll overflow-auto scroll-none py-6 md:py-8 md:p-8">
          <div className={cn(className)}>
            {children}
          </div>

          {/* Show placeholder instead of UI in mobile version */}
          {/* <div className="flex md:hidden bg-gradient-to-b from-transparent to-white flex-col justify-center items-center gap-3 py-10 relative h-[60vh]">
            <img src="/image/sad-heart.svg" alt="mobile-view-not-available" className="w-1/2" />
            <h1>Dashboard is not available for Mobile View</h1>
          </div> */}

        </div>
      </main>

      <ProfileSettingsModal />
      <WelcomeModal />
    </div>
  );
}

export default Layout;
