/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */

import { useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';

import DateTime from '../../../components/date-time';
import MiniScreen from './MiniScreen';
import config from '../../../config';

export default function TopBarTransparent({ localStream, videoStream, video, isScreen, audio }) {
    const localVideoRef = useRef(null);
    const [meeting] = useGlobal('meeting');
    const [videoStreamObject, setVideoStreamObject] = useState(null);

    useEffect(() => {
        if (localStream?.active !== true) return;

        const setVideoStream = async () => {
            const videoStreamObject = await videoStream;
            setVideoStreamObject(videoStreamObject);
            if (!localVideoRef?.current) return;
            if (videoStreamObject?.active && isScreen) {
                localVideoRef.current.srcObject = videoStreamObject;
            } else {
                localVideoRef.current.srcObject = localStream || '';
            }
        };

        setVideoStream();
    }, [localStream]);

    useEffect(() => {
        if (localStream?.active !== true) return;

        const setVideoStream = async () => {
            const videoStreamObject = await videoStream;
            setVideoStreamObject(videoStreamObject);
            if (!localVideoRef?.current) return;
            if (videoStreamObject?.active && isScreen) {
                localVideoRef.current.srcObject = videoStreamObject;
            } else {
                localVideoRef.current.srcObject = localStream || '';
            }
        };

        setVideoStream();
    }, [isScreen]);

    return (
        <div className="meeting-top-controls-transparent relative">
            <div
                className="m-10 mt-14 flex items-center gap-2"
            >
                <div
                    className="px-6 py-3 bg-gray-500 rounded-lg backdrop-blur-lg justify-center items-center gap-2 flex"
                >
                    {meeting?.meeting?.workspace?.brandLogo?.location && (
                        <div className="w-6 h-6">
                            <img src={`${config.url}/${meeting?.meeting?.workspace?.brandLogo?.location}`} alt="" className="w-6 h-6 rounded-full" />
                        </div>
                    )}
                    <div className="text-white text-base font-semibold leading-tight">
                        {meeting?.meeting?.workspace?.name}
                    </div>
                </div>
                <DateTime />
            </div>
            <div className="m-6" style={{ flexGrow: 0, minWidth: video || isScreen ? 137 : 0 }}>
                {
                    (videoStreamObject?.active && !isScreen) ?
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <></>
                        :
                        ((isScreen && !videoStreamObject?.active)) ?
                            <MiniScreen audio={audio} />
                            :
                            <video
                                hidden={!video && !isScreen}
                                className="video rounded-xl w-full h-[9rem]"
                                onLoadedMetadata={() => localVideoRef.current.play()}
                                ref={localVideoRef}
                                style={{ objectFit: 'cover', zIndex: 1000 }}
                                src={localStream}
                                playsInline
                            />
                }
            </div>
        </div>
    );
}
