import jwtDecode from 'jwt-decode';
import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate, Route, BrowserRouter as Router, Routes, useLocation, useParams,
} from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getGlobal, setGlobal, useGlobal } from 'reactn';
import initIO from './actions/initIO';
import setAuthToken from './actions/setAuthToken';
import './App.sass';
import { Toaster } from './components/ui/sonner';
import Config from './config';
import Meeting from './features/Meeting';
import DashboardIndex from './pages/Dashboard/pages';
import BackOfficePage from './pages/Dashboard/pages/back-office';
import URLEmbed from './pages/Dashboard/pages/back-office/components/url-embed';
import FormBuilder from './pages/Dashboard/pages/back-office/form-builder';
import Scheduling from './pages/Dashboard/pages/back-office/scheduling';
import BriefPage from './pages/Dashboard/pages/brief';
import ContactPage from './pages/Dashboard/pages/contact';
import Meetings from './pages/Dashboard/pages/meetings';
import MyBriefs from './pages/Dashboard/pages/my-briefs';
import ProjectSpace from './pages/Dashboard/pages/project-space';
import ProjectSpaceDetails from './pages/Dashboard/pages/project-space-details';
// import SettingsPage from './pages/Dashboard/pages/settings';
import { TooltipProvider } from './components/ui/tooltip';
import Design from './pages/Dashboard/pages/back-office/design';
import BriefPublicPage from './pages/Dashboard/pages/brief-public';
import NotAllowedPage from './pages/Dashboard/pages/not-allowed-guest-page';
import SettingsPage from './pages/Dashboard/pages/settings';
import Team from './pages/Dashboard/pages/settings/team';
import Workspace from './pages/Dashboard/pages/settings/workspace';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Onboarding from './pages/Login/onboarding';
import TourProviderWrapper from './pages/Dashboard/components/tour-provider-wrapper';

function ProtectedRoute({ children, token }) {
  const location = useLocation();
  const searchParams = location.search; // Extract query parameters

  if (!token) {
    return <Navigate to={`/login${searchParams}`} replace />;
  }

  return children;
}

function App() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const io = useSelector((state) => state.io.io);

  const token = useGlobal('token')[0];
  const setStartingPoint = useGlobal('entryPath')[1];

  if (!['dark', 'light'].includes(Config.theme)) Config.theme = 'light';

  useEffect(() => {
    if (!io || !getGlobal().user || !token) return;
    let focusCount = 0;
    const interval = setInterval(() => {
      if (!document.hasFocus()) {
        focusCount++;
        if (focusCount === 10) {
          io.emit('status', { status: 'away' });
        }
      } else if (focusCount !== 0) {
        focusCount = 0;
        io.emit('status', { status: 'online' });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [io, token]);

  useEffect(() => {
    return () => {
      try {
        if (getGlobal()?.audioStream) {
          getGlobal()
            ?.audioStream?.getTracks()
            ?.forEach((track) => track.stop());
        }
        // eslint-disable-next-line no-unused-vars
      } catch (e) { }
      try {
        if (getGlobal()?.videoStream) {
          getGlobal()
            ?.videoStream?.getTracks()
            ?.forEach((track) => track.stop());
        }
        // eslint-disable-next-line no-unused-vars
      } catch (e) { }
    };
  }, []);

  useEffect(() => {
    if (!window.location.hostname === 'meet.saybriefly.com') {
      // console.log(window.location.pathname);

      // Apply overflow styles to required elements
      ['body', 'html', '#root'].forEach((selector) => {
        const element = document.querySelector(selector);
        if (element) element.style.overflowY = 'auto';
      });
    }
  }, []);

  if (!window.loaded) {
    // console.log(window.location.href.replace(window.location.origin, ''));
    setStartingPoint(window.location.href.replace(window.location.origin, ''));
    const splitPath = window.location.pathname.split('/');
    const route = splitPath[1];
    const token = splitPath[2];
    if (route === 'login' && token && token.length > 20) {
      let decoded;
      try {
        decoded = jwtDecode(token);
        if (typeof decoded !== 'object' || typeof decoded.id !== 'string') return;
        setAuthToken(token);
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(decoded));
        setGlobal({
          user: decoded,
          token,
        }).then(() => {
          dispatch(initIO(token));
        });
        // eslint-disable-next-line no-unused-vars
      } catch (e) {
        addToast('Invalid token provided in URL. You can still login manually.', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
    window.loaded = true;
  }

  // Check the domain origin
  const isMeetDomain = window.location.hostname === 'meet.saybriefly.com';

  function RedirectToApp() {
    window.location.href = 'https://app.saybriefly.com';
    return (
      <div className="flex items-center justify-center h-screen w-screen bg-white text-ray-500 text-5xl">
        <p>Redirecting...</p>
      </div>
    );
  }

  function RedirectToLogin() {
    const params = useParams();
    window.location.href = `https://app.saybriefly.com/login?meetId=${params.id}`;
    return (
      <div className="flex items-center justify-center h-screen w-screen bg-white text-ray-500 text-5xl">
        <p>Redirecting...</p>
      </div>
    );
  }
  return (
    <div className="font-inter">
      <Suspense
        fallback={(
          <div className="flex items-center justify-center lg:h-screen w-screen bg-white text-ray-500 text-5xl">
            Loading...
          </div>
        )}
      >
        <TooltipProvider>
          <Router>
            <TourProviderWrapper>
              <Routes>
                {isMeetDomain ? (
                  <>
                    <Route path="/:id" element={token ? <Meeting /> : <RedirectToLogin />} />
                    <Route path="*" element={<RedirectToApp />} />
                  </>
                ) : (
                  <>
                    {/* Public routes */}
                    <Route path="/forgot-password" element={token ? <Navigate to="/" /> : <ForgotPassword />} />
                    <Route path="/login" element={token ? <Navigate to="/" /> : <Login />} />
                    <Route path="/onboarding" element={!token ? <Navigate to="/login" /> : <Onboarding />} />

                    {/* Protected routes */}
                    <Route path="/" element={!token ? <Navigate to="/login" /> : <DashboardIndex />} />
                    <Route path="/briefs" element={!token ? <Navigate to="/login" /> : <MyBriefs />} />
                    <Route path="/briefs/:id" element={!token ? <Navigate to="/login" /> : <BriefPage />} />
                    <Route path="/briefs/:meetingId/:requestId" element={<BriefPublicPage />} />
                    <Route
                      path="/meeting/:id"
                      element={
                        !token ? (
                          <Navigate to="/login" />
                        ) : (
                          <div className="flex items-center justify-center h-screen w-screen bg-white text-gray-800 uppercase tracking-widest font-bricolage animate-pulse text-5xl">
                            <p>Coming Soon!</p>
                          </div>
                        )
                      }
                    />
                    {/* <Route path="/meeting/:id" element={!token ? <Navigate to="/login" /> : <Div100vh><Meeting /></Div100vh>} /> */}
                    <Route path="/contact" element={!token ? <Navigate to="/login" /> : <ContactPage />} />

                    {/* Availability */}
                    <Route path="/calendar/*" element={!token ? <Navigate to="/login" /> : <BackOfficePage />}>
                      <Route index element={<Navigate to="/calendar/scheduling" replace />} />
                      <Route path="scheduling" element={<Scheduling />} />
                      <Route path="form-builder" element={<FormBuilder />} />
                      <Route path="design" element={<Design />} />
                      <Route path="url-embed" element={<URLEmbed />} />
                    </Route>
                    {/* Settings */}
                    <Route
                      path="/settings/*"
                      element={(
                        <ProtectedRoute token={token}>
                          <SettingsPage />
                        </ProtectedRoute>
                      )}
                    >
                      <Route index element={<Navigate to="/settings/workspace" replace />} />
                      {/* <Route path="my-details" element={<MyDetails />} /> */}
                      <Route path="team" element={<Team />} />
                      {/* <Route path="plan" element={<PricingPlan />} />
                    <Route path="billing" element={<BillingPage />} /> */}
                      <Route path="workspace" element={<Workspace />} />
                    </Route>
                    {/* <Route path="/settings" element={!token ? <Navigate to="/login" /> : <SettingsPage />} /> */}

                    {/* Other specific routes */}
                    <Route path="/transcript/:id" element={!token ? <Navigate to="/login" /> : <BriefPage />} />
                    <Route path="/highlights/:id" element={!token ? <Navigate to="/login" /> : <BriefPage />} />
                    <Route path="/project-space" element={!token ? <Navigate to="/login" /> : <ProjectSpace />} />
                    <Route
                      path="/project-space/:id"
                      element={!token ? <Navigate to="/login" /> : <ProjectSpaceDetails />}
                    />
                    <Route path="/meetings" element={!token ? <Navigate to="/login" /> : <Meetings />} />

                    {/* Route for Public Schedule */}
                    {/* <Route path="/:workspaceID/:username" element={<GetSchedule />} /> */}

                    <Route path="/not-allowed" element={<NotAllowedPage />} />

                    {/* Optional: Catch-all route */}
                    <Route path="*" element={<Navigate to="/" />} />
                  </>
                )}
              </Routes>
            </TourProviderWrapper>
          </Router>
        </TooltipProvider>
        <Toaster />
      </Suspense>
    </div>
  );
}

export default App;
