/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGlobal } from 'reactn';
import { toast } from 'sonner';
import getMeetingById from '../../../actions/getMeetingById';
import {
  AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle,
} from '../../../components/ui/alert-dialog';
import {
  DropdownMenu, DropdownMenuContent, DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';
import Config from '../../../config';
import BriefContainer from '../components/brief-container';
import EditProjectSpace from '../components/edit-project-space';
import MeetingHistoryDetails from '../components/meeting-history-details';
import MeetingStats from '../components/meeting-stats';
import MeetingHistory from '../components/meetings-history';
import ViewMeetingDetailsModal from '../components/view-meeting-details-modal';
import Layout from '../layout';

function ProjectSpaceDetails() {
  const { id: resourceId } = useParams();
  const [user] = useGlobal('user');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [resourceDetails, setResourceDetails] = useState(null);

  const isGuest = resourceDetails?.createdBy !== user?._id;

  const [meetings, setMeetings] = useState([]);
  const [briefs, setBriefs] = useState([]);

  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [meetingData, setMeetingData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [isMeetingLoading, setIsMeetingLoading] = useState(false);
  const [value, setValue] = useState([new DateObject()]);

  const [searchMeetings, setSearchMeetings] = useState('');
  const [page, setPage] = useState(1);
  const [refetch, setRefetch] = useState(false);

  const [selectedBrief, setSelectedBrief] = useState(null);

  const [selectedFolder, setSelectedFolder] = useState('meeting-history');

  const [stats, setStats] = useState(null);

  const [showArchiveAlert, setShowArchiveAlert] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    setPage(1);
    setSearchMeetings('');
  }, [selectedFolder]);

  const getResourceData = async () => {
    try {
      const { data } = await axios.get(`${Config.url || ''}/api/v1/user/resource/${resourceId}`);
      if (data?.data) {
        setResourceDetails(data?.data);
        if (!data?.data?.default) {
          setSelectedFolder('meeting-history');
        }
        setRefetch(false);
      } else {
        toast.error('Something went wrong', {
          position: 'top-center',
          description: 'Resource not found!',
        });
        navigate(-1);
      }
    } catch (err) {
      navigate(-1);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (resourceId) {
      getResourceData();
    }
  }, [resourceId]);

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        setIsMeetingLoading(true);
        try {
          const { data } = await axios.get(`${Config.url || ''}/api/v1/user/resource_meetings/${resourceId}?date=${new Date().toLocaleDateString('en-CA')}${searchMeetings ? `&search=${searchMeetings}` : ''}&page=${page}${value[0] && value[1] ? `&from=${value[0].format('MM-DD-YYYY')}&to=${value[1].format('MM-DD-YYYY')}` : ''}`);
          if (data?.success) {
            setMeetings(data?.data);
            setSelectedMeeting(data?.data[0]);
            setRefetch(false);
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsMeetingLoading(false);
        }
      };
      getData();
    }
  }, [page, refetch, selectedFolder, resourceId, searchMeetings, value]);

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        try {
          const { data } = await axios.get(`${Config.url || ''}/api/v1/user/resource_meeting_briefs/${resourceId}`);
          if (data?.success) {
            setBriefs(data?.data);
            setSelectedBrief(data?.data[0]);
            setRefetch(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getData();
    }
  }, [page, refetch, selectedFolder, resourceId]);

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        try {
          const { data } = await axios.get(`${Config.url || ''}/api/v1/user/resource_stats/${resourceId}`);
          if (data?.success) {
            setStats(data.stats);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getData();
    }
  }, [resourceId]);

  useEffect(() => {
    if (selectedMeeting?._id) {
      const getData = async () => {
        const { data: result } = await getMeetingById(selectedMeeting?._id);
        setMeetingData(result.data);
      };

      getData();
    }
  }, [selectedMeeting]);

  const handleArchive = async () => {
    try {
      const url = `${Config.url || ''}/api/v1/user/${isGuest ? 'remove_shared_resource' : 'delete_resource'}/${resourceId}`;
      const { data } = await axios.post(url);

      if (data?.success) {
        toast.success(data?.message, {
          position: 'top-center',
        });
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-full">
          <span className="size-8 border-4 border-t-[#fce657] border-[#173300] rounded-full animate-spin" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex justify-between">
        {/* <BackButton /> */}
        <div className="items-center flex gap-1">
          <Link to="/project-space" className="text-[#475467] hover:text-[#173300] text-sm font-medium font-inter leading-tight">Project space</Link>
          <span>
            /
          </span>
          <p className="text-[#344054] text-sm font-semibold font-inter leading-tight">
            {
              resourceDetails?.default ? 'Default project space' : resourceDetails?.name
            }
          </p>
        </div>
        {
          !resourceDetails?.default && (
            <DropdownMenu>
              <DropdownMenuTrigger>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="dots-vertical">
                    <g id="Icon">
                      <path d="M10.0003 10.833C10.4606 10.833 10.8337 10.4599 10.8337 9.99967C10.8337 9.53944 10.4606 9.16634 10.0003 9.16634C9.54009 9.16634 9.16699 9.53944 9.16699 9.99967C9.16699 10.4599 9.54009 10.833 10.0003 10.833Z" stroke="#101828" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.0003 4.99967C10.4606 4.99967 10.8337 4.62658 10.8337 4.16634C10.8337 3.7061 10.4606 3.33301 10.0003 3.33301C9.54009 3.33301 9.16699 3.7061 9.16699 4.16634C9.16699 4.62658 9.54009 4.99967 10.0003 4.99967Z" stroke="#101828" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.0003 16.6663C10.4606 16.6663 10.8337 16.2932 10.8337 15.833C10.8337 15.3728 10.4606 14.9997 10.0003 14.9997C9.54009 14.9997 9.16699 15.3728 9.16699 15.833C9.16699 16.2932 9.54009 16.6663 10.0003 16.6663Z" stroke="#101828" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                </svg>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {
                  !isGuest && (
                  <DropdownMenuItem onClick={() => setShowEditModal(true)}>
                    {/* Edit */}
                    <span className="text-[#101828] text-base font-medium font-inter leading-normal px-1.5">
                      Edit
                    </span>
                  </DropdownMenuItem>
                  )
                }

                {/* <DropdownMenuSeparator /> */}
                <DropdownMenuItem onClick={() => setShowArchiveAlert(true)}>
                  <span className="text-[#101828] text-base font-medium font-inter leading-normal px-1.5">
                    {
                      isGuest ? 'Leave project' : 'Delete'
                    }
                  </span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )
        }
      </div>

      <div className="space-y-6 pb-6 mt-2">
        <h2 className="text-[#101828] text-2xl font-semibold font-inter leading-loose">
          {resourceDetails?.default ? 'Default Project Space' : resourceDetails?.name}
        </h2>

        <div className="flex lg:flex-row flex-col lg:justify-between lg:items-end gap-6 w-full">
          <div className="space-y-6">
            <div className="flex flex-wrap gap-4">
              {/* <div onClick={() => setSelectedFolder('user')} className="w-[165px]">
                <ProjectCard
                  folderColor={resourceDetails?.default ? '#FFED74' : '#9EE870'}
                  title="User"
                  description=""
                  foldersData={[`Total: ${resourceDetails?.users?.length}`]}
                  folderDataClassName="scale-90 mt-3 -ml-10"
                />
              </div> */}

              <button onClick={() => setSelectedFolder('meeting-history')} className={`px-[18px] w-[200px] py-3.5 bg-[#e9eef6] rounded-lg justify-start items-center gap-[25px] inline-flex overflow-hidden ${selectedFolder === 'meeting-history' ? 'border-2 border-[#a8e5e5]' : ''}`}>
                <svg width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.82471" y="1.81641" width="11.1593" height="4.11955" fill="#a8e5e5" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.4115e-07 4.21162C-1.91278e-05 3.68385 -3.64399e-05 3.22851 0.0360985 2.8535C0.0742384 2.45767 0.158446 2.06398 0.386091 1.68514C0.725703 1.11998 1.26761 0.66048 1.93413 0.372513C2.38091 0.179486 2.84521 0.108084 3.31202 0.0757438C3.7543 0.0451031 4.29127 0.0451186 4.91371 0.0451365L9.6193 0.0450283C10.3202 0.0442436 10.9365 0.0435534 11.5032 0.2093C11.9996 0.354471 12.4517 0.591392 12.8268 0.902917C13.2551 1.25859 13.53 1.72634 13.8426 2.25823L14.8988 4.04975H19.1772C20.1277 4.04974 20.9121 4.04973 21.5511 4.094C22.2148 4.13998 22.825 4.23866 23.398 4.48625C24.2867 4.87021 25.0093 5.48287 25.4621 6.23642C25.7541 6.72235 25.8705 7.23967 25.9247 7.80245C25.9769 8.34429 25.9769 9.00945 25.9769 9.81538V14.3034C25.9769 15.1094 25.9769 15.7745 25.9247 16.3164C25.8705 16.8791 25.7541 17.3965 25.4621 17.8824C25.0093 18.6359 24.2867 19.2486 23.398 19.6326C22.825 19.8801 22.2148 19.9788 21.5511 20.0248C20.9121 20.0691 20.1277 20.0691 19.1772 20.0691H6.79971C5.84923 20.0691 5.06475 20.0691 4.42572 20.0248C3.76201 19.9788 3.15191 19.8801 2.57884 19.6326C1.69014 19.2486 0.967602 18.6359 0.514785 17.8824C0.222793 17.3965 0.106411 16.8791 0.0521844 16.3164C-2.55623e-05 15.7745 -1.35808e-05 15.1094 9.4115e-07 14.3034V4.21162ZM9.47246 2.04755C10.4057 2.04755 10.5907 2.06061 10.7332 2.10227C10.8986 2.15066 11.0493 2.22964 11.1744 2.33348C11.282 2.42287 11.3785 2.55733 11.7959 3.26515L12.2586 4.04975L2.36162 4.04975C2.36214 3.5842 2.36576 3.26596 2.38979 3.01656C2.416 2.74456 2.46048 2.64372 2.49023 2.59422C2.60344 2.40583 2.78407 2.25267 3.00625 2.15668C3.06463 2.13145 3.18355 2.09373 3.50432 2.07151C3.83888 2.04833 4.27845 2.04755 4.95922 2.04755H9.47246Z" fill="#a8e5e5" />
                  <path d="M0 9.01367H25.9769V16.4184C25.9769 18.5784 24.2259 20.3294 22.0659 20.3294H3.91096C1.751 20.3294 0 18.5784 0 16.4184V9.01367Z" fill="#173300" />
                </svg>

                <p className="text-[#101828] text-sm font-medium leading-tight text-left w-full truncate">
                  Meeting History
                </p>
              </button>
              <button onClick={() => setSelectedFolder('briefs')} className={`px-[18px] w-[200px] py-3.5 bg-[#e9eef6] rounded-lg justify-start items-center gap-[25px] inline-flex overflow-hidden ${selectedFolder === 'briefs' ? 'border-2 border-[#a8e5e5]' : ''}`}>
                <svg width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.82471" y="1.81641" width="11.1593" height="4.11955" fill="#a8e5e5" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.4115e-07 4.21162C-1.91278e-05 3.68385 -3.64399e-05 3.22851 0.0360985 2.8535C0.0742384 2.45767 0.158446 2.06398 0.386091 1.68514C0.725703 1.11998 1.26761 0.66048 1.93413 0.372513C2.38091 0.179486 2.84521 0.108084 3.31202 0.0757438C3.7543 0.0451031 4.29127 0.0451186 4.91371 0.0451365L9.6193 0.0450283C10.3202 0.0442436 10.9365 0.0435534 11.5032 0.2093C11.9996 0.354471 12.4517 0.591392 12.8268 0.902917C13.2551 1.25859 13.53 1.72634 13.8426 2.25823L14.8988 4.04975H19.1772C20.1277 4.04974 20.9121 4.04973 21.5511 4.094C22.2148 4.13998 22.825 4.23866 23.398 4.48625C24.2867 4.87021 25.0093 5.48287 25.4621 6.23642C25.7541 6.72235 25.8705 7.23967 25.9247 7.80245C25.9769 8.34429 25.9769 9.00945 25.9769 9.81538V14.3034C25.9769 15.1094 25.9769 15.7745 25.9247 16.3164C25.8705 16.8791 25.7541 17.3965 25.4621 17.8824C25.0093 18.6359 24.2867 19.2486 23.398 19.6326C22.825 19.8801 22.2148 19.9788 21.5511 20.0248C20.9121 20.0691 20.1277 20.0691 19.1772 20.0691H6.79971C5.84923 20.0691 5.06475 20.0691 4.42572 20.0248C3.76201 19.9788 3.15191 19.8801 2.57884 19.6326C1.69014 19.2486 0.967602 18.6359 0.514785 17.8824C0.222793 17.3965 0.106411 16.8791 0.0521844 16.3164C-2.55623e-05 15.7745 -1.35808e-05 15.1094 9.4115e-07 14.3034V4.21162ZM9.47246 2.04755C10.4057 2.04755 10.5907 2.06061 10.7332 2.10227C10.8986 2.15066 11.0493 2.22964 11.1744 2.33348C11.282 2.42287 11.3785 2.55733 11.7959 3.26515L12.2586 4.04975L2.36162 4.04975C2.36214 3.5842 2.36576 3.26596 2.38979 3.01656C2.416 2.74456 2.46048 2.64372 2.49023 2.59422C2.60344 2.40583 2.78407 2.25267 3.00625 2.15668C3.06463 2.13145 3.18355 2.09373 3.50432 2.07151C3.83888 2.04833 4.27845 2.04755 4.95922 2.04755H9.47246Z" fill="#a8e5e5" />
                  <path d="M0 9.01367H25.9769V16.4184C25.9769 18.5784 24.2259 20.3294 22.0659 20.3294H3.91096C1.751 20.3294 0 18.5784 0 16.4184V9.01367Z" fill="#173300" />
                </svg>

                <p className="text-[#101828] text-sm font-medium leading-tight w-full text-left truncate">
                  Briefs
                </p>
              </button>
            </div>
          </div>
          <div>
            {
              stats && !resourceDetails?.default && <MeetingStats data={stats} />
            }
          </div>
        </div>
        {
          selectedFolder === 'meeting-history' && (
            <div className="py-1 bg-[#f2f4f7] rounded-xl space-y-4">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
                <div className="h-full flex flex-col gap-8">
                  <div className="space-y-6 max-h-[450px] lg:max-h-[700px]">
                    <MeetingHistory borderColor={resourceDetails?.default ? '#ffed74' : '#9EE870'} data={meetings} isLoading={isMeetingLoading} page={page} setPage={setPage} value={value} setValue={setValue} selectedMeeting={selectedMeeting} setSelectedMeeting={setSelectedMeeting} search={searchMeetings} setSearch={setSearchMeetings} />
                  </div>
                </div>
                <div className="p-6">
                  {
                    (selectedMeeting && meetingData) && (
                      <MeetingHistoryDetails meetingData={meetingData} setShowDetails={setShowDetails} />
                    )
                  }
                </div>
              </div>
              {
                (selectedMeeting?._id && meetingData) && (
                  <ViewMeetingDetailsModal
                    open={showDetails}
                    setOpen={setShowDetails}
                    meetingData={meetingData}
                    meetingId={selectedMeeting?.meetingCode}
                  />
                )
              }
            </div>
          )
        }

        {
          selectedFolder === 'briefs' && (
            <div className="p-6 bg-[#f2f4f7] rounded-xl space-y-4">
              <BriefContainer
                selectedBrief={selectedBrief}
                setSelectedBrief={setSelectedBrief}
                briefs={briefs}
              />
            </div>
          )
        }

      </div>

      {
        showArchiveAlert && (
          <AlertDialog open={showArchiveAlert} onOpenChange={setShowArchiveAlert}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete your project space.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleArchive}>Continue</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )
      }

      <EditProjectSpace open={showEditModal} setOpen={setShowEditModal} resourceId={resourceId} prevData={resourceDetails} getResourceData={getResourceData} />

    </Layout>
  );
}

function ClientListItem({
  clientAvatar,
  clientName,
  clientRole,
  isSelected,
  onClick,
}) {
  return (
    <div className={`w-full px-6 py-4 cursor-pointer ${isSelected ? 'bg-gray-50' : 'hover:bg-gray-50'} rounded-lg border-b border-[#eaecf0] flex items-center gap-3`} onClick={onClick}>
      <figure className="w-10 h-10 rounded-full flex justify-center items-center">
        <img src={clientAvatar} alt="" className="w-10 h-10 object-cover object-center relative rounded-full border border-black/10" />
      </figure>
      <div className="flex-col">
        <h3 className="text-[#101828] lg:text-base text-sm font-medium font-inter leading-normal capitalize">
          {clientName}
        </h3>
        <p className="text-[#475467] text-xs font-normal font-inter leading-[18px]">
          {clientRole}
        </p>
      </div>
    </div>
  );
}

export default ProjectSpaceDetails;
