/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import { Info } from 'lucide-react';
import { useRef } from 'react';
import DatePicker from 'react-multi-date-picker';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '../../../components/ui/pagination';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../../components/ui/tooltip';
import MeetingHistoryItem from './meeting-history-item';
import MeetingNotFound from './meeting-not-found';
import SearchInput from './search-input';

function MeetingHistory({
  selectedMeeting, setSelectedMeeting, data: historyData, isLoading, value, setValue, page, setPage, search, setSearch, borderColor,
}) {
  const datePicker = useRef(null);

  const handleOpen = () => {
    datePicker.current.querySelector('input').focus();
  };

  return (
    <div className="space-y-4 px-6 py-6 rounded-xl flex flex-col h-full" style={{ background: '#F2F4F7' }}>
      <div className="flex flex-col gap-4">
        <h5 className="text-gray-900 text-lg font-semibold leading-7 flex items-center gap-2">
          Meeting History

          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild className="cursor-help">
              <span>
                <Info size={16} />
              </span>
            </TooltipTrigger>
            <TooltipContent side="right">
              <div className="bg-white p-2 px-4 rounded shadow max-w-[200px]">
                <p>Meetings lasting less than 1 minute will not appear in your meeting history.</p>
              </div>
            </TooltipContent>
          </Tooltip>
        </h5>

        <div className="flex flex-col lg:flex-row justify-between items-center lg:gap-6 gap-3">
          <SearchInput search={search} setSearch={setSearch} />
          <div className="lg:min-w-max w-full px-3.5 py-2.5 gap-2.5 bg-white rounded-lg shadow border border-gray-300 flex">
            <img src="/image/icons/calendar.svg" alt="Calender" className="w-5 h-5" />
            <button onClick={handleOpen} className="px-0.5 text-gray-500 text-sm font-semibold min-w-max">
              {' '}
              {value[0] && value[1]
                ? `${value[0].format('DD/MM/YYYY')} - ${value[1].format('DD/MM/YYYY')}`
                : 'Select Date'}
            </button>
            <DatePicker inputClass="w-0 m-0 p-0" ref={datePicker} value={value} onChange={setValue} rangeHover range />
          </div>
        </div>
      </div>

      <div>
        {isLoading ? (
          <p className="flex items-center gap-2">
            <span className="w-4 h-4 rounded-full border-t-black border-2 border-gray-500 block animate-spin" />
            <span className="animate-pulse">Loading meeting history...</span>
          </p>
        ) : historyData && historyData?.length > 0 ? ( // Safe check for null
          <div className="space-y-4 max-h-[65vh] overflow-auto">
            {historyData?.map((item) => (
              <MeetingHistoryItem
                borderColor={borderColor}
                key={item._id}
                data={item}
                selectedMeeting={selectedMeeting}
                setSelectedMeeting={setSelectedMeeting}
              />
            ))}
          </div>
        ) : (
          <MeetingNotFound />
        )}
      </div>

      {
        ((historyData?.length === 10) || page > 1) && (
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                onClick={() => setPage((prev) => (prev === 1 ? 1 : prev - 1))}
                className={page === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-800'}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink>{page}</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationNext
                onClick={() =>
                  setPage((prev) => (historyData?.length === 0 || historyData?.length < 10 ? prev : prev + 1))
                }
                className={
                  (historyData?.length === 0 && page > 1) || historyData?.length < 10
                    ? 'text-gray-500 cursor-not-allowed'
                    : 'text-gray-800'
                }
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
        )
      }
    </div>
  );
}

export default MeetingHistory;
